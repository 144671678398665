import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  Wrap,
  WrapItem,
  useColorModeValue
} from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { useDispatch, useSelector } from 'react-redux';
import { groupAddOrUpdate, groupClearShowSnackbar } from '../../actions/groupActions';
import { useToast } from '@chakra-ui/react';
import { GROUP_CLEAR_SNACKBAR } from "constants/types";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react';
import { Skeleton, SkeletonCircle, SkeletonText } from '@chakra-ui/react';
import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom';
function GroupViewForm() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("");
  const [adminId, setAdminId] = useState("");
  const dispatch = useDispatch();
  const toast = useToast();
  const history = useHistory();
  const responseStatus = useSelector(state => state.group.status);
  const responseMessage = useSelector(state => state.group.text);
  const loading = useSelector(state => state.group.loading);
  const groupDetail = useSelector(state => state.group.groupDetail);
  const showSnackbar = useSelector(state => state.group.showSnackbar);

  const params = useParams();
  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
        console.log(groupDetail);
        setName(groupDetail.name);
        setDescription(groupDetail.description);
        setStatus(groupDetail.status);
        setAdminId(groupDetail.adminId);
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }

      } else if (responseStatus === 'NOK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }

      }
    }

    dispatch(groupClearShowSnackbar());
  }, [loading]);

  useEffect(() => {
    console.log(params);
    const group= {
      _id : params.id
    };
    dispatch(groupAddOrUpdate(group,'view'));
  }, []);

  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])
  
  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
           Group View 
          </Text>
        </CardHeader>
        <CardBody>
          {loading ? <Stack>
            <Skeleton height='20px' />
            <Skeleton height='20px' />
            <Skeleton height='20px' />
          </Stack>
            :
            <TableContainer>
              <Table variant='simple'>
                <Tbody>
                  <Tr>
                    <Td>Name</Td>
                    <Td>{name}</Td>
                  </Tr>
                  <Tr>
                    <Td>Description</Td>
                    <Td>{description}</Td>
                  </Tr>
                  <Tr>
                    <Td>Status</Td>
                    <Td>{status?'Active' : 'Inactive'}</Td>
                  </Tr>
                  <Tr>
                    <Td>Admin</Td>
                    <Td>{adminId.name}</Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>}
        </CardBody>
      </Card>
    </Flex>
  );
}

export default GroupViewForm;
