import React, { useEffect, useState ,useRef } from 'react';
import { Flex, InputGroup, Spacer, Table, Tbody, Text, Td, Th, Thead, Tr, useColorModeValue, InputLeftElement, IconButton, Input, Icon, Link , Button  } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';

import { useToast } from '@chakra-ui/react';

import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';

import { AddIcon } from '@chakra-ui/icons';
import { CALL_SETTING_CLEAR_SNACKBAR  } from 'constants/types';


import {callSettingGetAll} from 'actions/callSettingAction'
// import CanteenInOutPagination from './CanteenInOutPagination';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import CallSettingTAbleRow from './CallSettingTableRow';
// import CallSettingForm from './CallSettingForm';
import CallSettingForm from "./callSettingForm"
import { Switch } from 'react-router-dom/cjs/react-router-dom';
// import CanteenInOutAdvanceSearch from './CanteenInOutAdvanceSearch';
const CallSettingTable = () => {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const responseStatus = useSelector(state => state.callSetting.status);
  const responseMessage = useSelector(state => state.callSetting.text);
  const loading = useSelector(state => state.callSetting.loading);
  const callSettingList = useSelector(state => state.callSetting.settingList);
  const response = useSelector(state => state.callSetting.response);
  const showSnackbar = useSelector(state => state.callSetting.showSnackbar);
  const currentPage = useSelector(state => state.callSetting.page);
  const limit = useSelector(state => state.callSetting.limit);
  const dispatch = useDispatch();
  const toast = useToast();
  const searchIconColor = useColorModeValue("gray.700", "gray.200");
  const inputBg = useColorModeValue("#edf3f8", "navy.800");
  const [search, setSearch] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const tableRef = useRef(null);
  useEffect(() => {
    dispatch(callSettingGetAll());
  }, [dispatch]);


  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: CALL_SETTING_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("desc");
    }
    const sorting = "&sortOrder=" + sortOrder + "&column=" + column;
    dispatch(callSettingGetAll(sorting));
  };

  useEffect(() => {
    const searching = "&search=" + search;
    dispatch(callSettingGetAll(searching));
  }, [search]);

  return (
    <>
    {/* <CanteenInOutAdvanceSearch></CanteenInOutAdvanceSearch> */}
    <CallSettingForm></CallSettingForm>
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <Flex>
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
            Call setting
            </Text>
          </CardHeader>
          <Spacer />


          <Flex justify="flex-end" style={{ padding: '10px', marginTop: '-5px', marginRight: '12px' }}>
            <DownloadTableExcel
              filename="Canteen Table"
              sheet="users"
              currentTableRef={tableRef.current}
            >
              <Button colorScheme="blue" _hover={{ backgroundColor: 'black', color: 'white' }}>
                Export Excel
              </Button>
            </DownloadTableExcel>
          </Flex>

          {/* <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              <InputGroup borderRadius="8px" w="200px">
                <InputLeftElement
                  children={
                    <IconButton
                      bg="inherit"
                      borderRadius="inherit"
                      _hover="none"
                      _active={{
                        bg: "inherit",
                        transform: "none",
                        borderColor: "transparent",
                      }}
                      _focus={{
                        boxShadow: "none",
                      }}
                      icon={
                        <SearchIcon
                          color={searchIconColor}
                          w="15px"
                          h="15px"
                        />
                      }
                    ></IconButton>
                  }
                />
                <Input
                  variant="search"
                  fontSize="xs"
                  bg={inputBg}
                  onKeyUp={(e) => {
                    setSearch(e.target.value);
                    if (e.target.value.length < 3) {
                      setSearch("");
                    }
                  }}
                  placeholder="Type here..."
                />
              </InputGroup>
            </Text>
          </CardHeader> */}
        </Flex>
        <CardBody pb={4}>
        <div style={{ overflowX: "auto" }}>
          <Table variant="simple" color={textColor}>
            <Thead>
              <Tr my=".8rem" pl="0px" color="gray.400">
              <Th
                    pl="15px"
                    borderColor={borderColor}
                    color="gray.400"
                    cursor="pointer"
                  >
                   SNO
                  </Th>
                <Th
                  pl="20px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("vechilenumber")}
                  cursor="pointer"
                >
                  Modules
                  {sortColumn === "vechilenumber" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("drivername")}
                  cursor="pointer"
                >
              Primary Name
                  {sortColumn === "drivername" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("drivername")}
                  cursor="pointer"
                >
              Primary Number
                  {sortColumn === "drivername" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("intime")}
                  cursor="pointer"
                >
                 Secondary Name
                  {sortColumn === "intime" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("intime")}
                  cursor="pointer"
                >
                 Secondary Number
                  {sortColumn === "intime" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
              </Tr>
            </Thead>
            <Tbody>
            {callSettingList ? (
  callSettingList.map((row , item, index) => {
    var primary = "Not Set";
    var primaryName = "Not Set";
    var secondary = "Not Set";
    var secondaryName = "Not Set";
    if(row.data.length >0)
    {
      primary = row.data[0].phone || row.data[0].primaryNumber;
      primaryName = row.data[0].employeeName || row.data[0].name;
    }
    if(row.data.length >1)
    {
      secondary = row.data[1].phone || row.data[0].secondaryNumber;
      secondaryName = row.data[1].employeeName || row.data[0].name;
    }
    return (
      <CallSettingTAbleRow
        serialnumber={item+1}
        name = {row.name}
        primaryName ={primaryName}
        primary ={primary}
        secondaryName ={secondaryName}
        secondary ={secondary}
        id = {row.name == "High Alert" ? row.data[0]?._id : ""}
      />
    );
  })
) : (<h1></h1>)}

            </Tbody>
          </Table>
        </div>
          {/* {callSettingList.length === 0 ? (
            <></>
          ) : (
            <CanteenInOutPagination
              totalPages={response.totalPages}
              currentPage={response.currentPage}
              perPage={response.perPage}
              sortBy={sortColumn}
              sortOrder={sortOrder}
              handlePageClick={(data) => {
                const selectedPage = data.selected + 1;
                dispatch(CanteenInOutGetAll(`&page=${selectedPage}`));
              }}
            />
          )} */}
        </CardBody>
      </Card>
    </Flex></>
  );
};

export default CallSettingTable;
