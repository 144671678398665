// Import the action type constants for floor
import {
    FLOOR_CLEAR_SNACKBAR,
    FLOOR_LOADING,
    FLOOR_ERROR,
    FLOOR_GET_ALL_SUCCESS,
  } from '../constants/types';
  
  const initialState = {
    response: {},
    floorList: [],
    floorDetail: {},
    loading: false,
    status: 'success',
    text: '',
    showSnackbar: false,
  };
  
  const floorReducer = (state = initialState, action) => {
    switch (action.type) {
      case FLOOR_CLEAR_SNACKBAR:
        return {
          ...state,
          showSnackbar: false,
        };
      case FLOOR_LOADING:
        return {
          ...state,
          loading: true,
        };
      case FLOOR_ERROR:
        return {
          ...state,
          floorList: [],
          response: {},
          floorDetail: {},
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true,
        };
      case FLOOR_GET_ALL_SUCCESS:
        return {
          ...state,
          floorList: action.payload.floorList,
          response: action.payload.response,
          floorDetail: action.payload.floorDetail,
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true,
        };
      default:
        return state || {};
    }
  };
  
  export default floorReducer;
  