import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  SimpleGrid,
  Spacer,
  Text,
  Wrap,
  WrapItem,
  useColorModeValue,
  Spinner,
  Select
} from "@chakra-ui/react";
import dataConstant from "constants/dataConstant";
import { useDispatch, useSelector } from "react-redux";
import { guardWorkEmpAddOrUpdate } from "../../actions/howItWorkAction";
import { checkpostGetAll } from "actions/checkpostActions";
import { deviceGetAll } from "actions/devicesAction";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader";
import { useToast } from '@chakra-ui/react'
import { howItWorkGetAll } from "../../actions/howItWorkCategoryAction"
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
} from '@chakra-ui/react'
import Relect from 'react-select';
import axios from "axios";
import { server_url } from "constants/types";
import { GUARD_WORKEMP_CLEAR_SNACKBAR } from "constants/types";
import CustomInput from "views/CustomValidation";
import { useHistory } from 'react-router-dom';


function HowItWorksForm() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [photo, setPhoto] = useState("");
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [image, setImage] = useState("");
  const [video, setVideo] = useState("");
  const [thumbNail, setThNail] = useState("");
  
  const [guardWorkEmpId, setGuardId] = useState("");
  const [checkpost, setCheckpost] = useState([]);
  const [homeModule, setHomeModule] = useState([]);
  const [bloodGroup, setBloodGroup] = useState("");
  // const [checkpostList, setCheckpostList] = useState([]);
  const [line1, setLine1] = useState("");
  const [line2, setLine2] = useState("");
  const [status, setStatus] = useState('');
  const [description, setDescription] = useState('');
  const [postalCode, setPostalCode] = useState("");
  const [bannerType, setType] = useState("");
  const [type, setPopType] = useState("");
  const [locality, setLocality] = useState("");
  const [state, setState] = useState("");
  const [guardWorkEmpDeviceNo, setGuardDeviceNo] = useState([]);
  const [isErrorStatus, setIsErrorStatus] = useState(false);
  const [isErrorPopType, setIsErrorPopType] = useState(false);
  const [isErrorBloodGroup, setIsErrorBloodGroup] = useState(false);
  const [bloodGroupId, setBloodGroupId] = useState(false);
  const [isErrorDescription, setIsErrorDescription] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isErrorCategory, setIsErrorCategory] = useState(false);
  const [isErrorImage, setIsErrorImage] = useState(false);
  const [isErrorVideo, setIsErrorVideo] = useState(false);
  const [isErrorType, setIsErrorType] = useState(false);
  const [isErrorThNail, setIsErrorThNail] = useState(false);

  
  const textRegex = /^[A-Za-z\s,0-9]+$/;
  const guardWorkEmpIdRegex = /^[a-zA-Z0-9]+$/;
  const postalCodeRegex = /^[0-9]{6}$/;
  const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,10}$/;
  const numberRegex = /^\d{10}$/;
  const [departmentList, setDepartmentList] = useState('')
  const [department, setDepartment] = useState("");
  const [designation, setDesignation] = useState("");
  const [subdepartment, setSubdepartment] = useState("");
  const [category, setCategory] = useState("");
  const [subdepartmentList, setSubdepartmentList] = useState([])
  const [designationList, setDesignationList] = useState([])

  const [isErrorName, setIsErrorName] = useState(false);
  const [isErrorPhoto, setIsErrorPhoto] = useState(false);
  const [isErrorNumber, setIsErrorNumber] = useState(false);
  const [isErrorEmail, setIsErrorEmail] = useState(false);
  const [isErrorGuardId, setIsErrorGuardId] = useState(false);
  const [isErrorCheckpost, setIsErrorCheckpost] = useState(false);
  const [isErrorPostalcode, setIsErrorPostalcode] = useState(false);
  const [isErrorLine1, setIsErrorLine1] = useState(false);
  const [isErrorLine2, setIsErrorLine2] = useState(false);
  const [isErrorLocality, setIsErrorLocality] = useState(false);
  const [isErrorState, setIsErrorState] = useState(false);
  const [isErrorGuardDeviceNo, setIsErrorGuardDeviceNo] = useState(false);
  const [isErrorHomeModule, setIsErrorHomeModule] = useState(false);

  const statuses = ['success', 'error', 'warning', 'info'];

  const responseStatus = useSelector(state => state.HowItWork.status);
  const responseMessage = useSelector(state => state.HowItWork.text);
  const loading = useSelector(state => state.HowItWork.loading);
  const showSnackbar = useSelector(state => state.HowItWork.showSnackbar);
  const checkpostList = useSelector(state => state.checkpost.checkpostList);
  const deviceList = useSelector(state => state.device.deviceList);
  const howItWorkList = useSelector(state => state.HowItWorkCategory.howItWorkList);

  const [isErrorDepartment, setIsErrorDepartment] = useState(false);
  const [isErrorDesignation, setIsErrorDesignation] = useState(false);
  const [isErrorSubdepartment, setIsErrorSubdepartment] = useState(false);

  const dispatch = useDispatch();
  console.log("howItWorkList");
  console.log(howItWorkList);

  useEffect(()=>{
    dispatch(howItWorkGetAll())
  }, [dispatch])

  console.log(loading ? "yes" : "no")

  const history = useHistory()
  const toast = useToast();

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);

    setIsErrorName(name === '');
    setIsErrorDescription(description === '');
    setIsErrorStatus(status === '');
    setIsErrorCategory(category === "");
    // setIsErrorImage(image === '');
    setIsErrorVideo(video === '');
    setIsErrorVideo(thumbNail === '');
    setIsErrorType(bannerType === '');
    setIsErrorPopType(type === "")



    if (
      name !== "" && status !== ''&& thumbNail !== "" && type !== "" && bannerType !== "" && description !== '' && category !== "" && video !== ""
      // && image !== ""

    ) {

      const newGuard = {
        name: name,
        description: description,
        status: status,
        howItWorkCategory: category,
        file1: video,
        file2: thumbNail,
        createdFor: bannerType,
        type: type,

       

      };
      console.log("newGuard")
      console.log(newGuard)
      dispatch(guardWorkEmpAddOrUpdate(newGuard, 'add'));


    }
  };




  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
          history.push('#/admin/gaurd-table');
        }
      } else if (responseStatus === 'NOK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: GUARD_WORKEMP_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])



  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <form onSubmit={handleSubmit}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">

          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              How It Work Form
            </Text>
            <SimpleGrid minChildWidth='120px' spacing='40px' mb='20px'>

              <FormControl isInvalid={isErrorName || (submitted && name === "")}>
                <CustomInput
                  type='text'
                  label='Name'
                  value={name}
                  onChange={(value) => {
                    setIsErrorName(value === '' || !textRegex.test(value));
                    setName(value);
                  }}
                  isInvalid={isErrorName || (submitted && name === "")}
                  regex={textRegex}
                  submitted={submitted}
                />
              </FormControl>

              <FormControl isInvalid={isErrorDescription}>
                <FormLabel>Description</FormLabel>
                <Input
                  type='text'
                  onChange={(e) => {
                    setIsErrorDescription(e.target.value === '');
                    setDescription(e.target.value);
                  }}
                />
                {!isErrorDescription ? (
                  <></>
                ) : (
                  <FormErrorMessage>Description is required.</FormErrorMessage>
                )}
              </FormControl>

            </SimpleGrid>

            <SimpleGrid minChildWidth='120px' spacing='40px' mb='20px'>

              <FormControl isInvalid={isErrorStatus}>
                <FormLabel>Status</FormLabel>
                <Select
                  placeholder="Select status"
                  onChange={(e) => {
                    setIsErrorStatus(e.target.value === '');
                    setStatus(e.target.value);
                  }}
                >
                  {dataConstant.status.map((element, index) => {
                    return (<option key={element['id']} value={element['value']} >{element['name']}</option>);
                  })}
                </Select>
                {!isErrorStatus ? (
                  <></>
                ) : (
                  <FormErrorMessage>Status is required.</FormErrorMessage>
                )}
              </FormControl>

              <FormControl isInvalid={isErrorCategory}>
                <FormLabel>Category</FormLabel>
                <Select
                  placeholder="Select Category"
                  onChange={(e) => {
                    setIsErrorCategory(e.target.value === '')
                    setCategory(e.target.value)
                  }}
                >
                  {howItWorkList.map((element, index) => {
                    return (<option key={element['_id']} value={element['_id']} >{element['name']}</option>);

                  })}
                </Select>
                {!isErrorCategory ? (
                  <></>
                ) : (
                  <FormErrorMessage>Category is required.</FormErrorMessage>
                )}
              </FormControl>
            </SimpleGrid>

            <SimpleGrid minChildWidth='120px' spacing='40px' mb='20px'>

              <FormControl isInvalid={isErrorVideo}>
                <FormLabel>Video</FormLabel>
                <Input
                  type='file'
                  onChange={(e) => {
                    console.log(e.target.files);
                    console.log(e.target.value);
                    setIsErrorVideo(e.target.value === '')
                    setVideo(e.target.files[0])
                  }}
                />
                {!isErrorVideo ? (
                  <></>
                ) : (
                  <FormErrorMessage>Video is required.</FormErrorMessage>
                )}
              </FormControl>

              <FormControl isInvalid={isErrorThNail}>
                <FormLabel>Thumbnail</FormLabel>
                <Input
                  type='file'
                  onChange={(e) => {
                    console.log(e.target.files);
                    console.log(e.target.value);
                    setIsErrorThNail(e.target.value === '')
                    setThNail(e.target.files[0])
                  }}
                />
                {!isErrorThNail ? (
                  <></>
                ) : (
                  <FormErrorMessage>Thumbnail is required.</FormErrorMessage>
                )}
              </FormControl>



              {/* <FormControl isInvalid={isErrorImage}>
              <FormLabel>Thumbnail</FormLabel>
              <Input
                type='file'
                onChange={(e) => {
                  console.log(e.target.files);
                  console.log(e.target.value);
                  setIsErrorImage(e.target.value === '')
                  setImage(e.target.files[0])
                }}
              />
              {!isErrorImage ? (
                <></>
              ) : (
                <FormErrorMessage>Thumbnail is required.</FormErrorMessage>
              )}
            </FormControl> */}

            </SimpleGrid>


            <SimpleGrid minChildWidth='120px' spacing='40px' mb='20px'>
            <FormControl isInvalid={isErrorType}>
                <FormLabel>Created By</FormLabel>
                <Select
                  placeholder="Created By"
                  onChange={(e) => {
                    setIsErrorType(e.target.value === '')
                    setType(e.target.value)
                  }}
                >
                  { dataConstant.bannerType.map((element,index) => {
                      return (<option key={element['name']} value={element['value']} >{element['name']}</option>);   
                        
                    })}
                </Select>
                {!isErrorType ? (
                  <></>
                ) : (
                  <FormErrorMessage>Created By is required.</FormErrorMessage>
                )}
              </FormControl>

              <FormControl isInvalid={isErrorPopType}>
                <FormLabel>Type</FormLabel>
                <Select
                  placeholder="Type"
                  onChange={(e) => {
                    setIsErrorPopType(e.target.value === '')
                    setPopType(e.target.value)
                  }}
                >
                  { dataConstant.type.map((element,index) => {
                      return (<option key={element['id']} value={element['name']} >{element['name']}</option>);   
                        
                    })}
                </Select>
                {!isErrorPopType ? (
                  <></>
                ) : (
                  <FormErrorMessage>Type is required.</FormErrorMessage>
                )}
              </FormControl>
            </SimpleGrid>

            <Center>
              {loading ? <Spinner mt='24px' size='lg' /> :
                <Button colorScheme='blue' mt='24px' type="submit">
                  Submit
                </Button>
              }
            </Center>
          </CardHeader>
          <CardBody>
            {/* ... */}
          </CardBody>
        </Card>
      </form>
    </Flex>
  );
}

export default HowItWorksForm;
