import axios from "axios";
import {
  MATERIAL_GATE_PASS_LOADING,
  MATERIAL_GATE_PASS_GET_ALL_SUCCESS,
  MATERIAL_GATE_PASS_ERROR,
  MATERIAL_GATE_PASS_CLEAR_SNACKBAR,
} from "../constants/types";

import { server_url } from "../constants/types";

export const materialGatePassGetAll = (pagination = "" ) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: MATERIAL_GATE_PASS_LOADING,
        payload: {
          loading: true,
        },
      });
      axios
        .get(`${server_url}/materialGatePass?${pagination}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("authToken"),
          },
        })
        .then((response) => {
          dispatch({
            type: MATERIAL_GATE_PASS_GET_ALL_SUCCESS,
            payload: {
              materialGatePassList: response.data.data,
              limit: response.data.limit,
              page: response.data.currentPage,
              totalPages: response.data.totalPages,
              response: response.data,
              logs: [],
              material: [],
              materialGatePass: [],
              loading: false,
              status: 'success',
              text: "Get all banner position successfully",
            },
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: MATERIAL_GATE_PASS_ERROR,
            payload: {
              status: "error",
              text: "Error occurred during getting materialGatePass data.",
              loading: false,
            },
          });
          resolve();
        });
    });
  };
};


export const materialGatePassAddOrUpdate = (materialGatePass, state ,page='' , limit='') => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: MATERIAL_GATE_PASS_LOADING,
        payload: {
          loading: true,
        },
      });
      if (state === "add") {
        axios
          .post(`${server_url}/materialGatePass`, materialGatePass, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("authToken"),
            },
          })
          .then((responseF) => {
            axios
              .get(`${server_url}/materialGatePass?limit=${limit}&page=${page}`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("authToken"),
                },
              })
              .then((response) => {
                dispatch({
                  type: MATERIAL_GATE_PASS_GET_ALL_SUCCESS,
                  payload: {
                    materialGatePassList: response.data.data,
                    limit: response.data.limit,
                    page: response.data.currentPage,
                    totalPages: response.data.totalPages,
                    response: response.data,
                    loading: false,
                    status: responseF.data["status"],
                    text: responseF.data["message"],
                  },
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: MATERIAL_GATE_PASS_ERROR,
                  payload: {
                    text: "Error occurred during getting materialGatePass data.",
                    status: "error",
                    loading: false,
                  },
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: MATERIAL_GATE_PASS_ERROR,
              payload: {
                text: "Error occurred during adding materialGatePass data.",
                status: "error",
                loading: false,
              },
            });
            resolve();
          });
      } else if (state === "edit") {
        axios
          .put(`${server_url}/materialGatePass/${materialGatePass._id}`, materialGatePass, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("authToken"),
            },
          })
          .then((responseF) => {
            axios
              .get(`${server_url}/materialGatePass?limit=${limit}&page=${page}`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("authToken"),
                },
              })
              .then((response) => {
                console.log("materialGatePassDetail)))))))))))");
                console.log(responseF.data["data"]);
                dispatch({
                  type: MATERIAL_GATE_PASS_GET_ALL_SUCCESS,
                  payload: {
                    materialGatePassList: response.data.data,
                    limit: response.data.limit,
                    page: response.data.currentPage,
                    totalPages: response.data.totalPages,
                    response: response.data,
                    materialGatePassDetail: responseF.data["data"],
                    loading: false,
                    status: responseF.data["status"],
                    text: responseF.data["message"],
                  },
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: MATERIAL_GATE_PASS_ERROR,
                  payload: {
                    text: "Error occurred during getting patrolling route data.",
                    status: "error",
                    loading: false,
                  },
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: MATERIAL_GATE_PASS_ERROR,
              payload: {
                text: "Error occurred during updating materialGatePass data.",
                status: "error",
                loading: false,
              },
            });
            resolve();
          });
      } else if (state === "view") {
        axios
          .get(`${server_url}/materialGatePass/${materialGatePass._id}`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("authToken")
            },
          })
          .then((responseF) => {
            axios
              .get(`${server_url}/materialGatePass?limit=${limit}&page=${page}`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("authToken")
                },
              })
              .then((response) => {
                dispatch({
                  type: MATERIAL_GATE_PASS_GET_ALL_SUCCESS,
                  payload: {
                    materialGatePassList: response.data.data,
                    limit: response.data.limit,
                    page: response.data.currentPage,
                    totalPages: response.data.totalPages,
                    response: response.data,
                    materialGatePassDetail: responseF.data["data"],
                    logs: responseF.data["data"]["logs"],
                    material: responseF.data["data"]["material"],
                    materialGatePass: responseF.data["data"]["materialGatePass"],
                    loading: false,
                    status: responseF.data["status"],
                    text: responseF.data["message"],
                  },
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: MATERIAL_GATE_PASS_ERROR,
                  payload: {
                    text: "Error occurred during getting materialGatePass data.",
                    status: "error",
                    loading: false,
                  },
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: MATERIAL_GATE_PASS_ERROR,
              payload: {
                text: "Error occurred during updating materialGatePass data.",
                status: "error",
                loading: false,
              },
            });
            resolve();
          });
      }
    });
  };
};

export const materialGatePassDelete = (materialGatePassId ,page='' , limit='') => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: MATERIAL_GATE_PASS_LOADING,
        payload: {
          loading: true,
        },
      });
      axios
        .delete(`${server_url}/materialGatePass/${materialGatePassId}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("authToken"),
          },
        })
        .then((responseF) => {
          axios
            .get(`${server_url}/materialGatePass?limit=${limit}&page=${page}`, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("authToken"),
              },
            })
            .then((response) => {
              dispatch({
                type: MATERIAL_GATE_PASS_GET_ALL_SUCCESS,
                payload: {
                  materialGatePassList: response.data.data,
                  limit: response.data.limit,
                  page: response.data.currentPage,
                  totalPages: response.data.totalPages,
                  response: response.data,
                  materialGatePassDetail: responseF.data["data"],
                  loading: false,
                  status: responseF.data["status"],
                  text: responseF.data["message"],
                },
              });
              resolve();
            })
            .catch((e) => {
              dispatch({
                type: MATERIAL_GATE_PASS_ERROR,
                payload: {
                  text: "Error occurred during getting patrolling route data.",
                  status: "error",
                  loading: false,
                },
              });
              resolve();
            });
        })
        .catch((e) => {
          dispatch({
            type: MATERIAL_GATE_PASS_ERROR,
            payload: {
              text: "Error occurred during deleting materialGatePass data.",
              status: "error",
              loading: false,
            },
          });
          resolve();
        });
    });
  };
};

export const materialGatePassClearShowSnackbar = () => {
  return (dispatch, getState) => {
    dispatch({
      type: MATERIAL_GATE_PASS_CLEAR_SNACKBAR,
    });
  };
};

