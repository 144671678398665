import { 
    BUS_LOADING, 
    BUS_GET_ALL_SUCCESS, 
    BUS_ERROR,
    BUS_CLEAR_SNACKBAR
} from '../constants/types';

const initialState = {
    busList: [],
    response: {},
    busDetail: {},
    loading: false,
    status: "success",
    text: "",
    showSnackbar: false
};

const busReducer = (state = initialState, action) => {
    switch (action.type) {
        case BUS_CLEAR_SNACKBAR: 
            return {
                ...state,
                showSnackbar: false
            }
        case BUS_LOADING: 
            return {
                ...state,
                loading: true,
            }
        case BUS_ERROR:
            return {
                ...state,
                busList: [],
                response: {},
                busDetail: {},
                loading: false,
                status: action.payload.status,
                text: action.payload.text,
                showSnackbar: true
            }
        case BUS_GET_ALL_SUCCESS:
            return {
                ...state,
                busList: action.payload.busList,
                busDetail: action.payload.busDetail,
                response : action.payload.response,
                loading: false,
                status: action.payload.status,
                text: action.payload.text,
                showSnackbar: true
            }
        default: 
            return state || {};
    }
}

export default busReducer;
