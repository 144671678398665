import React, { useEffect, useState } from 'react';
import {
  Flex,
  Input,
  SimpleGrid,
  Text,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Select,
  Button,
  Center,
  Spacer,
  Box,
  useColorModeValue,
  Image,
} from '@chakra-ui/react';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { useDispatch, useSelector } from 'react-redux';
import { departmentClearShowSnackbar, departmentAddOrUpdate } from '../../actions/departmentActions';
import { useToast } from '@chakra-ui/react';
import { DEPARTMENT_CLEAR_SNACKBAR } from 'constants/types';
import { Spinner } from '@chakra-ui/react';
import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import dataConstant from 'constants/dataConstant';

function DepartmentUpdateForm() {
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [status, setStatus] = useState('');
  const [image, setImage] = useState("");

  const [isErrorName, setIsErrorName] = useState(false);
  const [isErrorDescription, setIsErrorDescription] = useState(false);
  const [isErrorStatus, setIsErrorStatus] = useState(false);
  const [isErrorImage, setIsErrorImage] = useState(false);
  // const [isErrorAdminId, setIsErrorAdminId] = useState(false);
  const dispatch = useDispatch();
  const toast = useToast();

  const statuses = ['success', 'error', 'warning', 'info'];
  const responseStatus = useSelector(state => state.department.status);

  const responseMessage = useSelector(state => state.department.text);
  const loading = useSelector(state => state.department.loading);
  const departmentDetail = useSelector(state => state.department.departmentDetail);
  const showSnackbar = useSelector(state => state.department.showSnackbar);
  const params = useParams();
  const history = useHistory();
  console.log('departmentDetail123456');
  console.log(departmentDetail);

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && !loading) {
        setName(departmentDetail.name);
        setDescription(departmentDetail.description);
        setStatus(departmentDetail.status);
        setImage(departmentDetail.image)
        
        // setAdminId(departmentDetail.adminId);
        if (responseMessage !== "") {
          toastFunction(responseMessage, "success");
        }
      } else if (responseStatus === "NOK" && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, "error");
        }
      }
      dispatch(departmentClearShowSnackbar());
    }
  }, [showSnackbar, responseStatus, loading]);

  useEffect(() => {
    const state = 'view';
    const department = {
      _id: params.id,
    };
    dispatch(departmentAddOrUpdate(department, state));
  }, []);
  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])
  const handleSubmit = (event) => {
    event.preventDefault();
    setIsErrorName(name === '');
    setIsErrorDescription(description === '');
    setIsErrorStatus(status === '');
    setIsErrorImage(image === '');
    

    // setIsErrorAdminId(adminId === '');

    if (name !== '' && description !== '' && status !== '' && image !== "") {
      const departmentData = {
        _id: params.id,
        name: name,
        description: description,
        status: status,
        file: image,
        
        // adminId: adminId,
      };
      
      
      console.log("departmentDattfghfghfghfhghgfhfga");
      console.log(departmentData);
      dispatch(departmentAddOrUpdate(departmentData, 'edit'));
    }
    history.push("/admin/department")


  };



  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Department Update Form
          </Text>
          <br/>
          <SimpleGrid minChildWidth='120px' spacing='40px'>
            
            <FormControl isInvalid={isErrorName}>
              <FormLabel>Name</FormLabel>
              <Input
                type='text'
                value={name}
                onChange={(e) => {
                  setIsErrorName(e.target.value === '')
                  setName(e.target.value)
                }}
              />
              {!isErrorName ? (
                <></>
              ) : (
                <FormErrorMessage>Name is required.</FormErrorMessage>
              )}
            </FormControl>

            <FormControl isInvalid={isErrorImage}>
              <FormLabel>Image</FormLabel>
              <Input
                type='file'
                onChange={(e) => {

                  setIsErrorImage(e.target.value === '')
                  setImage(e.target.files[0])
                }}
              />
              {!isErrorImage ? (
                <></>
              ) : (
                <FormErrorMessage>Image is required.</FormErrorMessage>
              )}
            </FormControl>

            <FormControl isInvalid={isErrorDescription}>
              <FormLabel>Description</FormLabel>
              <Input
                type='text'
                value={description}
                onChange={(e) => {
                  setIsErrorDescription(e.target.value === '')
                  setDescription(e.target.value)
                }}
              />
              {!isErrorDescription ? (
                <></>
              ) : (
                <FormErrorMessage>Description is required.</FormErrorMessage>
              )}
            </FormControl>

            <FormControl isInvalid={isErrorStatus}>
              <FormLabel>Status</FormLabel>
              <Select
                placeholder="Select status"
                value={status}
                onChange={(e) => {
                  setIsErrorStatus(e.target.value === '')
                  setStatus(e.target.value)
                }}
              >
                {dataConstant.status.map((element, index) => {
                  return (
                    <option key={element['id']} value={element['value']}>
                      {element['name']}
                    </option>
                  );
                })}
              </Select>
              {!isErrorStatus ? (
                <></>
              ) : (
                <FormErrorMessage>Status is required.</FormErrorMessage>
              )}
            </FormControl>


          </SimpleGrid>
          <Center>
            {loading ? (
              <Spinner mt='24px' size='lg' />
            ) : (
              <Button colorScheme='blue' mt='24px' onClick={handleSubmit}>
                Submit
              </Button>
            )}
          </Center>
        </CardHeader>
        <CardBody></CardBody>
      </Card>

      <Spacer></Spacer>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px" mt="4">
        <CardHeader p="6px 0px 22px 0px">
        <Flex flexDirection="row">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Image
          </Text>
          <Box boxSize='sm'>
            <Image src={image} alt='Dan Abramov' />
          </Box>
        </Flex>

        </CardHeader>
        <CardBody>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default DepartmentUpdateForm;
