// Chakra imports
import {
    Flex,
    Box,
    Text,
    Table,
    Thead,
    Tbody,
    Tr,
    Td,
    WrapItem,
    TableContainer,
    Image,
    useColorModeValue,
    Wrap,
    Stack,
    HStack,
  } from "@chakra-ui/react";
  // Custom components
  import Card from "components/Card/Card.js";
  import CardBody from "components/Card/CardBody.js";
  import CardHeader from "components/Card/CardHeader.js";
  import React, { useEffect, useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import { outPassClearShowSnackbar, outPassAddOrUpdate } from '../../actions/outPassAction';
  import { useToast } from '@chakra-ui/react'
  import { useParams } from "react-router-dom/cjs/react-router-dom.min";
  import { OUTPASS_CLEAR_SNACKBAR } from "constants/types";
  import {
    Skeleton,
    SkeletonCircle,
    SkeletonText,
  } from "@chakra-ui/react";
// import { outPassClearShowSnackbar } from "actions/outPassAction";
import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom';
  function OutPassView() {
    const textColor = useColorModeValue("gray.700", "white");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    const [name, setName] = useState("");
const [description, setDescription] = useState("");
const [subDescription, setSubDescription] = useState("");
const [status, setStatus] = useState("");
const [subCreated,setSubCreatedAt ] = useState("");
const [depCreated,setDepCreatedAt ] = useState("");
const [created,setCreatedAt ] = useState("");
const [subUpdate,setSubUpdatedAt ] = useState("");
const [depUpdate,setDepUpdatedAt ] = useState("");
const [update,setUpdatedAt ] = useState("");
const [deptName,setSubDeptName ] = useState("");

const history = useHistory();
const [txn,setOutPassTxn ] = useState("");
const [pass,setPasCode ] = useState("");
const [photo,setPhoto ] = useState("");
const[address, setAddress] = useState('')
const[reason, setReason] = useState('')
const[leaveDesc, setLeaveDesc] = useState('')
const[duration, setDuration] = useState('')

// const [set,setCreatedAt ] = useState("");
// const [,setUpdatedAt ] = useState("");

  
    const dispatch = useDispatch();
    const toast = useToast();
  
    const statuses = ['success', 'error', 'warning', 'info'];
    const responseStatus = useSelector(state => state.outPass.status);
    const responseMessage = useSelector(state => state.outPass.text);
    const loading = useSelector(state => state.outPass.loading);
    // const designationDetail = useSelector(state => state.outPass.designationDetail);
    const outPassDetail = useSelector((state) => state.outPass.outPassDetail); 
    const showSnackbar = useSelector(state => state.outPass.showSnackbar);
    const logs = useSelector(state => state.outPass.logs);
    const outPassData = useSelector(state => state.outPass.outPass);
    
    const params = useParams();
    //  console.log("shasjgsadgjdagjadsjg-------");
    // console.log(outPassDetail.logs)
    // console.log("outPassDetail--outPassDetail")
    // console.log(outPass);
    console.log("nkdsdhgiyadkhuasd------------");
    console.log(logs);

    useEffect(() => {
      if (showSnackbar) {
        if (responseStatus === 'OK' && loading === false) {
         console.log('logs');
         console.log(logs);
         console.log('logs');
setName(outPassDetail.employeeId.department.name)
setDescription(outPassDetail.employeeId.department.description)
// setSubName(outPassDetail.subdepartment.department.name)
// setStatus(outPassDetail.department.status)
setDepCreatedAt(outPassDetail.employeeId.department.createdAt)
setDepUpdatedAt(outPassDetail.employeeId.department.updatedAt)
setSubDeptName(outPassDetail.employeeId.subdepartment.name)
setSubDescription(outPassDetail.employeeId.subdepartment.description)
setSubCreatedAt(outPassDetail.employeeId.subdepartment.createdAt)
setSubUpdatedAt(outPassDetail.employeeId.subdepartment.updatedAt)

setOutPassTxn(outPassDetail.txnNumber)

setPasCode(outPassDetail.employeeId.passcode)
setPhoto(outPassDetail.employeeId.photo)
setCreatedAt(outPassDetail.employeeId.createdAt)
setUpdatedAt(outPassDetail.employeeId.updatedAt)

setAddress(outPassDetail.employeeId.employeeAddress)
setReason(outPassDetail.reason)
setLeaveDesc(outPassDetail.description)
setDuration(outPassDetail.Duration)
          
          setStatus(outPassDetail.status ?'Active' : 'inActive');
          if (responseMessage !== "") {
            toastFunction(responseMessage, 'success');
          }
        } else if (responseStatus === 'NOK' && loading === false) {
          if (responseMessage !== "") {
            toastFunction(responseMessage, 'error');
          }
        }
        dispatch({
          type: OUTPASS_CLEAR_SNACKBAR,
          payload: {
            showSnackbar: false,
          }
        });
      }
    }, [loading]);
  
    useEffect(() => {
      const outPassId = params.id;
      const outPass = {
        _id: outPassId,
      };
      dispatch(outPassAddOrUpdate(outPass, 'view'));
    }, []);
    useEffect(() => {
      axios
        .get(`${server_url}/admin/detail`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then((responce) => {
          console.log("uadlfdafljadhfoadilf");
        })
        .catch((e) => {
          history.push('/auth/signin');
  
        });
    }, [])
    const toastFunction = (title, status) => {
      toast({
        title: title,
        status: status,
        duration: 3000,
        isClosable: true,
      });
    };
  
  
    return (
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px" mb={4} >
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Out Pass View 
            </Text>
          </CardHeader>
          <CardBody>
            {loading ?(<Stack>
              <Skeleton height="20px" />
              </Stack>
            ) : outPassDetail ?(
              <TableContainer>
                <Table variant="simple" >
                  <Tbody mb={4}>
                    <Tr>
                      <Td>Name</Td>
                      <Td>{name}</Td>
                    </Tr>
                    {/* <Tr>
                      <Td>Description</Td>
                      <Td>{description}</Td>
                    </Tr> */}
                    {/* <Tr>
                      <Td>Status</Td>
                      <Td>{status}</Td>
                    </Tr> */}
                    {/* <Tr>
                      <Td>Mode</Td>
                      <Td>{mode}</Td>
                    </Tr>
                    <Tr>
                      <Td>outPass</Td>
                      <Td>{outPass}</Td>
                    </Tr>
                    <Tr>
                      <Td>mobile</Td>
                      <Td>{mobile}</Td>
                    </Tr>
                    <Tr>
                      <Td>vehicle</Td>
                      <Td>{vehicle}</Td>
                    </Tr>
                    <Tr>
                      <Td>shipment</Td>
                      <Td>{shipment}</Td>
                    </Tr>
                    <Tr>
                      <Td>returnDate</Td>
                      <Td>{returnDate}</Td>
                    </Tr> */}
                    {/* <Tr>
                      <Td>guardName</Td>
                      <Td>{guardName}</Td>
                    </Tr>
                    <Tr>
                      <Td>guardStatus</Td>
                      <Td>{guardStatus}</Td>
                    </Tr> */}
                   
                    <Tr>
                      <Td>Department</Td>
                      <Td>{deptName}</Td>
                    </Tr>
                    <Tr>
                      <Td>Sub-Department Description</Td>
                      <Td>{subDescription}</Td>
                    </Tr>
                    {/* <Tr>
                      <Td>Sub-Department Created At</Td>
                      <Td>{subCreated}</Td>
                    </Tr>
                    <Tr>
                      <Td>Department Created At</Td>
                      <Td>{depCreated}</Td>
                    </Tr>
                    <Tr>
                      <Td>Created At</Td>
                      <Td>{created}</Td>
                    </Tr>
                    <Tr>
                      <Td>Sub-Department Updated At</Td>
                      <Td>{subUpdate}</Td>
                    </Tr> */}
                    {/* <Tr>
                      <Td>Department Updated At</Td>
                      <Td>{depUpdate}</Td>
                    </Tr>
                    <Tr>
                      <Td>Updated At</Td>
                      <Td>{update}</Td>
                    </Tr> */}
                    {/* <Tr>
                      <Td>matStatus</Td>
                      <Td>{matStatus}</Td>
                    </Tr>
                    <Tr>
                      <Td>matType</Td>
                      <Td>{matType}</Td>
                    </Tr>
                    <Tr>
                      <Td>matWeigh</Td>
                      <Td>{matWeigh}</Td>
                    </Tr> */}
                    <Tr>
                      <Td>Txn</Td>
                      <Td>{txn}</Td>
                    </Tr>
                    {/* <Tr>
                      <Td>newName</Td>
                      <Td>{newName}</Td>
                    </Tr>
                    <Tr>
                      <Td>newNumber</Td>
                      <Td>{newNumber}</Td>
                    </Tr>
                    <Tr>
                      <Td>newMail</Td>
                      <Td>{newMail}</Td>
                    </Tr>
                    <Tr>
                      <Td>newDevice</Td>
                      <Td>{newDevice}</Td>
                    </Tr> */}
                    {/* <Tr>
                      <Td>Postal</Td>
                      <Td>{pinCode}</Td>
                    </Tr> */}
                    {/* <Tr>
                      <Td>Locality</Td>
                      <Td>{local}</Td>
                    </Tr>
                    <Tr>
                      <Td>state</Td>
                      <Td>{state}</Td>
                    </Tr>
                    <Tr>
                      <Td>GuardId</Td>
                      <Td>{guardId}</Td>
                    </Tr> */}
                    <Tr>
                      <Td>Entry Code</Td>
                      <Td>{pass}</Td>
                    </Tr>
                    <Tr>
                      <Td>Description</Td>
                      <Td>{description}</Td>
                    </Tr>
                    <Tr>
                      <Td>Address</Td>
                      <Td>{address}</Td>
                    </Tr>
                    <Tr>
                      <Td>Reason</Td>
                      <Td>{reason}</Td>
                    </Tr>
                    <Tr>
                      <Td>Leave Description</Td>
                      <Td>{leaveDesc}</Td>
                    </Tr>
                    <Tr>
                      <Td>Duration</Td>
                      <Td>{duration}</Td>
                    </Tr>
                    <Tr>
                      <Td>Image</Td>
                      <Td>
                        <img src={photo}  style={{ maxWidth: '120px' }} />
                      </Td>
                    </Tr>

                    <Tr>
                      <Td>Logs</Td>
                      <Td>{logs.map((log) => {
                        return (<ul>
                          <li>{log.description} at {log.formattedDateTime} on {log.dateTime}</li>
                          {/* <li>log.dateTime</li> */}
                         
                        </ul>)
                      })}</Td>
                    </Tr>
                    
                    

                  </Tbody>
                </Table>
                
              </TableContainer>
            ) : null}
          </CardBody>
          
        </Card>
         
      </Flex>
    );
  }
export default OutPassView