import React from "react";
import {
  Avatar,
  Badge,
  Button,
  Flex,
  Link,
  Td,
  Th,
  Text,
  Tr,
  useColorModeValue,
  useDisclosure,
  ButtonGroup,
  IconButton,
  Image,
} from "@chakra-ui/react";
import { BsFillTrashFill } from "react-icons/bs";
import { ViewIcon } from "@chakra-ui/icons";
import { AiFillEdit } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { canteenDelete } from "../../actions/canteenAction";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";

function CallSettingTAbleRow(props) {
  const {
    serialnumber,
    name,
    primaryName,
    primary,
    secondaryName,
    secondary,
    id,
    isLast
 } = props;
  const textColor = useColorModeValue("gray.500", "white");
  const titleColor = useColorModeValue("gray.700", "white");
  const bgStatus = useColorModeValue("gray.400", "navy.900");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();

  console.log("id")
  console.log(id)
  console.log("id")
  const dispatch = useDispatch();

  return (
    <>
      <Tr>
      <Td
          >
            <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
              <Flex direction="column">
                <Text
                  fontSize="md"
                  color={titleColor}
                  fontWeight="bold"
                  minWidth="100%"
                >
                  {serialnumber}
                </Text>
              </Flex>
            </Flex>
          </Td>

          <Td
            borderColor={borderColor}
            borderBottom={isLast ? "none" : null}
          >
            <Flex direction="column">
              <Text fontSize="md" color={textColor} fontWeight="bold">
                {name}
              </Text>
            </Flex>
          </Td>
          <Td
            borderColor={borderColor}
            borderBottom={isLast ? "none" : null}
          >
            <Flex direction="column">
              <Text fontSize="md" color={textColor} fontWeight="bold">
                {primaryName}
              </Text>
            </Flex>
          </Td>
          <Td
            borderColor={borderColor}
            borderBottom={isLast ? "none" : null}
          >
            <Flex direction="column">
              <Text fontSize="md" color={textColor} fontWeight="bold">
                {primary}
              </Text>
            </Flex>
          </Td>
          
          <Td
            borderColor={borderColor}
            borderBottom={isLast ? "none" : null}
          >
            <Flex direction="column">
              <Text fontSize="md" color={textColor} fontWeight="bold">
                {secondaryName}
              </Text>
            </Flex>
          </Td>
          <Td
            borderColor={borderColor}
            borderBottom={isLast ? "none" : null}
          >
            <Flex direction="column">
              <Text fontSize="md" color={textColor} fontWeight="bold">
                {secondary}
              </Text>
            </Flex>
          </Td>


          <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          {

            name === "High Alert" ? (
              <Link href={`#/admin/highalert/settings/${id}`}>
              <IconButton
                colorScheme="green"
                icon={<AiFillEdit />}
                aria-label="Edit"
              />
            </Link>
            ) : (null)
          }
        </Td>
      </Tr>

    </>
  );
}

export default CallSettingTAbleRow;