import React from "react";
import {
  chakra,
  Badge,
  Td,
  Tr,
  Text,
  useColorModeValue,
  Flex,
  ButtonGroup,
  IconButton,
  Link,
  Image
} from "@chakra-ui/react";
import { ViewIcon } from "@chakra-ui/icons";

const DelivaryHeadCountTableRow = (props) => {
  const {
    name ,
    // image,
    contact,
    DeliveryFrom,
    Department,
    SubDepartment,
    Parcel,
    // Totalparcel,
    serialnumber,
    Status ,
    inTime,
    outTime,
    isLast,
    id,
   } = props;
  const textColor = useColorModeValue("gray.500", "white");
  const titleColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  return (
    <>
      <Tr>
      <Td
          >
            <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
              <Flex direction="column">
                <Text
                  fontSize="md"
                  color={titleColor}
                  fontWeight="bold"
                  minWidth="100%"
                >
                  {serialnumber}
                </Text>
              </Flex>
            </Flex>
          </Td>
        <Td
          minWidth={{ sm: "250px" }}
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {name}
              </Text>
            </Flex>
          </Flex>
        </Td>

       
        {/* <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
            <Image
                boxSize='100px'
                objectFit='cover'
                src={image}
                alt='Dan Abramov'
              />
              
            </Text>
          </Flex>
        </Td> */}

        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {contact}
            </Text>
          </Flex>
        </Td>

        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {DeliveryFrom}
            </Text>
          </Flex>
        </Td>

        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {Department}
            </Text>
          </Flex>
        </Td>

        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {SubDepartment}
            </Text>
          </Flex>
        </Td>

        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {Parcel}
            </Text>
          </Flex>
        </Td>

        {/* <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {Totalparcel}
            </Text>
          </Flex>
        </Td> */}

        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
            <Badge colorScheme={Status == "ALLOW"  ? "green" : "red"}>{Status}</Badge>
          </Td>

        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {inTime}
            </Text>
          </Flex>
        </Td>

        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {outTime}
            </Text>
          </Flex>
        </Td>
        {/* <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <ButtonGroup>
            <Link href={`#/admin/delivary-view/${id}`}>
              <IconButton
                colorScheme="blue"
                icon={<ViewIcon />}
                aria-label="Up"
              />
            </Link>
            </ButtonGroup>
            </Td> */}
      </Tr>
    </>
  );
};

export default DelivaryHeadCountTableRow;
