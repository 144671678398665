// Chakra Icons
import { BellIcon } from "@chakra-ui/icons";
// Chakra Imports
import {
  Box, Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList, Stack, Text, useColorMode,
  useColorModeValue,
  useToast
} from "@chakra-ui/react";
// Assets
import avatar1 from "assets/img/avatars/avatar1.png";
import avatar2 from "assets/img/avatars/avatar2.png";
import avatar3 from "assets/img/avatars/avatar3.png";
// Custom Icons
import { ArgonLogoDark, ArgonLogoLight, ChakraLogoDark, ChakraLogoLight, ProfileIcon, SettingsIcon,ArrowBackIcon } from "components/Icons/Icons";
// Custom Components
import { ItemContent } from "components/Menu/ItemContent";
import { SearchBar } from "components/Navbars/SearchBar/SearchBar";
import { SidebarResponsive } from "components/Sidebar/Sidebar";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import routes from "routes.js";
import { useHistory } from 'react-router-dom';
import { adminLogout } from "../../actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { ADMIN_CLEAR_SNACKBAR } from "constants/types";

export default function HeaderLinks(props) {
  const {
    variant,
    children,
    fixed,
    scrolled,
    secondary,
    onOpen,
    ...rest
  } = props;

  const { colorMode } = useColorMode();
  const history = useHistory();
  const dispatch = useDispatch();
  const toast = useToast();
  const responseStatus = useSelector((state) => state.auth.status);
  const responseMessage = useSelector((state) => state.auth.text);
  const loading = useSelector((state) => state.auth.loading);
  const showSnackbar = useSelector((state) => state.auth.showSnackbar);
  const authDetail = useSelector(state => state.auth.authDetail);
  const [authName,setAuthName]=useState('');
  // const [adminName,setAdminName]=useState('');

  console.log("authDetailllllllll-------");
  console.log(authDetail);

  useEffect(() => {
    
    setAuthName(authDetail?.name);
  }, [authDetail]);

  // Chakra Color Mode
  let navbarIcon =
    fixed && scrolled
      ? useColorModeValue("gray.700", "gray.200")
      : useColorModeValue("white", "gray.200");
  let menuBg = useColorModeValue("white", "navy.800");
  if (secondary) {
    navbarIcon = "white";
  }

  const logoutHandel = () =>
  {
      dispatch(adminLogout());
      localStorage.clear();
      history.push('/auth/signin');
  };

  // useEffect(() => {
  //   if (showSnackbar) {
  //     if (responseStatus === "OK" && loading === false) {
  //       if (responseMessage !== "") {
  //         toastFunction(responseMessage, "success");
  //         localStorage.clear();
  //         history.push('/auth/signin');
  //       }
  //     } else if (responseStatus === "NOK" && loading === false) {
  //       if (responseMessage !== "") {
  //         toastFunction(responseMessage, "error");
  //         localStorage.clear();
  //         history.push('/auth/signin');
  //       }
  //     }
  //   }
  //   dispatch({
  //     type: ADMIN_CLEAR_SNACKBAR,
  //     payload: {
  //       showSnackbar: false,
  //     },
  //   });
  // }, [loading]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Flex
      pe={{ sm: "0px", md: "16px" }}
      w={{ sm: "100%", md: "auto" }}
      alignItems='center'
      flexDirection='row'>
      {/* <SearchBar me='18px' /> */}
        <Button
        // onClick={
        //   logoutHandel
        // }
          ms='0px'
          px='0px'
          me={{ sm: "2px", md: "16px" }}
          color={navbarIcon}
          variant='no-effects'
          rightIcon={
            document.documentElement.dir ? (
              ""
            ) : (
              <ProfileIcon color={navbarIcon} w='40px' h='40px' me='0px' />
            )
          }
          leftIcon={
            document.documentElement.dir ? (
              <ProfileIcon color={navbarIcon} w='22px' h='22px' me='0px' />
            ) : (
              ""
            )
          }>
          <Text  display={{ sm: "none", md: "flex" }}>{authName}</Text>
        </Button>
       

            {/* Menu button */}
      <SidebarResponsive
        hamburgerColor={"white"}
        // logo={
        //   <Stack direction='row' spacing='12px' align='center' justify='center'>
        //     {colorMode === "dark" ? (
        //       <ArgonLogoLight w='74px' h='27px' />
        //     ) : (
        //       <ArgonLogoDark w='74px' h='27px' />
        //     )}
        //     <Box
        //       w='1px'
        //       h='20px'
        //       bg={colorMode === "dark" ? "white" : "gray.700"}
        //     />
        //     {colorMode === "dark" ? (
        //       <ChakraLogoLight w='82px' h='21px' />
        //     ) : (
        //       <ChakraLogoDark w='82px' h='21px' />
        //     )}
        //   </Stack>
        // }
        colorMode={colorMode}
        secondary={props.secondary}
        routes={routes}
        {...rest}
      />

      {/* Setting icons */}
      <SettingsIcon
        cursor='pointer'
        ms={{ base: "16px", xl: "0px" }}
        me='16px'
        onClick={props.onOpen}
        color={navbarIcon}
        w='18px'
        h='18px'
      />

      {/* Bell icon */}
        {/* <Menu>
          <MenuButton>
            <BellIcon color={navbarIcon} w='18px' h='18px' />
          </MenuButton>
          <MenuList p='16px 8px' bg={menuBg}>
            <Flex flexDirection='column'>
              <MenuItem borderRadius='8px' mb='10px'>
                <ItemContent
                  time='13 minutes ago'
                  info='from Alicia'
                  boldInfo='New Message'
                  aName='Alicia'
                  aSrc={avatar1}
                />
              </MenuItem>
              <MenuItem borderRadius='8px' mb='10px'>
                <ItemContent
                  time='2 days ago'
                  info='by Josh Henry'
                  boldInfo='New Album'
                  aName='Josh Henry'
                  aSrc={avatar2}
                />
              </MenuItem>
              <MenuItem borderRadius='8px'>
                <ItemContent
                  time='3 days ago'
                  info='Payment successfully completed!'
                  boldInfo=''
                  aName='Kara'
                  aSrc={avatar3}
                />
              </MenuItem>
            </Flex>
          </MenuList>
        </Menu> */}
    </Flex>
  );
}