// Chakra imports
import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  SimpleGrid,
  Spacer,
  Text,
  Wrap,
  WrapItem,
  useColorModeValue,
  Select,
  Image,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bannerClearShowSnackbar, bannerAddOrUpdate } from '../../actions/bannerActions';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
} from '@chakra-ui/react'
import { useToast } from '@chakra-ui/react'
import { BANNER_CLEAR_SNACKBAR } from "constants/types";
import { Spinner } from '@chakra-ui/react'
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import dataConstant from "constants/dataConstant";

import axios from 'axios';
import { server_url } from '../../constants/types';

function BannerUpdateForm() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [photo, setPhoto] = useState("");
  const [status, setStatus] = useState("");
  const [position, setPosition] = useState("");
  const [number, setNumber] = useState("");
  const [alertMessage, setAlertMessage] = useState('');
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [description, setDesc] = useState("");


  const [isErrorEndDate, setIsErrorEndDate] = useState(false);
  const [isErrorName, setIsErrorName] = useState(false);
  const [isErrorImage, setIsErrorImage] = useState(false);
  const [isErrorStatus, setIsErrorStatus] = useState(false);
  const [isErrorPosition, setIsErrorPosition] = useState(false);
  const [isErrorNumber, setIsErrorNumber] = useState(false);
  const [isErrorSecoundarycontactNo, setIsErrorSecoundarycontactNo] = useState(false);
  const [isErrorContactNo, setIsErrorContactNo] = useState(false);
  const [isErrorStartDate, setIsErrorStartDate] = useState(false);
  const [isErrorBannerPosition, setIsErrorBannerPosition] = useState(false);
  const [isErrorDesc, setIsErrorDesc] = useState(false);
  const [bannerPosition, setBannerPosition] = useState("");

  const dispatch = useDispatch();
  const toast = useToast();
  const history = useHistory();

  const statuses = ['success', 'error', 'warning', 'info'];
  const responseStatus = useSelector(state => state.banner.status);
  const responseMessage = useSelector(state => state.banner.text);
  const loading = useSelector(state => state.banner.loading);
  const showSnackbar = useSelector(state => state.banner.showSnackbar);
  const bannerDetail = useSelector(state => state.banner.bannerDetail);
  const bannerPositionList = useSelector(state => state.bannerposition.bannerPositionList);

  console.log("./././././././/././");
  console.log(bannerPositionList);

  const selectedBanner = useSelector(state => state.banner.selectedBanner); // Assuming you have a selectedBanner state in your Redux store
  const params = useParams();
  const bannerId = params.id;
  console.log("bannerId");
  console.log(bannerDetail);
  useEffect(() => {

    const banner = {
      _id: bannerId
    };
    dispatch(bannerAddOrUpdate(banner, 'edit'));
  }, []);

  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])

  // Function to handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    setIsErrorName(name === '');
    setIsErrorImage(image === '');
    setIsErrorStatus(status === '');
    setIsErrorPosition(position === '');
    setIsErrorNumber(number === '');
    setIsErrorDesc(description === '');
    setIsErrorStartDate(startDate === '')
    setIsErrorEndDate(endDate === '')
    setBannerPosition(bannerPosition === '')
    // console.log("Submit");

    if (name !== "" && image !== "" && status !== "" && position !== "" && description !== "" && number !== "" && bannerPosition!== '') {
      console.log("Submit");
      // Get form data
      const bannerData = {
        _id: bannerId, // Assuming your banner model has an id field
        name: name,
        image: image,
        status: status,
        position: position,
        number: number,
        description: description,
        startDate : startDate,
        endDate : endDate,
        bannerPosition : bannerPosition
        // Other banner data fields
      };
      console.log("bannerData");
      console.log(bannerData);
      console.log("bannerData");

      // Call the bannerUpdate action
      dispatch(bannerAddOrUpdate(bannerData, 'edit'));
      // history.push("/admin/banners")
    }
  };

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
        console.log(bannerDetail);
        
        setName(bannerDetail.name);
        // setImage(bannerDetail.image);
        setPhoto(bannerDetail.image);
        setStatus(bannerDetail.status);
        setPosition(bannerDetail.position);
        setNumber(bannerDetail.number);
        setDesc(bannerDetail.description)
        setStartDate(bannerDetail.startDate)
        setEndDate(bannerDetail.endDate)
        setBannerPosition(bannerDetail.bannerPosition)
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }

    dispatch({
      type: BANNER_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };


  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Banner Update Form
          </Text>
          <br/>
          <SimpleGrid minChildWidth='120px' spacing='40px'>

            <FormControl isInvalid={isErrorName}>
              <FormLabel>Name</FormLabel>
              <Input
                type='text'
                value={name}
                onChange={(e) => {
                  setIsErrorName(e.target.value === '')
                  setName(e.target.value)
                }}
              />
              {!isErrorName ? (
                <></>
              ) : (
                <FormErrorMessage>Name is required.</FormErrorMessage>
              )}
            </FormControl>

            <FormControl isInvalid={isErrorImage}>
              <FormLabel>Image</FormLabel>
              <Input
                type='file'
                onChange={(e) => {
                  // setIsErrorImage(e.target.value === '')
                  setImage(e.target.files[0])
                }}
              />
              {/* {!isErrorImage ? (
                <></>
              ) : (
                <FormErrorMessage>Image is required.</FormErrorMessage>
              )} */}
            </FormControl>


            <FormControl isInvalid={isErrorNumber}>
              <FormLabel>Number</FormLabel>
              <Input
                type='text'
                value={number}
                onChange={(e) => {
                  setIsErrorNumber(e.target.value === '')
                  setNumber(e.target.value)
                }}
              />
              {!isErrorNumber ? (
                <></>
              ) : (
                <FormErrorMessage>Number is required.</FormErrorMessage>
              )}
            </FormControl>

          </SimpleGrid>
          <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>

            <FormControl isInvalid={isErrorStatus}>
              <FormLabel>Status</FormLabel>
              <Select
                placeholder="Select status"
                value={status}
                onChange={(e) => {
                  setIsErrorStatus(e.target.value === '')
                  setStatus(e.target.value)
                }}
              >
                {dataConstant.status.map((element, index) => {
                  return (<option key={element['id']} value={element['name']} >{element['name']}</option>);

                })}
              </Select>
              {!isErrorStatus ? (
                <></>
              ) : (
                <FormErrorMessage>Status is required.</FormErrorMessage>
              )}
            </FormControl>

            <FormControl isInvalid={isErrorPosition}>
              <FormLabel>Position</FormLabel>
              <Input
                type='text'
                value={position}
                onChange={(e) => {
                  setIsErrorPosition(e.target.value === '')
                  setPosition(e.target.value)
                }}
              />
              {!isErrorPosition ? (
                <></>
              ) : (
                <FormErrorMessage>Position is required.</FormErrorMessage>
              )}
            </FormControl>

            <FormControl isInvalid={isErrorDesc}>
              <FormLabel>Description</FormLabel>
              <Input
                type='text'
                value={description}
                onChange={(e) => {
                  setIsErrorDesc(e.target.value === '')
                  setDesc(e.target.value)
                }}
              />
              {!isErrorDesc ? (
                <></>
              ) : (
                <FormErrorMessage>Description is required.</FormErrorMessage>
              )}
            </FormControl>



          </SimpleGrid>
          <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>

            <FormControl isInvalid={isErrorContactNo}>
              <FormLabel>Start Date</FormLabel>
              <Input
                type="text"
                value={startDate}
                onChange={(e) => {
                  setIsErrorStartDate(e.target.value === "");
                  setStartDate(e.target.value);
                }}
              />
              {!isErrorContactNo ? (
                <></>
              ) : (
                <FormErrorMessage>Start date is required.</FormErrorMessage>
              )}
            </FormControl>

            <FormControl isInvalid={isErrorSecoundarycontactNo}>
              <FormLabel>End Date</FormLabel>
              <Input
                type="text"
                value={endDate}
                onChange={(e) => {
                  setIsErrorEndDate(e.target.value === "");
                  setEndDate(e.target.value);
                }}
              />
              {!isErrorSecoundarycontactNo ? (
                <></>
              ) : (
                <FormErrorMessage>End date is required.</FormErrorMessage>
              )}
            </FormControl>

            <FormControl isInvalid={isErrorBannerPosition}>
              <FormLabel>Banner Position</FormLabel>
              <Select
                placeholder="Select Banner Position"
                onChange={(e) => {
                  setIsErrorBannerPosition(e.target.value === '')
                  setBannerPosition(e.target.value)
                }}
              >
                {bannerPositionList.length > 0 ? (
                  bannerPositionList.map((element, index) => (
                    <option key={element['_id']} value={element['_id']} selected={bannerPosition === element._id ? true : false}>
                      {element['position']}
                    </option>
                  ))
                ) : (
                  <option value="">Nothing to show, the list is empty</option>
                )}
              </Select>
              {!isErrorBannerPosition ? (
                <></>
              ) : (
                <FormErrorMessage>Banner position is required.</FormErrorMessage>
              )}
            </FormControl>

          </SimpleGrid>
          <Center>
            {loading ? <Spinner mt='24px' size='lg' /> :
              <Button colorScheme='blue' mt='24px' onClick={handleSubmit}>
                Submit
              </Button>
            }
          </Center>
        </CardHeader>
        <CardBody>
        </CardBody>
      </Card>
      <Spacer></Spacer>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px" mt="4">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Banner
          </Text>
          <Box boxSize='sm'>
            <Image src={photo} alt='Dan Abramov' />
          </Box>
        </CardHeader>
        <CardBody>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default BannerUpdateForm;
