import { 
    CHECKPOST_LOADING, 
    CHECKPOST_GET_ALL_SUCCESS, 
    CHECKPOST_ERROR,
    CHECKPOST_CLEAR_SNACKBAR
} from '../constants/types';

const initialState = {
    response : {},
    checkpostList: [],
    checkpostDetail: {},
    loading: false,
    status: "success",
    text: "",
    showSnackbar: false
};

const checkpostReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHECKPOST_CLEAR_SNACKBAR: 
            return {
                ...state,
                showSnackbar: false
            }
        case CHECKPOST_LOADING: 
            return {
                ...state,
                loading: true,
            }
        case CHECKPOST_ERROR:
            return {
                ...state,
                checkpostList: [],
                response : {},
                checkpostDetail : {},
                loading: false,
                status: action.payload.status,
                text: action.payload.text,
                showSnackbar: true
            }
        case CHECKPOST_GET_ALL_SUCCESS:
            return {
                ...state,
                checkpostList: action.payload.checkpostList,
                response: action.payload.response,
                checkpostDetail: action.payload.checkpostDetail,
                loading: false,
                status: action.payload.status,
                text: action.payload.text,
                showSnackbar: true
            }
        default: 
            return state || {};
    }
}

export default checkpostReducer;
