const {
    SOS_CATEGORY_LOADING,
    SOS_CATEGORY_GET_ALL_SUCCESS,
    SOS_CATEGORY_ERROR,
    SOS_CATEGORY_CLEAR_SNACKBAR
  } = require('../constants/types');
  
  const initialState = {
    response: {},
    sosCategoryList: [],
    sosCategoryDetail: {},
    loading: false,
    status: 'success',
    text: '',
    showSnackbar: false
  };
  
  const sosCategoryReducer = (state = initialState, action) => {
    switch (action.type) {
      case SOS_CATEGORY_CLEAR_SNACKBAR:
        return {
          ...state,
          showSnackbar: false
        };
      case SOS_CATEGORY_LOADING:
        return {
          ...state,
          loading: true
        };
      case SOS_CATEGORY_ERROR:
        return {
          ...state,
          sosCategoryList: [],
          response: {},
          sosCategoryDetail: {},
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true
        };
      case SOS_CATEGORY_GET_ALL_SUCCESS:
        return {
          ...state,
          sosCategoryList: action.payload.sosCategoryList,
          response: action.payload.response,
          sosCategoryDetail: action.payload.sosCategoryDetail,
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true
        };
      default:
        return state || {};
    }
  };
  
  // module.exports = sosCategoryReducer;
  export default sosCategoryReducer;
  