import {
    GUARDMODULE_LOADING,
    GUARDMODULE_GET_ALL_SUCCESS,
    GUARDMODULE_ERROR,
    GUARDMODULE_CLEAR_SNACKBAR
  } from '../constants/types';
  
  const initialState = {
    response: {},
    guardmoduleList: [],
    guardmoduleDetail: {},
    loading: false,
    status: 'success',
    text: '',
    showSnackbar: false
  };
  
  const guardmoduleReducer = (state = initialState, action) => {
    switch (action.type) {
      case GUARDMODULE_CLEAR_SNACKBAR:
        return {
          ...state,
          showSnackbar: false
        };
      case GUARDMODULE_LOADING:
        return {
          ...state,
          loading: true
        };
      case GUARDMODULE_ERROR:
        return {
          ...state,
          guardmoduleList: [],
          response: {},
          guardmoduleDetail: {},
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true
        };
      case GUARDMODULE_GET_ALL_SUCCESS:
        return {
          ...state,
          guardmoduleList: action.payload.guardmoduleList,
          response: action.payload.response,
          guardmoduleDetail: action.payload.guardmoduleDetail,
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true
        };
      default:
        return state || {};
    }
  };
  
  export default guardmoduleReducer;