// Chakra imports
import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";


import { PARKINGDEPARTMENT_CLEAR_SNACKBAR } from "constants/types";
import {
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
} from "@chakra-ui/react";
import dataConstant from "constants/dataConstant";
import { permissionGetAll } from "actions/permissionActions";
import { moduleGetAll } from "actions/moduleActions";

import { adminGetAll } from "actions/adminActions";

import { parkingdepartmentClearShowSnackbar } from "actions/parkingDepartmentAction";
import { parkingdepartmentGetAll } from "actions/parkingDepartmentAction";
import { parkingdepartmentAddOrUpdate } from "actions/parkingDepartmentAction";
import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom';
function ParkingDepartmentForm() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("");

  const [adminId, setAdminId] = useState("");
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [isErrorName, setIsErrorName] = useState(false);
  const [isErrorDescription, setIsErrorDescription] = useState(false);
  const [isErrorStatus, setIsErrorStatus] = useState(false);
  const [isErrorAdminId, setIsErrorAdminId] = useState(false);
  const [isErrorType, setIsErrorType] = useState(false);
  const [permissionStyle, setPermissionStyle] = useState({
    display: "none",
  });
  const history = useHistory();


  const dispatch = useDispatch();
  const toast = useToast();
  const params = useParams();

  const statuses = ["success", "error", "warning", "info"];
  const responseStatus = useSelector((state) => state.parkingdepartment.status);
  const responseMessage = useSelector((state) => state.parkingdepartment.text);
  const loading = useSelector((state) => state.parkingdepartment.loading);
  const showSnackbar = useSelector(
    (state) => state.parkingdepartment.showSnackbar
  );
  const moduleList = useSelector((state) => state.module.moduleList);
  const permissionList = useSelector(
    (state) => state.permission.permissionList
  );
  console.log("responseMessage")
  console.log(showSnackbar)
  const parkingdepartmentList = useSelector((state) => state.parkingdepartment.parkingdepartmentList)

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === "OK" && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, "success");
          history.push('#/admin/parkingdepartment');
        }
      } else if (responseStatus === "NOK" && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, "error");
        }
      }
    }
    dispatch({
      type: PARKINGDEPARTMENT_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })

  }, [loading]);

  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  };


  useEffect(() => {
    dispatch(moduleGetAll());
    dispatch(permissionGetAll());
    dispatch(parkingdepartmentGetAll());
    dispatch(adminGetAll());


  }, []);


  const handleSubmit = (event) => {
    console.log(event);
    event.preventDefault();
    setIsErrorName(name === "");
    setIsErrorDescription(description === "");
    setIsErrorStatus(status === "");


    if (
      name !== "" &&
      description !== "" &&
      status !== ""
    ) {
      const parkingdepartment = {
        name: name,
        description: description,
        status: status,
        plant: params.id,
        permissions: selectedPermissions,
      };

      const state = "add";
      dispatch(parkingdepartmentAddOrUpdate(parkingdepartment, state));
    }
  };

  const handlePermissionChange = (permissionId, moduleId) => {
    setSelectedPermissions((prevSelectedPermissions) => {
      const existingPermissionIndex = prevSelectedPermissions.findIndex(
        (permission) =>
          permission._id === permissionId &&
          permission.modules._id === moduleId
      );

      if (existingPermissionIndex !== -1) {
        const updatedPermissions = [...prevSelectedPermissions];
        updatedPermissions.splice(existingPermissionIndex, 1);
        return updatedPermissions;
      } else {
        const permission = permissionList.find(
          (permission) =>
            permission._id === permissionId &&
            permission.modules._id === moduleId
        );

        return [...prevSelectedPermissions, permission];
      }
    });
  };


  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Parking Department Form
          </Text>
          <SimpleGrid minChildWidth="120px" spacing="40px">
            <FormControl isInvalid={isErrorName}>
              <FormLabel>Name</FormLabel>
              <Select
                placeholder="Select Name"
                onChange={(e) => {
                  setIsErrorName(e.target.value === '')
                  setName(e.target.value);
                  console.log(e.target.value);
                }}
              >
                {dataConstant.parkingDepartment.map((element, index) => {
                  return (<option key={element['id']} value={element['value']} >{element['name']}</option>);

                })}
              </Select>
              {!isErrorName ? (
                <></>
              ) : (
                <FormErrorMessage>Name is required.</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={isErrorDescription}>
              <FormLabel>Description</FormLabel>
              <Input
                type="text"
                onChange={(e) => {
                  setIsErrorDescription(e.target.value === "");
                  setDescription(e.target.value);
                }}
              />
              {!isErrorDescription ? (
                <></>
              ) : (
                <FormErrorMessage>Description is required.</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={isErrorStatus}>
              <FormLabel>Status</FormLabel>
              <Select
                placeholder="Select status"
                onChange={(e) => {
                  setIsErrorStatus(e.target.value === "");
                  setStatus(e.target.value);
                }}
              >
                {dataConstant.status.map((status) => (
                  <option key={status.id} value={status.value}>
                    {status.name}
                  </option>
                ))}
              </Select>
              {!isErrorStatus ? (
                <></>
              ) : (
                <FormErrorMessage>Status is required.</FormErrorMessage>
              )}
            </FormControl>

            <FormControl mt="24px" style={permissionStyle}>
              <FormLabel>Permissions</FormLabel>
              <Stack spacing={4}>
                <OrderedList>
                  {/* Render the permission checkboxes here */}
                </OrderedList>
              </Stack>
            </FormControl>
          </SimpleGrid>
          <Center>
            {loading ? (
              <Spinner mt="24px" size="lg" />
            ) : (
              <Button colorScheme="blue" mt="24px" onClick={handleSubmit}>
                Submit
              </Button>
            )}
          </Center>
        </CardHeader>
        <CardBody></CardBody>
      </Card>
    </Flex>
  );
}

export default ParkingDepartmentForm;
