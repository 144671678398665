import axios from "axios";
import {
  WRONG_ENTRY_LOADING,
  WRONG_ENTRY_GET_ALL_SUCCESS,
  WRONG_ENTRY_ERROR,
  WRONG_ENTRY_CLEAR_SNACKBAR,
} from "../constants/types";

import { server_url } from "../constants/types";

export const wrongEntryGetAll = (pagination = "") => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: WRONG_ENTRY_LOADING,
        payload: {
          loading: true,
        },
      });
      axios
        .get(`${server_url}/admin/longstay-wrongentry?${pagination}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("authToken"),
          },
        })
        .then((response) => {
          dispatch({
            type: WRONG_ENTRY_GET_ALL_SUCCESS,
            payload: {
              wrongEntryList: response.data.data,
              limit: response.data.limit,
              page: response.data.currentPage,
              totalPages: response.data.totalPages,
              response: response.data,
              logs: [],
              material: [],
              wrongEntry: [],
              loading: false,
              status: 'success',
              text: "Get all banner position successfully",
            },
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: WRONG_ENTRY_ERROR,
            payload: {
              status: "error",
              text: "Error occurred during getting wrongEntry data.",
              loading: false,
            },
          });
          resolve();
        });
    });
  };
};


export const wrongEntryAddOrUpdate = (wrongEntry, state ,limit='' , page='') => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: WRONG_ENTRY_LOADING,
        payload: {
          loading: true,
        },
      });
      if (state === "add") {
        axios
          .post(`${server_url}/wrongEntry`, wrongEntry, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("authToken"),
            },
          })
          .then((responseF) => {
            axios
              .get(`${server_url}/wrongEntry?limit=${limit}&page=${page}`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("authToken"),
                },
              })
              .then((response) => {
                dispatch({
                  type: WRONG_ENTRY_GET_ALL_SUCCESS,
                  payload: {
                    wrongEntryList: response.data.data,
                    limit: response.data.limit,
                    page: response.data.currentPage,
                    totalPages: response.data.totalPages,
                    response: response.data,
                    loading: false,
                    status: responseF.data["status"],
                    text: responseF.data["message"],
                  },
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: WRONG_ENTRY_ERROR,
                  payload: {
                    text: "Error occurred during getting wrongEntry data.",
                    status: "error",
                    loading: false,
                  },
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: WRONG_ENTRY_ERROR,
              payload: {
                text: "Error occurred during adding wrongEntry data.",
                status: "error",
                loading: false,
              },
            });
            resolve();
          });
      } else if (state === "edit") {
        axios
          .put(`${server_url}/wrongEntry/${wrongEntry._id}`, wrongEntry, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("authToken"),
            },
          })
          .then((responseF) => {
            axios
              .get(`${server_url}/wrongEntry?limit=${limit}&page=${page}`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("authToken"),
                },
              })
              .then((response) => {
                console.log("wrongEntryDetail)))))))))))");
                console.log(responseF.data["data"]);
                dispatch({
                  type: WRONG_ENTRY_GET_ALL_SUCCESS,
                  payload: {
                    wrongEntryList: response.data.data,
                    limit: response.data.limit,
                    page: response.data.currentPage,
                    totalPages: response.data.totalPages,
                    response: response.data,
                    wrongEntryDetail: responseF.data["data"],
                    loading: false,
                    status: responseF.data["status"],
                    text: responseF.data["message"],
                  },
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: WRONG_ENTRY_ERROR,
                  payload: {
                    text: "Error occurred during getting patrolling route data.",
                    status: "error",
                    loading: false,
                  },
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: WRONG_ENTRY_ERROR,
              payload: {
                text: "Error occurred during updating wrongEntry data.",
                status: "error",
                loading: false,
              },
            });
            resolve();
          });
      } else if (state === "view") {
        axios
          .get(`${server_url}/admin/longstay-wrongentry/${wrongEntry._id}`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("authToken")
            },
          })
          .then((responseF) => {
            axios
              .get(`${server_url}/admin/longstay-wrongentry?limit=${limit}&page=${page}`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("authToken")
                },
              })
              .then((response) => {
                dispatch({
                  type: WRONG_ENTRY_GET_ALL_SUCCESS,
                  payload: {
                    wrongEntryList: response.data.data,
                    limit: response.data.limit,
                    page: response.data.currentPage,
                    totalPages: response.data.totalPages,
                    response: response.data,
                    wrongEntryDetail: responseF.data["data"],
                    logs: responseF.data["data"]["logs"],
                    material: responseF.data["data"]["material"],
                    wrongEntry: responseF.data["data"]["wrongEntry"],
                    loading: false,
                    status: responseF.data["status"],
                    text: responseF.data["message"],
                  },
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: WRONG_ENTRY_ERROR,
                  payload: {
                    text: "Error occurred during getting wrongEntry data.",
                    status: "error",
                    loading: false,
                  },
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: WRONG_ENTRY_ERROR,
              payload: {
                text: "Error occurred during updating wrongEntry data.",
                status: "error",
                loading: false,
              },
            });
            resolve();
          });
      }
    });
  };
};

export const wrongEntryDelete = (wrongEntryId , page='' , limit='') => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: WRONG_ENTRY_LOADING,
        payload: {
          loading: true,
        },
      });
      axios
        .delete(`${server_url}/wrongEntry/${wrongEntryId}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("authToken"),
          },
        })
        .then((responseF) => {
          axios
            .get(`${server_url}/wrongEntry?limit=${limit}&page=${page}`, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("authToken"),
              },
            })
            .then((response) => {
              dispatch({
                type: WRONG_ENTRY_GET_ALL_SUCCESS,
                payload: {
                  wrongEntryList: response.data.data,
                  limit: response.data.limit,
                  page: response.data.currentPage,
                  totalPages: response.data.totalPages,
                  response: response.data,
                  wrongEntryDetail: responseF.data["data"],
                  loading: false,
                  status: responseF.data["status"],
                  text: responseF.data["message"],
                },
              });
              resolve();
            })
            .catch((e) => {
              dispatch({
                type: WRONG_ENTRY_ERROR,
                payload: {
                  text: "Error occurred during getting patrolling route data.",
                  status: "error",
                  loading: false,
                },
              });
              resolve();
            });
        })
        .catch((e) => {
          dispatch({
            type: WRONG_ENTRY_ERROR,
            payload: {
              text: "Error occurred during deleting wrongEntry data.",
              status: "error",
              loading: false,
            },
          });
          resolve();
        });
    });
  };
};

export const wrongEntryClearShowSnackbar = () => {
  return (dispatch, getState) => {
    dispatch({
      type: WRONG_ENTRY_CLEAR_SNACKBAR,
    });
  };
};

