import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { useDispatch, useSelector } from "react-redux";

import { useToast } from '@chakra-ui/react';
import { useParams } from "react-router-dom";
import { EMERGENCY_CONTACT_CLEAR_SNACKBAR } from "constants/types";
import {
  Table,
  Tbody,
  Td,
  Tr,
  TableContainer,
} from '@chakra-ui/react';
import { Skeleton, SkeletonText } from '@chakra-ui/react';
import { emergencyContactAddOrUpdate } from "actions/EmergencyContactAction";

function EmergencyContactView() { // Fixed the component name to start with a capital letter
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [primaryContact, setPrimaryContact] = useState("");
  const [secondaryContact, setSecondaryContact] = useState("");
  const [status, setStatus] = useState([]) // Initialize status as a string

  const dispatch = useDispatch();
  const toast = useToast();

  const responseStatus = useSelector(state => state.emergencyContact.status);
  const responseMessage = useSelector(state => state.emergencyContact.text);
  const loading = useSelector(state => state.emergencyContact.loading);
  const showSnackbar = useSelector(state => state.emergencyContact.showSnackbar);
  const emergencyContactDetail = useSelector(state => state.emergencyContact.emergencyContactDetail);

  const params = useParams();
  console.log("emergencyContactDetail");
  console.log(emergencyContactDetail);
  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
        setName(emergencyContactDetail.name);
        setDescription(emergencyContactDetail.description);
        setPrimaryContact(emergencyContactDetail.primaryContactNo);
        setSecondaryContact(emergencyContactDetail.secondaryContactNo);
        setStatus(emergencyContactDetail.status? "Active" : "InActive"); // Set the status here

        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }

    dispatch({
      type: EMERGENCY_CONTACT_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    });
  }, [loading]);

  useEffect(() => {
    const emergencyContactId = params.id;
    const emergencyContact = {
      _id: emergencyContactId
    };
    dispatch(emergencyContactAddOrUpdate(emergencyContact, 'view'));
  }, [params.id]); // Include params.id as a dependency

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Emergency Contact Form
          </Text>
        </CardHeader>
        <CardBody>
          {loading ? <SkeletonText noOfLines={3} spacing="4" /> :
            <TableContainer>
              <Table variant='simple'>
                <Tbody>
                  <Tr>
                    <Td>Name</Td>
                    <Td>{name}</Td>
                  </Tr>
                  <Tr>
                    <Td>Description</Td>
                    <Td>{description}</Td>
                  </Tr>
                  <Tr>
                    <Td>Primary Contact No</Td>
                    <Td>{primaryContact}</Td>
                  </Tr>
                  <Tr>
                    <Td>Secondary Contact No</Td>
                    <Td>{secondaryContact}</Td>
                  </Tr>
                  <Tr>
                    <Td>Status</Td>
                    <Td>{status}</Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          }
        </CardBody>
      </Card>
    </Flex>
  );
}

export default EmergencyContactView;
