import React from "react";
import {
  Avatar,
  Badge,
  Button,
  Td,
  Tr,
  Text,
  useColorModeValue,
  Flex,
  ButtonGroup,
  IconButton,
  Link,
} from "@chakra-ui/react";
import { ViewIcon } from "@chakra-ui/icons";

import { useDispatch } from "react-redux";

function EmployeeAttendenceInTableRow(props) {
  const {
    serialnumber,
    inGuard,
    checkpostName,
    employeename,
    designationName,
    departmentName,
    subdepartmentName,
    inTime,
    logs,
    isLast,
    id,
  } = props;
  const textColor = useColorModeValue("gray.500", "white");
  const titleColor = useColorModeValue("gray.700", "white");
  const bgStatus = useColorModeValue("gray.400", "navy.900");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  const dispatch = useDispatch();

  return (
    <>
      <Tr>
      <Td
          >
            <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
              <Flex direction="column">
                <Text
                  fontSize="md"
                  color={titleColor}
                  fontWeight="bold"
                  minWidth="100%"
                >
                  {serialnumber}
                </Text>
              </Flex>
            </Flex>
          </Td>
        <Td
          minWidth={{ sm: "250px" }}
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {inGuard}
              </Text>
            </Flex>
          </Flex>
        </Td>

        <Td
          minWidth={{ sm: "250px" }}
          pl="30px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {checkpostName}
            </Text>
          </Flex>
        </Td>
        <Td
          minWidth={{ sm: "250px" }}
          pl="20px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {employeename}
            </Text>
          </Flex>
        </Td>

        <Td
          minWidth={{ sm: "250px" }}
          pl="30px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {designationName}
            </Text>
          </Flex>
        </Td>
        <Td
          minWidth={{ sm: "250px" }}
          pl="10px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {departmentName}
            </Text>
          </Flex>
        </Td>
        <Td
          minWidth={{ sm: "250px" }}
          pl="10px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {subdepartmentName}
            </Text>
          </Flex>
        </Td>
        <Td
          minWidth={{ sm: "250px" }}
          pl="10px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {inTime}
            </Text>
          </Flex>
        </Td>
        <Td
          minWidth={{ sm: "250px" }}
          pl="60px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
  <Flex direction="column">
    <Text fontSize="md" color={textColor} fontWeight="bold">
    {logs.map((ele, index) => (
  <ul key={index}>
    {ele.type === 'IN' ? (
      <React.Fragment>
        <li>
          Type: {ele.type} Checkpost: {ele.checkpost}
        </li>
        <li>
          Time: {ele.time} Guard Name: {ele.guardName}
        </li>
      </React.Fragment>
    ) : (
      // Render something else or nothing based on the condition
      null
    )}
  </ul>
))}

    </Text>
  </Flex>
</Td>
        {/* <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {attendanceAt}
            </Text>
          </Flex>
        </Td> */}
        {/* <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {outCheckPointName}
            </Text>
          </Flex>
        </Td>

        <Td
          minWidth={{ sm: "250px" }}
          pl="60px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
  <Flex direction="column">
    <Text fontSize="md" color={textColor} fontWeight="bold">
      {viewAttendanceLog.map((ele, index) => (
        <ul key={index}>
        <li> 
        Type: {ele.type} Checkpost: {ele.checkpost}
        </li>
         <li>
         Time: {ele.time} Guard Name: {ele.guardName}
         </li>
        </ul>
      ))}
    </Text>
  </Flex>
</Td> */}

        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          {/* <ButtonGroup>
            <Link href={`#/admin/employee-attendence-view/${id}`}>
              <IconButton
                colorScheme="blue"
                icon={<ViewIcon />}
                aria-label="Up"
              />
            </Link>
            </ButtonGroup> */}
        </Td>
      </Tr>
    </>
  );
}

export default EmployeeAttendenceInTableRow;
