import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { useDispatch, useSelector } from "react-redux";

import { useToast } from '@chakra-ui/react';
import { useParams } from "react-router-dom";
import {PURPOSE_CLEAR_SNACKBAR } from "constants/types";
import {
  Table,
  Tbody,
  Td,
  Tr,
  TableContainer,
} from '@chakra-ui/react';
import { Skeleton, SkeletonText } from '@chakra-ui/react';
// import { deviceAddOrUpdate } from "../../actions/devicesAction"; 
import {purposeAddOrUpdate} from '../../actions/purposeAction'
import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom';
function PurposeView() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [admin, setAdmin] = useState("");
  const [status, setStatus] = useState("");
  
  const history = useHistory();
  const dispatch = useDispatch();
  const toast = useToast();

  const responseStatus = useSelector(state => state.purpose.status); // Make sure you have the correct state slice for device status
  const responseMessage = useSelector(state => state.purpose.text); // Make sure you have the correct state slice for device text
  const loading = useSelector(state => state.purpose.loading); // Make sure you have the correct state slice for device loading
  const purposeDetail = useSelector(state => state.purpose.purposeDetail); // Make sure you have the correct state slice for device detail
  const showSnackbar = useSelector(state => state.purpose.showSnackbar); // Make sure you have the correct state slice for device showSnackbar
  console.log("purposeDetail>?>>?>?>?>?>,.,..")
  console.log(purposeDetail)
  const params = useParams();
  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
        setName(purposeDetail.name);
        setDescription(purposeDetail.description);
        setAdmin(purposeDetail.plantAdmin.name);
        setStatus(purposeDetail.status);
    
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }

    dispatch({
      type: PURPOSE_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    });
  }, [loading]);

  useEffect(() => {
    const purposeId = params.id;
    const purpose = {
      _id: purposeId
    };
    dispatch(purposeAddOrUpdate(purpose, 'view')); // Make sure you have the correct action for deviceAddOrUpdate
  }, []);

  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])
  
  console.log("purposeDetail")
  console.log(purposeDetail)

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Purpose View
          </Text>
        </CardHeader>
        <CardBody>
          {loading ? <SkeletonText noOfLines={3} spacing="4" /> :
            <TableContainer>
              <Table variant='simple'>
                <Tbody>
                  <Tr>
                    <Td>Name</Td>
                    <Td>{name}</Td>
                  </Tr>
                  <Tr>
                    <Td>Description</Td>
                    <Td>{description}</Td>
                  </Tr>
                  <Tr>
                    <Td>Admin</Td>
                    <Td>{admin}</Td>
                  </Tr>
                  <Tr>
                    <Td>Status</Td>
                    <Td>{status ? (<p>Active</p>) : (<p>InActive</p>)}</Td>
                  </Tr>
             
                </Tbody>
              </Table>
            </TableContainer>
          }
        </CardBody>
      </Card>
    </Flex>
  );
}

export default PurposeView;
