import {
    PURPOSE_LOADING,
    PURPOSE_GET_ALL_SUCCESS,
    PURPOSE_ERROR,
    PURPOSE_CLEAR_SNACKBAR
  } from '../constants/types';
  
  const initialState = {
    response: {},
    purposeList: [],
    purposeDetail: {},
    loading: true,
    status: "success",
    text: "",
    showSnackbar: false
  };
  
  const purposeReducer = (state = initialState, action) => {
    switch (action.type) {
      case  PURPOSE_CLEAR_SNACKBAR:
        return {
          ...state,
          showSnackbar: false
        };
      case PURPOSE_LOADING:
        return {
          ...state,
          loading: true,
        };
      case PURPOSE_ERROR:
        return {
          ...state,
          purposeList: [],
          response: {},
          purposeDetail: {},
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true
        };
      case PURPOSE_GET_ALL_SUCCESS:
        return {
          ...state,
          purposeList: action.payload.purposeList,
          response: action.payload.response,
          purposeDetail: action.payload.purposeDetail,
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true
        };
      default:
        return state || {};
    }
  };
  
  export default purposeReducer;
  