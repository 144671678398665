import axios from "axios";
import {
  BUS_ROUTE_LOADING,
  BUS_ROUTE_GET_ALL_SUCCESS,
  BUS_ROUTE_ERROR,
  BUS_ROUTE_CLEAR_SNACKBAR,
} from "../constants/types";

import { server_url } from "../constants/types";

// Action to get all bus routes
export const busRouteGetAll = (pagination = "") => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: BUS_ROUTE_LOADING,
        payload: {
          loading: true,
        },
      });
      axios
        .get(`${server_url}/routes?${pagination}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("authToken"),
          },
        })
        .then((response) => {
          dispatch({
            type: BUS_ROUTE_GET_ALL_SUCCESS,
            payload: {
              busRouteList: response.data.data,
              response: response.data,
              loading: false,
              status: response.data.status,
              text: response.data.message,
            },
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: BUS_ROUTE_ERROR,
            payload: {
              status: "error",
              text: "Error occurred during getting bus route data.",
              loading: false,
            },
          });
          resolve();
        });
    });
  };
};

// Action to add or update a bus route
export const busRouteAddOrUpdate = (busRoute, state) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: BUS_ROUTE_LOADING,
        payload: {
          loading: true,
        },
      });
      const authToken = localStorage.getItem("authToken");

      if (state === "add") {
        axios
          .post(`${server_url}/routes`, busRoute, {
            headers: {
              Authorization: "Bearer " + authToken,
            },
          })
          .then((responseF) => {
            axios
              .get(`${server_url}/routes`, {
                headers: {
                  Authorization: "Bearer " + authToken,
                },
              })
              .then((response) => {
                dispatch({
                  type: BUS_ROUTE_GET_ALL_SUCCESS,
                  payload: {
                    busRouteList: response.data.data,
                    response: response.data,
                    loading: false,
                    status: responseF.data["status"],
                    text: responseF.data["message"],
                  },
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: BUS_ROUTE_ERROR,
                  payload: {
                    status: "error",
                    text: "Error occurred during getting bus route data.",
                    loading: false,
                  },
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: BUS_ROUTE_ERROR,
              payload: {
                status: "error",
                text: "Error occurred during adding bus route data.",
                loading: false,
              },
            });
            resolve();
          });
      } else if (state === "edit") {
        axios
          .put(`${server_url}/routes/${busRoute._id}`, busRoute, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("authToken"),
            },
          })
          .then((responseF) => {
            axios
              .get(`${server_url}/routes`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("authToken"),
                },
              })
              .then((response) => {
                dispatch({
                  type: BUS_ROUTE_GET_ALL_SUCCESS,
                  payload: {
                    busRouteList: response.data.data,
                    response: response.data,
                    busRouteDetail: responseF.data["data"],
                    loading: false,
                    status: responseF.data["status"],
                    text: responseF.data["message"],
                  },
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: BUS_ROUTE_ERROR,
                  payload: {
                    text: "Error occurred during getting bus route data.",
                    status: "error",
                    loading: false,
                  },
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: BUS_ROUTE_ERROR,
              payload: {
                text: "Error occurred during updating bus route data.",
                status: "error",
                loading: false,
              },
            });
            resolve();
          });
      } else if (state === "view") {
        axios
          .get(`${server_url}/routes/${busRoute._id}`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("authToken"),
            },
          })
          .then((responseF) => {
            axios
              .get(`${server_url}/routes`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("authToken"),
                },
              })
              .then((response) => {
                console.log("busRouteDetail2222222");
                console.log(responseF.data["data"]);
                dispatch({
                  type: BUS_ROUTE_GET_ALL_SUCCESS,
                  payload: {
                    busRouteList: response.data.data,
                    response: response.data,
                    busRouteDetail: responseF.data["data"],
                    loading: false,
                    status: responseF.data["status"],
                    text: responseF.data["message"],
                  },
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: BUS_ROUTE_ERROR,
                  payload: {
                    status: "error",
                    text: "Error occurred during getting bus route data.",
                    loading: false,
                  },
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: BUS_ROUTE_ERROR,
              payload: {
                status: "error",
                text: "Error occurred during updating bus route data.",
                loading: false,
              },
            });
            resolve();
          });
      }
    });
  };
};

// Action to delete a bus route
export const busRouteDelete = (busRouteId) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: BUS_ROUTE_LOADING,
        payload: {
          loading: true,
        },
      });
      const authToken = localStorage.getItem("authToken");

      axios
        .delete(`${server_url}/routes/${busRouteId}`, {
          headers: {
            Authorization: "Bearer " + authToken,
          },
        })
        .then((responseF) => {
          axios
            .get(`${server_url}/routes`, {
              headers: {
                Authorization: "Bearer " + authToken,
              },
            })
            .then((response) => {
              dispatch({
                type: BUS_ROUTE_GET_ALL_SUCCESS,
                payload: {
                  busRouteList: response.data.data,
                  response: response.data,
                  busRouteDetail: responseF.data["data"],
                  loading: false,
                  status: responseF.data["status"],
                  text: responseF.data["message"],
                },
              });
              resolve();
            })
            .catch((e) => {
              dispatch({
                type: BUS_ROUTE_ERROR,
                payload: {
                  text: "Error occurred during getting bus route data.",
                  status: "error",
                  loading: false,
                },
              });
              resolve();
            });
        })
        .catch((e) => {
          dispatch({
            type: BUS_ROUTE_ERROR,
            payload: {
              text: "Error occurred during deleting bus route data.",
              status: "error",
              loading: false,
            },
          });
          resolve();
        });
    });
  };
};

// Action to clear the bus route Snackbar
export const busRouteClearShowSnackbar = () => {
  return (dispatch, getState) => {
    dispatch({
      type: BUS_ROUTE_CLEAR_SNACKBAR,
    });
  };
};
