import axios from "axios";
import {
  LONG_ALERT_LOADING,
  LONG_ALERT_GET_ALL_SUCCESS,
  LONG_ALERT_ERROR,
  LONG_ALERT_CLEAR_SNACKBAR,
} from "../constants/types";

import { server_url } from "../constants/types";

export const longStayAlertGetAll = (pagination = "") => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: LONG_ALERT_LOADING,
        payload: {
          loading: true,
        },
      });
      axios
        .get(`${server_url}/admin/longstay-wrongentry?${pagination}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("authToken"),
          },
        })
        .then((response) => {
          dispatch({
            type: LONG_ALERT_GET_ALL_SUCCESS,
            payload: {
              longStayAlertList: response.data.data,
              limit: response.data.limit,
              page: response.data.currentPage,
              totalPages: response.data.totalPages,
              response: response.data,
              logs: [],
              material: [],
              longStayAlert: [],
              loading: false,
              status: 'success',
              text: "Get all longStayAlert successfully",
            },
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: LONG_ALERT_ERROR,
            payload: {
              status: "error",
              text: "Error occurred during getting longStayAlert data.",
              loading: false,
            },
          });
          resolve();
        });
    });
  };
};


export const longStayAlertAddOrUpdate = (longStayAlert, state , page='' ,limit='') => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: LONG_ALERT_LOADING,
        payload: {
          loading: true,
        },
      });
      if (state === "add") {
        axios
          .post(`${server_url}/longStayAlert`, longStayAlert, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("authToken"),
            },
          })
          .then((responseF) => {
            axios
              .get(`${server_url}/longStayAlert?limit=${limit}&page=${page}`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("authToken"),
                },
              })
              .then((response) => {
                dispatch({
                  type: LONG_ALERT_GET_ALL_SUCCESS,
                  payload: {
                    longStayAlertList: response.data.data,
                    limit: response.data.limit,
                    page: response.data.currentPage,
                    totalPages: response.data.totalPages,
                    response: response.data,
                    loading: false,
                    status: responseF.data["status"],
                    text: responseF.data["message"],
                  },
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: LONG_ALERT_ERROR,
                  payload: {
                    text: "Error occurred during getting longStayAlert data.",
                    status: "error",
                    loading: false,
                  },
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: LONG_ALERT_ERROR,
              payload: {
                text: "Error occurred during adding longStayAlert data.",
                status: "error",
                loading: false,
              },
            });
            resolve();
          });
      } else if (state === "edit") {
        axios
          .put(`${server_url}/longStayAlert/${longStayAlert._id}`, longStayAlert, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("authToken"),
            },
          })
          .then((responseF) => {
            axios
              .get(`${server_url}/longStayAlert?limit=${limit}&page=${page}`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("authToken"),
                },
              })
              .then((response) => {
                console.log("longStayAlertDetail)))))))))))");
                console.log(responseF.data["data"]);
                dispatch({
                  type: LONG_ALERT_GET_ALL_SUCCESS,
                  payload: {
                    longStayAlertList: response.data.data,
                    limit: response.data.limit,
                    page: response.data.currentPage,
                    totalPages: response.data.totalPages,
                    response: response.data,
                    longStayAlertDetail: responseF.data["data"],
                    loading: false,
                    status: responseF.data["status"],
                    text: responseF.data["message"],
                  },
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: LONG_ALERT_ERROR,
                  payload: {
                    text: "Error occurred during getting patrolling route data.",
                    status: "error",
                    loading: false,
                  },
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: LONG_ALERT_ERROR,
              payload: {
                text: "Error occurred during updating longStayAlert data.",
                status: "error",
                loading: false,
              },
            });
            resolve();
          });
      } else if (state === "view") {
        axios
          .get(`${server_url}/admin/longstay-wrongentry/${longStayAlert._id}`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("authToken")
            },
          })
          .then((responseF) => {
            axios
              .get(`${server_url}/admin/longstay-wrongentry?limit=${limit}&page=${page}`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("authToken")
                },
              })
              .then((response) => {
                dispatch({
                  type: LONG_ALERT_GET_ALL_SUCCESS,
                  payload: {
                    longStayAlertList: response.data.data,
                    limit: response.data.limit,
                    page: response.data.currentPage,
                    totalPages: response.data.totalPages,
                    response: response.data,
                    longStayAlertDetail: responseF.data["data"],
                    logs: responseF.data["data"]["logs"],
                    material: responseF.data["data"]["material"],
                    longStayAlert: responseF.data["data"]["longStayAlert"],
                    loading: false,
                    status: responseF.data["status"],
                    text: responseF.data["message"],
                  },
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: LONG_ALERT_ERROR,
                  payload: {
                    text: "Error occurred during getting longStayAlert data.",
                    status: "error",
                    loading: false,
                  },
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: LONG_ALERT_ERROR,
              payload: {
                text: "Error occurred during updating longStayAlert data.",
                status: "error",
                loading: false,
              },
            });
            resolve();
          });
      }
    });
  };
};

export const longStayAlertDelete = (longStayAlertId , page='' , limit='') => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: LONG_ALERT_LOADING,
        payload: {
          loading: true,
        },
      });
      axios
        .delete(`${server_url}/longStayAlert/${longStayAlertId}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("authToken"),
          },
        })
        .then((responseF) => {
          axios
            .get(`${server_url}/longStayAlert?limit=${limit}&page=${page}`, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("authToken"),
              },
            })
            .then((response) => {
              dispatch({
                type: LONG_ALERT_GET_ALL_SUCCESS,
                payload: {
                  longStayAlertList: response.data.data,
                  limit: response.data.limit,
                  page: response.data.currentPage,
                  totalPages: response.data.totalPages,
                  response: response.data,
                  longStayAlertDetail: responseF.data["data"],
                  loading: false,
                  status: responseF.data["status"],
                  text: responseF.data["message"],
                },
              });
              resolve();
            })
            .catch((e) => {
              dispatch({
                type: LONG_ALERT_ERROR,
                payload: {
                  text: "Error occurred during getting patrolling route data.",
                  status: "error",
                  loading: false,
                },
              });
              resolve();
            });
        })
        .catch((e) => {
          dispatch({
            type: LONG_ALERT_ERROR,
            payload: {
              text: "Error occurred during deleting longStayAlert data.",
              status: "error",
              loading: false,
            },
          });
          resolve();
        });
    });
  };
};

export const longStayAlertClearShowSnackbar = () => {
  return (dispatch, getState) => {
    dispatch({
      type: LONG_ALERT_CLEAR_SNACKBAR,
    });
  };
};

