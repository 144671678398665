import axios from "axios";
import {
  PATROLLING_REQUEST_LOADING,
  PATROLLING_REQUEST_GET_ALL_SUCCESS,
  PATROLLING_REQUEST_ERROR,
  PATROLLING_REQUEST_CLEAR_SNACKBAR,
} from "../constants/types";

import { server_url } from "../constants/types";

export const patrollingRequestGetAll = (pagination = "") => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: PATROLLING_REQUEST_LOADING,
        payload: {
          loading: true,
        },
      });
      axios
        .get(`${server_url}/patrolling-request?${pagination}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("authToken"),
          },
        })
        .then((response) => {
          dispatch({
            type: PATROLLING_REQUEST_GET_ALL_SUCCESS,
            payload: {
              patrollingRequestList: response.data.data,
              response: response.data,
              loading: false,
              status: response.data.status,
              text: response.data.message,
            },
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: PATROLLING_REQUEST_ERROR,
            payload: {
              status: "error",
              text: "Error occurred during getting patrolling request data.",
              loading: false,
            },
          });
          resolve();
        });
    });
  };
};


export const patrollingRequestAddOrUpdate = (patrollingRequest, state) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: PATROLLING_REQUEST_LOADING,
        payload: {
          loading: true,
        },
      });

      const authToken = localStorage.getItem("authToken");

      if (state === "add") {
        axios
          .post(`${server_url}/patrolling-request`, patrollingRequest, {
            headers: {
              Authorization: "Bearer " + authToken,
            },
          })
          .then((response) => {
            axios
              .get(`${server_url}/patrolling-request`, {
                headers: {
                  Authorization: "Bearer " + authToken,
                },
              })
              .then((responseAll) => {
                dispatch({
                  type: PATROLLING_REQUEST_GET_ALL_SUCCESS,
                  payload: {
                    patrollingRequestList: responseAll.data.data,
                    response: responseAll.data,
                    loading: false,
                    status: response.data["status"],
                    text: response.data["message"],
                  },
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: PATROLLING_REQUEST_ERROR,
                  payload: {
                    text: "Error occurred during getting patrolling request data.",
                    status: "error",
                    loading: false,
                  },
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: PATROLLING_REQUEST_ERROR,
              payload: {
                text: "Error occurred during adding patrolling request data.",
                status: "error",
                loading: false,
              },
            });
            resolve();
          });
      } else if (state === "edit") {
        axios
          .put(`${server_url}/patrolling-request/${patrollingRequest._id}`, patrollingRequest, {
            headers: {
              Authorization: "Bearer " + authToken,
            },
          })
          .then((response) => {
            axios
              .get(`${server_url}/patrolling-request`, {
                headers: {
                  Authorization: "Bearer " + authToken,
                },
              })
              .then((responseAll) => {
                dispatch({
                  type: PATROLLING_REQUEST_GET_ALL_SUCCESS,
                  payload: {
                    patrollingRequestList: responseAll.data.data,
                    response: responseAll.data,
                    patrollingRequestDetail: response.data["data"],
                    loading: false,
                    status: response.data["status"],
                    text: response.data["message"],
                  },
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: PATROLLING_REQUEST_ERROR,
                  payload: {
                    text: "Error occurred during getting patrolling request data.",
                    status: "error",
                    loading: false,
                  },
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: PATROLLING_REQUEST_ERROR,
              payload: {
                text: "Error occurred during updating patrolling request data.",
                status: "error",
                loading: false,
              },
            });
            resolve();
          });
      } else if (state === "view") {
        axios
          .get(`${server_url}/patrolling-request/${patrollingRequest._id}`, {
            headers: {
              Authorization: "Bearer " + authToken,
            },
          })
          .then((response) => {
            axios
              .get(`${server_url}/patrolling-request`, {
                headers: {
                  Authorization: "Bearer " + authToken,
                },
              })
              .then((responseAll) => {
                // console.log("patrollingRequestDetail11111");
                // console.log( response.data["data"]);
                dispatch({
                  type: PATROLLING_REQUEST_GET_ALL_SUCCESS,
                  payload: {
                    patrollingRequestList: responseAll.data.data,
                    response: responseAll.data,
                    patrollingRequestDetail: response.data["data"],
                    loading: false,
                    status: response.data["status"],
                    text: response.data["message"],
                  },
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: PATROLLING_REQUEST_ERROR,
                  payload: {
                    text: "Error occurred during getting patrolling request data.",
                    status: "error",
                    loading: false,
                  },
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: PATROLLING_REQUEST_ERROR,
              payload: {
                text: "Error occurred during updating patrolling request data.",
                status: "error",
                loading: false,
              },
            });
            resolve();
          });
      }
    });
  };
};


export const patrollingRequestDelete = (patrollingRequestId) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: PATROLLING_REQUEST_LOADING,
        payload: {
          loading: true,
        },
      });

      const authToken = localStorage.getItem("authToken");

      axios
        .delete(`${server_url}/patrolling-request/${patrollingRequestId}`, {
          headers: {
            Authorization: "Bearer " + authToken,
          },
        })
        .then((responseF) => {
          axios
            .get(`${server_url}/patrolling-request`, {
              headers: {
                Authorization: "Bearer " + authToken,
              },
            })
            .then((response) => {
              dispatch({
                type: PATROLLING_REQUEST_GET_ALL_SUCCESS,
                payload: {
                  patrollingRequestList: response.data.data,
                  response: response.data,
                  patrollingRequestDetail: responseF.data["data"],
                  loading: false,
                  status: responseF.data["status"],
                  text: responseF.data["message"],
                },
              });
              resolve();
            })
            .catch((e) => {
              dispatch({
                type: PATROLLING_REQUEST_ERROR,
                payload: {
                  text: "Error occurred during getting patrolling request data.",
                  status: "error",
                  loading: false,
                },
              });
              resolve();
            });
        })
        .catch((e) => {
          dispatch({
            type: PATROLLING_REQUEST_ERROR,
            payload: {
              text: "Error occurred during deleting patrolling request data.",
              status: "error",
              loading: false,
            },
          });
          resolve();
        });
    });
  };
};

export const patrollingRequestClearShowSnackbar = () => {
    return (dispatch, getState) => {
      dispatch({
        type: PATROLLING_REQUEST_CLEAR_SNACKBAR,
      });
    };
  };