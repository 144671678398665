// Chakra imports
import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  SimpleGrid,
  Spacer,
  Text,
  Wrap,
  WrapItem,
  useColorModeValue,
  Select,
  Image,
} from "@chakra-ui/react";
// Custom components
import Relect from 'react-select';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Divider } from '@chakra-ui/react';

import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
} from '@chakra-ui/react'
import { useToast } from '@chakra-ui/react'
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import { Spinner } from '@chakra-ui/react'
import dataConstant from "constants/dataConstant";
import { EMPLOYEE_CLEAR_SNACKBAR } from "constants/types";
import { employeeAddOrUpdate } from "actions/EmployeeAction";
import axios from "axios";
import { server_url } from "constants/types";
import CustomInput from "views/CustomValidation";


function EmployeeUpdateForm() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [employeeName, setEmployeeName] = useState("");
  const [photo, setPhoto] = useState("");
  const [photoDisplay, setPhotoDisplay] = useState("");
  const [phone, setPhone] = useState("");
  const [alternateNumber, setAlternateNumber] = useState("");
  const [officialEmail, setOfficialEmail] = useState('');



  const [departmentId, setDepartmentId] = useState("");
  const [designation, setDesignation] = useState("");
  const [subdepartment, setSubdepartment] = useState("");

  const [employeeType, setEmployeeType] = useState('');
  const [employeeAddress, setEmployeeAddress] = useState('');

  const [departmentList, setDepartmentList] = useState('')
  const [subdepartmentList, setSubdepartmentList] = useState([])
  const [designationList, setDesignationList] = useState([])

  const [vehicleType, setVehicleType] = useState("");
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [licenseNumber, setLicenseNumber] = useState('');
  const [licenseExpiry, setLicenseExpiry] = useState('');
  const [licensePhoto, setLicensePhoto] = useState('')
  const [disable, setDisable] = useState(false);
  const [appAccess, setAppAccess] = useState(false);
  const [phoneCall , setPhoneCall] = useState(false)
  const [phoneCallType , setPhoneCallType] = useState("")
  const [notification, setNotification] = useState(false);
  const [bloodGroup, setBloodGroup] = useState(false);
  const [requestType, setRequestType] = useState('');
  const [module, setmodule] = useState('');
  const [moduleList, setModuleList] = useState([]);
  const [endDate, setEndDate] = useState('');
  const [isErrorEmployeeName, setIsErrorEmployeeName] = useState(false);
  const [isErrorPhoto, setIsErrorPhoto] = useState(false);
  const [isErrorPhone, setIsErrorPhone] = useState(false);
  const [isErrorAlternateNumber, setIsErrorAlternateNumber] = useState(false);
  const [isErrorOfficialEmail, setIsErrorOfficialEmail] = useState(false);
  const [isErrorPhoneCall, setIsErrorPhoneCall] = useState(false);
  const [isErrorPhoneCallType, setIsErrorPhoneCallType] = useState(false);
  const [isErrorStartDate, setIsErrorStartDate] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [isErrorCompanyName, setIsErrorCompanyName] = useState(false);
  const [companyName, setCompanyName] = useState("");

  const [isErrorDepartment, setIsErrorDepartment] = useState(false);
  const [isErrorDesignation, setIsErrorDesignation] = useState(false);
  const [isErrorSubdepartment, setIsErrorSubdepartment] = useState(false);

  const [isErrorEmployeeType, setIsErrorEmployeeType] = useState(false);
  const [isErrorEmployeeAddress, setIsErrorEmployeeAddress] = useState(false);
  const [isErrorVehiclType, setIsErrorVehilcType] = useState(false);
  const [isErrorVehicleNumber, setIsErrorVehicleNumber] = useState(false);
  const [isErrorLicenseNumber, setIsErrorLicenseNumber] = useState(false);
  const [isErrorLicenseExpiry, setIsErrorLicenseExpiry] = useState(false);
  const [isErrorLicensePhoto, setIsErrorLicensePhoto] = useState(false);
  const [isErrorAppAccess, setIsErrorAppAccess] = useState(false);
  const [isErrorNotification, setIsErrorNotification] = useState(false);
  const [isErrorBloodGroup, setIsErrorBloodGroup] = useState(false);
  const [isErrorRequestType, setIsErrorRequestType] = useState(false);
  const [isErrorEndDate, setIsErrorEndDate] = useState(false);
  const [isErrorModule, setIsErrorModule] = useState(false);

  const [submitted, setSubmitted] = useState(false);

  const textRegex = /^[A-Za-z\s]+$/;
  const guardIdRegex = /^[a-zA-Z0-9]+$/;
  const postalCodeRegex = /^[0-9]{6}$/;
  const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,10}$/;
  const numberRegex = /^\d{10}$/;
  const vechilenoRegex = /^[A-Za-z0-9!@#$%^&*()_\-\[\]{};':"\\|,.<>\/? ]*$/;
  const licencenoRegex = /^[A-Za-z0-9]{6,12}$/


  const dispatch = useDispatch();
  const toast = useToast();

  const statuses = ['success', 'error', 'warning', 'info'];
  const responseStatus = useSelector(state => state.employee.status);
  const responseMessage = useSelector(state => state.employee.text);
  const loading = useSelector(state => state.employee.loading);
  const showSnackbar = useSelector(state => state.employee.showSnackbar);
  const employeeDetail = useSelector(state => state.employee.employeeDetail);
  const employeeList = useSelector(state => state.employee.employeeList)



  const params = useParams();
  const empId = params.id;
  console.log("employeeDetailemployeeDetail");
  console.log(employeeDetail);
  useEffect(() => {

    const employee = {
      _id: empId
    };
    dispatch(employeeAddOrUpdate(employee, 'view'));
  }, []);


  // Function to handle form submission
  const handleSubmit = (event) => {
    const empId = params.id;
    event.preventDefault();
    setSubmitted(true);
    setIsErrorEmployeeName(employeeName === '');
    setIsErrorPhoto(photo === '');
    setIsErrorPhone(phone === '');
    setIsErrorAlternateNumber(alternateNumber === '');
    setIsErrorOfficialEmail(officialEmail === '');
    setIsErrorCompanyName(companyName === '');
    setIsErrorStartDate(startDate === '');

    setIsErrorDepartment(departmentId === '');
    setIsErrorDesignation(designation === '');
    setIsErrorSubdepartment(subdepartment === '');

    setIsErrorEmployeeType(employeeType === '');
    setIsErrorEmployeeAddress(employeeAddress === '');
    setIsErrorVehilcType(vehicleType === '');
    setIsErrorLicenseExpiry(licenseExpiry === '');
    setIsErrorLicensePhoto(licensePhoto === '');
    setIsErrorLicensePhoto(licensePhoto === '');
    setIsErrorBloodGroup(bloodGroup === '');
    setIsErrorRequestType(requestType === '');
    setIsErrorPhoneCall(phoneCall === '');



    if (employeeName !== "" && phone !== "" && alternateNumber !== "" && officialEmail !== "" && designation !== "" && employeeType !== "" && employeeAddress !== "" && bloodGroup !== "" && requestType !== "" && phoneCall !== "") {
      console.log("Submit");
       
      // Get form data
      const employee = {
        _id: params.id,
        employeeName: employeeName,
       
        phone: phone,
        alternateNumber: alternateNumber,
        officialEmail: officialEmail,
        phoneCall : phoneCall,
        department: departmentId,
        designation: designation,
        subdepartment: subdepartment,
        employeeType: employeeType,
        endDate: endDate,
        startDate: startDate,
        companyName: companyName,
        employeeAddress: employeeAddress,
        appAccess: appAccess,
        phoneType: phoneCallType,
        enableNotification: notification,
        bloodGroup: bloodGroup,
        requestType: requestType,
        'employeeModules': module.map((module) => module.value),
        vehicleDetails: {
          vehicleType: vehicleType,
          vehicleNumber: vehicleNumber,
          licenseNumber: licenseNumber,
          licenseExpiry: licenseExpiry,
          file2: licensePhoto,

        },


      };
      if(phone)
      {
        employee. file1= photo;
      }
      console.log("employeeData----------");
      console.log(employee.file2);

      // Determine if it's an add or edit operation
      const state = 'edit';
      // Call the employeeAddOrUpdate action
      dispatch(employeeAddOrUpdate(employee, state))

    }
  };


  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
        console.log("employeeDetail--");
        console.log(employeeDetail.companyName);
        setEmployeeName(employeeDetail.employeeName);
        // setPhoto(employeeDetail.photo);
        setPhotoDisplay(employeeDetail.photo);
        setPhone(employeeDetail.phone);
        setPhoneCallType(employeeDetail.phoneType);
        setAlternateNumber(employeeDetail.alternateNumber);
        setOfficialEmail(employeeDetail.officialEmail);
        setBloodGroup(employeeDetail.bloodGroup);
        setRequestType(employeeDetail.requestType);
        setmodule(employeeDetail.employeeModules.map((item) => ({
          value: item._id,
          label: item.name
        })));

        // setEmployeeId(employeeDetail.employeeId);
        setDepartmentId(employeeDetail.department._id);
        subdepartmentListforDropdown(employeeDetail.department._id);
        setDesignation(employeeDetail.designation._id);
        setSubdepartment(employeeDetail.subdepartment._id);
        designationListforDropdown(employeeDetail.subdepartment._id);
        setEmployeeType(employeeDetail.employeeType);
        setEmployeeAddress(employeeDetail.employeeAddress);
        // setVehilcType(employeeDetail.vehicleDetails.vehicleType)
        setVehicleNumber(employeeDetail.vehicleDetails.vehicleNumber);
        setLicenseNumber(employeeDetail.vehicleDetails.licenseNumber);
        setLicenseExpiry(employeeDetail.vehicleDetails.licenseExpiry);
        setLicensePhoto(employeeDetail.vehicleDetails.licensePhoto);
        setAppAccess(employeeDetail.appAccess)
        setPhoneCall(employeeDetail.phoneCall)
        setNotification(employeeDetail.enableNotification)
        setVehicleType(employeeDetail.vehicleDetails.vehicleType);
        setStartDate(employeeDetail.startDate);
        setEndDate(employeeDetail.endDate);
        setCompanyName(employeeDetail.companyName);
      }
    }

    dispatch({
      type: EMPLOYEE_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);




  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: EMPLOYEE_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };
  console.log("departmentList");
  console.log(departmentId);

  const departmentListforDropdown = () => {
    axios
      .get(`${server_url}/department`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        setDepartmentList(responce.data.data)
      }).catch(() => console.log("error"))
  }

  const subdepartmentListforDropdown = (depId) => {
    if (depId) {
      axios
        .get(`${server_url}/plant/subdepartment-list/${depId}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then((responce) => {
          setSubdepartmentList(responce.data.data)
        })
    }
    else {
      setSubdepartmentList([])
    }

  }

  const designationListforDropdown = (subdepId) => {
    if (subdepId) {
      axios
        .get(`${server_url}/plant/designation-list/${subdepId}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then((responce) => {
          setDesignationList(responce.data.data)
        })
    }
    else {
      setDesignationList([])
    }

  }

  const moduleListDropdown = () => {
    axios
      .get(`${server_url}/employeeModules?limit=100`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        setModuleList(responce.data.data)
      })


  }



  useEffect(() => {
    departmentListforDropdown()
    moduleListDropdown();

  }, [])

  // console.log("department")
  // console.log(department)


  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">

        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Employee Update Form
          </Text>
          <br />
          <SimpleGrid minChildWidth='120px' spacing='40px'>
            {/* Employee Name */}
            <FormControl isInvalid={isErrorEmployeeName || (submitted && employeeName === "")}>

              <CustomInput
                type='text'
                label='Employee Name'
                value={employeeName}
                onChange={(value) => {
                  setIsErrorEmployeeName(value === '' || !textRegex.test(value));
                  setEmployeeName(value);
                }}
                regex={textRegex}
                submitted={submitted}
              />
            </FormControl>

            {/* Photo */}
            <FormControl >
              <FormLabel>Photo</FormLabel>
              <Input
                type='file'

                onChange={(e) => {

                  setIsErrorPhoto(e.target.value === '')
                  setPhoto(e.target.files[0])
                }}
              />
            </FormControl>
          </SimpleGrid>

          <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
            {/* Phone Number */}
            <FormControl isInvalid={isErrorPhone || (submitted && phone === "")}>

              <CustomInput
                type='number'
                label='Phone Number'
                value={phone}
                onChange={(value) => {
                  setIsErrorPhone(value === '' || !numberRegex.test(value));
                  setPhone(value);
                }}
                regex={numberRegex}
                submitted={submitted}
              />
            </FormControl>

            {/* Alternate Number */}
            <FormControl isInvalid={isErrorAlternateNumber || (submitted && alternateNumber === "")}>

              <CustomInput
                type='number'
                label='Alternate Number'
                value={alternateNumber}
                onChange={(value) => {
                  setIsErrorAlternateNumber(value === '' || !numberRegex.test(value));
                  setAlternateNumber(value);
                }}
                regex={numberRegex}
                submitted={submitted}
              />
            </FormControl>
          </SimpleGrid>

          <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
            {/* Official Email */}
            <FormControl isInvalid={isErrorOfficialEmail || (submitted && officialEmail === "")}>

              <CustomInput
                type='text'
                label='Official Email'
                value={officialEmail}
                onChange={(value) => {
                  setIsErrorOfficialEmail(value === '' || !emailRegex.test(value));
                  setOfficialEmail(value);
                }}
                regex={emailRegex}
                submitted={submitted}
              />
            </FormControl>

            {/* Employee Id
            <FormControl isInvalid={isErrorEmployeeId || (submitted && employeeId === "")}>
            
              <CustomInput
                type='text'
                label='Employee Id'
                value={employeeId}
                onChange={(value) => {
                  setIsErrorEmployeeId(value === '' || !textRegex.test(value));
                  setEmployeeId(value);
                }}
                regex={textRegex}
                submitted={submitted}
              />
            </FormControl> */}
            <FormControl isInvalid={isErrorBloodGroup || (submitted && bloodGroup === "")}>
              <FormLabel>Blood Group</FormLabel>
              <Select
                placeholder="--- Select Blood Group ---"
                onChange={(e) => {
                  setIsErrorBloodGroup(e.target.value === '');
                  setBloodGroup(e.target.value);
                }}
                value={bloodGroup}
              >
                {dataConstant.bloodGroup.map((element, index) => (
                  <option key={element.id} value={element.value} selected={element.value == bloodGroup ? true : false}>{element.name}</option>
                ))}
              </Select>
              {isErrorBloodGroup && submitted && (
                <FormErrorMessage>Blood group is required.</FormErrorMessage>
              )}
            </FormControl>
          </SimpleGrid>




          <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
            {/* Department */}
            <FormControl isInvalid={isErrorDepartment || (submitted && departmentId === "")}>
              <FormLabel>Department</FormLabel>
              <Select
                placeholder="Department"
                onChange={(e) => {
                  setIsErrorDepartment(e.target.value === '');
                  setDepartmentId(e.target.value);
                  subdepartmentListforDropdown(e.target.value);
                }}
                value={departmentId}
              >
                 {departmentList ? (departmentList.map((item, index) => (
                  <option key={item._id} value={item._id} selected={departmentId == item._id ? true : false}>{item.name}</option>

                ))) : (<></>)}
              </Select>
              {isErrorDepartment && submitted && (
                <FormErrorMessage>Department is required.</FormErrorMessage>
              )}
            </FormControl>
            {/* Subdepartment */}
            <FormControl isInvalid={isErrorSubdepartment || (submitted && subdepartment === "")}>
              <FormLabel>Sub Department</FormLabel>
              <Select
                placeholder="Subdepartment"
                onChange={(e) => {
                  setIsErrorSubdepartment(e.target.value === '');
                  setSubdepartment(e.target.value);
                  designationListforDropdown(e.target.value);
                }}
                value={subdepartment}
              >
                  {subdepartmentList ? (subdepartmentList.map((item, index) => (
                  <option key={item._id} value={item._id} selected={subdepartment == item._id ? true : false}>{item.name}</option>
                ))) : (<></>)}
              </Select>
              {isErrorSubdepartment && submitted && (
                <FormErrorMessage>Subdepartment is required.</FormErrorMessage>
              )}
            </FormControl>

              {/* Designation */}
            <FormControl isInvalid={isErrorDesignation || (submitted && designation === "")}>
              <FormLabel>Designation</FormLabel>
              <Select
                placeholder="Designation"
                onChange={(e) => {
                  setIsErrorDesignation(e.target.value === '');
                  setDesignation(e.target.value);
                }}
                value={designation}
              >
                {designationList ? (designationList.map((item, index) => (
                  <option key={item._id} value={item._id} selected={designation == item._id ? true : false}>{item.name}</option>
                ))) : (<></>)}
              </Select>
              {isErrorDesignation && submitted && (
                <FormErrorMessage>Designation is required.</FormErrorMessage>
              )}
            </FormControl>

          </SimpleGrid>

          

          

          <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
                     {/* Employee Address */}
                     <FormControl isInvalid={isErrorEmployeeAddress || (submitted && employeeAddress === "")}>
                      <CustomInput
                        type='text'
                        label='Employee Address'
                        value={employeeAddress}
                        onChange={(value) => {
                          setIsErrorEmployeeAddress(value === '' || !textRegex.test(value));
                          setEmployeeAddress(value);
                        }}
                        regex={textRegex}
                        submitted={submitted}
                      />
                      </FormControl>
            {/* Employee Type */}
            <FormControl isInvalid={isErrorRequestType || (submitted && requestType === "")}>
              <FormLabel>Authorization Type </FormLabel>
              <Select
                placeholder="--- Select Request Type ---"
                value={requestType}
                onChange={(e) => {
                  setIsErrorRequestType(e.target.value === '');
                  setRequestType(e.target.value);
                }}

              >
                {dataConstant.requestType.map((element, index) => (
                  <option key={element.id} value={element.value}>{element.name}</option>
                ))}
              </Select>
              {isErrorRequestType && submitted && (
                <FormErrorMessage> Authorization Type is required.</FormErrorMessage>
              )}
            </FormControl>

            <FormControl isInvalid={isErrorModule || (submitted && module === "")}>
              <FormLabel>Module</FormLabel>
              <Relect
                isMulti
                name="colors"
                placeholder="--select module--"
                options={moduleList.map((item) => ({
                  value: item._id,
                  label: item.name
                }))}
                value={module}
                onChange={(value) => {
                  setmodule(value)
                  setIsErrorModule(value === '')
                }}
                className="basic-multi-select"
                classNamePrefix="select"
              />

              {isErrorModule && submitted && (
                <FormErrorMessage>Module is required.</FormErrorMessage>
              )}
            </FormControl>
          </SimpleGrid>

          <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
          <FormControl isInvalid={isErrorPhoneCall || (submitted && phoneCall === "")}>
              <FormLabel>Phone Call Access </FormLabel>
              <Select
                placeholder="--- Select call Access ---"
                onChange={(e) => {
                  setIsErrorPhoneCall(e.target.value === '');
                  setPhoneCall(e.target.value);
                }}
                value={phoneCall}
              >
                {dataConstant.accessApp.map((element, index) => (
                  <option key={element.id} value={element.value}>{element.name}</option>
                ))}
              </Select>
              {isErrorPhoneCall && submitted && (
                <FormErrorMessage>call Access is required.</FormErrorMessage>
              )}
            </FormControl>
            {/* Call Type */}
            <FormControl isInvalid={isErrorPhoneCallType || (submitted && phoneCallType === "")}>
              <FormLabel> Call Tpye </FormLabel>
              <Select
                placeholder="--- Select call type ---"
                onChange={(e) => {
                  setIsErrorPhoneCallType(e.target.value === '');
                  setPhoneCallType(e.target.value);
                }}
                value={phoneCallType}
              >
                {dataConstant.callTypeList.map((element, index) => (
                  <option key={element.id} value={element.value}>{element.name}</option>
                ))}
              </Select>
              {isErrorPhoneCallType && submitted && (
                <FormErrorMessage>Call type is required.</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={isErrorAppAccess || (submitted && appAccess === "")}>
              <FormLabel>App Access</FormLabel>
              <Select
                placeholder="--- Select App Access ---"
                onChange={(e) => {
                  setIsErrorAppAccess(e.target.value === '');
                  setAppAccess(e.target.value);
                }}
                value={appAccess}
              >
                {dataConstant.accessApp.map((element, index) => (
                  <option key={element.id} value={element.value}>{element.name}</option>
                ))}
              </Select>
              {isErrorAppAccess && submitted && (
                <FormErrorMessage>App Access is required.</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={isErrorNotification || (submitted && notification === "")}>
              <FormLabel>Department Notification</FormLabel>
              <Select
                placeholder="--- Select Department Notification ---"
                onChange={(e) => {
                  setIsErrorNotification(e.target.value === '');
                  setNotification(e.target.value);
                }}
                value={notification}
              >
                {dataConstant.notification.map((element, index) => (
                  <option key={element.id} value={element.value}>{element.name}</option>
                ))}
              </Select>
              {isErrorNotification && submitted && (
                <FormErrorMessage>Notification is required.</FormErrorMessage>
              )}
            </FormControl>
          </SimpleGrid>
          


          <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
            <FormControl >
              <FormLabel>Vehicle Type</FormLabel>
              <Select
                placeholder="--- Select Vehicle Type ---"
                value={vehicleType}
                onChange={(e) => {
                  setIsErrorVehilcType(e.target.value === '');
                  setVehicleType(e.target.value);

                  { e.target.value == "BY_WALK" ? (setDisable(true)) : (setDisable(false)) }

                }}
              >
                {dataConstant.vechilType.map((element, index) => (
                  <option key={element.id} value={element.name}>{element.name}</option>
                ))}
              </Select>
              {isErrorVehiclType && submitted && (
                <FormErrorMessage>Vehicle Type is required.</FormErrorMessage>
              )}
            </FormControl>

            {/* vehicle Number */}
            <FormControl >

              <CustomInput
                type='text'
                label='Vehicle Number'
                isDisabled={disable}
                value={vehicleNumber}
                onChange={(value) => {
                  setIsErrorVehicleNumber(value === '' || !vechilenoRegex.test(value));
                  setVehicleNumber(value);
                }}
                regex={vechilenoRegex}

              />
            </FormControl>
            <FormControl >

              <CustomInput
                type='text'
                isDisabled={disable}
                label='License Number'
                value={licenseNumber}
                onChange={(value) => {
                  setIsErrorLicenseNumber(value === '' || !licencenoRegex.test(value));
                  setLicenseNumber(value);
                }}
                regex={licencenoRegex}

              />
            </FormControl>
          </SimpleGrid>
          <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>




            <FormControl >
              <FormLabel>License Expiry Date</FormLabel>
              <Input
                isDisabled={disable}
                type='Text'
                value={licenseExpiry}
                onChange={(e) => {
                  setIsErrorLicenseExpiry(e.target.value === '')
                  setLicenseExpiry(e.target.value)
                }}
              />
              {!isErrorLicenseExpiry ? (
                <></>
              ) : (
                <FormErrorMessage>
                  License Expiry Date is required.
                </FormErrorMessage>
              )}
            </FormControl>

            <FormControl >
              <FormLabel>License Photo</FormLabel>
              <Input
                type='file'
                isDisabled={disable}

                onChange={(e) => {

                  setIsErrorLicensePhoto(e.target.value === '')
                  setLicensePhoto(e.target.files[0])
                }}
              />
              {!isErrorLicensePhoto ? (
                <></>
              ) : (
                <FormErrorMessage>License Photo is required.</FormErrorMessage>
              )}
            </FormControl>
            {/* Employee Type */}
            <FormControl isInvalid={isErrorEmployeeType || (submitted && employeeType === "")}>
              <FormLabel>Employee Type</FormLabel>
              <Select
                placeholder="--- Select Employee Type ---"
                onChange={(e) => {
                  setIsErrorEmployeeType(e.target.value === '');
                  setEmployeeType(e.target.value);
                }}
                value={employeeType}
              >
                {dataConstant.employeeType.map((element, index) => (
                  <option key={element.id} value={element.value}>{element.name}</option>
                ))}
              </Select>
              {isErrorEmployeeType && submitted && (
                <FormErrorMessage>Employee Type is required.</FormErrorMessage>
              )}
            </FormControl>
          </SimpleGrid>

          <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
            {employeeType == 3 || employeeType == 4 ?
              <>

                <FormControl isInvalid={isErrorStartDate || (submitted && startDate === "")}>
                  <FormLabel>Start Date</FormLabel>
                  <Input
                    type='text'
                    value={startDate}
                    onChange={(e) => {

                      setIsErrorStartDate(e.target.value === '')
                      setStartDate(e.target.value)
                    }}
                  />
                  {!isErrorStartDate ? (
                    <></>
                  ) : (
                    <FormErrorMessage>Start Date is required.</FormErrorMessage>
                  )}
                </FormControl>

                <FormControl isInvalid={isErrorEndDate || (submitted && endDate === "")}>
                  <FormLabel>End Date</FormLabel>
                  <Input
                    type='text'
                    value={endDate}
                    onChange={(e) => {

                      setIsErrorEndDate(e.target.value === '')
                      setEndDate(e.target.value)
                    }}
                  />
                  {!isErrorLicensePhoto ? (
                    <></>
                  ) : (
                    <FormErrorMessage>End Date is required.</FormErrorMessage>
                  )}
                </FormControl>

                <FormControl isInvalid={isErrorCompanyName || (submitted && companyName === "")}>
                  <FormLabel>Service Provider Company Name</FormLabel>
                  <Input
                    type='text'
                    value={companyName}
                    // onChange={(e) => {
                    //   setIsErrorCompanyName(e.target.value === '' || !textRegex.test(value));
                    //   setCompanyName(e.target.value);
                    // }}
                    onChange={(e) => {

                      setIsErrorCompanyName(e.target.value === '')
                      setCompanyName(e.target.value)
                    }}
                    regex={textRegex}

                  />
                  {!isErrorCompanyName ? (
                    <></>
                  ) : (
                    <FormErrorMessage>Service Provider Company Name is required.</FormErrorMessage>
                  )}
                </FormControl>

              </>
              : <></>}
          </SimpleGrid>
          <Center>
            {loading ? <Spinner mt='24px' size='lg' /> :
              <Button colorScheme='blue' mt='24px' onClick={handleSubmit}>
                Submit
              </Button>
            }
          </Center>
        </CardHeader>
        <CardBody>
        </CardBody>
      </Card>
      <Spacer></Spacer>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px" mt="4">
        <CardHeader p="6px 0px 22px 0px">
          <Flex flexDirection="row">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Employee Photo
            </Text>
            <Box boxSize='sm'>
              <Image src={photoDisplay} alt='Dan Abramov' />
            </Box>
            <Divider orientation="vertical" mx={4} h="auto" />
            {licensePhoto ? (
              <>
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                  License Photo
                </Text>
                <Box boxSize='sm'>
                  <Image src={licensePhoto} alt='Employee Photo' />
                </Box>
              </>
            ) : null}
          </Flex>

        </CardHeader>
        <CardBody>
        </CardBody>
      </Card>


    </Flex>
  );
}

export default EmployeeUpdateForm;
