import {
  DRIVER_LOADING,
  DRIVER_GET_ALL_SUCCESS,
  DRIVER_ERROR,
  DRIVER_CLEAR_SNACKBAR,
  DRIVER_SEARCH  // Add the new action type
} from '../constants/types';

const initialState = {
  response: {},
  driverList: [],
  driverAttendenceHistoryList : [],
  driverDetail: {},
  loading: false,
  status: 'success',
  text: '',
  showSnackbar: false,
  limit: 0,  // Add missing properties
  page: 0,
  totalPages: 0,
  searching: ""  // Add missing properties
};

const driverReducer = (state = initialState, action) => {
  switch (action.type) {
    case DRIVER_CLEAR_SNACKBAR:
      return {
        ...state,
        showSnackbar: false
      };
    case DRIVER_LOADING:
      return {
        ...state,
        loading: true
      };
    case DRIVER_ERROR:
      return {
        ...state,
        driverList: [],
        driverAttendenceHistoryList : [],
        response: {},
        driverDetail: {},
        limit: 0,  // Add missing properties
        page: 0,
        totalPages: 0,
        loading: false,
        status: action.payload.status,
        text: action.payload.text,
        showSnackbar: true
      };
    case DRIVER_GET_ALL_SUCCESS:
      return {
        ...state,
        driverList: action.payload.driverList,
        driverAttendenceHistoryList : action.payload.driverAttendenceHistoryList,
        response: action.payload.response,
        driverDetail: action.payload.driverDetail,
        limit: action.payload.limit,
        page: action.payload.page,
        totalPages: action.payload.totalPages,
        loading: false,
        status: action.payload.status,
        text: action.payload.text,
        showSnackbar: true
      };
    case DRIVER_SEARCH: // Add the new case
      return {
        ...state,
        searching: action.payload.searching
      };
    default:
      return state;
  }
};

export default driverReducer;
