import axios from 'axios';
import {
  CALL_SETTING_LOADING,
  CALL_SETTING_GET_ALL_SUCCESS,
  CALL_SETTING_ERROR,
  CALL_SETTING_CLEAR_SNACKBAR
} from '../constants/types';

import { server_url } from '../constants/types';

export const callSettingGetAll = (pagination , callSetting) => {

  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: CALL_SETTING_LOADING,
        payload: {
          loading: true
        }
      });
      axios
        .post(`${server_url}/employee/call-setting` ,callSetting , {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
        .then((response) => {
          dispatch({
            type: CALL_SETTING_GET_ALL_SUCCESS,
            payload: {
              settingList: response.data.data,
              response: response.data,
              limit: response.data.limit,
              page: response.data.currentPage,
              totalPages: response.data.totalPages,
              loading: false,
              status: 'success',
              text: 'Get all call settings successfully.'
            }
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: CALL_SETTING_ERROR,
            payload: {
              status: 'error',
              text: 'Error occurred during getting call setting data.',
              loading: false
            }
          });
          resolve();
        });
    });
  };
};

export const callSettingAddOrUpdate = (callSetting, state) => {
  console.log("callSetting");
  console.log(callSetting);

  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: CALL_SETTING_LOADING,
        payload: {
          loading: true
        }
      });

      if (state === "edit") {
        axios
          .put(
            `${server_url}/highalert/settings/${callSetting._id}`,
            callSetting,
            {
              headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
              }
            }
          )
          .then((responseF) => {
            axios
              .get(`${server_url}/highalert/settings-list`, {
                headers: {
                  'Authorization': 'Bearer ' + localStorage.getItem('authToken')
                }
              })
              .then((response) => {
                dispatch({
                  type: CALL_SETTING_GET_ALL_SUCCESS,
                  payload: {
                    settingList: response.data.data,
                    response: response.data,
                    limit: response.data.limit,
                    page: response.data.currentPage,
                    totalPages: response.data.totalPages,
                    highDetail: responseF.data['data'],
                    loading: false,
                    status: responseF.data['status'],
                    text:responseF.data['message'],
                  }
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: CALL_SETTING_ERROR,
                  payload: {
                    status: 'error',
                    text: 'Error occurred during getting call setting data.',
                    loading: false
                  }
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: CALL_SETTING_ERROR,
              payload: {
                status: 'error',
                text: 'Error occurred during updating call setting data.',
                loading: false
              }
            });
            resolve();
          });
      }
      else if (state === "view") {
        axios.get(`${server_url}/highalert/settings-Details/${callSetting._id}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
        .then((responseF) => {
          axios
            .get(`${server_url}/highalert/settings-list`, {
              headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
              }
            })
              .then(response => {
                dispatch({
                  type: CALL_SETTING_GET_ALL_SUCCESS,
                  payload: {
                    settingList: response.data.data,
                    response: response.data,
                    limit: response.data.limit,
                    page: response.data.currentPage,
                    totalPages: response.data.totalPages,
                    highDetail: responseF.data['data'],
                    loading: false,
                    status: responseF.data['status'],
                    text:responseF.data['message']
                  }
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: CALL_SETTING_ERROR,
                  payload: {
                    status: 'error',
                    text: 'Error occurred during getting call setting data.',
                    loading: false
                  }
                });
                resolve();
              })
          })
          .catch((e) => {
            dispatch({
              type: CALL_SETTING_ERROR,
              payload: {
                status: 'error',
                text: 'Error occurred during getting call setting data.',
                loading: false
              }
            });
            resolve();
          })
      }
    });
  };
};

// export const callSettingAddOrUpdate = (callSetting) => {

//   return (dispatch, getState) => {
//     return new Promise((resolve) => {
//       dispatch({
//         type: CALL_SETTING_LOADING,
//         payload: {
//           loading: true
//         }
//       });
//       axios
//         .put(`${server_url}/highalert/settings/${callSetting._id}` ,callSetting , {
//           headers: {
//             'Authorization': 'Bearer ' + localStorage.getItem('authToken')
//           }
//         })
//         .then((responseF) => {
//           axios.get(`${server_url}/highalert/settings-list` , {
//             headers: {
//               'Authorization': 'Bearer ' + localStorage.getItem('authToken')
//             }
//           })
//         })
//         .then((response) => {
//           dispatch({
//             type: CALL_SETTING_GET_ALL_SUCCESS,
//             payload: {
//               settingList: response.data.data,
//               response: response.data,
//               limit: response.data.limit,
//               page: response.data.currentPage,
//               totalPages: response.data.totalPages,
//               highDetail: responseF.data['data'],
//               loading: false,
//               status: responseF.data['status'],
//               text: 'Get all call settings successfully.'
//             }
//           });
//           resolve();
//         })
//         .catch((e) => {
//           dispatch({
//             type: CALL_SETTING_ERROR,
//             payload: {
//               status: 'error',
//               text: 'Error occurred during getting call setting data.',
//               loading: false
//             }
//           });
//           resolve();
//         });
//     })
//     .catch((e) => {
//       dispatch({
//         type: CALL_SETTING_ERROR,
//         payload: {
//           text: "Error occurred during adding setting data.",
//           status: "error",
//           loading: false
//         }
//       });
//       resolve();
//     })
//   };
// };
