// Chakra imports
import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  SimpleGrid,
  Spacer,
  Text,
  Wrap,
  WrapItem,
  useColorModeValue,
  Select,

} from "@chakra-ui/react";
import Relect from 'react-select';
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { bannerAddOrUpdate, bannerClearShowSnackbar } from '../../actions/bannerActions';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
} from '@chakra-ui/react'
import { useToast } from '@chakra-ui/react'

import { Spinner } from '@chakra-ui/react'
import dataConstant from "constants/dataConstant";
import { EMPLOYEE_CLEAR_SNACKBAR } from "constants/types";
import { employeeAddOrUpdate } from "actions/EmployeeAction";
import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom';

import CustomInput from "views/CustomValidation";

function EmployeeForm() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [employeeName, setEmployeeName] = useState("");
  const [photo, setPhoto] = useState("");
  const [phone, setPhone] = useState("");
  const [alternateNumber, setAlternateNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [officialEmail, setOfficialEmail] = useState('');
  const [phoneCall , setPhoneCall] = useState(false)
  const [phoneCallType , setPhoneCallType] = useState("NOTASSIGNED");

  const [departmentList, setDepartmentList] = useState('')
  const [departmentId, setDepartmentId] = useState("");
  const [designation, setDesignation] = useState("");
  const [subdepartment, setSubdepartment] = useState("");
  const [employeeType, setEmployeeType] = useState('');
  const [employeeAddress, setEmployeeAddress] = useState('');
  const [subdepartmentList, setSubdepartmentList] = useState([])
  const [designationList, setDesignationList] = useState([])
  const [vehicleType, setVehicleType] = useState("");
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [licenseNumber, setLicenseNumber] = useState('');
  const [licenseExpiry, setLicenseExpiry] = useState('');
  const [licensePhoto, setLicensePhoto] = useState('')
  const [disable, setDisable] = useState(false);
  const [appAccess, setAppAccess] = useState(false);
  const [notification, setNotification] = useState(false);
  const [bloodGroup, setBloodGroup] = useState(false);
  const [requestType, setRequestType] = useState('');
  const [module, setmodule] = useState();
  const [moduleList, setModuleList] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isErrorEmployeeName, setIsErrorEmployeeName] = useState(false);
  const [isErrorPhoto, setIsErrorPhoto] = useState(false);
  const [isErrorPhone, setIsErrorPhone] = useState(false);
  const [isErrorAlternateNumber, setIsErrorAlternateNumber] = useState(false);
  const [isErrorCompanyName, setIsErrorCompanyName] = useState(false);
  const [isErrorOfficialEmail, setIsErrorOfficialEmail] = useState(false);
  const [isErrorPhoneCall, setIsErrorPhoneCall] = useState(false);
  const [isErrorPhoneCallType, setIsErrorPhoneCallType] = useState(false);

  const [isErrorDepartment, setIsErrorDepartment] = useState(false);
  const [isErrorDesignation, setIsErrorDesignation] = useState(false);
  const [isErrorSubdepartment, setIsErrorSubdepartment] = useState(false);
  const [isErrorEmployeeType, setIsErrorEmployeeType] = useState(false);
  const [isErrorEmployeeAddress, setIsErrorEmployeeAddress] = useState(false);
  const [isErrorVehiclType, setIsErrorVehilcType] = useState(false);
  const [isErrorVehicleNumber, setIsErrorVehicleNumber] = useState(false);
  const [isErrorLicenseNumber, setIsErrorLicenseNumber] = useState(false);
  const [isErrorLicenseExpiry, setIsErrorLicenseExpiry] = useState(false);
  const [isErrorLicensePhoto, setIsErrorLicensePhoto] = useState(false);
  const [isErrorAppAccess, setIsErrorAppAccess] = useState(false);
  const [isErrorNotification, setIsErrorNotification] = useState(false);
  const [isErrorBloodGroup, setIsErrorBloodGroup] = useState(false);
  const [isErrorRequestType, setIsErrorRequestType] = useState(false);
  const [isErrorStartDate, setIsErrorStartDate] = useState(false);
  const [isErrorEndDate, setIsErrorEndDate] = useState(false);
  const [isErrorModule, setIsErrorModule] = useState(false);

  const [submitted, setSubmitted] = useState(false);
console.log("companyName")
console.log(companyName)
  const textRegex = /^[A-Za-z,\s]+$/;

  const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,10}$/;
  const numberRegex = /^\d{10}$/;
  const vechilenoRegex = /^[A-Za-z0-9!@#$%^&*()_\-\[\]{};':"\\|,.<>\/? ]*$/;
  const licencenoRegex = /^[A-Za-z0-9]{6,12}$/

  const dispatch = useDispatch();
  const toast = useToast();

  const statuses = ['success', 'error', 'warning', 'info'];
  const responseStatus = useSelector(state => state.employee.status);
  const responseMessage = useSelector(state => state.employee.text);
  const loading = useSelector(state => state.employee.loading);
  const showSnackbar = useSelector(state => state.employee.showSnackbar);
  const history = useHistory();
  // const employeeList = useSelector(state => state.employee.employeeList)



  // Function to handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    setIsErrorEmployeeName(employeeName === '');
    setIsErrorPhoto(photo === '');
    setIsErrorPhone(phone === '');
    // setIsErrorAlternateNumber(alternateNumber === '');
    setIsErrorCompanyName(companyName === '');
    setIsErrorOfficialEmail(officialEmail === '');

    setIsErrorDepartment(departmentId === '');
    setIsErrorDesignation(designation === '');
    setIsErrorSubdepartment(subdepartment === '');
    setIsErrorEmployeeType(employeeType === '');
    setIsErrorEmployeeAddress(employeeAddress === '');
    setIsErrorVehilcType(vehicleType === '');
    setIsErrorLicenseExpiry(licenseExpiry === '');
    setIsErrorLicensePhoto(licensePhoto === '');
    setIsErrorLicensePhoto(licensePhoto === '');
    setIsErrorBloodGroup(bloodGroup === '');
    setIsErrorRequestType(requestType === '');
    setIsErrorStartDate(startDate === '');
    setIsErrorEndDate(endDate === '');
    setIsErrorPhoneCall(phoneCall === '');


    if (employeeName !== "" && photo !== "" && phone !== ""  && officialEmail !== "" && designation !== "" && employeeType !== "" && employeeAddress !== "" && bloodGroup !== "" && requestType !== "" || startDate !== "" || endDate !== "" || phoneCall !== "") {
      console.log("Submit");
      // Get form data
      var newModule = [];
      const employee = {

        employeeName: employeeName,
        file1: photo,
        phone: phone,
        alternateNumber: alternateNumber,
        officialEmail: officialEmail,
        department: departmentId,
        designation: designation,
        subdepartment: subdepartment,
        employeeType: employeeType,
        employeeAddress: employeeAddress,
        companyName : companyName,
        appAccess: appAccess,
        phoneType: phoneCallType,
        phoneCall : phoneCall,
        enableNotification: notification,
        startDate: startDate,
        endDate: endDate,
        'employeeModules': module.map((module) => module.value),
        bloodGroup: bloodGroup,
        requestType: requestType,
        vehicleDetails: {
          vehicleType: vehicleType,
          vehicleNumber: vehicleNumber,
          licenseNumber: licenseNumber,
          licenseExpiry: licenseExpiry,

        },
        file2: licensePhoto,


      };
      console.log("employeeData");
      console.log(employee);

      // Determine if it's an add or edit operation
      const state = 'add';
      // Call the employeeAddOrUpdate action
      dispatch(employeeAddOrUpdate(employee, state))
      // history.push("/admin/employee-table")

    }
  };

  const departmentListforDropdown = () => {
    axios
      .get(`${server_url}/department`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        setDepartmentList(responce.data.data)
      }).catch(() => console.log("error"))
  }

  const subdepartmentListforDropdown = (depId) => {
    if (depId) {
      axios
        .get(`${server_url}/plant/subdepartment-list/${depId}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then((responce) => {
          setSubdepartmentList(responce.data.data)
        })
    }
    else {
      setSubdepartmentList([])
    }

  }

  const designationListforDropdown = (subdepId) => {
    if (subdepId) {
      axios
        .get(`${server_url}/plant/designation-list/${subdepId}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then((responce) => {
          setDesignationList(responce.data.data)
        })
    }
    else {
      setDesignationList([])
    }

  }

  const moduleListDropdown = () => {
    axios
      .get(`${server_url}/employeeModules`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        setModuleList(responce.data.data)
      })


  }


  useEffect(() => {
    departmentListforDropdown()
    moduleListDropdown();

  }, [])

  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])


  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
          history.push('/admin/employee-table');
        }
      } else if (responseStatus === 'NOK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: EMPLOYEE_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })


  };



  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">

        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Employee Form 
          </Text>
          <SimpleGrid minChildWidth='120px' spacing='40px'>
            {/* Employee Name */}
            <FormControl isInvalid={isErrorEmployeeName || (submitted && employeeName === "")}>

              <CustomInput
                type='text'
                label='Employee Name'
                value={employeeName}
                onChange={(value) => {
                  setIsErrorEmployeeName(value === '' || !textRegex.test(value));
                  setEmployeeName(value);
                }}
                regex={textRegex}
                submitted={submitted}
              />
            </FormControl>

            {/* Photo */}
            <FormControl isInvalid={isErrorPhoto}>
              <FormLabel>Photo</FormLabel>
              <Input
                type='file'

                onChange={(e) => {

                  setIsErrorPhoto(e.target.value === '')
                  setPhoto(e.target.files[0])
                }}
              />
              {!isErrorPhoto ? (
                <></>
              ) : (
                <FormErrorMessage>Photo is required.</FormErrorMessage>
              )}
            </FormControl>
          </SimpleGrid>

          <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
            {/* Phone Number */}
            <FormControl isInvalid={isErrorPhone || (submitted && phone === "")}>

              <CustomInput
                type='number'
                label='Phone Number'
                value={phone}
                onChange={(value) => {
                  setIsErrorPhone(value === '' || !numberRegex.test(value));
                  setPhone(value);
                }}
                regex={numberRegex}
                submitted={submitted}
              />
            </FormControl>

            {/* Alternate Number */}
            <FormControl >
            {/* <FormLabel>Alternate Number</FormLabel> */}
              <CustomInput
                type='number'
                label='Alternate Number'
                value={alternateNumber}
                onChange={(value) => {
                  // setIsErrorAlternateNumber(value === '' || !numberRegex.test(value));
                  setAlternateNumber(value);
                }}
                regex={numberRegex}
                submitted={submitted}
              />
            </FormControl>
          </SimpleGrid>

          <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
            {/* Official Email */}
            <FormControl isInvalid={isErrorOfficialEmail || (submitted && officialEmail === "")}>

              <CustomInput
                type='text'
                label='Official Email'
                value={officialEmail}
                onChange={(value) => {
                  setIsErrorOfficialEmail(value === '' || !emailRegex.test(value));
                  setOfficialEmail(value);
                }}
                regex={emailRegex}
                submitted={submitted}
              />
            </FormControl>

            {/* Employee Id
              <FormControl isInvalid={isErrorEmployeeId || (submitted && employeeId === "")}>
              
                <CustomInput
                  type='text'
                  label='Employee Id'
                  value={employeeId}
                  onChange={(value) => {
                    setIsErrorEmployeeId(value === '' || !textRegex.test(value));
                    setEmployeeId(value);
                  }}
                  regex={textRegex}
                  submitted={submitted}
                />
              </FormControl> */}
            <FormControl isInvalid={isErrorBloodGroup || (submitted && bloodGroup === "")}>
              <FormLabel>Blood Group</FormLabel>
              <Select
                placeholder="--- Select Blood Group ---"
                onChange={(e) => {
                  setIsErrorBloodGroup(e.target.value === '');
                  setBloodGroup(e.target.value);
                }}
                value={bloodGroup}
              >
                {dataConstant.bloodGroup.map((element, index) => (
                  <option key={element.id} value={element.value}>{element.name}</option>
                ))}
              </Select>
              {isErrorBloodGroup && submitted && (
                <FormErrorMessage>Blood group is required.</FormErrorMessage>
              )}
            </FormControl>
          </SimpleGrid>

          <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
            {/* Department */}
            <FormControl isInvalid={isErrorDepartment || (submitted && departmentId === "")}>
              <FormLabel>Department</FormLabel>
              <Select
                placeholder="Department"
                onChange={(e) => {
                  setIsErrorDepartment(e.target.value === '');
                  setDepartmentId(e.target.value);
                  subdepartmentListforDropdown(e.target.value);
                }}
                value={departmentId}
              >
                {departmentList ? (departmentList.map((item, index) => (
                  <option key={item._id} value={item._id}>{item.name}</option>
                ))) : (<></>)}
              </Select>
              {isErrorDepartment && submitted && (
                <FormErrorMessage>Department is required.</FormErrorMessage>
              )}
            </FormControl>
            {/* Subdepartment */}
            <FormControl isInvalid={isErrorSubdepartment || (submitted && subdepartment === "")}>
              <FormLabel>Sub Department</FormLabel>
              <Select
                placeholder="Subdepartment"
                onChange={(e) => {
                  setIsErrorSubdepartment(e.target.value === '');
                  setSubdepartment(e.target.value);
                  designationListforDropdown(e.target.value);
                }}
                value={subdepartment}
              >
                {subdepartmentList ? (subdepartmentList.map((item, index) => (
                  <option key={item._id} value={item._id}>{item.name}</option>
                ))) : (<></>)}
              </Select>
              {isErrorSubdepartment && submitted && (
                <FormErrorMessage>Subdepartment is required.</FormErrorMessage>
              )}
            </FormControl>

              {/* Designation */}
            <FormControl isInvalid={isErrorDesignation || (submitted && designation === "")}>
              <FormLabel>Designation</FormLabel>
              <Select
                placeholder="Designation"
                onChange={(e) => {
                  setIsErrorDesignation(e.target.value === '');
                  setDesignation(e.target.value);
                }}
                value={designation}
              >
                {designationList ? (designationList.map((item, index) => (
                  <option key={item._id} value={item._id}>{item.name}</option>
                ))) : (<></>)}
              </Select>
              {isErrorDesignation && submitted && (
                <FormErrorMessage>Designation is required.</FormErrorMessage>
              )}
            </FormControl>

          </SimpleGrid>

          <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
             {/* Employee Address */}
             <FormControl isInvalid={isErrorEmployeeAddress || (submitted && employeeAddress === "")}>

              <CustomInput
                type='text'
                label='Employee Address'
                value={employeeAddress}
                onChange={(value) => {
                  setIsErrorEmployeeAddress(value === '' || !vechilenoRegex.test(value));
                  setEmployeeAddress(value);
                }}
                regex={vechilenoRegex}
                submitted={submitted}
              />
              </FormControl>
            {/* Employee Type */}
            <FormControl isInvalid={isErrorRequestType || (submitted && requestType === "")}>
              <FormLabel> Authorization Type</FormLabel>
              <Select
                placeholder="--- Select Request Type ---"
                onChange={(e) => {
                  setIsErrorRequestType(e.target.value === '');
                  setRequestType(e.target.value);
                }}
                value={requestType}
              >
                {dataConstant.requestType.map((element, index) => (
                  <option key={element.id} value={element.value}>{element.name}</option>
                ))}
              </Select>
              {isErrorRequestType && submitted && (
                <FormErrorMessage>Authorization Type is required.</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={isErrorModule || (submitted && module === "")}>
              <FormLabel>Module</FormLabel>
              <Relect
                isMulti
                name="colors"
                placeholder="--select module--"
                options={moduleList.map((item) => ({
                  value: item._id,
                  label: item.name
                }))}

                onChange={(value) => {
                  setmodule(value)
                  setIsErrorModule(value === '')
                }}
                className="basic-multi-select"
                classNamePrefix="select"
              />

              {isErrorModule && submitted && (
                <FormErrorMessage>Module is required.</FormErrorMessage>
              )}
            </FormControl>
          </SimpleGrid>

          <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
          

          <FormControl isInvalid={isErrorPhoneCall || (submitted && phoneCall === "")}>
              <FormLabel>Phone Call Access </FormLabel>
              <Select
                placeholder="--- Select call Access ---"
                onChange={(e) => {
                  setIsErrorPhoneCall(e.target.value === '');
                  setPhoneCall(e.target.value);
                }}
                value={phoneCall}
              >
                {dataConstant.accessApp.map((element, index) => (
                  <option key={element.id} value={element.value}>{element.name}</option>
                ))}
              </Select>
              {isErrorPhoneCall && submitted && (
                <FormErrorMessage>Call access is required.</FormErrorMessage>
              )}
            </FormControl>
            {/* Call Type */}
            <FormControl isInvalid={isErrorPhoneCallType || (submitted && phoneCallType === "")}>
              <FormLabel> Call Tpye </FormLabel>
              <Select
                placeholder="--- Select call type ---"
                onChange={(e) => {
                  setIsErrorPhoneCallType(e.target.value === '');
                  setPhoneCallType(e.target.value);
                }}
                value={phoneCallType}
              >
                {dataConstant.callTypeList.map((element, index) => (
                  <option key={element.id} value={element.value}>{element.name}</option>
                ))}
              </Select>
              {isErrorPhoneCallType && submitted && (
                <FormErrorMessage>Call type is required.</FormErrorMessage>
              )}
            </FormControl>

            <FormControl isInvalid={isErrorAppAccess || (submitted && appAccess === "")}>
              <FormLabel>App Access</FormLabel>
              <Select
                placeholder="--- Select App Access ---"
                onChange={(e) => {
                  setIsErrorAppAccess(e.target.value === '');
                  setAppAccess(e.target.value);
                }}
                value={appAccess}
              >
                {dataConstant.accessApp.map((element, index) => (
                  <option key={element.id} value={element.value}>{element.name}</option>
                ))}
              </Select>
              {isErrorAppAccess && submitted && (
                <FormErrorMessage>App Access is required.</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={isErrorNotification || (submitted && notification === "")}>
              <FormLabel>Department Notification</FormLabel>
              <Select
                placeholder="--- Select Department Notification ---"
                onChange={(e) => {
                  setIsErrorNotification(e.target.value === '');
                  setNotification(e.target.value);
                }}
                value={notification}
              >
                {dataConstant.notification.map((element, index) => (
                  <option key={element.id} value={element.value}>{element.name}</option>
                ))}
              </Select>
              {isErrorNotification && submitted && (
                <FormErrorMessage>Notification is required.</FormErrorMessage>
              )}
            </FormControl>
          </SimpleGrid>


          <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
            <FormControl >
              <FormLabel>Vehicle Type</FormLabel>
              <Select
                placeholder="--- Select Vehicle Type ---"
                value={vehicleType}
                onChange={(e) => {
                  setIsErrorVehilcType(e.target.value === '');
                  setVehicleType(e.target.value);

                  { e.target.value == "BY_WALK" ? (setDisable(true)) : (setDisable(false)) }

                }}
              >
                {dataConstant.vechilType.map((element, index) => (
                  <option key={element.id} value={element.name}>{element.name}</option>
                ))}
              </Select>
              {isErrorVehiclType && submitted && (
                <FormErrorMessage>Vehicle Type is required.</FormErrorMessage>
              )}
            </FormControl>

            {/* vehicle Number */}
            <FormControl >

              <CustomInput
                type='text'
                label='Vehicle Number'
                isDisabled={disable}
                value={vehicleNumber}
                onChange={(value) => {
                  setIsErrorVehicleNumber(value === '' || !vechilenoRegex.test(value));
                  setVehicleNumber(value);
                }}
                regex={vechilenoRegex}

              />
            </FormControl>
            <FormControl >

              <CustomInput
                type='text'
                isDisabled={disable}
                label='License Number'
                value={licenseNumber}
                onChange={(value) => {
                  setIsErrorLicenseNumber(value === '' || !licencenoRegex.test(value));
                  setLicenseNumber(value);
                }}
                regex={licencenoRegex}

              />
            </FormControl>
          </SimpleGrid>

          <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
            <FormControl >
              <FormLabel>License Expiry Date</FormLabel>
              <Input
                isDisabled={disable}
                type='Date'
                value={licenseExpiry}
                onChange={(e) => {
                  setIsErrorLicenseExpiry(e.target.value === '')
                  setLicenseExpiry(e.target.value)
                }}
              />
              {!isErrorLicenseExpiry ? (
                <></>
              ) : (
                <FormErrorMessage>
                  License Expiry Date is required.
                </FormErrorMessage>
              )}
            </FormControl>

            <FormControl >
              <FormLabel>License Photo</FormLabel>
              <Input
                type='file'
                isDisabled={disable}

                onChange={(e) => {

                  setIsErrorLicensePhoto(e.target.value === '')
                  setLicensePhoto(e.target.files[0])
                }}
              />
              {!isErrorLicensePhoto ? (
                <></>
              ) : (
                <FormErrorMessage>License Photo is required.</FormErrorMessage>
              )}
            </FormControl>
            {/* Employee Type */}
            <FormControl isInvalid={isErrorEmployeeType || (submitted && employeeType === "")}>
              <FormLabel>Employee Type</FormLabel>
              <Select
                placeholder="--- Select Employee Type ---"
                onChange={(e) => {
                  setIsErrorEmployeeType(e.target.value === '');
                  setEmployeeType(e.target.value);
                }}
                value={employeeType}
              >
                {dataConstant.employeeType.map((element, index) => (
                  <option key={element.id} value={element.value}>{element.name}</option>
                ))}
              </Select>
              {!isErrorEmployeeType ? (
                <></>
              ) : (
                <FormErrorMessage>Employee Type is required.</FormErrorMessage>
              )}
            </FormControl>
          </SimpleGrid>

          <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
            {/* <Flex  minChildWidth='120px' spacing='40px' mt='24px'> */}
            {employeeType == 3 || employeeType == 4 ?
              <>

                <FormControl isInvalid={isErrorStartDate || (submitted && startDate === "")}>
                  <FormLabel>Start Date</FormLabel>
                  <Input
                    type='date'
                    onChange={(e) => {

                      setIsErrorStartDate(e.target.value === '')
                      setStartDate(e.target.value)
                    }}
                  />
                  {!isErrorStartDate ? (
                    <></>
                  ) : (
                    <FormErrorMessage>Start Date is required.</FormErrorMessage>
                  )}
                </FormControl>

                <FormControl isInvalid={isErrorEndDate || (submitted && endDate === "")}>
                  <FormLabel>End Date</FormLabel>
                  <Input
                    type='date'
                    onChange={(e) => {

                      setIsErrorEndDate(e.target.value === '')
                      setEndDate(e.target.value)
                    }}
                  />
                  {!isErrorLicensePhoto ? (
                    <></>
                  ) : (
                    <FormErrorMessage>End Date is required.</FormErrorMessage>
                  )}
                </FormControl>

                <FormControl isInvalid={isErrorCompanyName || (submitted && endDate === "")}>
                  <FormLabel>Service Provider Company Name</FormLabel>
                  <Input
                    type='text'
                    // value={companyName}
                    // onChange={(e) => {
                    //   setIsErrorCompanyName(e.target.value === '' || !textRegex.test(value));
                    //   setCompanyName(e.target.value);
                    // }}
                      onChange={(e) => {

                        setIsErrorCompanyName(e.target.value === '')
                        setCompanyName(e.target.value)
                    }}
                    regex={textRegex}

                  />
                  {!isErrorCompanyName ? (
                    <></>
                  ) : (
                    <FormErrorMessage>Service Provider Company Name is required.</FormErrorMessage>
                  )}
                </FormControl>
              </>
              : <></>}
            {/* </Flex> */}

          </SimpleGrid>

          <Center>
            {loading ? <Spinner mt='24px' size='lg' /> :
              <Button colorScheme='blue' mt='24px' onClick={handleSubmit}>
                Submit
              </Button>
            }
          </Center>
        </CardHeader>
        <CardBody>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default EmployeeForm;

