import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  SimpleGrid,
  Spacer,
  Text,
  Wrap,
  WrapItem,
  useColorModeValue,
  Select,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Spinner,
  Image
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader";
import { useDispatch, useSelector } from "react-redux";
import { driverAddOrUpdate } from "actions/DriverAction";
import CustomInput from "views/CustomValidation";
import axios from "axios";
import { server_url } from "constants/types";
import { useToast } from '@chakra-ui/react'
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { DRIVER_CLEAR_SNACKBAR } from "constants/types";
import { useHistory } from 'react-router-dom';
import dataConstant from "constants/dataConstant";
function DriverForm() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [photo, setPhoto] = useState("");
  const [name, setName] = useState("");

  const [phone, setPhone] = useState("");
  const [alternateNumber, setAlternateNumber] = useState("");
  const [officialEmail, setOfficialEmail] = useState("");

  const [designationList, setDesignationList] = useState([])
  const [subdepartmentList, setSubdepartmentList] = useState([])
  const [departmentList, setDepartmentList] = useState('')

  const [licenseNumber, setLicenseNumber] = useState("");
  const [licenseExpiry, setLicenseExpiry] = useState("");
  const [licensePhoto, setLicensePhoto] = useState("");
  const [employeeaddress, setEmployeeaddress] = useState("");
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [employeeType, setEmployeeType] = useState('');
  const [companyName, setCompanyName] = useState("");
  const [isErrorStartDate, setIsErrorStartDate] = useState(false);
  const [isErrorEndDate, setIsErrorEndDate] = useState(false);
  const [isErrorEmployeeType, setIsErrorEmployeeType] = useState(false);
  const [isErrorCompanyName, setIsErrorCompanyName] = useState(false);
  const [isErrorDepartment, setIsErrorDepartment] = useState(false);
  const [isErrorDesignation, setIsErrorDesignation] = useState(false);
  const [isErrorSubdepartment, setIsErrorSubdepartment] = useState(false);

  const [departmentId, setDepartmentId] = useState("");
  const [designation, setDesignation] = useState("");
  const [subdepartment, setSubdepartment] = useState("");

  const [isErrorName, setIsErrorName] = useState(false);
  const [isErrorBloodGroup, setIsErrorBloodGroup] = useState(false);
  const [bloodGroup, setBloodGroup] = useState(false);
  const [isErrorPhone, setIsErrorPhone] = useState(false);
  const [isErrorPhoto, setIsErrorPhoto] = useState(false);
  const [isErrorAlternateNumber, setIsErrorAlternateNumber] = useState(false);
  const [isErrorOfficialEmail, setIsErrorOfficialEmail] = useState(false);
  const [isErrorEmployeeaddress, setIsErrorEmployeeaddress] = useState(false);


  const [isErrorLicenseNumber, setIsErrorLicenseNumber] = useState(false);
  const [isErrorLicenseExpiry, setIsErrorLicenseExpiry] = useState(false);
  const [isErrorLicensePhoto, setIsErrorLicensePhoto] = useState(false);

  const [submitted, setSubmitted] = useState(false);
  const history = useHistory();
  const statuses = ['success', 'error', 'warning', 'info'];
  const responseStatus = useSelector(state => state.driver.status);
  const responseMessage = useSelector(state => state.driver.text);
  const loading = useSelector(state => state.driver.loading);

  const showSnackbar = useSelector(state => state.driver.showSnackbar);

  const dispatch = useDispatch();
  const toast = useToast();

  const params = useParams();
  const IdofDriver = params.id;


  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    setIsErrorName(name === '');
    setIsErrorPhoto(photo === '');
    setIsErrorPhone(phone === '');
    setIsErrorBloodGroup(bloodGroup === '');
    setIsErrorAlternateNumber(alternateNumber === '');
    setIsErrorOfficialEmail(officialEmail === '');
    setIsErrorCompanyName(companyName === '');
    setIsErrorEmployeeType(employeeType === '');
    setIsErrorStartDate(startDate === '');
    setIsErrorEndDate(endDate === '');

    setIsErrorLicenseExpiry(licenseExpiry === '');
    setIsErrorLicensePhoto(licensePhoto === '');

    setIsErrorLicenseNumber(licenseNumber === '');


    if (
      name !== "" && phone !== "" && alternateNumber !== "" && officialEmail !== "" && licenseNumber !== "" && licenseExpiry !== "" && employeeaddress !== "" && bloodGroup !== ""   &&   employeeType !== 0 &&
      subdepartment !== 0 || startDate !== "" || endDate !== ""
    ) {
      const newDriver = {

        file1: photo,
        Name: name,
        phone: phone,
        alternateNumber: alternateNumber,
        officialEmail: officialEmail,
        bloodGroup: bloodGroup,
        licenseDetails: {

          licenseNumber: licenseNumber,
          licenseExpiry: licenseExpiry,
        },

        file2: licensePhoto,
        employeeaddress: employeeaddress,
        department : departmentId,
        subdepartment :  subdepartment,
        designation : designation,
        startDate: startDate,
        endDate: endDate,
        companyName : companyName,
        employeeType: employeeType,

      };
      console.log("newDriver1")
      console.log(newDriver)
      dispatch(driverAddOrUpdate(newDriver, 'add'));
      // history.push("/admin/driver-table")
    }

  };

  const departmentListforDropdown = () => {
    axios
      .get(`${server_url}/department`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        setDepartmentList(responce.data.data)
      }).catch(() => console.log("error"))
  }

  const subdepartmentListforDropdown = (depId) => {
    if (depId) {
      axios
        .get(`${server_url}/plant/subdepartment-list/${depId}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then((responce) => {
          setSubdepartmentList(responce.data.data)
        })
    }
    else {
      setSubdepartmentList([])
    }

  }

  const designationListforDropdown = (subdepId) => {
    if (subdepId) {
      axios
        .get(`${server_url}/plant/designation-list/${subdepId}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then((responce) => {
          setDesignationList(responce.data.data)
        })
    }
    else {
      setDesignationList([])
    }

  }


  useEffect(() => {
    departmentListforDropdown()


  }, [])

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
          // history.push('#/admin/driver-table');
           history.push("/admin/driver-table")
        }
      } else if (responseStatus === 'NOK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: DRIVER_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])
  const textRegex = /^[A-Za-z0-9,\s]+$/;

  const phoneRegex = /^\d{10}$/;
  const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,10}$/;

  const licencenoRegex = /^[A-Za-z0-9]{6,12}$/

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <form onSubmit={handleSubmit}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Driver Form
            </Text>
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing="8">
              <FormControl isInvalid={isErrorName || (submitted && name === "")}>
                <CustomInput
                  type="text"
                  label="Name"
                  value={name}
                  onChange={(value) => {
                    setIsErrorName(value === "" || !textRegex.test(value));
                    setName(value);
                  }}
                  isInvalid={isErrorName || (submitted && name === "")}
                  regex={textRegex}
                  submitted={submitted}
                />
              </FormControl>

              <FormControl isInvalid={isErrorPhoto || (submitted && photo === "")}>
                <FormLabel>Photo</FormLabel>
                <Input
                  type="file"
                  onChange={(e) => {
                    setIsErrorPhoto(e.target.value === "");
                    setPhoto(e.target.files[0]);
                  }}
                />
                {isErrorPhoto && submitted && (
                  <FormErrorMessage>Photo is required.</FormErrorMessage>
                )}
              </FormControl>

              <FormControl isInvalid={isErrorPhone || (submitted && phone === "")}>
                <CustomInput
                  type="tel"
                  label="Phone"
                  value={phone}
                  onChange={(value) => {
                    setIsErrorPhone(value === "" || !phoneRegex.test(value));
                    setPhone(value);
                  }}
                  isInvalid={isErrorPhone || (submitted && phone === "")}
                  regex={phoneRegex}
                  submitted={submitted}
                />
              </FormControl>
              <FormControl
                isInvalid={isErrorAlternateNumber || (submitted && alternateNumber === "")}
              >
                <CustomInput
                  type="tel"
                  label="Alternate Number"
                  value={alternateNumber}
                  onChange={(value) => {
                    setIsErrorAlternateNumber(value === "" || !phoneRegex.test(value));
                    setAlternateNumber(value);
                  }}
                  isInvalid={isErrorAlternateNumber || (submitted && alternateNumber === "")}
                  regex={phoneRegex}
                  submitted={submitted}
                />
              </FormControl>
              <FormControl
                isInvalid={isErrorOfficialEmail || (submitted && officialEmail === "")}
              >
                <CustomInput
                  type="email"
                  label="Official Email"
                  value={officialEmail}
                  onChange={(value) => {
                    setIsErrorOfficialEmail(value === "" || !emailRegex.test(value));
                    setOfficialEmail(value);
                  }}
                  isInvalid={isErrorOfficialEmail || (submitted && officialEmail === "")}
                  regex={emailRegex}
                  submitted={submitted}
                />
              </FormControl>

              <FormControl isInvalid={isErrorBloodGroup || (submitted && bloodGroup === "")}>
                <FormLabel>Blood Group</FormLabel>
                <Select
                  placeholder="Blood Group"
                  onChange={(e) => {
                    setIsErrorBloodGroup(e.target.value === '');
                    setBloodGroup(e.target.value);

                  }}
                  value={bloodGroup}
                >
                  {dataConstant.bloodGroup.map((item, index) => (
                    <option key={item._id} value={item._id}>{item.name}</option>
                  ))}
                </Select>
                {isErrorBloodGroup && submitted && (
                  <FormErrorMessage>Blood Group is required.</FormErrorMessage>
                )}
              </FormControl>

              {/* vehicle Number
            <FormControl isInvalid={isErrorVehicleNumber || (submitted && vehicleNumber === "")} >
              
              <CustomInput
                type='text'
                label='Vehicle Number'
               
                value={vehicleNumber}
                onChange={(value) => {
                  setIsErrorVehicleNumber(value === '' || !vechilenoRegex.test(value));
                  setVehicleNumber(value);
                }}
                regex={vechilenoRegex}
                submitted={submitted}
                
              />
            </FormControl> */}
            </SimpleGrid>
            <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>

              <FormControl isInvalid={isErrorLicenseNumber || (submitted && licenseNumber === "")}>

                <CustomInput
                  type='text'

                  label='License Number'
                  value={licenseNumber}
                  onChange={(value) => {
                    setIsErrorLicenseNumber(value === '' || !licencenoRegex.test(value));
                    setLicenseNumber(value);
                  }}
                  regex={licencenoRegex}
                  submitted={submitted}

                />
              </FormControl>


              <FormControl isInvalid={isErrorLicenseExpiry || (submitted && licenseExpiry === "")}>
                <FormLabel>License Expiry Date</FormLabel>
                <Input

                  type='Date'
                  value={licenseExpiry}
                  onChange={(e) => {
                    setIsErrorLicenseExpiry(e.target.value === '')
                    setLicenseExpiry(e.target.value)
                  }}
                />
                {!isErrorLicenseExpiry ? (
                  <></>
                ) : (
                  <FormErrorMessage>
                    License Expiry Date is required.
                  </FormErrorMessage>
                )}
              </FormControl>

              <FormControl isInvalid={isErrorLicensePhoto || (submitted && licensePhoto === "")} >
                <FormLabel>License Photo</FormLabel>
                <Input
                  type='file'

                  onChange={(e) => {

                    setIsErrorLicensePhoto(e.target.value === '')
                    setLicensePhoto(e.target.files[0])
                  }}
                />
                {!isErrorLicensePhoto ? (
                  <></>
                ) : (
                  <FormErrorMessage>License Photo is required.</FormErrorMessage>
                )}
              </FormControl>
            </SimpleGrid>
            <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
              <FormControl isInvalid={isErrorEmployeeaddress || (submitted && employeeaddress === "")}>
                <CustomInput
                  type='text'
                  label="Employee Address"
                  placeholder="Employee Address"

                  value={employeeaddress}
                  onChange={(value) => {
                    setIsErrorEmployeeaddress(value === '' || !textRegex.test(value));
                    setEmployeeaddress(value);
                  }}
                  isInvalid={isErrorEmployeeaddress || (submitted && employeeaddress === "")}
                  regex={textRegex}
                  submitted={submitted}
                />
              </FormControl>
              {/* <FormControl isInvalid={isErrorLine2 || (submitted && line2 === "")}>
                <CustomInput
                  type='text'
                  label="Line 2"
                  placeholder="Line 2"
                  regex={textRegex}
                  value={line2}
                  onChange={(value) => {
                    setIsErrorLine2(value === '' || !textRegex.test(value));
                    setLine2(value);
                  }}
                  isInvalid={isErrorLine2 || (submitted && line2 === "")}
                  submitted={submitted}
                />
              </FormControl>
            </SimpleGrid>

            <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
              <FormControl isInvalid={isErrorPostalcode || (submitted && postalCode === "")}>
                <CustomInput
                  type='number'
                  label="Postal Code"
                  placeholder="Postal Code"
                  regex={postalCodeRegex}
                  value={postalCode}
                  onChange={(value) => {
                    setIsErrorPostalcode(value === '' || !postalCodeRegex.test(value));
                    setPostalCode(value);
                  }}
                  isInvalid={isErrorPostalcode || (submitted && postalCode === "")}
                  submitted={submitted}
                />
              </FormControl>

              <FormControl isInvalid={isErrorLocality || (submitted && locality === "")}>
                <CustomInput
                  type='text'
                  label="Locality"
                  placeholder="Locality"
                  regex={textRegex}
                  value={locality}
                  onChange={(value) => {
                    setIsErrorLocality(value === '' || !textRegex.test(value));
                    setLocality(value);
                  }}
                  isInvalid={isErrorLocality || (submitted && locality === "")}
                  submitted={submitted}
                />
              </FormControl>

              <FormControl isInvalid={isErrorState || (submitted && state === "")}>
                <CustomInput
                  type='text'
                  label="State"
                  placeholder="State"
                  regex={textRegex}
                  value={state}
                  onChange={(value) => {
                    setIsErrorState(value === '' || !textRegex.test(value));
                    setState(value);
                  }}
                  isInvalid={isErrorState || (submitted && state === "")}
                  submitted={submitted}
                />
              </FormControl> */}
            </SimpleGrid>

            <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
              {/* Department */}
              <FormControl isInvalid={isErrorDepartment || (submitted && departmentId === "")}>
                <FormLabel>Department</FormLabel>
                <Select
                  placeholder="Department"
                  onChange={(e) => {
                    setIsErrorDepartment(e.target.value === '');
                    setDepartmentId(e.target.value);
                    subdepartmentListforDropdown(e.target.value);
                  }}
                  // value={departmentId}
                >
                  {departmentList ? (departmentList.map((item, index) => (
                    <option key={item._id} value={item._id}>{item.name}</option>
                  ))) : (<></>)}
                </Select>
                {isErrorDepartment && submitted && (
                  <FormErrorMessage>Department is required.</FormErrorMessage>
                )}
              </FormControl>
              {/* Subdepartment */}
              <FormControl isInvalid={isErrorSubdepartment || (submitted && subdepartment === "")}>
                <FormLabel>Sub Department</FormLabel>
                <Select
                  placeholder="Subdepartment"
                  onChange={(e) => {
                    setIsErrorSubdepartment(e.target.value === '');
                    setSubdepartment(e.target.value);
                    designationListforDropdown(e.target.value);
                  }}
                  // value={subdepartment}
                >
                  {subdepartmentList ? (subdepartmentList.map((item, index) => (
                    <option key={item._id} value={item._id}>{item.name}</option>
                  ))) : (<></>)}
                </Select>
                {isErrorSubdepartment && submitted && (
                  <FormErrorMessage>Subdepartment is required.</FormErrorMessage>
                )}
              </FormControl>

              {/* Designation */}
              <FormControl isInvalid={isErrorDesignation || (submitted && designation === "")}>
                <FormLabel>Designation</FormLabel>
                <Select
                  placeholder="Designation"
                  onChange={(e) => {
                    setIsErrorDesignation(e.target.value === '');
                    setDesignation(e.target.value);
                  }}
                  // value={subdepartment}
                >
                  {designationList ? (designationList.map((item, index) => (
                    <option key={item._id} value={item._id}>{item.name}</option>
                  ))) : (<></>)}
                </Select>
                {isErrorDesignation && submitted && (
                  <FormErrorMessage>Designation is required.</FormErrorMessage>
                )}
              </FormControl>


            </SimpleGrid>

            <FormControl isInvalid={isErrorEmployeeType || (submitted && employeeType === "")}>
              <FormLabel>Employee Type</FormLabel>
              <Select
                placeholder="--- Select Employee Type ---"
                onChange={(e) => {
                  setIsErrorEmployeeType(e.target.value === '');
                  setEmployeeType(e.target.value);
                }}
                // value={employeeType}
              >
                {dataConstant.employeeType.map((element, index) => (
                  <option key={element.id} value={element.value}>{element.name}</option>
                ))}
              </Select>
              {!isErrorEmployeeType ? (
                <></>
              ) : (
                <FormErrorMessage>Employee Type is required.</FormErrorMessage>
              )}
            </FormControl>

            
            <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
            {/* <Flex  minChildWidth='120px' spacing='40px' mt='24px'> */}
            {employeeType == 3 || employeeType == 4 ?
              <>

                <FormControl isInvalid={isErrorStartDate || (submitted && startDate === "")}>
                  <FormLabel>Start Date</FormLabel>
                  <Input
                    type='date'
                    onChange={(e) => {

                      setIsErrorStartDate(e.target.value === '')
                      setStartDate(e.target.value)
                    }}
                  />
                  {!isErrorStartDate ? (
                    <></>
                  ) : (
                    <FormErrorMessage>Start Date is required.</FormErrorMessage>
                  )}
                </FormControl>

                <FormControl isInvalid={isErrorEndDate || (submitted && endDate === "")}>
                  <FormLabel>End Date</FormLabel>
                  <Input
                    type='date'
                    onChange={(e) => {

                      setIsErrorEndDate(e.target.value === '')
                      setEndDate(e.target.value)
                    }}
                  />
                  {!isErrorEndDate ? (
                    <></>
                  ) : (
                    <FormErrorMessage>End Date is required.</FormErrorMessage>
                  )}
                </FormControl>

                <FormControl isInvalid={isErrorCompanyName || (submitted && endDate === "")}>
                  <FormLabel>Service Provider Company Name</FormLabel>
                  <Input
                    type='text'
                    // value={companyName}
                    // onChange={(e) => {
                    //   setIsErrorCompanyName(e.target.value === '' || !textRegex.test(value));
                    //   setCompanyName(e.target.value);
                    // }}
                      onChange={(e) => {

                        setIsErrorCompanyName(e.target.value === '')
                        setCompanyName(e.target.value)
                    }}
                    regex={textRegex}

                  />
                  {!isErrorCompanyName ? (
                    <></>
                  ) : (
                    <FormErrorMessage>Company Name is required.</FormErrorMessage>
                  )}
                </FormControl>
              </>
              : <></>}
            {/* </Flex> */}

          </SimpleGrid>
            <Center>
              {loading ? (
                <Spinner mt="24px" size="lg" />
              ) : (
                <Button colorScheme="blue" mt="24px" type="submit">
                  Submit
                </Button>
              )}
            </Center>
          </CardHeader>
          <CardBody>
            {/* ... */}
          </CardBody>
        </Card>
      </form>
    </Flex>
  );
}

export default DriverForm;
