// Chakra imports
import {
    Box,
    Button,
    Center,
    Flex,
    Image,
    Input,
    SimpleGrid,
    Spacer,
    Stack,
    Text,
    Wrap,
    WrapItem,
    useColorModeValue
  } from "@chakra-ui/react";
  // Custom components
  import Card from "components/Card/Card.js";
  import CardBody from "components/Card/CardBody.js";
  import CardHeader from "components/Card/CardHeader.js";
  import React, { useEffect, useState } from "react";
  import { useDispatch, useSelector } from 'react-redux';

  import { useToast } from '@chakra-ui/react'
  import { useParams } from "react-router-dom/cjs/react-router-dom.min";
  import { VISITORINOUT_CLEAR_SNACKBAR } from "constants/types";
  import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
  } from '@chakra-ui/react'
  import { Skeleton, SkeletonCircle, SkeletonText } from '@chakra-ui/react'
import { visitorGetById } from "actions/VisitorInOutAction";
  
  function VisitorInOutView() {
    const textColor = useColorModeValue("gray.700", "white");
    const borderColor = useColorModeValue("gray.200", "gray.600");
  const [name , setName] = useState("");
  const [image , setImage] = useState("");
  const [contactNo , setContactNo] = useState("");
  const [company , setCompany] = useState("");
  const [purpose , setPurpose] = useState("");
  const [visitorType , setVisitorType] = useState("");
  const [department , setDepartment] = useState("");
  const [subdepartment , setSubdepartment] = useState("");
  const [status , setStatus] = useState("");
  const [inTime , setInTime] = useState("")
  const [outTime , setOutTime] = useState("")
   
    const dispatch = useDispatch();
    const toast = useToast();
  
    const statuses = ['success', 'error', 'warning', 'info'];
    const responseStatus = useSelector(state => state.visitorInOut.status);
    const responseMessage = useSelector(state => state.visitorInOut.text);
    const loading = useSelector(state => state.visitorInOut.loading);
    const VisitorInOutDetails = useSelector(state => state.visitorInOut.visitorInOutDetail);
    const showSnackbar = useSelector(state => state.visitorInOut.showSnackbar);
  
    console.log("VisitorInOutDetails")
    console.log(VisitorInOutDetails)
    const params = useParams();
    useEffect(() => {
      if (showSnackbar) {
        if (responseStatus === 'OK' && loading === false) {
          console.log(VisitorInOutDetails);
          setName(VisitorInOutDetails.name);
          setImage(VisitorInOutDetails.image);
          setContactNo(VisitorInOutDetails.contactNo);
          setCompany(VisitorInOutDetails.company);
          setPurpose(VisitorInOutDetails.purpose);
          setVisitorType(VisitorInOutDetails.visitorType);
          setDepartment(VisitorInOutDetails.departmentId.name);
          setSubdepartment(VisitorInOutDetails.subDepartmentId.name);
          setStatus(VisitorInOutDetails.status);
          setInTime(VisitorInOutDetails.inTime);
          setOutTime(VisitorInOutDetails.outTime);
          
         
            
          if (responseMessage !== "") {
            toastFunction(responseMessage, 'success');
          }
        } else if (responseStatus === 'NOK' && loading === false) {
          if (responseMessage !== "") {
            toastFunction(responseMessage, 'error');
          }
        }
      }
  
      dispatch({
        type: VISITORINOUT_CLEAR_SNACKBAR,
        payload: {
          showSnackbar: false,
        }
      })
    }, [loading]);
  
    
    const toastFunction = (title, status) => {
      toast({
        title: title,
        status: status,
        duration: 3000,
        isClosable: true,
      })
    };


    useEffect(() => {
      console.log("params");
      console.log(params);
      const visitorId = params.id;
      const visitor = {
        _id: visitorId,
      };
      dispatch(visitorGetById(visitor._id));
    }, [dispatch]);
  
    console.log("VisitorInOutDetails")
    console.log(VisitorInOutDetails)
  
    return (
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              View Form
            </Text>
          </CardHeader>
          <CardBody>
            {loading ? <Stack>
              <Skeleton height='20px' />
              <Skeleton height='20px' />
              <Skeleton height='20px' />
            </Stack>
              :
              <TableContainer>
                <Table variant='simple'>
                  <Tbody>
                    <Tr>
                      <Td>Name</Td>
                      <Td>{name}</Td>
                    </Tr>

                    <Tr>
                      <Td>Image</Td>
                      <Td><Flex direction="column">
                        <Text fontSize="md" color={textColor} fontWeight="bold">
                        <Image
                            boxSize='100px'
                            objectFit='cover'
                            src={image}
                            alt='Dan Abramov'
                          />
                          
                        </Text>
                      </Flex>
                      </Td>
                    </Tr>
                   
                    
                    <Tr>
                      <Td>Mobile Number</Td>
                      <Td>{contactNo}</Td>
                    </Tr>
                    <Tr>
                      <Td>Company</Td>
                      <Td>{company}</Td>
                    </Tr>
                    <Tr>
                      <Td>Purpose</Td>
                      <Td>{purpose}</Td>
                    </Tr>
                    <Tr>
                      <Td>VisitorType</Td>
                      <Td>{visitorType}</Td>
                    </Tr>
                    <Tr>
                      <Td>Department</Td>
                      <Td>{department}</Td>
                    </Tr>
                   
                    <Tr>
                      <Td>Subdepartment</Td>
                      <Td>{subdepartment}</Td>
                    </Tr>

                    <Tr>
                      <Td>Status</Td>
                      <Td>{status ? "Active" : "Inactive"}</Td>
                    </Tr>

                    <Tr>
                      <Td>InTime</Td>
                      <Td>{inTime}</Td>
                    </Tr>
                  
                    <Tr>
                      <Td>OutTime</Td>
                      <Td>{outTime}</Td>
                    </Tr>
                    
                  
                  </Tbody>
                </Table>
             </TableContainer>}
          </CardBody>
        </Card>
      </Flex>
    );
  }
  
  export default VisitorInOutView;
