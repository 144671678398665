// Chakra imports
import {
    Flex,
    Button,
    Spacer,
    Table,
    Tbody,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    InputLeftElement,
    IconButton,
    Input,
    Icon,
    Link,
    InputGroup
  } from "@chakra-ui/react";
  import { SearchIcon } from "@chakra-ui/icons";
  // Custom components
  import Card from "components/Card/Card.js";
  import { AddIcon } from "@chakra-ui/icons";
  import CardBody from "components/Card/CardBody.js";
  import CardHeader from "components/Card/CardHeader.js";
  
  import React, { useEffect, useState,useRef } from "react";
  import { useDispatch, useSelector } from 'react-redux';
  import { purposeGetAll } from '../../actions/purposeAction';
  import { useToast } from '@chakra-ui/react'
  import { PURPOSE_CLEAR_SNACKBAR } from "constants/types";
  import { FaChevronDown, FaChevronUp } from "react-icons/fa";
// import PurposeTableRow from "./PurposeTableRow";
import PurposeTableRow from "./PurposeTableRow"
//   import ParkingPagination from "./ParkingPagination";
import PurposePagination from "./PurposePagination";
  import axios from 'axios';
  import { server_url } from "constants/types";
  import { useHistory } from 'react-router-dom';
  import { DownloadTableExcel } from 'react-export-table-to-excel';
  
  function PurposeTable(props) {
    const { url = "", plant = "" } = props;
    const textColor = useColorModeValue("gray.700", "white");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    const responseStatus = useSelector(state => state.purpose.status);
    const responseMessage = useSelector(state => state.purpose.text);
    const loading = useSelector(state => state.purpose.loading);
    const purposeList = useSelector(state => state.purpose.purposeList);
    const response = useSelector(state => state.purpose.response);
    const showSnackbar = useSelector(state => state.purpose.showSnackbar);
    const dispatch = useDispatch();
    const toast = useToast();
    const searchIconColor = useColorModeValue("gray.700", "gray.200");
    const inputBg = useColorModeValue("#edf3f8", "navy.800");
    const [search, setSearch] = useState("");
    const [sortColumn, setSortColumn] = useState("");
    const [sortOrder, setSortOrder] = useState("asc");
    const history = useHistory();
    const tableRef = useRef(null);
    console.log(purposeList);
    console.log("purposeList");
    useEffect(() => {
      dispatch(purposeGetAll("&plant=" + plant));
    }, [dispatch]);
  
    useEffect(() => {
      if (showSnackbar) {
        if (responseStatus === 'OK' && loading === false) {
          if (responseMessage !== "") {
            toastFunction(responseMessage, 'success');
          }
        } else if (responseStatus === 'NOK' && loading === false) {
          if (responseMessage !== "") {
            toastFunction(responseMessage, 'error');
          }
        }
      }
      dispatch({
        type:   PURPOSE_CLEAR_SNACKBAR,
        payload: {
          showSnackbar: false,
        }
      })
    }, [loading]);
  
    const toastFunction = (title, status) => {
      toast({
        title: title,
        status: status,
        duration: 3000,
        isClosable: true,
      })
    };
  
    const handleSort = (column) => {
      if (column === sortColumn) {
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
      } else {
        setSortColumn(column);
        setSortOrder("desc");
      }
      const sorting = "&sortOrder=" + sortOrder + "&column=" + column;
      dispatch(purposeGetAll(sorting));
    };
  
    useEffect(() => {
      const searching = "&search=" + search+"&plant=" + plant;
      dispatch(purposeGetAll(searching));
    }, [search]);
    
    useEffect(() => {
      axios
        .get(`${server_url}/admin/detail`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then((responce) => {
          console.log("uadlfdafljadhfoadilf");
        })
        .catch((e) => {
          history.push('/auth/signin');
  
        });
    }, [])
    return (
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
          <Flex>
            <CardHeader p="6px 0px 22px 0px">
              <Text fontSize="xl" color={textColor} fontWeight="bold">
                Purposes
              </Text>
            </CardHeader>
            <Spacer />
           <Flex justify="flex-end" style={{ padding: '10px' ,marginTop : '-5px' ,marginRight :'12px' }}> 
           {url == "" ? <></> : <Link href={url}>
                <IconButton
                  colorScheme="blue"
                  aria-label="Search database"
                  icon={<AddIcon />}
                />
              </Link>}
            </Flex>
            <Flex justify="flex-end" style={{ padding: '10px', marginTop: '-5px', marginRight: '12px' }}>
              <DownloadTableExcel
                filename="Parking Table"
                sheet="users"
                currentTableRef={tableRef.current}
              >
                <Button colorScheme="blue" _hover={{ backgroundColor: 'black', color: 'white' }}>
                  Export Excel
                </Button>
              </DownloadTableExcel>
            </Flex>
            <CardHeader p="6px 0px 22px 0px">
              <Text fontSize="xl" color={textColor} fontWeight="bold">
                <InputGroup borderRadius='8px' w='200px' >
                  <InputLeftElement
                    children={
                      <IconButton
                        bg='inherit'
                        borderRadius='inherit'
                        _hover='none'
                        _active={{
                          bg: "inherit",
                          transform: "none",
                          borderColor: "transparent",
                        }}
                        _focus={{
                          boxShadow: "none",
                        }}
                        icon={
                          <SearchIcon color={searchIconColor} w='15px' h='15px' />
                        }></IconButton>
                    }
                  />
                  <Input
                    variant='search'
                    fontSize='xs'
                    bg={inputBg}
                    onKeyUp={
                      (e) => {
                        setSearch(e.target.value);
                        if (e.target.value.length < 3) {
                          setSearch("");
                        }
                      }
                    }
                    placeholder='Type here...'
                  />
                </InputGroup>
              </Text>
            </CardHeader>
          </Flex>
          <CardBody pb={4}>
            <div style={{ overflowX: "auto" }}>
            
              <Table variant="simple" color={textColor} ref={tableRef}>
                <Thead>
                  <Tr my=".8rem" pl="0px" color="gray.400" >
                  <Th
                      pl="15px"
                      borderColor={borderColor}
                      color="gray.400"
                      cursor="pointer"
                    >
                     SNO
                    </Th>
                    <Th pl="0px" borderColor={borderColor} color="gray.400"
                      onClick={() => handleSort("name")}
                      cursor="pointer"
                    >
                      Name
                      {sortColumn === "name" && (
                        <Icon
                          as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                          boxSize={4}
                          ml={1}
                        />
                      )}
                    </Th>
                    <Th borderColor={borderColor} color="gray.400" onClick={() => handleSort("description")}
                      cursor="pointer">
                      Description
                      {sortColumn === "description" && (
                        <Icon
                          as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                          boxSize={4}
                          ml={1}
                        />
                      )}
                    </Th>
                    <Th borderColor={borderColor} color="gray.400" onClick={() => handleSort("description")}
                      cursor="pointer">
                      Status
                      {sortColumn === "description" && (
                        <Icon
                          as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                          boxSize={4}
                          ml={1}
                        />
                      )}
                    </Th>
                    <Th borderColor={borderColor} color="gray.400" onClick={() => handleSort("status")}
                      cursor="pointer">
                      Admin
                      {sortColumn === "status" && (
                        <Icon
                          as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                          boxSize={4}
                          ml={1}
                        />
                      )}
                    </Th>

                  </Tr>
                </Thead>
                <Tbody>
                  {purposeList ? (purposeList.map((row, index, arr) => {
  
                    return (
                      <PurposeTableRow
                       serialnumber={((response.currentPage - 1)*response.limit) +( index + 1)}
                        name={row.name}
                        description={row.description}
                        plantAdmin={row.plantAdmin.name}
                        status={row.status ? "Active" : "Inactive"}
                        isLast={index === arr.length - 1 ? true : false}
                        id={row._id}
                      />
                    );
                  })) : (<></>)}
                </Tbody>
              </Table>
            </div>
            {purposeList ? (purposeList.length === 0 ? <></> : (
              <PurposePagination
              plant = {plant}
              limit = {response.limit}
                totalPages={response.totalPages}
                currentPage={response.currentPage}
              />
            )) : (<></>)}
          </CardBody>
        </Card>
      </Flex>
    );
  }
  
  export default PurposeTable;
  