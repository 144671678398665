// Chakra imports
import {
    Box,
    Button,
    Center,
    Flex,
    Input,
    SimpleGrid,
    Spacer,
    Text,
    Wrap,
    WrapItem,
    useColorModeValue,
    Select,
    Image,
  } from "@chakra-ui/react";
  // Custom components
  import Card from "components/Card/Card.js";
  import CardBody from "components/Card/CardBody.js";
  import CardHeader from "components/Card/CardHeader.js";
  import React, { useEffect, useState } from "react";
  import { useDispatch, useSelector } from 'react-redux';
  import { guardmoduleClearShowSnackbar, guardmoduleAddOrUpdate } from '../../actions/guardModuleAction';
  import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
  } from '@chakra-ui/react'
  import { useToast } from '@chakra-ui/react'
  import { GUARDMODULE_CLEAR_SNACKBAR } from "constants/types";
  import { Spinner } from '@chakra-ui/react'
  import { useParams } from "react-router-dom/cjs/react-router-dom.min";
  import dataConstant from "constants/dataConstant";
  import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { server_url } from '../../constants/types';
  function GuardmoduleUpdateForm() {
    const textColor = useColorModeValue("gray.700", "white");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    const [name, setName] = useState("");
    const [image, setImage] = useState("");
    const [updateimage, setUpdateimage] = useState("");
    const [status, setStatus] = useState("");
    const [description, setDescription] = useState("");
    const [alertMessage, setAlertMessage] = useState('');
    const [isErrorName, setIsErrorName] = useState(false);
    const [isErrorImage, setIsErrorImage] = useState(false);
    const [isErrorStatus, setIsErrorStatus] = useState(false);
    const [isErrorDescription, setIsErrorDescription] = useState(false);
  
    const dispatch = useDispatch();
    const toast = useToast();
    const history = useHistory()
    
    const statuses = ['success', 'error', 'warning', 'info'];
    const responseStatus = useSelector(state => state.guardmodule.status);
    const responseMessage = useSelector(state => state.guardmodule.text);
    const loading = useSelector(state => state.guardmodule.loading);
    const showSnackbar = useSelector(state => state.guardmodule.showSnackbar);
    const guardmoduleDetail = useSelector(state => state.guardmodule.guardmoduleDetail);
  
    const selectedGuardmodule = useSelector(state => state.guardmodule.selectedGuardmodule); // Assuming you have a selectedGuardmodule state in your Redux store
    const params = useParams();
    const guardmoduleId = params.id;
    console.log("guardmoduleId");
    console.log(guardmoduleDetail);

    useEffect(() => {
      axios
        .get(`${server_url}/admin/detail`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then((responce) => {
          console.log("uadlfdafljadhfoadilf");
        })
        .catch((e) => {
          history.push('/auth/signin');
  
        });
    }, [])

    useEffect(() => {
      
        const guardmodule = {
          _id : guardmoduleId
        };
        dispatch(guardmoduleAddOrUpdate(guardmodule,'edit'));
    }, []);
  
    // Function to handle form submission
    const handleSubmit = (event) => {
      event.preventDefault();
      setIsErrorName(name === '');
      setIsErrorStatus(status === '');
      setIsErrorDescription(description === '');
      // console.log("Submit");
  
      if (name !== "" && status !== "" && description !== "") {
        console.log("Submit");
        // Get form data
        const guardmoduleData = {
          _id: guardmoduleId, // Assuming your guardmodule model has an id field
          name: name,
          // image: updateimage,
          status: status,
          description: description,
          // Other guardmodule data fields
        };
        if(updateimage)
        {
          guardmoduleData.image = updateimage;
        }
        console.log(guardmoduleData);
  
        // Call the guardmoduleUpdate action
        dispatch(guardmoduleAddOrUpdate(guardmoduleData,'edit'));
      }
    };
  
    useEffect(() => {
      if (showSnackbar) {
        if (responseStatus === 'OK' && loading === false) {
          console.log(guardmoduleDetail);
          setName(guardmoduleDetail.name);
          setImage(guardmoduleDetail.image);
          setStatus(guardmoduleDetail.status);
          setDescription(guardmoduleDetail.description);
          if (responseMessage !== "") {
            toastFunction(responseMessage, 'success');
          }
        } else if (responseStatus === 'NOK' && loading === false) {
          if (responseMessage !== "") {
            toastFunction(responseMessage, 'error');
          }
        }
      }
  
      dispatch({
        type: GUARDMODULE_CLEAR_SNACKBAR,
        payload: {
          showSnackbar: false,
        }
      })
    }, [loading]);
  
    const toastFunction = (title, status) => {
      toast({
        title: title,
        status: status,
        duration: 3000,
        isClosable: true,
      })
    };
  
    return (
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
            Guard Module Update Form
            </Text>
            <SimpleGrid minChildWidth='120px' spacing='40px'>
              <FormControl isInvalid={isErrorName}>
                <FormLabel>Name</FormLabel>
                <Input
                  type='text'
                  value={name}
                  onChange={(e) => {
                    setIsErrorName(e.target.value === '')
                    setName(e.target.value)
                  }}
                />
                {!isErrorName ? (
                  <></>
                ) : (
                  <FormErrorMessage>Name is required.</FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={isErrorImage}>
                <FormLabel>Image</FormLabel>
                <Input
                  type='file'
                  onChange={(e) => {
                    setIsErrorImage(e.target.value === '')
                    setUpdateimage(e.target.files[0])
                    console.log(e.target.files[0]);
                  }}
                />
                {!isErrorImage ? (
                  <></>
                ) : (
                  <FormErrorMessage>Image is required.</FormErrorMessage>
                )}
              </FormControl>
            </SimpleGrid>
            <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
              <FormControl isInvalid={isErrorStatus}>
                <FormLabel>Status</FormLabel>
                <Select
                  placeholder="Select status"
                  value={status}
                  onChange={(e) => {
                    setIsErrorStatus(e.target.value === '')
                    setStatus(e.target.value)
                  }}
                >
                  { dataConstant.status.map((element,index) => {
                      return (<option key={element['id']} value={element['value']} >{element['name']}</option>);   
                        
                    })}
                </Select>
                {!isErrorStatus ? (
                  <></>
                ) : (
                  <FormErrorMessage>Status is required.</FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={isErrorDescription}>
                <FormLabel>Description</FormLabel>
                <Input
                  type='text'
                  value={description}
                  onChange={(e) => {
                    setIsErrorDescription(e.target.value === '')
                    setDescription(e.target.value)
                  }}
                />
                {!isErrorDescription ? (
                  <></>
                ) : (
                  <FormErrorMessage>Description is required.</FormErrorMessage>
                )}
              </FormControl>
            </SimpleGrid>
            <Center>
              {loading ? <Spinner mt='24px' size='lg' /> :
                <Button colorScheme='blue' mt='24px' onClick={handleSubmit}>
                  Submit
                </Button>
              }
            </Center>
          </CardHeader>
          <CardBody>
          </CardBody>
        </Card>
        <Spacer></Spacer>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px" mt="4">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Guard Module Image
            </Text>
            <Box boxSize='sm'>
              <Image src={image} alt='Dan Abramov' />
            </Box>
          </CardHeader>
          <CardBody>
          </CardBody>
        </Card>
      </Flex>
    );
  }
  
  export default GuardmoduleUpdateForm;
  