// Chakra imports
import {
    Flex,
    Text,
    Table,
    Thead,
    Tbody,
    Tr,
    Td,
    Stack,
    TableContainer,
    Image,
    useColorModeValue,
  } from "@chakra-ui/react";
  // Custom components
  import Card from "components/Card/Card.js";
  import CardBody from "components/Card/CardBody.js";
  import CardHeader from "components/Card/CardHeader.js";
  import React, { useEffect, useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import { designationAddOrUpdate, designationClearShowSnackbar } from '../../actions/designationAction';
  import { useToast } from '@chakra-ui/react'
  import { useParams } from "react-router-dom/cjs/react-router-dom.min";
  import { DESIGNATION_CLEAR_SNACKBAR } from "constants/types";
  import {
    Skeleton,
    SkeletonCircle,
    SkeletonText,
  } from "@chakra-ui/react";
  import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom';
  function DesignationView() {
    const textColor = useColorModeValue("gray.700", "white");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [status, setStatus] = useState("");
    const [department, setDepartment] = useState("");
    const [subDepartment, setSubDepartment] = useState("");
  
    const dispatch = useDispatch();
    const toast = useToast();
    const history = useHistory();
    const statuses = ['success', 'error', 'warning', 'info'];
    const responseStatus = useSelector(state => state.designation.status);
    const responseMessage = useSelector(state => state.designation.text);
    const loading = useSelector(state => state.designation.loading);
    const designationDetail = useSelector(state => state.designation.designationDetail);
    const showSnackbar = useSelector(state => state.designation.showSnackbar);
  
    const params = useParams();
    console.log("designationDetail--designationDetail") 
    console.log(designationDetail)
    useEffect(() => {
      if (showSnackbar) {
        if (responseStatus === 'OK' && loading === false) {
          setName(designationDetail.name);
          setDescription(designationDetail.description);
          setStatus(designationDetail.status ?'Active' : 'inActive');
          setDepartment(designationDetail.department.name);
          setSubDepartment(designationDetail.subDepartment.name);
          if (responseMessage !== "") {
            toastFunction(responseMessage, 'success');
          }
        } else if (responseStatus === 'NOK' && loading === false) {
          if (responseMessage !== "") {
            toastFunction(responseMessage, 'error');
          }
        }
        dispatch({
          type: DESIGNATION_CLEAR_SNACKBAR,
          payload: {
            showSnackbar: false,
          }
        });
      }
    }, [loading]);
  
    useEffect(() => {
      const designationId = params.id;
      const designation = {
        _id: designationId,
      };
      dispatch(designationAddOrUpdate(designation, 'view'));
    }, []);
    useEffect(() => {
      axios
        .get(`${server_url}/admin/detail`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then((responce) => {
          console.log("uadlfdafljadhfoadilf");
        })
        .catch((e) => {
          history.push('/auth/signin');
  
        });
    }, [])
    const toastFunction = (title, status) => {
      toast({
        title: title,
        status: status,
        duration: 3000,
        isClosable: true,
      });
    };
  
    console.log("designation-detail")
    console.log(designationDetail)
  
    return (
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Designation View
            </Text>
          </CardHeader>
          <CardBody>
            {loading ? (<Stack>
              <Skeleton height="20px" />
              </Stack>
            ) : designationDetail ?(
              <TableContainer>
                <Table variant="simple">
                  <Tbody>
                    <Tr>
                      <Td>Name</Td>
                      <Td>{name}</Td>
                    </Tr>
                    <Tr>
                      <Td>Description</Td>
                      <Td>{description}</Td>
                    </Tr>
                    <Tr>
                      <Td>Status</Td>
                      <Td>{status}</Td>
                    </Tr>
                    <Tr>
                      <Td>Department</Td>
                      <Td>{department}</Td>
                    </Tr>
                    <Tr>
                      <Td>Sub Department</Td>
                      <Td>{subDepartment}</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            ) : null}
          </CardBody>
        </Card>
      </Flex>
    );
  }
  
  export default DesignationView;
  