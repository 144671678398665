// patrolling-logs
import axios from "axios";
import {
  PATROLLING_LOGS_LOADING,
  PATROLLING_LOGS_GET_ALL_SUCCESS,
  PATROLLING_LOGS_ERROR,
  PATROLLING_LOGS_CLEAR_SNACKBAR,
} from "../constants/types";

import { server_url } from "../constants/types";

export const patrollingLogsGetAll = (pagination = "") => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: PATROLLING_LOGS_LOADING,
        payload: {
          loading: true,
        },
      });
      axios
        .get(`${server_url}/patrolling-Duration?${pagination}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("authToken"),
          },
        })
        .then((response) => {
          dispatch({
            type: PATROLLING_LOGS_GET_ALL_SUCCESS,
            payload: {
              patrollingLogsList: response.data.data,
              response: response.data,
              limit: response.data.limit,
              page: response.data.currentPage,
              totalPages: response.data.totalPages,
              patrollingLogsDetail: {},
              patrollingLogs: [],
              loading: false,
              status: response.data.status,
              text: response.data.message,
            },
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: PATROLLING_LOGS_ERROR,
            payload: {
              status: "error",
              text: "Error occurred during getting patrolling request data.",
              loading: false,
            },
          });
          resolve();
        });
    });
  };
};

export const patrollingLogsAddOrUpdate = (patrollingLogs, state,limit="",page="") => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: PATROLLING_LOGS_LOADING,
        payload: {
          loading: true,
        },
      });

      const authToken = localStorage.getItem("authToken");

      if (state === "add") {
        axios
          .post(`${server_url}/patrolling-Duration`, patrollingLogs, {
            headers: {
              Authorization: "Bearer " + authToken,
            },
          })
          .then((response) => {
            axios
              .get(`${server_url}/patrolling-Duration?limit=${limit}&page=${page}`, {
                headers: {
                  Authorization: "Bearer " + authToken,
                },
              })
              .then((responseAll) => {
                dispatch({
                  type: PATROLLING_LOGS_GET_ALL_SUCCESS,
                  payload: {
                    patrollingLogsList: responseAll.data.data,
                    response: responseAll.data,
                    limit: responseAll.data.limit,
                    page: responseAll.data.currentPage,
                    totalPages: responseAll.data.totalPages,
                    loading: false,
                    status: response.data["status"],
                    text: response.data["message"],
                  },
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: PATROLLING_LOGS_ERROR,
                  payload: {
                    text: "Error occurred during getting patrolling request data.",
                    status: "error",
                    loading: false,
                  },
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: PATROLLING_LOGS_ERROR,
              payload: {
                text: "Error occurred during adding patrolling request data.",
                status: "error",
                loading: false,
              },
            });
            resolve();
          });
      } else if (state === "edit") {
        axios
          .put(`${server_url}/patrolling-Duration/${patrollingLogs._id}`, patrollingLogs, {
            headers: {
              Authorization: "Bearer " + authToken,
            },
          })
          .then((response) => {
            axios
              .get(`${server_url}/patrolling-Duration?limit=${limit}&page=${page}`, {
                headers: {
                  Authorization: "Bearer " + authToken,
                },
              })
              .then((responseAll) => {
                dispatch({
                  type: PATROLLING_LOGS_GET_ALL_SUCCESS,
                  payload: {
                    patrollingLogsList: responseAll.data.data,
                    response: responseAll.data,
                    limit: responseAll.data.limit,
                    page: responseAll.data.currentPage,
                    totalPages: responseAll.data.totalPages,
                    patrollingLogsDetail: response.data["data"],
                    loading: false,
                    status: response.data["status"],
                    text: response.data["message"],
                  },
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: PATROLLING_LOGS_ERROR,
                  payload: {
                    text: "Error occurred during getting patrolling request data.",
                    status: "error",
                    loading: false,
                  },
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: PATROLLING_LOGS_ERROR,
              payload: {
                text: "Error occurred during updating patrolling request data.",
                status: "error",
                loading: false,
              },
            });
            resolve();
          });
      } else if (state === "view") {
        axios
          .get(`${server_url}/patrolling-Duration/${patrollingLogs._id}`, {
            headers: {
              Authorization: "Bearer " + authToken,
            },
          })
          .then((response) => {
            axios
              .get(`${server_url}/patrolling-Duration?limit=${limit}&page=${page}`, {
                headers: {
                  Authorization: "Bearer " + authToken,
                },
              })
              .then((responseAll) => {
                console.log("patrollingLogsDetail11111");
                console.log(response.data["data"]);
                dispatch({
                  type: PATROLLING_LOGS_GET_ALL_SUCCESS,
                  payload: {
                    patrollingLogsList: responseAll.data.data,
                    response: responseAll.data,
                    limit: responseAll.data.limit,
                    page: responseAll.data.currentPage,
                    totalPages: responseAll.data.totalPages,
                    patrollingLogsDetail: response.data["data"],
                    patrollingLogs: response.data["data"]['patrollingLogs'],
                    loading: false,
                    status: response.data["status"],
                    text: response.data["message"],
                    
                  },
                });
                resolve();
              })
              
              .catch((e) => {
                dispatch({
                  type: PATROLLING_LOGS_ERROR,
                  payload: {
                    text: "Error occurred during getting patrolling logs data.",
                    status: "error",
                    loading: false,
                  },
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: PATROLLING_LOGS_ERROR,
              payload: {
                text: "Error occurred during updating patrolling logs data.",
                status: "error",
                loading: false,
              },
            });
            resolve();
          });
      }
    });
  };
};


export const patrollingLogsDelete = (patrollingLogsId,limit="",page="") => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: PATROLLING_LOGS_LOADING,
        payload: {
          loading: true,
        },
      });

      const authToken = localStorage.getItem("authToken");

      axios
        .delete(`${server_url}/patrolling-Duration/${patrollingLogsId}`, {
          headers: {
            Authorization: "Bearer " + authToken,
          },
        })
        .then((responseF) => {
          axios
            .get(`${server_url}/patrolling-Duration?limit=${limit}&page=${page}`, {
              headers: {
                Authorization: "Bearer " + authToken,
              },
            })
            .then((response) => {
              dispatch({
                type: PATROLLING_LOGS_GET_ALL_SUCCESS,
                payload: {
                  patrollingLogsList: response.data.data,
                  response: response.data,
                  limit: response.data.limit,
                  page: response.data.currentPage,
                  totalPages: response.data.totalPages,patrollingLogsDetail: responseF.data["data"],
                  loading: false,
                  status: responseF.data["status"],
                  text: responseF.data["message"],
                },
              });
              resolve();
            })
            .catch((e) => {
              dispatch({
                type: PATROLLING_LOGS_ERROR,
                payload: {
                  text: "Error occurred during getting patrolling logs data.",
                  status: "error",
                  loading: false,
                },
              });
              resolve();
            });
        })
        .catch((e) => {
          dispatch({
            type: PATROLLING_LOGS_ERROR,
            payload: {
              text: "Error occurred during deleting patrolling logs data.",
              status: "error",
              loading: false,
            },
          });
          resolve();
        });
    });
  };
};

export const patrollingLogsClearShowSnackbar = () => {
  return (dispatch, getState) => {
    dispatch({
      type: PATROLLING_LOGS_CLEAR_SNACKBAR,
    });
  };
};
