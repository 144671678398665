// Import the action type constants for materialGatePass
import {
  MATERIAL_GATE_PASS_CLEAR_SNACKBAR,
  MATERIAL_GATE_PASS_LOADING,
  MATERIAL_GATE_PASS_ERROR,
  MATERIAL_GATE_PASS_GET_ALL_SUCCESS,
  MATERIAL_GATE_PASS_SEARCH // Add the new action type
} from '../constants/types';

const initialState = {
  response: {},
  materialGatePassList: [],
  materialGatePassDetail: {},
  logs: [],
  material: [],
  materialGatePass: [],
  loading: false,
  status: 'success',
  text: '',
  showSnackbar: false,
  limit: 0, // Add missing properties
  page: 0,
  totalPages: 0,
  searching: "" // Add missing properties
};

const materialGatePassReducer = (state = initialState, action) => {
  switch (action.type) {
    case MATERIAL_GATE_PASS_CLEAR_SNACKBAR:
      return {
        ...state,
        showSnackbar: false,
      };
    case MATERIAL_GATE_PASS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case MATERIAL_GATE_PASS_ERROR:
      return {
        ...state,
        materialGatePassList: [],
        response: {},
        materialGatePassDetail: {},
        logs: [],
        material: [],
        materialGatePass: [],
        limit: 0, // Add missing properties
        page: 0,
        totalPages: 0,
        loading: false,
        status: action.payload.status,
        text: action.payload.text,
        showSnackbar: true,
      };
    case MATERIAL_GATE_PASS_GET_ALL_SUCCESS:
      return {
        ...state,
        materialGatePassList: action.payload.materialGatePassList,
        response: action.payload.response,
        materialGatePassDetail: action.payload.materialGatePassDetail,
        logs: action.payload.logs,
        material: action.payload.material,
        materialGatePass: action.payload.materialGatePass,
        limit: action.payload.limit,
        page: action.payload.page,
        totalPages: action.payload.totalPages,
        loading: false,
        status: action.payload.status,
        text: action.payload.text,
        showSnackbar: true,
      };
    case MATERIAL_GATE_PASS_SEARCH: // Add the new case
      return {
        ...state,
        searching: action.payload.searching,
      };
    default:
      return state || {};
  }
};

export default materialGatePassReducer;
