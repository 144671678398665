import {
    PERMISSION_LOADING,
    PERMISSION_GET_ALL_SUCCESS,
    PERMISSION_ERROR,
    PERMISSION_CLEAR_SNACKBAR
  } from '../constants/types';
  
  const initialState = {
    response: {},
    permissionList: [],
    permissionDetail: {},
    loading: true,
    status: "success",
    text: "",
    showSnackbar: false
  };
  
  const permissionReducer = (state = initialState, action) => {
    switch (action.type) {
      case PERMISSION_CLEAR_SNACKBAR:
        return {
          ...state,
          showSnackbar: false
        };
      case PERMISSION_LOADING:
        return {
          ...state,
          loading: true,
        };
      case PERMISSION_ERROR:
        return {
          ...state,
          permissionList: [],
          response: {},
          permissionDetail: {},
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true
        };
      case PERMISSION_GET_ALL_SUCCESS:
        return {
          ...state,
          permissionList: action.payload.permissionList,
          response: action.payload.response,
          permissionDetail: action.payload.permissionDetail,
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true
        };
      default:
        return state || {};
    }
  };
  
  export default permissionReducer;
  