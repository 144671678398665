
export const USER_LOADING = 'USER_GET_ALL_BIGIN';
export const USER_GET_ALL_SUCCESS = 'USER_GET_ALL_SUCCESS';
export const USER_ERROR = 'USER_ERROR';
export const USER_CLEAR_SNACKBAR = 'USER_CLEAR_SNACKBAR';
export const ADMIN_LOADING = 'ADMIN_GET_ALL_BIGIN';
export const ADMIN_GET_ALL_SUCCESS = 'ADMIN_GET_ALL_SUCCESS';
export const ADMIN_ERROR = 'ADMIN_ERROR';
export const ADMIN_CLEAR_SNACKBAR = 'ADMIN_CLEAR_SNACKBAR';
export const AUTH_LOADING = 'AUTH_GET_ALL_BIGIN';
export const AUTH_GET_ALL_SUCCESS = 'AUTH_GET_ALL_SUCCESS';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_CLEAR_SNACKBAR = 'AUTH_CLEAR_SNACKBAR';
export const BANNER_LOADING = 'BANNER_LOADING';
export const BANNER_GET_ALL_SUCCESS = 'BANNER_GET_ALL_SUCCESS';
export const BANNER_CLEAR_SNACKBAR = 'BANNER_CLEAR_SNACKBAR';
export const BANNER_ERROR = 'BANNER_ERROR';
export const ROLE_LOADING = 'ROLE_LOADING';
export const ROLE_GET_ALL_SUCCESS = 'ROLE_GET_ALL_SUCCESS';
export const ROLE_CLEAR_SNACKBAR = 'ROLE_CLEAR_SNACKBAR';
export const ROLE_ERROR = 'ROLE_ERROR';
export const PERMISSION_LOADING = 'PERMISSION_LOADING';
export const PERMISSION_GET_ALL_SUCCESS = 'PERMISSION_GET_ALL_SUCCESS';
export const PERMISSION_CLEAR_SNACKBAR = 'PERMISSION_CLEAR_SNACKBAR';
export const PERMISSION_ERROR = 'PERMISSION_ERROR';
export const MODULE_LOADING = 'MODULE_LOADING';
export const MODULE_GET_ALL_SUCCESS = 'MODULE_GET_ALL_SUCCESS';
export const MODULE_CLEAR_SNACKBAR = 'MODULE_CLEAR_SNACKBAR';
export const MODULE_ERROR = 'MODULE_ERROR';
export const CITY_LOADING = 'CITY_LOADING';
export const CITY_GET_ALL_SUCCESS = 'CITY_GET_ALL_SUCCESS';
export const CITY_CLEAR_SNACKBAR = 'CITY_CLEAR_SNACKBAR';
export const CITY_ERROR = 'CITY_ERROR';
export const FACTORY_LOADING = 'FACTORY_LOADING';
export const FACTORY_GET_ALL_SUCCESS = 'FACTORY_GET_ALL_SUCCESS';
export const FACTORY_CLEAR_SNACKBAR = 'FACTORY_CLEAR_SNACKBAR';
export const FACTORY_ERROR = 'FACTORY_ERROR';
export const CHECKPOST_LOADING = 'CHECKPOST_LOADING';
export const CHECKPOST_GET_ALL_SUCCESS = 'CHECKPOST_GET_ALL_SUCCESS';
export const CHECKPOST_CLEAR_SNACKBAR = 'CHECKPOST_CLEAR_SNACKBAR';
export const CHECKPOST_ERROR = 'CHECKPOST_ERROR';

export const DEPARTMENT_LOADING = 'DEPARTMENT_LOADING';
export const DEPARTMENT_GET_ALL_SUCCESS = 'DEPARTMENT_GET_ALL_SUCCESS';
export const DEPARTMENT_CLEAR_SNACKBAR = 'DEPARTMENT_CLEAR_SNACKBAR';
export const DEPARTMENT_ERROR = 'DEPARTMENT_ERROR';
export const GROUP_LOADING = 'GROUP_LOADING';
export const GROUP_GET_ALL_SUCCESS = 'GROUP_GET_ALL_SUCCESS';
export const GROUP_CLEAR_SNACKBAR = 'GROUP_CLEAR_SNACKBAR';
export const GROUP_ERROR = 'GROUP_ERROR';
export const SUBDEPARTMENT_LOADING ="SUBDEPARTMENT_LOADING";
export const SUBDEPARTMENT_GET_ALL_SUCCESS = "SUBDEPARTMENT_GET_ALL_SUCCESS";
export const SUBDEPARTMENT_ERROR = "SUBDEPARTMENT_ERROR";
export const SUBDEPARTMENT_CLEAR_SNACKBAR = "SUBDEPARTMENT_CLEAR_SNACKBAR";
export const PLANTADMIN_LOADING ="PLANTADMIN_LOADING";
export const PLANTADMIN_GET_ALL_SUCCESS = "PLANTADMIN_GET_ALL_SUCCESS";
export const PLANTADMIN_ERROR = "PLANTADMIN_ERROR";
export const PLANTADMIN_CLEAR_SNACKBAR = "PLANTADMIN_CLEAR_SNACKBAR";
export const EMPLOYEE_LOADING ="EMPLOYEE_LOADING";
export const EMPLOYEE_GET_ALL_SUCCESS = "EMPLOYEE_GET_ALL_SUCCESS";
export const EMPLOYEE_ERROR = "EMPLOYEE_ERROR";
export const EMPLOYEE_CLEAR_SNACKBAR = "EMPLOYEE_CLEAR_SNACKBAR";
export const EMPLOYEE_SEARCH = "EMPLOYEE_SEARCH";

export const GUARD_LOADING ="GUARD_LOADING";
export const GUARD_GET_ALL_SUCCESS = "GUARD_GET_ALL_SUCCESS";
export const GUARD_ERROR = "GUARD_ERROR";
export const GUARD_CLEAR_SNACKBAR = "GUARD_CLEAR_SNACKBAR";
export const GUARD_SEARCH = "GUARD_SEARCH";

export const SOS_LOADING ="SOS_LOADING";
export const SOS_GET_ALL_SUCCESS = "SOS_GET_ALL_SUCCESS";
export const SOS_ERROR = "SOS_ERROR";
export const SOS_CLEAR_SNACKBAR = "SOS_CLEAR_SNACKBAR";
export const SOS_SEARCH = "SOS_SEARCH";

export const SOS_CATEGORY_LOADING ="SOS_CATEGORY_LOADING";
export const SOS_CATEGORY_GET_ALL_SUCCESS = "SOS_CATEGORY_GET_ALL_SUCCESS";
export const SOS_CATEGORY_ERROR = "SOS_CATEGORY_ERROR";
export const SOS_CATEGORY_CLEAR_SNACKBAR = "SOS_CATEGORY_CLEAR_SNACKBAR";

export const IAMSAFE_LOADING ="IAMSAFE_LOADING";
export const IAMSAFE_GET_ALL_SUCCESS = "IAMSAFE_GET_ALL_SUCCESS";
export const IAMSAFE_ERROR = "IAMSAFE_ERROR";
export const IAMSAFE_CLEAR_SNACKBAR = "IAMSAFE_CLEAR_SNACKBAR";
export const IAMSAFE_SEARCH = "IAMSAFE_SEARCH";


 
export const BUS_CLEAR_SNACKBAR = "BUS_CLEAR_SNACKBAR";
export const BUS_ERROR = "BUS_ERROR";
export const BUS_GET_ALL_SUCCESS = "BUS_GET_ALL_SUCCESS";
export const BUS_LOADING = "BUS_LOADING";
export const GUARDATTENDENCE_LOADING ="GUARDATTENDENCE_LOADING";
export const GUARDATTENDENCE_GET_ALL_SUCCESS = "GUARDATTENDENCE_GET_ALL_SUCCESS";
export const GUARDATTENDENCE_ERROR = "GUARDATTENDENCE_ERROR";
export const GUARDATTENDENCE_CLEAR_SNACKBAR = "GUARDATTENDENCE_CLEAR_SNACKBAR";
export const EMPLOYEEATTENDENCE_LOADING ="EMPLOYEEATTENDENCE_LOADING";
export const EMPLOYEEATTENDENCE_GET_ALL_SUCCESS = "EMPLOYEEATTENDENCE_GET_ALL_SUCCESS";
export const EMPLOYEEATTENDENCE_ERROR = "EMPLOYEEATTENDENCE_ERROR";
export const EMPLOYEEATTENDENCE_CLEAR_SNACKBAR = "EMPLOYEEATTENDENCE_CLEAR_SNACKBAR";
export const DRIVER_LOADING ="DRIVER_LOADING";
export const DRIVER_GET_ALL_SUCCESS = "DRIVER_GET_ALL_SUCCESS";
export const DRIVER_ERROR = "DRIVER_ERROR";
export const DRIVER_CLEAR_SNACKBAR = "DRIVER_CLEAR_SNACKBAR";
export const DRIVER_SEARCH = "DRIVER_SEARCH";

export const TRANSPORTINOUT_LOADING ="TRANSPORTINOUT_LOADING";
export const TRANSPORTINOUT_GET_ALL_SUCCESS = "TRANSPORTINOUT_GET_ALL_SUCCESS";
export const TRANSPORTINOUT_ERROR = "TRANSPORTINOUT_ERROR";
export const TRANSPORTINOUT_SEARCH = "TRANSPORTINOUT_SEARCH";
export const TRANSPORTINOUT_CLEAR_SNACKBAR = "TRANSPORTINOUT_CLEAR_SNACKBAR";
export const INTERCARTINGINOUT_LOADING ="INTERCARTINGINOUT_LOADING";
export const INTERCARTINGINOUT_GET_ALL_SUCCESS = "INTERCARTINGINOUT_GET_ALL_SUCCESS";
export const INTERCARTINGINOUT_ERROR = "INTERCARTINGINOUT_ERROR";
export const INTERCARTINGINOUT_CLEAR_SNACKBAR = "INTERCARTINGINOUT_CLEAR_SNACKBAR";
export const INTERCARTINGINOUT_SEARCH = "INTERCARTINGINOUT_SEARCH";
export const PARKINGDEPARTMENT_LOADING = 'PARKINGDEPARTMENT_LOADING';
export const PARKINGDEPARTMENT_GET_ALL_SUCCESS = 'PARKINGDEPARTMENT_GET_ALL_SUCCESS';
export const PARKINGDEPARTMENT_CLEAR_SNACKBAR = 'PARKINGDEPARTMENT_CLEAR_SNACKBAR';
export const PARKINGDEPARTMENT_ERROR = 'PARKINGDEPARTMENT_ERROR';
export const PARKING_LOADING = 'PARKING_LOADING';
export const PARKING_GET_ALL_SUCCESS = 'PARKING_GET_ALL_SUCCESS';
export const PARKING_CLEAR_SNACKBAR = 'PARKING_CLEAR_SNACKBAR';
export const PARKING_ERROR = 'PARKING_ERROR';
export const DELIVARY_LOADING = 'DELIVARY_LOADING';
export const DELIVARY_GET_ALL_SUCCESS = 'DELIVARY_GET_ALL_SUCCESSG_GET_ALL_SUCCESS';
export const DELIVARY_CLEAR_SNACKBAR = 'DELIVARY_CLEAR_SNACKBAR';
export const DELIVARY_ERROR = 'DELIVARY_ERROR';
export const CANTEEN_LOADING = 'CANTEEN_LOADING';
export const CANTEEN_GET_ALL_SUCCESS = 'CANTEEN_GET_ALL_SUCCESSG_GET_ALL_SUCCESS';
export const CANTEEN_CLEAR_SNACKBAR = 'CANTEEN_CLEAR_SNACKBAR';
export const CANTEEN_ERROR = 'CANTEEN_ERROR';
export const CANTEENINOUT_LOADING = 'CANTEENINOUT_LOADING';
export const CANTEENINOUT_GET_ALL_SUCCESS = 'CANTEENINOUT_GET_ALL_SUCCESSG_GET_ALL_SUCCESS';
export const CANTEENINOUT_CLEAR_SNACKBAR = 'CANTEENINOUT_CLEAR_SNACKBAR';
export const CANTEENINOUT_ERROR = 'CANTEENINOUT_ERROR';
export const CANTEENINOUT_SEARCH = 'CANTEENINOUT_SEARCH';
export const DELIVARYINOUT_LOADING = 'DELIVARYINOUT_LOADING';
export const DELIVARYINOUT_GET_ALL_SUCCESS = 'DELIVARYINOUT_GET_ALL_SUCCESSG_GET_ALL_SUCCESS';
export const DELIVARYINOUT_CLEAR_SNACKBAR = 'DELIVARYINOUT_CLEAR_SNACKBAR';
export const DELIVARYINOUT_ERROR = 'DELIVARYINOUT_ERROR';
export const DELIVARYINOUT_SEARCH = 'DELIVARYINOUT_SEARCH';
export const GUARDMODULE_LOADING = 'GUARDMODULE_LOADING';
export const GUARDMODULE_GET_ALL_SUCCESS = 'GUARDMODULE_GET_ALL_SUCCESSG_GET_ALL_SUCCESS';
export const GUARDMODULE_CLEAR_SNACKBAR = 'GUARDMODULE_CLEAR_SNACKBAR';
export const GUARDMODULE_ERROR = 'GUARDMODULE_ERROR';
export const VISITORINOUT_LOADING = 'VISITORINOUT_LOADING';
export const VISITORINOUT_GET_ALL_SUCCESS = 'VISITORINOUT_GET_ALL_SUCCESSG_GET_ALL_SUCCESS';
export const VISITORINOUT_CLEAR_SNACKBAR = 'VISITORINOUT_CLEAR_SNACKBAR';
export const VISITORINOUT_ERROR = 'VISITORINOUT_ERROR';
export const VISITOR_SEARCH = 'VISITOR_SEARCH';

export const REPORTINCEDENT_LOADING = 'REPORTINCEDENT_LOADING';
export const REPORTINCEDENT_GET_ALL_SUCCESS = 'REPORTINCEDENT_GET_ALL_SUCCESSG_GET_ALL_SUCCESS';
export const REPORTINCEDENT_CLEAR_SNACKBAR = 'REPORTINCEDENT_CLEAR_SNACKBAR';
export const REPORTINCEDENT_ERROR = 'REPORTINCEDENT_ERROR';
export const REPORTINCEDENT_SEARCH  = 'REPORTINCEDENT_SEARCH';

export const BANNER_POSITION_LOADING = 'BANNER_POSITION_LOADING';
export const BANNER_POSITION_GET_ALL_SUCCESS = 'BANNER_POSITION_GET_ALL_SUCCESS';
export const BANNER_POSITION_CLEAR_SNACKBAR = 'BANNER_POSITION_CLEAR_SNACKBAR';
export const BANNER_POSITION_ERROR = 'BANNER_POSITION_ERROR';
export const DEVICE_LOADING = 'DEVICE_LOADING';
export const DEVICE_GET_ALL_SUCCESS = ' DEVICE_GET_ALL_SUCCESS';
export const DEVICE_ERROR = 'DEVICE_ERROR';
export const DEVICE_CLEAR_SNACKBAR = 'DEVICE_CLEAR_SNACKBAR';
export const DESIGNATION_LOADING = 'DESIGNATION_LOADING';
export const DESIGNATION_GET_ALL_SUCCESS = 'DESIGNATION_GET_ALL_SUCCESS';
export const DESIGNATION_ERROR = 'DESIGNATION_ERROR';
export const DESIGNATION_CLEAR_SNACKBAR ='DESIGNATION_CLEAR_SNACKBAR';
export const EMPLOYEEMODULE_LOADING = 'EMPLOYEEMODULE_LOADING';
export const EMPLOYEEMODULE_GET_ALL_SUCCESS = 'EMPLOYEEMODULE_GET_ALL_SUCCESS';
export const EMPLOYEEMODULE_ERROR = 'EMPLOYEEMODULE_ERROR';
export const EMPLOYEEMODULE_CLEAR_SNACKBAR = 'EMPLOYEEMODULE_CLEAR_SNACKBAR';
export const EMPLOYEETYPE_LOADING = 'EMPLOYEETYPE_LOADING';
export const EMPLOYEETYPE_GET_ALL_SUCCESS = 'EMPLOYEETYPE_GET_ALL_SUCCESS';
export const EMPLOYEETYPE_ERROR = 'EMPLOYEETYPE_ERROR';
export const EMPLOYEETYPE_CLEAR_SNACKBAR = 'EMPLOYEETYPE_CLEAR_SNACKBAR';
export const HIGH_ALERT_LOADING = 'HIGH_ALERT_LOADING';
export const HIGH_ALERT_GET_ALL_SUCCESS = 'HIGH_ALERT_GET_ALL_SUCCESS';
export const HIGH_ALERT_CLEAR_SNACKBAR = 'HIGH_ALERT_CLEAR_SNACKBAR';
export const HIGH_ALERT_ERROR = 'HIGH_ALERT_ERROR';
export const HIGH_ALERT_SEARCH = 'HIGH_ALERT_SEARCH';

export const SCHEDULE_HIGH_ALERT_LOADING = 'SCHEDULE_HIGH_ALERT_LOADING';
export const SCHEDULE_HIGH_ALERT_GET_ALL_SUCCESS = 'SCHEDULE_SCHEDULE_HIGH_ALERT_GET_ALL_SUCCESS';
export const SCHEDULE_HIGH_ALERT_CLEAR_SNACKBAR = 'SCHEDULE_HIGH_ALERT_CLEAR_SNACKBAR';
export const SCHEDULE_HIGH_ALERT_ERROR = 'SCHEDULE_HIGH_ALERT_ERROR';

export const EMERGENCY_CONTACT_LOADING = 'EMERGENCY_CONTACT_LOADING';
export const EMERGENCY_CONTACT_GET_ALL_SUCCESS = 'EMERGENCY_CONTACT_GET_ALL_SUCCESS';
export const EMERGENCY_CONTACT_ERROR = 'EMERGENCY_CONTACT_ERROR';
export const EMERGENCY_CONTACT_CLEAR_SNACKBAR = 'EMERGENCY_CONTACT_CLEAR_SNACKBAR';

export const PATROLLING_ROUTE_CLEAR_SNACKBAR = 'PATROLLING_ROUTE_CLEAR_SNACKBAR';
export const PATROLLING_ROUTE_LOADING = 'PATROLLING_ROUTE_LOADING';
export const PATROLLING_ROUTE_ERROR = 'PATROLLING_ROUTE_ERROR';
export const PATROLLING_ROUTE_GET_ALL_SUCCESS = 'PATROLLING_ROUTE_GET_ALL_SUCCESS';
export const PATROLLING_ROUTE_SEARCH = 'PATROLLING_ROUTE_SEARCH';

export const PATROLLING_LOGS_CLEAR_SNACKBAR = 'PATROLLING_LOGS_CLEAR_SNACKBAR';
export const PATROLLING_LOGS_LOADING = 'PATROLLING_LOGS_LOADING';
export const PATROLLING_LOGS_ERROR = 'PATROLLING_LOGS_ERROR';
export const PATROLLING_LOGS_GET_ALL_SUCCESS = 'PATROLLING_LOGS_GET_ALL_SUCCESS';
export const PATROLLING_LOGS_SEARCH = 'PATROLLING_LOGS_SEARCH';

export const FLOOR_CLEAR_SNACKBAR = 'FLOOR_CLEAR_SNACKBAR';
export const FLOOR_LOADING = 'FLOOR_LOADING';
export const FLOOR_ERROR = 'FLOOR_ERROR';
export const FLOOR_GET_ALL_SUCCESS = 'FLOOR_GET_ALL_SUCCESS';
export const PATROLLING_REQUEST_CLEAR_SNACKBAR = 'PATROLLING_REQUEST_CLEAR_SNACKBAR';
export const PATROLLING_REQUEST_LOADING = 'PATROLLING_REQUEST_LOADING';
export const PATROLLING_REQUEST_ERROR = 'PATROLLING_REQUEST_ERROR';
export const PATROLLING_REQUEST_GET_ALL_SUCCESS = 'PATROLLING_REQUEST_GET_ALL_SUCCESS';
export const BUS_ROUTE_CLEAR_SNACKBAR = 'BUS_ROUTE_CLEAR_SNACKBAR';
export const BUS_ROUTE_LOADING = 'BUS_ROUTE_LOADING';
export const BUS_ROUTE_ERROR = 'BUS_ROUTE_ERROR';
export const BUS_ROUTE_GET_ALL_SUCCESS = 'BUS_ROUTE_GET_ALL_SUCCESS';

export const MATERIAL_CLEAR_SNACKBAR = 'MATERIAL_CLEAR_SNACKBAR';
export const MATERIAL_LOADING = 'MATERIAL_LOADING';
export const MATERIAL_ERROR = 'MATERIAL_ERROR';
export const MATERIAL_GET_ALL_SUCCESS = 'MATERIAL_GET_ALL_SUCCESS';
export const MATERIAL_SEARCH = 'MATERIAL_SEARCH';

export const MATERIAL_GATE_PASS_CLEAR_SNACKBAR = 'MATERIAL_CLEAR_SNACKBAR';
export const MATERIAL_GATE_PASS_LOADING = 'MATERIAL_LOADING';
export const MATERIAL_GATE_PASS_ERROR = 'MATERIAL_ERROR';
export const MATERIAL_GATE_PASS_GET_ALL_SUCCESS = 'MATERIAL_GET_ALL_SUCCESS';
export const MATERIAL_GATE_PASS_SEARCH = 'MATERIAL_GATE_PASS_SEARCH';

export const RAISED_GATE_PASS_CLEAR_SNACKBAR = 'RAISED_CLEAR_SNACKBAR';
export const RAISED_GATE_PASS_LOADING = 'RAISED_LOADING';
export const RAISED_GATE_PASS_ERROR = 'RAISED_ERROR';
export const RAISED_GATE_PASS_GET_ALL_SUCCESS = 'RAISED_GET_ALL_SUCCESS';
export const RAISED_GATE_PASS_SEARCH = 'RAISED_GATE_PASS_SEARCH';

export const OUTPASS_CLEAR_SNACKBAR = 'OUTPASS_CLEAR_SNACKBAR';
export const OUTPASS_LOADING = 'OUTPASS_LOADING';
export const OUTPASS_ERROR = 'OUTPASS_ERROR';
export const OUTPASS_GET_ALL_SUCCESS = 'OUTPASS_GET_ALL_SUCCESS';
export const OUTPASS_SEARCH = 'OUTPASS_SEARCH';

export const WEIGHMENTBRIDGE_CLEAR_SNACKBAR = 'WEIGHMENTBRIDGE_CLEAR_SNACKBAR';
export const WEIGHMENTBRIDGE_LOADING = 'WEIGHMENTBRIDGE_LOADING';
export const WEIGHMENTBRIDGE_ERROR = 'WEIGHMENTBRIDGE_ERROR';
export const WEIGHMENTBRIDGE_GET_ALL_SUCCESS = 'WEIGHMENTBRIDGE_GET_ALL_SUCCESS';
export const WEIGHMENTBRIDGE_SEARCH = 'WEIGHMENTBRIDGE_SEARCH';

export const REPORTINCEDENT_MODULE_CLEAR_SNACKBAR = 'REPORTINCEDENT_CLEAR_SNACKBAR';
export const REPORTINCEDENT_MODULE_LOADING = 'REPORTINCEDENT_LOADING';
export const REPORTINCEDENT_MODULE_ERROR = 'REPORTINCEDENT_ERROR';
export const REPORTINCEDENT_MODULE_GET_ALL_SUCCESS = 'REPORTINCEDENT_GET_ALL_SUCCESS';

export const VISIT_CLEAR_SNACKBAR =  "VISIT_CLEAR_SNACKBAR";
export const  VISIT_LOADING     =   "VISIT_LOADING";
export const VISIT_GET_ALL_SUCCESS = " VISIT_GET_ALL_SUCCESS";
export const  VISIT_ERROR = "VISIT_ERROR";

export const LONG_ALERT_LOADING = "LONG_ALERT_LOADING";
export const LONG_ALERT_GET_ALL_SUCCESS = "LONG_ALERT_GET_ALL_SUCCESS";
export const LONG_ALERT_ERROR = "LONG_ALERT_ERROR";
export const LONG_ALERT_CLEAR_SNACKBAR = "LONG_ALERT_CLEAR_SNACKBAR";
export const LONG_ALERT_SEARCH = "LONG_ALERT_SEARCH";

export const WRONG_ENTRY_LOADING  = "WRONG_ENTRY_LOADING";
export const WRONG_ENTRY_GET_ALL_SUCCESS = "WRONG_ENTRY_GET_ALL_SUCCESS";
export const WRONG_ENTRY_ERROR = "WRONG_ENTRY_ERROR";
export const WRONG_ENTRY_CLEAR_SNACKBAR = "WRONG_ENTRY_CLEAR_SNACKBAR";
export const WRONG_ENTRY_SEARCH = "WRONG_ENTRY_SEARCH";

export const GUARD_WORKEMP_LOADING = "GUARD_WORKEMP_LOADING";
export const GUARD_WORKEMP_GET_ALL_SUCCESS = "GUARD_WORKEMP_GET_ALL_SUCCESS";
export const GUARD_WORKEMP_ERROR = "GUARD_WORKEMP_ERROR";
export const GUARD_WORKEMP_CLEAR_SNACKBAR = "GUARD_WORKEMP_CLEAR_SNACKBAR";

export const HOWITWORK_LOADING = "HOWITWORK_LOADING";
export const HOWITWORK_GET_ALL_SUCCESS = "HOWITWORK_GET_ALL_SUCCESS";
export const HOWITWORK_ERROR = "HOWITWORK_ERROR";
export const HOWITWORK_CLEAR_SNACKBAR = "HOWITWORK_CLEAR_SNACKBAR";

export const  PURPOSE_LOADING = "PURPOSE_LOADING";
export const PURPOSE_GET_ALL_SUCCESS = "PURPOSE_GET_ALL_SUCCESS";
export const PURPOSE_ERROR = "PURPOSE_ERROR";
export const PURPOSE_CLEAR_SNACKBAR = "PURPOSE_CLEAR_SNACKBAR";

export const  CALL_SETTING_LOADING = "CALL_SETTING_LOADING";
export const CALL_SETTING_GET_ALL_SUCCESS = "CALL_SETTING_GET_ALL_SUCCESS";
export const CALL_SETTING_ERROR = "CALL_SETTING_ERROR";
export const CALL_SETTING_CLEAR_SNACKBAR = "CALL_SETTING_CLEAR_SNACKBAR";



export const server_url = 'https://api-kavach.anxion.co.in/api';
// export const server_url = 'http://localhost:3500/api';

