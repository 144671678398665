import React, { useEffect, useState } from 'react';
import {
  Flex,
  Input,
  SimpleGrid,
  Text,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Button,
  Center,
  useColorModeValue,
  Select
} from '@chakra-ui/react';
import Relect from 'react-select';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { useDispatch, useSelector } from 'react-redux';
import { useToast } from '@chakra-ui/react';
import { PATROLLING_REQUEST_CLEAR_SNACKBAR } from 'constants/types';
import { Spinner } from '@chakra-ui/react';
import dataConstant from 'constants/dataConstant';
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { patrollingRequestAddOrUpdate } from 'actions/patrollingRequestAction';
import { patrollingRouteGetAll } from "actions/PatrollingRouteAction";
import {floorGetAll} from 'actions/floorAction';
import { guardGetAll } from 'actions/GaurdAction';
// import {guardGetAll} from ''
import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom';
function PatrollingRequestForm() {
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const [patrollingRouteName, setPatrollingRouteName] = useState([]);
  const [floor, SetFloor] = useState('');
  const [guard, setGuard] = useState('');
  const [dateTime, setDateTime] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const [isErrorPatrollingRouteName, setIsErrorPatrollingRouteName] = useState(false);
  const [isErrorFloor, setIsErrorFloor] = useState(false);
  const [isErrorGuard, setIsErrorGuard] = useState(false);
  const [isErrorDateTime, setIsErrorDateTime] = useState(false);

  const dispatch = useDispatch();
  const toast = useToast();
  const params = useParams();
  const history = useHistory();
  const statuses = ['success', 'error', 'warning', 'info'];
  const responseStatus = useSelector((state) => state.patrollingRequest.status);
  const responseMessage = useSelector((state) => state.patrollingRequest.text);
  const loading = useSelector((state) => state.patrollingRequest.loading);
  const showSnackbar = useSelector((state) => state.patrollingRequest.showSnackbar);
  const patrollingRouteList = useSelector(state => state.patrollingRoute.patrollingRouteList);
  const floorList = useSelector(state=> state.floor.floorList);
  const guardList = useSelector(state => state.guard.guardList);
  console.log("guardList");
  console.log(guardList);
  console.log("guardList");

  // Function to handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    setIsErrorPatrollingRouteName(patrollingRouteName === '');
    setIsErrorFloor(floor === '');
    setIsErrorGuard(guard === '');
    setIsErrorDateTime(dateTime === '');


    if (patrollingRouteName.length !== '' && floor.length !== '' && guard !== '' && dateTime !== '' ) {
      // Get form data
      const patrollingRequestData = {
        patrollingRoute : patrollingRouteName.map((module) => module.value),
        floor: 
        floor.map((module) => module.value),
        guard: guard,
        dateTime : dateTime,

      };
console.log("patrollingRequestData")
console.log(patrollingRequestData)

      // Determine if it's an add or edit operation
      const state = 'add';

      // Call the patrollingRequestAddOrUpdate action
      dispatch(patrollingRequestAddOrUpdate(patrollingRequestData, state));
    }
  };


  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
        if (responseMessage !== '') {
          toastFunction(responseMessage, 'success');
          history.goBack();
          // Redirect to the PatrollingRequest table or the desired page after successful submission
        }
      } else if (responseStatus === 'NOK' && loading === false) {
        if (responseMessage !== '') {
          toastFunction(responseMessage, 'error');
        }
      }
      // history.push('/admin/PatrollingRequest-table')
    }
    dispatch({
      type: PATROLLING_REQUEST_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      },
    });
  }, [loading]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  };

  useEffect(() => {
    dispatch(patrollingRouteGetAll('limit=1000'));
    dispatch(floorGetAll('limit=1000'));
    dispatch(guardGetAll('limit=1000'))
  }, [])
  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])
  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Patrolling Request Form
          </Text>
          <br/>
          <SimpleGrid minChildWidth='120px' spacing='40px'>
          <FormControl isInvalid={isErrorPatrollingRouteName  || (submitted &&  patrollingRouteName === "")}>
                <FormLabel>Patrolling-Route</FormLabel>
                <Relect
               isMulti
               name="colors"
               placeholder="--Select Patrolling-Route--"
               options={patrollingRouteList.map((item) => ({
                  value: item._id,
                  label: item.name
                  }))}
              
               onChange={(value) => {
                setPatrollingRouteName(value)
                 setIsErrorPatrollingRouteName(value === '')}}
                className="basic-multi-select"
                classNamePrefix="select"
              />
              
              {!isErrorPatrollingRouteName && submitted && (
                  <FormErrorMessage> Patrolling-Route is required.</FormErrorMessage>
                )}
                
              </FormControl>

              <FormControl isInvalid={isErrorFloor  || (submitted &&  floor === "")}>
                <FormLabel>Floor</FormLabel>
                <Relect
               isMulti
               name="colors"
               placeholder="--Select Patrolling-Route--"
               options={floorList.map((item) => ({
                  value: item._id,
                  label: item.name
                  }))}
              
               onChange={(value) => {
                SetFloor(value)
                 setIsErrorFloor(value === '')}}
                className="basic-multi-select"
                classNamePrefix="select"
              />
              
              {!isErrorFloor && submitted && (
                  <FormErrorMessage>floor is required.</FormErrorMessage>
                )}
                
              </FormControl>

          </SimpleGrid>

          <SimpleGrid minChildWidth='120px' spacing='40px' marginTop='30px'>
            <FormControl isInvalid={isErrorGuard}>
              <FormLabel>Guard</FormLabel>
              <Select
                placeholder="Select Guard"
                onChange={(e) => {
                  setIsErrorGuard(e.target.value === '');
                  setGuard(e.target.value);
                }}
              >
                {guardList.map((element, index) => {
                  return (<option key={element['_id']} value={element._id} >{element.name}</option>);
                })}
              </Select>
              {!isErrorGuard ? (
                <></>
              ) : (
                <FormErrorMessage>Guard is required.</FormErrorMessage>
              )}
            </FormControl>


            <FormControl isInvalid={isErrorDateTime || (submitted && dateTime === "")}>
                  <FormLabel>Date & Time</FormLabel>
                  <Input
                    type='datetime-local'
                    onChange={(e) => {

                      setIsErrorDateTime(e.target.value === '')
                      setDateTime(e.target.value)
                    }}
                  />
                  {!isErrorDateTime ? (
                    <></>
                  ) : (
                    <FormErrorMessage>Date Time is required.</FormErrorMessage>
                  )}
                </FormControl>

           
          </SimpleGrid>
          <Center>
            {loading ? <Spinner mt='24px' size='lg' /> :
              <Button colorScheme='blue' mt='24px' onClick={handleSubmit}>
                Submit
              </Button>
            }
          </Center>
        </CardHeader>
        <CardBody>
          {/* Additional form fields and content can be added here */}
        </CardBody>
      </Card>
    </Flex>
  );
}

export default PatrollingRequestForm;
