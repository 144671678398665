import React, { useEffect, useState,useRef } from 'react';
import { Flex,Button, InputGroup, Spacer, Table, Tbody, Text, Td, Th, Thead, Tr, useColorModeValue, InputLeftElement, IconButton, Input, Icon, Link } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { useToast } from '@chakra-ui/react';
import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
// import DevicePagination from './DevicePagination';
import { AddIcon } from '@chakra-ui/icons';
import { PATROLLING_LOGS_CLEAR_SNACKBAR,PATROLLING_LOGS_SEARCH } from 'constants/types';
// import {  } from '../../actions/devicesAction'; // Assuming you have a DeviceAction file
// import DeviceTableRow from './DevicesTableRow';
import PatrollingLogsTableRow from './PatrollingLogsTableRow'
// import DevicesPagination from './DevicesPagination';
// import PatrollingLogsagination from './PatrollingLogsPagination'

import { patrollingLogsGetAll } from '../../actions/patrollingLogsAction';
import PatrollingLogsPagination from './PatrollingLogsPagination';
import PatrollingLogsAdvanceSearch from './PatrollingLogsAdvanceSearch';

const PatrollingLogsTable = (props) => {
  const {url= "",plant} = props;
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const responseStatus = useSelector(state => state.patrollingLogs.status);
  const responseMessage = useSelector(state => state.patrollingLogs.text);
  const loading = useSelector(state => state.patrollingLogs.loading);
  const patrollingLogsList = useSelector(state => state.patrollingLogs.patrollingLogsList);
  const response = useSelector(state => state.patrollingLogs.response);
  const showSnackbar = useSelector(state => state.patrollingLogs.showSnackbar);
  const currentPage = useSelector(state => state.patrollingLogs.page);
  const limit = useSelector(state => state.patrollingLogs.limit);
  const dispatch = useDispatch();
  const toast = useToast();
  const searchIconColor = useColorModeValue("gray.700", "gray.200");
  const inputBg = useColorModeValue("#edf3f8", "navy.800");
  const [search, setSearch] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const history = useHistory();
  const tableRef = useRef(null);
  useEffect(() => {
    dispatch({
      type: PATROLLING_LOGS_SEARCH,
      payload: {
        searching: "",
      },
    });
    dispatch(patrollingLogsGetAll("&plant="+plant));
  }, [dispatch]);
  console.log("patrollingLogsList")
  console.log(patrollingLogsList)
  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: PATROLLING_LOGS_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);
  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])
  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("desc");
    }
    const sorting = "&sortOrder=" + sortOrder + "&column=" + column;
    dispatch({
      type: PATROLLING_LOGS_SEARCH,
      payload: {
        searching: "",
      },
    });
    dispatch(deviceGetAll(sorting));
  };

  useEffect(() => {
    const searching = "&search=" + search;
    dispatch({
      type: PATROLLING_LOGS_SEARCH,
      payload: {
        searching: "",
      },
    });
    dispatch(patrollingLogsGetAll(searching));
  }, [search]);

  return (
    <>
    <PatrollingLogsAdvanceSearch></PatrollingLogsAdvanceSearch>
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <Flex>
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
            Patrolling Logs
            </Text>
          </CardHeader>
          <Spacer />
          {/* <Flex justify="flex-end" style={{ padding: '10px' ,marginTop : '-5px' ,marginRight :'12px' }}> 
            <Link href={`#/admin/patrolling-logs-form`}>
            <IconButton
              colorScheme="blue"
              aria-label="Search database"
              icon={<AddIcon />}
            />
          </Link>
          </Flex> */}
          <Flex justify="flex-end" style={{ padding: '10px', marginTop: '-5px', marginRight: '12px' }}>
            <DownloadTableExcel
              filename="Patrolling Logs Table"
              sheet="users"
              currentTableRef={tableRef.current}
            >
              <Button colorScheme="blue" _hover={{ backgroundColor: 'black', color: 'white' }}>
                Export Excel
              </Button>
            </DownloadTableExcel>
          </Flex>
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              <InputGroup borderRadius="8px" w="200px">
                <InputLeftElement
                  children={
                    <IconButton
                      bg="inherit"
                      borderRadius="inherit"
                      _hover="none"
                      _active={{
                        bg: "inherit",
                        transform: "none",
                        borderColor: "transparent",
                      }}
                      _focus={{
                        boxShadow: "none",
                      }}
                      icon={
                        <SearchIcon
                          color={searchIconColor}
                          w="15px"
                          h="15px"
                        />
                      }
                    ></IconButton>
                  }
                />
                <Input
                  variant="search"
                  fontSize="xs"
                  bg={inputBg}
                  onKeyUp={(e) => {
                    setSearch(e.target.value);
                    if (e.target.value.length < 3) {
                      setSearch("");
                    }
                  }}
                  placeholder="Type here..."
                />
              </InputGroup>
            </Text>
          </CardHeader>
        </Flex>
        <CardBody pb={4}>
        <div style={{ overflowX: "auto" }}>
        
          <Table variant="simple" color={textColor} ref={tableRef}>
            <Thead>
              <Tr my=".8rem" pl="0px" color="gray.400">
              <Th
                    pl="15px"
                    borderColor={borderColor}
                    color="gray.400"
                    cursor="pointer"
                  >
                   SNO
                  </Th>
                <Th
                  pl="0px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("name")}
                  cursor="pointer"
                >
                 guard Name
                  {sortColumn === "name" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
              
                
                            
                <Th
                  pl="0px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("name")}
                  cursor="pointer"
                >
                 Start-Date-Time
                  {sortColumn === "name" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                            
                <Th
                  pl="0px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("name")}
                  cursor="pointer"
                >
                 End-Date-Time
                  {sortColumn === "name" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                            
                <Th
                  pl="0px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("name")}
                  cursor="pointer"
                >
                 route Name
                  {sortColumn === "name" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>

                <Th
                  pl="0px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("name")}
                  cursor="pointer"
                >
                 no-Of-Scan
                  {sortColumn === "name" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  pl="0px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("name")}
                  cursor="pointer"
                >
                 scan-Log
                  {sortColumn === "name" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  pl="0px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("name")}
                  cursor="pointer"
                >
                 missed-Scan-Log
                  {sortColumn === "name" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                {/* <Th
                  pl="0px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("name")}
                  cursor="pointer"
                >
                 patrolling Logs
                  {sortColumn === "name" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th> */}
                
              </Tr>
            </Thead>
            <Tbody>
              { patrollingLogsList ? (patrollingLogsList.map((row, index, arr) => {
                    
                return (
                  <PatrollingLogsTableRow
                   serialnumber={((currentPage - 1)*limit) + (index+1)}
                    guardName={row.guard.name}
                    startDateTime = {row.startDateTime}
                    endDateTime = {row.endDateTime}
                    routeName = {row.route.name}
                    noOfScan = {row.noOfScan}
                    scanLog = {row.scanLog.length !== 0 ? row.scanLog : [] }
                    missedScanLog = {row.missedScanLog.length !== 0 ? row.missedScanLog : [] }
                    // patrollingLogs={row.patrollingLogs.length !== 0 ? row.patrollingLogs : [] }
                    isLast={index === arr.length - 1 ? true : false}
                    id={row._id}
                    key={row._id}
                  />
                );
              })):(<h1></h1>)
              }
            </Tbody>
          </Table>
          </div>
          {patrollingLogsList.length === 0 ? (
            <></>
          ) : (
            <PatrollingLogsPagination
              totalPages={response.totalPages}
              currentPage={response.currentPage}
              perPage={response.perPage}
              sortBy={sortColumn}
              sortOrder={sortOrder}
              handlePageClick={(data) => {
                const selectedPage = data.selected + 1;
                dispatch(patrollingLogsGetAll(`&page=${selectedPage}`));
              }}
            />
          )}
        </CardBody>
      </Card>
    </Flex>
    </>
  );
};

export default PatrollingLogsTable;
