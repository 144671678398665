import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  SimpleGrid,
  Spacer,
  Text,
  Wrap,
  WrapItem,
  useColorModeValue,
  Spinner,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader";
import { useToast } from '@chakra-ui/react'
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
} from '@chakra-ui/react'
import { useHistory } from 'react-router-dom';
import { guardAddOrUpdate } from "actions/GaurdAction";
import { GUARDATTENDENCE_CLEAR_SNACKBAR } from "constants/types";

function guardBulkUpload() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [file, setFile] = useState("");
  
  const [submitted, setSubmitted] = useState(false);
  const history = useHistory();
  

  const [isErrorFile, setIsErrorFile] = useState(false);


  const statuses = ['success', 'error', 'warning', 'info'];
  const responseStatus = useSelector(state => state.guard.status);
  const responseMessage = useSelector(state => state.guard.text);
  const loading = useSelector(state => state.guard.loading);
  const showSnackbar = useSelector(state => state.guard.showSnackbar);

  
  console.log(loading ? "yes" : "no")

  const dispatch = useDispatch();
  const toast = useToast();

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);


    if (
      file !== ""
    ) {

      const newGuard = {
        excelFile: file,
      };
      console.log(newGuard)
      dispatch(guardAddOrUpdate(newGuard, 'upload'));

    }
  };

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: GUARDATTENDENCE_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <form onSubmit={handleSubmit}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">

          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Guard Bulk Upload
            </Text>
            <br/>
            <SimpleGrid minChildWidth='120px' spacing='40px'>

              <FormControl isInvalid={isErrorFile}>
               
                <Input
                  type='file'
                  onChange={(e) => {
                    setIsErrorFile(e.target.value === '')
                    setFile(e.target.files[0])
                  }}
                />
                {isErrorFile && submitted && (
                  <FormErrorMessage>File is required.</FormErrorMessage>
                )}
              </FormControl>
            </SimpleGrid>


            <Center>
              {loading ? <Spinner mt='24px' size='lg' /> :
                <Button colorScheme='blue' mt='24px' type="submit">
                  Submit
                </Button>
              }
            </Center>
          </CardHeader>
          <CardBody>
            {/* ... */}
          </CardBody>
        </Card>
      </form>
    </Flex>
  );
}

export default guardBulkUpload;

