import axios from 'axios';
import {
  SCHEDULE_HIGH_ALERT_LOADING,
  SCHEDULE_HIGH_ALERT_GET_ALL_SUCCESS,
  SCHEDULE_HIGH_ALERT_ERROR,
  SCHEDULE_HIGH_ALERT_CLEAR_SNACKBAR
} from '../constants/types';

import { server_url } from '../constants/types';

export const schedulehighAlertGetAll = (pagination = '') => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
        
      dispatch({
        type: SCHEDULE_HIGH_ALERT_LOADING,
        payload: {
          loading: true
        }
      });
      axios
        .get(`${server_url}/schedule/highalert?${pagination}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
        .then((response) => {
          dispatch({
            type: SCHEDULE_HIGH_ALERT_GET_ALL_SUCCESS,
            payload: {
              schedulehighAlertList: response.data.data,
              response: response.data,
              loading: false,
              status: 'success',
              text: 'Get all high alerts successfully.'
            }
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: SCHEDULE_HIGH_ALERT_ERROR,
            payload: {
              status: 'error',
              text: 'Error occurred during getting high alert data.',
              loading: false
            }
          });
          resolve();
        });
    });
  };
};

export const schedulehighAlertAddOrUpdate = (highAlert, state) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: SCHEDULE_HIGH_ALERT_LOADING,
        payload: {
          loading: true
        }
      });

      if (state === 'add') {
        axios
          .post(`${server_url}/schedule/highalert`, highAlert, {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
          })
          .then((responseF) => {
            axios
              .get(`${server_url}/schedule/highalert`, {
                headers: {
                  'Authorization': 'Bearer ' + localStorage.getItem('authToken')
                }
              })
              .then((response) => {
                console.log("response.data.data");
                console.log(response.data.data);
                dispatch({
                  type: SCHEDULE_HIGH_ALERT_GET_ALL_SUCCESS,
                  payload: {
                    schedulehighAlertList: response.data.data,
                    response: response.data,
                    loading: false,
                    status: responseF.data['status'],
                    text: responseF.data['message']
                  }
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                    type: SCHEDULE_HIGH_ALERT_ERROR,
                    payload: {
                      text: 'Error occurred during getting highalert data.',
                      status: 'error',
                      loading: false
                    }
                  });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
                type: SCHEDULE_HIGH_ALERT_ERROR,
                payload: {
                  text: 'Error occurred during adding highalert data.',
                  status: 'error',
                  loading: false
                }
              });
            resolve();
          });
      }
      else if (state === 'edit') {
        dispatch({
            type: SCHEDULE_HIGH_ALERT_LOADING,
            payload: {
              loading: true
            }
          });
        axios
          .put(`${server_url}/schedule/highalert/${highAlert._id}`, highAlert, {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
          })
          .then((responseF) => {
            axios
              .get(`${server_url}/schedule/highalert`, {
                headers: {
                  'Authorization': 'Bearer ' + localStorage.getItem('authToken')
                }
              })
              .then((response) => {
                console.log("response.data.data");
                console.log(response.data.data);
                dispatch({
                  type: SCHEDULE_HIGH_ALERT_GET_ALL_SUCCESS,
                  payload: {
                    schedulehighAlertDetail: responseF.data['data'],
                    schedulehighAlertList: response.data.data,
                    response: response.data,
                    loading: false,
                    status: responseF.data['status'],
                    text: responseF.data['message']
                  }
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                    type: SCHEDULE_HIGH_ALERT_ERROR,
                    payload: {
                      text: 'Error occurred during getting highalert data.',
                      status: 'error',
                      loading: false
                    }
                  });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
                type: SCHEDULE_HIGH_ALERT_ERROR,
                payload: {
                  text: 'Error occurred during adding highalert data.',
                  status: 'error',
                  loading: false
                }
              });
            resolve();
          });
      } else if (state === 'view') {
        dispatch({
            type: SCHEDULE_HIGH_ALERT_LOADING,
            payload: {
              loading: true
            }
          });
        axios
        .get(`${server_url}/schedule/highalert-view/${highAlert._id}`, {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
          })
          .then((responseF) => {
            axios
              .get(`${server_url}/schedule/highalert`, {
                headers: {
                  'Authorization': 'Bearer ' + localStorage.getItem('authToken')
                }
              })
              .then((response) => {
                console.log(" this is highalert deatil")
                console.log(responseF.data['data'])
                dispatch({
                    type: SCHEDULE_HIGH_ALERT_GET_ALL_SUCCESS,
                    payload: {
                      schedulehighAlertList: response.data.data,
                      response: response.data,
                      schedulehighAlertDetail: responseF.data['data'],
                      loading: false,
                      status: responseF.data['status'],
                      text: responseF.data['message']
                  }
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: SCHEDULE_HIGH_ALERT_ERROR,
                  payload: {
                    text: 'Error occurred during getting high alert data.',
                    status: 'error',
                    loading: false
                  }
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
                type: SCHEDULE_HIGH_ALERT_ERROR,
                payload: {
                  text: 'Error occurred during viewing high alert data.',
                  status: 'error',
                  loading: false
                }
            });
            resolve();
          });
      }
    });
  };
};

export const schedulehighAlertClearShowSnackbar = () => {
    return (dispatch, getState) => {
      dispatch({
        type: SCHEDULE_HIGH_ALERT_CLEAR_SNACKBAR
      });
    };
  };
  
  
