import { useRef } from 'react';
import React, { useEffect, useState } from "react";
import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { useDispatch, useSelector } from "react-redux";
import { useToast } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { FLOOR_CLEAR_SNACKBAR } from "constants/types";
import { Table, Tbody, Td, Tr, TableContainer } from "@chakra-ui/react";
import { Skeleton, SkeletonText } from "@chakra-ui/react";
import { floorAddOrUpdate } from "actions/floorAction";
import axios from "axios";
import { server_url } from "constants/types";
import { useHistory } from "react-router-dom";
import QRCode from 'react-qr-code';
import { toPng } from 'html-to-image';
import {
  Button,
} from "@chakra-ui/react";
function FloorView() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [name, setName] = useState("");
  const [scan, setScan] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState(""); // Initialize status as a string
  const [patrollingRouteName, setPatrollingRouteName] = useState("");
  const [patrollingDescription, setPatrollingDescription] = useState("");
  const [time, setTime] = useState("");
  const [plantName, setPlantName] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const toast = useToast();

  const responseStatus = useSelector((state) => state.floor.status);
  const responseMessage = useSelector((state) => state.floor.text);
  const loading = useSelector((state) => state.floor.loading);
  const showSnackbar = useSelector((state) => state.floor.showSnackbar);
  const floorDetail = useSelector((state) => state.floor.floorDetail);
  const cancelRef = React.useRef();
  const qrCodeRef = useRef();
  const params = useParams();

  console.log("floorDetail");
  console.log(floorDetail);

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === "OK" && loading === false) {
        setName(floorDetail.name);
        setScan(floorDetail.qrCode);
        setDescription(floorDetail.description);
        setStatus(floorDetail.status ? "Active" : "Inactive");
        setPatrollingRouteName(floorDetail.patrollingRoute.name);
        setPatrollingDescription(floorDetail.patrollingRoute.description);
        setTime(floorDetail.time);
        setPlantName(floorDetail.plant.name);

        if (responseMessage !== "") {
          toastFunction(responseMessage, "success");
        }
      } else if (responseStatus === "NOK" && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, "error");
        }
      }
    }

    dispatch({
      type: FLOOR_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      },
    });
  }, [loading]);

  useEffect(() => {
    const floorId = params.id;
    const floor = {
      _id: floorId,
    };
    dispatch(floorAddOrUpdate(floor, "view"));
  }, [params.id]); // Include params.id as a dependency

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  };

  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("authToken"),
        },
      })
      .then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push("/auth/signin");
      });
  }, []);


  const handleDownloadQRCode = async () => {
    try {
      const svgString = qrCodeRef.current.querySelector('svg').outerHTML;
      const dataUrl = await toPng(qrCodeRef.current);

      const a = document.createElement('a');
      a.href = dataUrl;
      a.download = `${name}.png`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading QR code:', error);
    }
  };
  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Floor View
          </Text>
        </CardHeader>
        <CardBody>
          {loading ? (
            <SkeletonText noOfLines={3} spacing="4" />
          ) : (
            <TableContainer>
              <Table variant="simple">
                <Tbody>
                  <Tr>
                    <Td>Name</Td>
                    <Td>{name}</Td>
                  </Tr>
                  <Tr>
                    <Td>Description</Td>
                    <Td>{description}</Td>
                  </Tr>
                  <Tr>
                    <Td>Status</Td>
                    <Td>{status}</Td>
                  </Tr>
                  <Tr>
                    <Td>Scanner</Td>
                    
        <Td>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold" ref={qrCodeRef} style={{ textAlign: 'center', color: 'black' }}>
              <QRCode value={scan.toString()} />
              {/* <span style={{ display: 'inline-block', fontWeight: 'italic', color: 'white', padding: '15px',paddingTop: '5px', paddingBottom: '5px', textAlign: 'center', backgroundColor: 'blue', margin: "10px", marginLeft: '90px', marginRight: '90px', borderRadius: '10px'}}>{name}</span> */}
              {/* <span style={{
                display: 'inline-block',
                margin: '5px',
                borderRadius: '8px',
                fontFamily: 'Arial, sans-serif',
                fontSize: '16px',
                marginTop: '20px',
                backgroundColor: '#ccccff',
                color: 'black',
                border: '2px solid #3498db',
                padding: '15px',paddingTop: '5px', paddingBottom: '5px', textAlign: 'center',
              }}>{`Floor: ${name} Admin: ${plantName}`}</span> */}
            </Text>

            {/* <Button onClick={handleDownloadQRCode}>Download QR Code</Button> */}
          </Flex>
        </Td>
                  </Tr>
                  <Tr>
                    <Td>Patrolling-Route</Td>
                    <Td>
                      <ul>
                        <li>
                          <strong>Name:</strong> {patrollingRouteName}
                        </li>
                        <li>
                          <strong>Description:</strong> {patrollingDescription}
                        </li>
                      </ul>
                    </Td>
                  </Tr>
                  {/* <Tr>
                    <Td>patrollingRoute-Name</Td>
                    <Td>{patrollingRouteName}</Td>
                  </Tr>
                  <Tr>
                    <Td>patrollingRoute-Description</Td>
                    <Td>{patrollingDescription}</Td>
                  </Tr> */}
                  <Tr>
                    <Td>Plant Admin Name</Td>
                    <Td>{plantName}</Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          )}
        </CardBody>
      </Card>
    </Flex>
  );
}

export default FloorView;
