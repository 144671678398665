import React, { useEffect, useState,useRef } from 'react';
import { Flex, InputGroup,Button, Spacer, Table, Tbody, Text, Td, Th, Thead, Tr, useColorModeValue, InputLeftElement, IconButton, Input, Icon, Link } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { FaChevronDown, FaChevronUp ,FaUpload} from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';

import { useToast } from '@chakra-ui/react';
import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom';

import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import GaurdPagination from './GaurdPagination';
import { AddIcon } from '@chakra-ui/icons';
import { GUARD_CLEAR_SNACKBAR , GUARD_SEARCH} from 'constants/types';
import { guardGetAll } from '../../actions/GaurdAction';
import GaurdTableRow from './GaurdTableRow';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import GaurdAdvanceSearch from './GaurdAdvanceSearch';
const GaurdTable = () => {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const responseStatus = useSelector(state => state.guard.status);
  const responseMessage = useSelector(state => state.guard.text);
  const loading = useSelector(state => state.guard.loading);
  const guardList = useSelector(state => state.guard.guardList);
  const response = useSelector(state => state.guard.response);
  const showSnackbar = useSelector(state => state.guard.showSnackbar);
  const dispatch = useDispatch();
  const toast = useToast();
  const history = useHistory();
  const searchIconColor = useColorModeValue("gray.700", "gray.200");
  const inputBg = useColorModeValue("#edf3f8", "navy.800");
  const [search, setSearch] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const tableRef = useRef(null);
  useEffect(() => {
    dispatch({
      type : GUARD_SEARCH,
      payload : {
        searching : "",
      }
    })
    dispatch(guardGetAll());
  }, [dispatch]);

  console.log("ihuhsuhs-----------------");
  console.log(guardList);

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: GUARD_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("desc");
    }
    const sorting = "&sortOrder=" + sortOrder + "&column=" + column;
    dispatch({
      type : GUARD_SEARCH,
      payload : {
        searching : "",
      }
    })
    dispatch(guardGetAll(sorting));
  };

  useEffect(() => {
    const searching = "&search=" + search;
    dispatch({
      type : GUARD_SEARCH,
      payload : {
        searching : "",
      }
    })
    dispatch(guardGetAll(searching));
  }, [search]);
  
  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])
  return (
   <>
    <GaurdAdvanceSearch></GaurdAdvanceSearch>
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <Flex>
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Gaurds
            </Text>
          </CardHeader>
          <Spacer />
           
          <Flex justify="flex-end" style={{ padding: '10px', marginTop: '-5px', marginRight: '12px' }}>
            <Link href={`#/admin/gaurd-form`}>
              <IconButton
                colorScheme="blue"
                aria-label="Search database"
                icon={<AddIcon />}
              />
            </Link>
          </Flex>

          <Flex justify="flex-end" style={{ padding: '2px', marginTop: '3px', marginRight: '12px' }}>
            <Link href={`#/admin/guard-upload-excelsheet`}>
              <IconButton
                colorScheme="blue"
                aria-label="Search database"
                icon={<FaUpload />}
              />
            </Link>
          </Flex>

          <Flex justify="flex-end" style={{ padding: '10px', marginTop: '-5px', marginRight: '12px' }}>
            <DownloadTableExcel
              filename="Gaurd Table"
              sheet="users"
              currentTableRef={tableRef.current}
            >
              <Button colorScheme="blue" _hover={{ backgroundColor: 'black', color: 'white' }}>
                Export Excel
              </Button>
            </DownloadTableExcel>
          </Flex>
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              <InputGroup borderRadius="8px" w="200px">
                <InputLeftElement
                  children={
                    <IconButton
                      bg="inherit"
                      borderRadius="inherit"
                      _hover="none"
                      _active={{
                        bg: "inherit",
                        transform: "none",
                        borderColor: "transparent",
                      }}
                      _focus={{
                        boxShadow: "none",
                      }}
                      icon={
                        <SearchIcon
                          color={searchIconColor}
                          w="15px"
                          h="15px"
                        />
                      }
                    ></IconButton>
                  }
                />
                <Input
                  variant="search"
                  fontSize="xs"
                  bg={inputBg}
                  onKeyUp={(e) => {
                    setSearch(e.target.value);
                    if (e.target.value.length < 3) {
                      setSearch("");
                    }
                  }}
                  placeholder="Type here..."
                />
              </InputGroup>
            </Text>
          </CardHeader>
        </Flex>
        <CardBody pb={4}>
          <div style={{ overflowX: "auto" }}>
          
            <Table variant="simple" color={textColor} ref={tableRef}>
              <Thead>
                <Tr my=".8rem" pl="0px" color="gray.400">
                <Th
                    pl="15px"
                    borderColor={borderColor}
                    color="gray.400"
                    cursor="pointer"
                  >
                   SNO
                  </Th>
                  <Th
                    pl="0px"
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("name")}
                    cursor="pointer"
                  >
                    Name
                    {sortColumn === "name" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    pl="0px"
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("name")}
                    cursor="pointer"
                  >
                    Passcode
                    {sortColumn === "name" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("photo")}
                    cursor="pointer"
                  >
                    Photo
                    {sortColumn === "photo" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("number")}
                    cursor="pointer"
                  >
                    Number
                    {sortColumn === "number" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>

                  <Th borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("status")}
                    cursor="pointer">
                    Switch
                    {sortColumn === "status" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("status")}
                    cursor="pointer">
                    Department
                    {sortColumn === "status" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("status")}
                    cursor="pointer">
                    Subdepartment
                    {sortColumn === "status" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("status")}
                    cursor="pointer">
                    Designation
                    {sortColumn === "status" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>

                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("email")}
                    cursor="pointer"
                  >
                    checkpost
                    {sortColumn === "email" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>

                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("guardId")}
                    cursor="pointer"
                  >
                    Guard Id
                    {sortColumn === "guardId" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("guardId")}
                    cursor="pointer"
                  >
                    Start Date
                    {sortColumn === "guardId" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("guardId")}
                    cursor="pointer"
                  >
                    End Date
                    {sortColumn === "guardId" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("guardId")}
                    cursor="pointer"
                  >
                    Service Provider
                    {sortColumn === "guardId" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>

                  <Th
                    pl="0px"
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("employeeName")}
                    cursor="pointer"
                  >
                    Attendance History
                    {sortColumn === "employeeName" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th borderColor={borderColor}></Th>
                </Tr>
              </Thead>
              <Tbody>

                {guardList ? (guardList.map((row, index, arr) => {
                  return (
                    <GaurdTableRow
                      serialnumber={((response.currentPage - 1)*response.limit) +( index + 1)}
                      name={row.name}
                      photo={row.photo}
                      number={row.number}
                      checkpost={row.checkpost}
                      guardId={row.guardId}
                      status={row.status}
                      department={row.department.name}
                      subdepartment={row.subdepartment.name}
                      designation={row.designation.name}
                      passcode={row.passcode}
                      startDate={row.startDate}
                      endDate={row.endDate}
                      company={row.companyName}
                      activeDate ={row.activeDate}
                      inactiveDate ={row.inactiveDate}
                      isLast={index === arr.length - 1 ? true : false}
                      id={row._id}

                    />
                  );
                })) : (<h1></h1>)
                }

              </Tbody>
            </Table>
          </div>
          {guardList.length === 0 ? (
            <></>
          ) : (
            <GaurdPagination
            limit = {response.limit}
              totalPages={response.totalPages}
              currentPage={response.currentPage}
              perPage={response.perPage}
              sortBy={sortColumn}
              sortOrder={sortOrder}
              handlePageClick={(data) => {
                const selectedPage = data.selected + 1;
                dispatch(guardGetAll(`&page=${selectedPage}`));
              }}
            />
          )}
        </CardBody>
      </Card>
    </Flex>
   </>
  );
};

export default GaurdTable;
