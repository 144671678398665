import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  Select,
  SimpleGrid,
  Spacer,
  Spinner,
  Text,
  Wrap,
  WrapItem,
  useColorModeValue
} from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { useDispatch, useSelector } from 'react-redux';
import { getGroup, groupClearShowSnackbar, groupAddOrUpdate } from '../../actions/groupActions';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
} from '@chakra-ui/react'
import { useToast } from '@chakra-ui/react'
import { GROUP_CLEAR_SNACKBAR } from "constants/types";
import dataConstant from "constants/dataConstant";
import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom';
function GroupUpdateForm() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState(false);
  const [adminId, setAdminId] = useState("");
  const [isErrorName, setIsErrorName] = useState(false);
  const [isErrorStatus, setIsErrorStatus] = useState(false);

  const dispatch = useDispatch();
  const toast = useToast();
  const history = useHistory();
  const responseStatus = useSelector(state => state.group.status);
  const responseMessage = useSelector(state => state.group.text);
  const loading = useSelector(state => state.group.loading);
  const groupDetail = useSelector(state => state.group.groupDetail);
  const showSnackbar = useSelector(state => state.group.showSnackbar);

  const params = useParams();

  // Function to handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!isErrorName && !isErrorStatus) {
      // Get form data
      const group = {
        _id: params.id,
        name: name,
        description: description,
        status: status,
        // Other group data fields
      };
      console.log("group");
      console.log(group);

      // Determine if it's an add or edit operation
      const state = 'edit';
      // Call the addOrUpdateGroup action
      dispatch(groupAddOrUpdate(group, state));
    } else {
      setIsErrorName(name === '');
      setIsErrorStatus(status === '');
    }
  };

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
        console.log(groupDetail);
        setName(groupDetail.name);
        setDescription(groupDetail.description);
        setStatus(groupDetail.status);
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }

    dispatch(groupClearShowSnackbar());
  }, [loading]);

  useEffect(() => {
    const group = {
        _id : params.id
    };
    console.log(params);
    dispatch(groupAddOrUpdate(group, 'view'));
  }, []);

  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])
  
  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Update Form
          </Text>
          <SimpleGrid minChildWidth='120px' spacing='40px'>
            <FormControl isInvalid={isErrorName}>
              <FormLabel>Name</FormLabel>
              <Input
                type='text'
                value={name}
                onChange={(e) => {
                  setIsErrorName(e.target.value === '');
                  setName(e.target.value);
                }}
              />
              {!isErrorName ? (
                <></>
              ) : (
                <FormErrorMessage>Name is required.</FormErrorMessage>
              )}
            </FormControl>
            <FormControl>
              <FormLabel>Description</FormLabel>
              <Input
                type='text'
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </FormControl>
          </SimpleGrid>
          
          <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
            <FormControl isInvalid={isErrorStatus}>
              <FormLabel>Status</FormLabel>
              <Select
                placeholder="Select status"
                value={status}
                onChange={(e) => {
                  setIsErrorStatus(e.target.value === '')
                  setStatus(e.target.value)
                }}
              >
                { dataConstant.status.map((element,index) => {
                    return (<option key={element['id']} value={element['value']} >{element['name']}</option>);   
                  })}
              </Select>
              {!isErrorStatus ? (
                <></>
              ) : (
                <FormErrorMessage>Status is required.</FormErrorMessage>
              )}
            </FormControl>
          </SimpleGrid>
          <Center>
            {loading ? (
              <Spinner size='lg' mt='24px' />
            ) : (
              <Button
                colorScheme='blue'
                mt='24px'
                onClick={handleSubmit}
              >
                Submit
              </Button>
            )}
          </Center>
        </CardHeader>
        <CardBody>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default GroupUpdateForm;