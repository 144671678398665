import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  Wrap,
  WrapItem,
  useColorModeValue
} from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { useDispatch, useSelector } from 'react-redux';
import { checkpostAddOrUpdate, checkpostClearShowSnackbar } from '../../actions/checkpostActions';
import { useToast } from '@chakra-ui/react';
import { CHECKPOST_CLEAR_SNACKBAR } from "constants/types";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react';
import { Skeleton, SkeletonCircle, SkeletonText } from '@chakra-ui/react';
import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom';
function CheckpostViewForm() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [home, setHome] = useState("");
  const [guardmod, setGuardMod] = useState([]);
  const dispatch = useDispatch();
  const toast = useToast();
  const history = useHistory();
  const responseStatus = useSelector(state => state.checkpost.status);
  const responseMessage = useSelector(state => state.checkpost.text);
  const loading = useSelector(state => state.checkpost.loading);
  const checkpostDetail = useSelector(state => state.checkpost.checkpostDetail);
  const showSnackbar = useSelector(state => state.checkpost.showSnackbar);

  const params = useParams();
  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
        console.log(checkpostDetail);
        setName(checkpostDetail.name);
        setDescription(checkpostDetail.description);
        setStatus(checkpostDetail.status);
        setHome(checkpostDetail.homeModule)
        setGuardMod(checkpostDetail.gaurdModule)

        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }

      } else if (responseStatus === 'NOK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }

      }
    }

    dispatch(checkpostClearShowSnackbar());
  }, [loading]);

  useEffect(() => {
    console.log(params);
    const checkpost = {
      _id: params.id
    };
    dispatch(checkpostAddOrUpdate(checkpost, 'view'));
  }, []);
  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])
  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Checkpost View
          </Text>
        </CardHeader>
        <CardBody>
          {loading ? <Stack>
            <Skeleton height='20px' />
            <Skeleton height='20px' />
            <Skeleton height='20px' />
          </Stack>
            :
            <TableContainer>
              <Table variant='simple'>
                <Tbody>
                  <Tr>
                    <Td>Name</Td>
                    <Td>{name}</Td>
                  </Tr>
                  <Tr>
                    <Td>Description</Td>
                    <Td>{description}</Td>
                  </Tr>
                  <Tr>
                    <Td>Status</Td>
                    <Td>{status ? 'Active' : 'Inactive'}</Td>
                  </Tr>
                  <Tr>
                    <Td>Home Module</Td>
                    <Td>
                      {Array.isArray(home) ? (
                        home.map((ele) => <li >{ele}</li>)
                      ) : (
                        "No home data available"
                      )}
                    </Td>
                  </Tr>
                 
                </Tbody>
              </Table>
            </TableContainer>}
        </CardBody>
      </Card>
      <br />
      <br />
      <Flex direction="row" gap="15px" flexWrap="wrap">
        {guardmod.map((ele, index) => (
          <Wrap spacing={4} justify="center" align="center" key={ele._id}>
            <Box>
              <Card mb={4} width={{ base: '100%', md: '100%' }}>
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                  Guard Module
                  <hr />
                </Text>

                <ul style={{ listStyleType: "none" }}>
                  <li>
                    <img src={ele.image} alt={ele.name} style={{ width: '60%', height: 'auto' }} />
                  </li>
                  <br />
                  <li style={{ fontWeight: 'bold', color: 'black', fontSize: '1em' }}>
                    Name: {ele.name}
                  </li>
                  <hr />
                  <li style={{ fontWeight: 'bold', color: 'black', fontSize: '1em' }}>
                    Description: {ele.description}
                  </li>
                </ul>
              </Card>
            </Box>
          </Wrap>
        ))}
      </Flex>




    </Flex>
  );
}
export default CheckpostViewForm;
