import React, { useEffect, useState ,useRef} from 'react';
import { Flex,Button, InputGroup, Spacer, Table, Tbody, Text, Td, Th, Thead, Tr, useColorModeValue, InputLeftElement, IconButton, Input, Icon, Link } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { useToast } from '@chakra-ui/react';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
// import ScheduleHighAlertPagination from './ScheduleHighAlertPagination'; // Import your HighAlertPagination component
import ScheduleHighAlertPagination from './ScheduleHighAlertPagination'
import { AddIcon } from '@chakra-ui/icons';
import { SCHEDULE_HIGH_ALERT_CLEAR_SNACKBAR } from 'constants/types'; // Import HIGH_ALERT_CLEAR_SNACKBAR
import { schedulehighAlertGetAll } from '../../actions/schedulehighalertAction'; // Import your highAlertGetAll action
import ScheduleHighAlertTableRow from './ScheduleHighAlertTableRow'; // Import your HighAlertTableRow component

import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom';
import { DownloadTableExcel } from 'react-export-table-to-excel';
const ScheduleHighAlertTable = (props) => {
  const { url = "", plant = " " } = props;
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const responseStatus = useSelector(state => state.SchedulehighAlert.status);
  const responseMessage = useSelector(state => state.SchedulehighAlert.text);
  const loading = useSelector(state => state.SchedulehighAlert.loading);
  const schedulehighAlertList = useSelector(state => state.SchedulehighAlert.schedulehighAlertList);
  const response = useSelector(state => state.SchedulehighAlert.response);
  const showSnackbar = useSelector(state => state.SchedulehighAlert.showSnackbar);
  const dispatch = useDispatch();
  const toast = useToast();
  const searchIconColor = useColorModeValue("gray.700", "gray.200");
  const inputBg = useColorModeValue("#edf3f8", "navy.800");
  const [search, setSearch] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const tableRef = useRef(null);
  const history = useHistory();

  useEffect(() => {
    dispatch(schedulehighAlertGetAll("&plant=" + plant));
  }, [dispatch]);

  console.log("highAlertList22222")
console.log(schedulehighAlertList); 
 useEffect(() => {
    dispatch(schedulehighAlertGetAll());
  }, [dispatch]);

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: SCHEDULE_HIGH_ALERT_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("desc");
    }
    const sorting = "&sortOrder=" + sortOrder + "&column=" + column;
    dispatch(schedulehighAlertGetAll(sorting));
  };

  useEffect(() => {
    const searching = "&search=" + search + "&plant=" + plant;
    dispatch(schedulehighAlertGetAll(searching));
  }, [search]);
  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])
  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
      <Flex>
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
           Schedule High Alert Table
            </Text>
          </CardHeader>
          <Spacer />
          <Flex justify="flex-end" style={{ padding: '10px', marginTop: '-5px', marginRight: '12px' }}>
            {url == "" ? <></> : <Link href={url}>
              <IconButton
                colorScheme="blue"
                aria-label="Search database"
                icon={<AddIcon />}
              />
            </Link>}
          </Flex>
          <Flex justify="flex-end" style={{ padding: '10px', marginTop: '-5px', marginRight: '12px' }}>
            <DownloadTableExcel
              filename="High Alert Table"
              sheet="users"
              currentTableRef={tableRef.current}
            >
              <Button colorScheme="blue" _hover={{ backgroundColor: 'black', color: 'white' }}>
                Export Excel
              </Button>
            </DownloadTableExcel>
          </Flex>
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              <InputGroup borderRadius="8px" w="200px">
                <InputLeftElement
                  children={
                    <IconButton
                      bg="inherit"
                      borderRadius="inherit"
                      _hover="none"
                      _active={{
                        bg: "inherit",
                        transform: "none",
                        borderColor: "transparent",
                      }}
                      _focus={{
                        boxShadow: "none",
                      }}
                      icon={
                        <SearchIcon
                          color={searchIconColor}
                          w="15px"
                          h="15px"
                        />
                      }
                    ></IconButton>
                  }
                />
                <Input
                  variant="search"
                  fontSize="xs"
                  bg={inputBg}
                  onKeyUp={(e) => {
                    setSearch(e.target.value);
                    if (e.target.value.length < 3) {
                      setSearch("");
                    }
                  }}
                  placeholder="Type here..."
                />
              </InputGroup>
            </Text>
          </CardHeader>
        </Flex>
        <CardBody pb={4}>
        <div style={{ overflowX: "auto" }}>
       
          <Table variant="simple" color={textColor} ref={tableRef}>
            <Thead>
            <Tr my=".8rem" pl="0px" color="gray.400">
            <Th
                    pl="15px"
                    borderColor={borderColor}
                    color="gray.400"
                    cursor="pointer"
                  >
                   SNO
                  </Th>
                <Th
                  pl="0px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("name")}
                  cursor="pointer"
                >
                 checkpost
                  {sortColumn === "name" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("photo")}
                  cursor="pointer"
                >
                  
                  Start Date & Time
                  {sortColumn === "photo" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("number")}
                  cursor="pointer"
                >
                  End Date & Time
                  {sortColumn === "number" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("number")}
                  cursor="pointer"
                >
                  Popup Time
                  {sortColumn === "number" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("number")}
                  cursor="pointer"
                >
                  Interval
                  {sortColumn === "number" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                
               
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("guardId")}
                  cursor="pointer"
                >
                  Status
                  {sortColumn === "guardId" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("number")}
                  cursor="pointer"
                >
                  Plant
                  {sortColumn === "number" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {schedulehighAlertList ? (
                schedulehighAlertList.map((row, index, arr) => (
                  <ScheduleHighAlertTableRow
                   serialnumber={response.currentPage - 1 + index + 1}
                   checkpost = {row.checkpost}
                   startDate = {row.startDate}
                   endDate = {row.endDate}
                   popupDuration = {row.popupDuration}
                   duration = {row.duration}
                   status = {row.status}
                   plantAdminName = {row.plantAdminName}
                   id = {row._id}
                   isLast={index === arr.length - 1 ? true : false}
                  />
                ))
              ) : (
                <h1></h1>
              )}
            </Tbody>
          </Table>
          </div>
          {schedulehighAlertList ? (schedulehighAlertList.length === 0 ? <></> : (
            <ScheduleHighAlertPagination
            plant = {plant}
            limit = {response.limit}
              totalPages={response.totalPages}
              currentPage={response.currentPage}
            />
          )) : (<></>)}
        </CardBody>
      </Card>
    </Flex>
  );
};

export default ScheduleHighAlertTable;
