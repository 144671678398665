// Chakra imports
import {
    Flex,
    Box,
    Text,
    Table,
    Thead,
    Tbody,
    Tr,
    Td,
    WrapItem,
    TableContainer,
    Image,
    useColorModeValue,
    Wrap,
    HStack,
  } from "@chakra-ui/react";
  // Custom components
  import Card from "components/Card/Card.js";
  import CardBody from "components/Card/CardBody.js";
  import CardHeader from "components/Card/CardHeader.js";
  import React, { useEffect, useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
//   import { raisedGatePassClearShowSnackbar , raisedGatePassAddOrUpdate} from '../../actions/raisedGatePassAction';
  import { raisedGatePassAddOrUpdate , raisedGatePassClearShowSnackbar} from "../../actions/raisedGatePassAction";
  import { useToast } from '@chakra-ui/react'
  import { useParams } from "react-router-dom/cjs/react-router-dom.min";

  import {
    Skeleton,
    SkeletonCircle,
    SkeletonText,
  } from "@chakra-ui/react";
  import { RAISED_GATE_PASS_CLEAR_SNACKBAR } from "constants/types";
  // import { materialGatePassClearShowSnackbar } from "actions/materialGatePassAction";
  import axios from 'axios';
  import { server_url } from "constants/types";
  import { useHistory } from 'react-router-dom';
  function RaisedGatePassView() {
    const textColor = useColorModeValue("gray.700", "white");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [status, setStatus] = useState("");
    const [mode, setMode] = useState("");
    const [materialGatePass, setMaterialBy] = useState("");
    const [mobile, setMobileNumber] = useState("");
    const [vehicle, setVehicleNumber] = useState("");
    const [shipment, setMaterialShipmentType] = useState("");
    const [returnDate, setReturnDate] = useState("");
    const [guardName, setSubName] = useState("");
    // const [guardStatus, setGuardStatus] = useState("");
    const [guardCreated, setCreatedAt] = useState("");
    const [guardUpdate, setUpdatedAt] = useState("");
    const [deptName, setSubDeptName] = useState("");
    // const [newCreated,setCreatedAt ] = useState("");
    // const [newUpdate,setUpdatedAt ] = useState("");
    const [matStatus, setMaterialStatus] = useState("");
    const [matType, setMaterialType] = useState("");
    const [matWeigh, setMaterialWeigh] = useState("");
    const [txn, setMaterialTxn] = useState("");
    const [newName, setGuardName] = useState("");
    const [newNumber, setGuardNumber] = useState("");
    const [newMail, setGuardEmail] = useState("");
    const [newDevice, setDevice] = useState("");
    const [pinCode, setPostal] = useState("");
    const [local, setLocality] = useState("");
    const [state, setState] = useState("");
    const [guardId, setGuardId] = useState("");
    const [pass, setPasCode] = useState("");
    const [photo, setPhoto] = useState("");
    const [empName, setEmpName] = useState("");
    const [empPhone, setEmpPhone] = useState("");
    const [empAltPhone, setEmpAltPhone] = useState("");
    const [empMail, setEmpMail] = useState("");
    const [empAddress, setEmpAddress] = useState("");
    const [empCreatedAt, setEmpCreatedAt] = useState("");
    const [empUpdatedAt, setEmpUpdatedAt] = useState("");
    const [empBloodGroup, setEmpBloodGroup] = useState("");
    const [helper, setHelper] = useState("");
    const [helperNumber, setHelperNumber] = useState("");
    const [driverPhoto, setDriverPhoto] = useState("");
    const [vendor, setVendor] = useState("");
    const [waiting, setWaiting] = useState("");
    const [gatePass, setGatePass] = useState("");
    const [gatePassPhoto, setGatePassPhoto] = useState("");
    const [empVehNum, setEmpVehNum] = useState("");
    const [empVehType, setEmpVehType] = useState("");
    const [empLicNum, setEmpLicNum] = useState("");
    const [purpose, setPurpose] = useState("");
    const [weighment, setWeighment] = useState("");
    // const [set,setCreatedAt ] = useState("");
    // const [,setUpdatedAt ] = useState("");
  
    const [raiseemployeeName ,setRaisedName ] = useState("")
    const [raiseemployeeNumber ,setRaisedNum ] = useState("")
    const [raisematerialDescription , setRaisedDesc] = useState("")
    const [raisematerialType ,setRaisedType ] = useState("")
    const [raiseoum ,setRaisedOum ] = useState("")
    const [raisephotos ,setRaisedImg ] = useState("")
    const [ raisequantity, setRaisedQty] = useState("")
    const [raisevehicleNumber ,setRaisedVeh ] = useState("")
    const [raisevendor , setRaisedVendor] = useState("")
    const[gatepassNumber ,setEmpGatePassNum ] = useState("")
  const[gatepassPhoto , setEmpGatePassPhoto] = useState("")
  const[gatepassStatus , setEmpGatePassStatus] = useState("")
  const[guardStatus , setEmpStatus] = useState("")
  const[passType , setEmpPassType] = useState("")
  const[reasonOfRejection , setReject] = useState("")
  
  
    const history = useHistory();
    const dispatch = useDispatch();
    const toast = useToast();
  
    const statuses = ['success', 'error', 'warning', 'info'];
    const responseStatus = useSelector(state => state.raisedGatePass.status);
    const responseMessage = useSelector(state => state.raisedGatePass.text);
    const loading = useSelector(state => state.raisedGatePass.loading);
    // const designationDetail = useSelector(state => state.materialGatePass.designationDetail);
    const raisedGatePassDetail = useSelector((state) => state.raisedGatePass.raisedGatePassDetail);
    const showSnackbar = useSelector(state => state.raisedGatePass.showSnackbar);
    const logs = useSelector(state => state.raisedGatePass.logs);
    // const  = useSelector(state => state.materialGatePass.materialId.logs);
    const materialGatePassData = useSelector(state => state.raisedGatePass.raised);
  
    const params = useParams();
    //  console.log("shasjgsadgjdagjadsjg-------");
    // console.log(materialGatePassDetail.logs)
    // console.log("materialGatePassDetail--materialGatePassDetail")
    // console.log(materialGatePass);
    console.log("logs-------------=-=-=");
    console.log(logs);
    // console.log(logs2);
    // console.log("mat0=-=-=-=-==-");
    // console.log(materialGatePassData);
    console.log("jkhagdkfugaekufdyawodhasufdfaejxcz?uj,lzcxv");
    console.log(raisedGatePassDetail);
  
  
    useEffect(() => {
      if (showSnackbar) {
        if (responseStatus === 'OK' && loading === false) {
          console.log('logs');
          console.log(logs);
          console.log('logs');
          setMode(raisedGatePassDetail.mode || "");
          //  setMaterialBy(materialGatePassDetail.materialId.materialBy || "");
          //  setMobileNumber(materialGatePassDetail.materialId.mobileNumber || "");
          //  setVehicleNumber(materialGatePassDetail.materialId.vehicleNumber || "");
          //  setMaterialShipmentType(materialGatePassDetail.materialId.type || "");
          // setReturnDate(materialGatePassDetail.returnDate)
          // setSubName(materialGatePassDetail.subdepartment.department.name)
          // setStatus(materialGatePassDetail.department.status)
          // setCreatedAt(materialGatePassDetail.materialId.createdAt)
          // setUpdatedAt(materialGatePassDetail.materialId.updatedAt)
          // setSubDeptName(materialGatePassDetail.subdepartment.name)
          // setCreatedAt(materialGatePassDetail.subdepartment.createdAt)
          // setUpdatedAt(materialGatePassDetail.subdepartment.updatedAt)
          // Material
          setMaterialStatus(raisedGatePassDetail.status)
          // setMaterialType(materialGatePassDetail.type)
          // setMaterialWeigh(materialGatePassDetail.weighment)
          setMaterialTxn(raisedGatePassDetail.txnNumber)
          // Guard
          // setGuardName(materialGatePassDetail.guard.name)
          // setGuardNumber(materialGatePassDetail.guard.number)
          // setGuardEmail(materialGatePassDetail.guard.email)
          // setDevice(materialGatePassDetail.guard.guardDeviceNo)
          // setPostal(materialGatePassDetail.Address.postalCode)
          // setLocality(materialGatePassDetail.guard.Address[0].locality)
          // setState(materialGatePassDetail.guard.Address[0].state)
          // setGuardId(materialGatePassDetail.guard.guardId)
          // setPasCode(materialGatePassDetail.guard.passcode)
          // setPhoto(materialGatePassDetail.guard.photo)
          // setCreatedAt(materialGatePassDetail.guard.createdAt)
          // setUpdatedAt(materialGatePassDetail.guard.updatedAt)
          // driver
          // setHelper(materialGatePassDetail.materialId.otherDetails.HelperName)
          // setHelperNumber(materialGatePassDetail.materialId.otherDetails.HelperMobileNo)
          // setDriverPhoto(materialGatePassDetail.materialId.otherDetails.driverPhoto)
          // setVendor(materialGatePassDetail.materialId.vendor)
          // setWaiting(materialGatePassDetail.materialId.waitingTime)
          // setGatePass(materialGatePassDetail.materialId.gatepassNumber)
          // setGatePassPhoto(materialGatePassDetail.materialId.gatepassPhoto)
          setEmpVehNum(raisedGatePassDetail.employee.vehicleDetails.vehicleNumber)
          setEmpVehType(raisedGatePassDetail.employee.vehicleDetails.vehicleType)
          setEmpLicNum(raisedGatePassDetail.employee.vehicleDetails.licenseNumber)
          // employee
          setEmpName(raisedGatePassDetail.employee.employeeName)
          setEmpPhone(raisedGatePassDetail.employee.phone)
          setEmpAltPhone(raisedGatePassDetail.employee.alternateNumber)
          setEmpMail(raisedGatePassDetail.employee.officialEmail)
          setEmpAddress(raisedGatePassDetail.employee.employeeAddress)
          setEmpCreatedAt(raisedGatePassDetail.employee.createdAt)
          setEmpUpdatedAt(raisedGatePassDetail.employee.updatedAt)
          setEmpBloodGroup(raisedGatePassDetail.employee.bloodGroup)
  
  
  
          setEmpGatePassNum(raisedGatePassDetail.gatepassNumber)
          setEmpGatePassPhoto(raisedGatePassDetail.gatepassPhoto)
          setEmpGatePassStatus(raisedGatePassDetail.gatepassStatus)
          setEmpStatus(raisedGatePassDetail.guardStatus)
  
          setEmpPassType(raisedGatePassDetail.passType)
  
          setRaisedName(raisedGatePassDetail.raisedMaterial.employeeName)
          setRaisedNum(raisedGatePassDetail.raisedMaterial.employeeNumber)
          setRaisedDesc(raisedGatePassDetail.raisedMaterial.materialDescription)
          setRaisedType(raisedGatePassDetail.raisedMaterial.materialType)
          setRaisedOum(raisedGatePassDetail.raisedMaterial.oum)
          setRaisedImg(raisedGatePassDetail.raisedMaterial.photos)
          setRaisedQty(raisedGatePassDetail.raisedMaterial.quantity)
          setRaisedVeh(raisedGatePassDetail.raisedMaterial.vehicleNumber)
          setRaisedVendor(raisedGatePassDetail.raisedMaterial.vendor)
  
  
          setReject(raisedGatePassDetail.reasonOfRejection)
  
  
  
  
          // setPurpose(materialGatePassDetail.materialId.purpose)
          // setWeighment(materialGatePassDetail.materialId.weighment)
  
  
  
  
  
          setStatus(raisedGatePassDetail.status ? 'Active' : 'inActive');
          if (responseMessage !== "") {
            toastFunction(responseMessage, 'success');
          }
        } else if (responseStatus === 'NOK' && loading === false) {
          if (responseMessage !== "") {
            toastFunction(responseMessage, 'error');
          }
        }
        dispatch({
          type: RAISED_GATE_PASS_CLEAR_SNACKBAR,
          payload: {
            showSnackbar: false,
          }
        });
      }
    }, [loading]);
  
    useEffect(() => {
      const raisedGatePassId = params.id;
      const raisedGatePass = {
        _id: raisedGatePassId,
      };
      dispatch(raisedGatePassAddOrUpdate(raisedGatePass, 'view'));
    }, []);
  
    useEffect(() => {
      axios
        .get(`${server_url}/admin/detail`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then((responce) => {
          console.log("uadlfdafljadhfoadilf");
        })
        .catch((e) => {
          history.push('/auth/signin');
  
        });
    }, [])
  
    const toastFunction = (title, status) => {
      toast({
        title: title,
        status: status,
        duration: 3000,
        isClosable: true,
      });
    };
  
  
    return (
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px" mb={4} >
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Material Gate Pass View
            </Text>
          </CardHeader>
          <CardBody>
            {loading ? (
              <Skeleton height="20px" />
            ) : (
              <TableContainer>
                <Table variant="simple" >
                  <Tbody mb={4}>
                    {/* <Tr>
                      <Td>Name</Td>
                      <Td>{name}</Td>
                    </Tr> */}
                    {/* <Tr>
                        <Td>Description</Td>
                        <Td>{description}</Td>
                      </Tr> */}
                    {/* <Tr>
                        <Td>Status</Td>
                        <Td>{status}</Td>
                      </Tr> */}
                    
                    {/* <Tr>
                      <Td>Material Gate Pass</Td>
                      <Td>{materialGatePass}</Td>
                    </Tr> */}
                    {/* <Tr>
                      <Td>Mobile</Td>
                      <Td>{mobile}</Td>
                    </Tr>
                    <Tr>
                      <Td>Vehicle</Td>
                      <Td>{vehicle}</Td>
                    </Tr>
                    <Tr>
                      <Td>Shipment</Td>
                      <Td>{shipment}</Td>
                    </Tr> */}
                    <Tr>
                      <Td>Gate Pass Number</Td>
                      <Td>{gatepassNumber}</Td>
                    </Tr>
                    <Tr>
                      <Td>Gate Pass Image</Td>
                      <Image src={gatepassPhoto}></Image>
                    </Tr>
                    <Tr>
                      <Td>Gate Pass Status</Td>
                      <Td>{gatepassStatus}</Td>
                    </Tr>
                    <Tr>
                      <Td>Guard Status</Td>
                      <Td>{guardStatus}</Td>
                    </Tr>
                    <Tr>
                      <Td>Employee Pass Type</Td>
                      <Td>{passType}</Td>
                    </Tr>
                    {/* <Tr>
                        <Td>returnDate</Td>
                        <Td>{returnDate}</Td>
                      </Tr> */}
                    {/* <Tr>
                        <Td>guardName</Td>
                        <Td>{guardName}</Td>
                      </Tr>
                      <Tr>
                        <Td>guardStatus</Td>
                        <Td>{guardStatus}</Td>
                      </Tr> */}
                    {/* <Tr>
                        <Td>Guard Created</Td>
                        <Td>{guardCreated}</Td>
                      </Tr>
                      <Tr>
                        <Td>Guard Update</Td>
                        <Td>{guardUpdate}</Td>
                      </Tr> */}
                    {/* <Tr>
                        <Td>deptName</Td>
                        <Td>{deptName}</Td>
                      </Tr> */}
                      <Tr>
                      <Td>Mode</Td>
                      <Td>{mode}</Td>
                    </Tr>
                    <Tr>
                      <Td>Material Status</Td>
                      <Td>{matStatus}</Td>
                    </Tr>
                    {/* <Tr>
                        <Td>matType</Td>
                        <Td>{matType}</Td>
                      </Tr>
                      <Tr>
                        <Td>matWeigh</Td>
                        <Td>{matWeigh}</Td>
                      </Tr> */}
                    <Tr>
                      <Td>Txn</Td>
                      <Td>{txn}</Td>
                    </Tr>
                    <Tr>
                      <Td>Guard Name</Td>
                      <Td>{newName}</Td>
                    </Tr>
                    {/* <Tr>
                      <Td>New Number</Td>
                      <Td>{newNumber}</Td>
                    </Tr>
                    <Tr>
                      <Td>New Mail</Td>
                      <Td>{newMail}</Td>
                    </Tr>
                    <Tr>
                      <Td>New Device</Td>
                      <Td>{newDevice}</Td>
                    </Tr> */}
                    {/* <Tr>
                        <Td>Postal</Td>
                        <Td>{pinCode}</Td>
                      </Tr> */}
                    {/* <Tr>
                      <Td>Locality</Td>
                      <Td>{local}</Td>
                    </Tr>
                    <Tr>
                      <Td>State</Td>
                      <Td>{state}</Td>
                    </Tr>
                    <Tr>
                      <Td>Guard Id</Td>
                      <Td>{guardId}</Td>
                    </Tr>
                    <Tr>
                      <Td>Entry Code</Td>
                      <Td>{pass}</Td>
                    </Tr>
                    <Tr>
                      <Td>Purpose</Td>
                      <Td>{purpose}</Td>
                    </Tr>
                    <Tr>
                      <Td>Weighment</Td>
                      <Td>{weighment}</Td>
                    </Tr> */}
  
                    <Tr>
                      <Td>Employee Name</Td>
                      <Td>{empName}</Td>
                    </Tr>
                    <Tr>
                      <Td>Employee Phone</Td>
                      <Td>{empPhone}</Td>
                    </Tr>
                    <Tr>
                      <Td>Employee Alternate Number</Td>
                      <Td>{empAltPhone}</Td>
                    </Tr>
                    <Tr>
                      <Td>Employee Email</Td>
                      <Td>{empMail}</Td>
                    </Tr>
                    <Tr>
                      <Td>Employee Address</Td>
                      <Td>{empAddress}</Td>
                    </Tr>
                    {/* <Tr>
                        <Td>Employee Created At</Td>
                        <Td>{empCreatedAt}</Td>
                      </Tr>
                      <Tr>
                        <Td>Employee Updated At</Td>
                        <Td>{empUpdatedAt}</Td>
                      </Tr> */}
                    <Tr>
                      <Td>Blood Group</Td>
                      <Td>{empBloodGroup}</Td>
                    </Tr>
                    
                    {/* <Tr>
                      <Td>Gate Pass</Td>
                      <Td>{gatePass}</Td>
                    </Tr>
                    <Tr>
                      <Td>Gate Pass Image</Td>
                      <Image src={gatePassPhoto} style={{ maxWidth: '30px',  maxHeight : '30px' }}>
                      
                      </Image>
                    </Tr> */}
                    <Tr>
                      <Td>Employee Vehicle Number</Td>
                      <Td>{empVehNum}</Td>
                    </Tr>
                    <Tr>
                      <Td>Employee Vehicle Type</Td>
                      <Td>{empVehType}</Td>
                    </Tr>
                    
  
                    <Tr>
                      <Td>Reason For Rejection</Td>
                      <Td>{reasonOfRejection}</Td>
                    </Tr>
  
                    <Tr>
                      <Td>Image</Td>
                      <Image src={photo} style={{ maxWidth: '120px' }}></Image>
                    </Tr>
  
                    <Tr>
                      <Td>Logs</Td>
                      <Td>{logs.map((log) => {
                        return (<ul>
                          <li>{log.description} at {log.dateTime} on {log.formattedDateTime} </li>
                        </ul>)
                      })}</Td>
                    </Tr>
  
  
  
                  </Tbody>
                </Table>
  
              </TableContainer>
            )}
          </CardBody>
  
        </Card>
        <Flex direction='row' gap='15px' >
          {materialGatePassData.map((hello) => {
            return (
  
              <Wrap spacing={4} justify="center" align="center" >
  
                <Box >
                  <Card mb={4}>
                    <Text fontSize="xl" color={textColor} fontWeight="bold">Material</Text>
                    <ul style={{ listStyleType: 'none' }}>
                      <li>
                        <img src={hello.invoice} alt="" srcset="" />
                      </li>
                      <br />
                      <li >
                        Invoice Number = {hello.invoiceNumber}
                      </li>
                      <li >
                        Vendor = {hello.vendor}
                      </li>
                      <li>
                        MaterialDescription = {hello.materialDescription}
                      </li>
                      <li>
                        Oum = {hello.oum}
                      </li>
                      <li>
                        Quantity = {hello.quantity}
                      </li>
                    </ul>
                  </Card>
                </Box>
  
              </Wrap >
            )
          })}
        </Flex>
        <Flex direction='row' gap='15px' >
              <Wrap spacing={4} justify="center" align="center" >
  
                <Box >
                  <Card mb={4}>
                    <Text fontSize="xl" color={textColor} fontWeight="bold">Raised Material Details</Text>
                    <Table variant="simple" >
                  <Tbody mb={4}>
                    <Tr>
                      <Td>Employee Name</Td>
                      <Td>{raiseemployeeName}</Td>
                    </Tr>
                    <Tr>
                      <Td>Employee Number</Td>
                      <Td>{raiseemployeeNumber}</Td>
                    </Tr>
                    <Tr>
                      <Td>Employee Description</Td>
                      <Td>{raisematerialDescription}</Td>
                    </Tr>
                    <Tr>
                      <Td>Employee Material Type</Td>
                      <Td>{raisematerialType}</Td>
                    </Tr>
                    <Tr>
                      <Td>OUM</Td>
                      <Td>{raiseoum}</Td>
                    </Tr>
                    <Tr>
                      <Td>Image</Td>
                      <Image src ={raisephotos}></Image>
                    </Tr>
                    <Tr>
                      <Td>Quantity</Td>
                      <Td>{raisequantity}</Td>
                    </Tr>
                    <Tr>
                      <Td>Vehicle Number</Td>
                      <Td>{raisevehicleNumber}</Td>
                    </Tr>
                    <Tr>
                      <Td>Vendor</Td>
                      <Td>{raisevendor}</Td>
                    </Tr>
  
  
  
                  </Tbody>
  
                  </Table>
                  </Card>
                </Box>
  
              </Wrap >
           
        </Flex>
      </Flex>
    );
  }
  export default RaisedGatePassView