// Chakra imports
import {
    Box,
    Button,
    Center,
    Flex,
    Input,
    SimpleGrid,
    Spacer,
    Text,
    Wrap,
    WrapItem,
    useColorModeValue,
    Select,
  } from "@chakra-ui/react";
  // Custom components
  import Card from "components/Card/Card.js";
  import CardBody from "components/Card/CardBody.js";
  import CardHeader from "components/Card/CardHeader.js";
  import React, { useEffect, useState } from "react";
  import { useDispatch, useSelector } from 'react-redux';

  import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
  } from '@chakra-ui/react'
  import { useToast } from '@chakra-ui/react'
  import { CALL_SETTING_CLEAR_SNACKBAR } from "constants/types";
  import { Spinner } from '@chakra-ui/react'
  import { useParams } from "react-router-dom/cjs/react-router-dom.min";
  import dataConstant from "constants/dataConstant";
  import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { server_url } from '../../constants/types';
import { callSettingAddOrUpdate } from "actions/callSettingAction";
  
  function CallSettingUpdateForm() {
    const textColor = useColorModeValue("gray.700", "white");
    const borderColor = useColorModeValue("gray.200", "gray.600");

    const [name, setName] = useState("");
    const [primaryNumber, setPrimaryNumber] = useState("");
    const [secondaryNumber, setSecondaryNumber] = useState('');
    const [status, setStatus] = useState("");

    const [isErrorName, setIsErrorName] = useState(false);
   const [isErrorPrimaryNumber , setIsErrorPrimaryNumber] = useState(false)
   const [isErrorSecondaryNumber , setIsErrorSecondaryNumber] = useState(false)
    const [isErrorStatus, setIsErrorStatus] = useState(false);

  
    const dispatch = useDispatch();
    const toast = useToast();
  
    const statuses = ['success', 'error', 'warning', 'info'];
    const responseStatus = useSelector(state => state.callSetting.status);
    const responseMessage = useSelector(state => state.callSetting.text);
    const loading = useSelector(state => state.callSetting.loading);
    const showSnackbar = useSelector(state => state.callSetting.showSnackbar);
    const highDetail = useSelector(state => state.callSetting.highDetail);

    const params = useParams();
    const highId = params.id;
    const history = useHistory()
   


    // Function to handle form submission
    const handleSubmit = (event) => {
      event.preventDefault();
      setIsErrorName(name === '');
      setIsErrorPrimaryNumber(primaryNumber === '');
      setIsErrorSecondaryNumber(secondaryNumber === '');
      setIsErrorStatus(status === '');
  
      if (name !== "" &&  primaryNumber !== "" && secondaryNumber !== ""  && status !== "") {
        console.log("Submit");
        // Get form data
        const highData = {
          _id: highId, // Assuming your city model has an id field
          name: name,
          primaryNumber: primaryNumber,
          secondaryNumber: secondaryNumber,
          status: status,

        };
        console.log(highData);
  
        // Call the cityUpdate action
        dispatch(callSettingAddOrUpdate(highData , "edit"));
      }
    };

    useEffect(() => {
    const highData = {
     "_id" : params.id
    }
      dispatch(callSettingAddOrUpdate(highData, "view"));
    }, [params.id]);
    
  
    useEffect(() => {
      if (showSnackbar) {
        if (responseStatus === 'OK' && loading === false) {
          setName(highDetail.name);
          setPrimaryNumber(highDetail.primaryNumber);
          setSecondaryNumber(highDetail.secondaryNumber);
          setStatus(highDetail.status);
          if (responseMessage !== "") {
            toastFunction(responseMessage, 'success');
          }
        } else if (responseStatus === 'NOK' && loading === false) {
          if (responseMessage !== "") {
            toastFunction(responseMessage, 'error');
          }
        }
      }
  
      dispatch({
        type: CALL_SETTING_CLEAR_SNACKBAR,
        payload: {
          showSnackbar: false,
        }
      })
    }, [loading]);
  
    const toastFunction = (title, status) => {
      toast({
        title: title,
        status: status,
        duration: 3000,
        isClosable: true,
      })
    };

    useEffect(() => {
      axios
        .get(`${server_url}/admin/detail`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then((responce) => {
          console.log("uadlfdafljadhfoadilf");
        })
        .catch((e) => {
          history.push('/auth/signin');
  
        });
    }, [])
  
    return (
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
            High Alert Call Setting Update Form
            </Text>
            <br/>
            <SimpleGrid minChildWidth='120px' spacing='40px'>
              <FormControl isInvalid={isErrorName}>
                <FormLabel>Name</FormLabel>
                <Input
                  type='text'
                  value={name}
                  onChange={(e) => {
                    setIsErrorName(e.target.value === '')
                    setName(e.target.value)
                  }}
                />
                {!isErrorName ? (
                  <></>
                ) : (
                  <FormErrorMessage>Name is required.</FormErrorMessage>
                )}
              </FormControl>
              
              <FormControl isInvalid={isErrorStatus}>
                <FormLabel>Status</FormLabel>
                <Select
                  placeholder="Select status"
                  value={status}
                  onChange={(e) => {
                    setIsErrorStatus(e.target.value === '')
                    setStatus(e.target.value)
                  }}
                >
                  { dataConstant.status.map((element,index) => {
                      return (<option key={element['id']} value={element['value']} >{element['name']}</option>);   
                        
                    })}
                </Select>
                {!isErrorStatus ? (
                  <></>
                ) : (
                  <FormErrorMessage>Status is required.</FormErrorMessage>
                )}
              </FormControl>
            </SimpleGrid>


            <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
             
              <FormControl isInvalid={isErrorPrimaryNumber}>
                <FormLabel>Primary Number</FormLabel>
                <Input
                  type='text'
                  value={primaryNumber}
                  onChange={(e) => {
                    setIsErrorPrimaryNumber(e.target.value === '')
                    setPrimaryNumber(e.target.value)
                  }}
                />
                {!isErrorPrimaryNumber ? (
                  <></>
                ) : (
                  <FormErrorMessage>Prime Number is required.</FormErrorMessage>
                )}
              </FormControl>

              <FormControl isInvalid={isErrorSecondaryNumber}>
                <FormLabel>Secondary Number</FormLabel>
                <Input
                  type='text'
                  value={secondaryNumber}
                  onChange={(e) => {
                    setIsErrorSecondaryNumber(e.target.value === '')
                    setSecondaryNumber(e.target.value)
                  }}
                />
                {!isErrorSecondaryNumber ? (
                  <></>
                ) : (
                  <FormErrorMessage>Secondary Number is required.</FormErrorMessage>
                )}
              </FormControl>

            </SimpleGrid>
            <Center>
              {loading ? <Spinner mt='24px' size='lg' /> :
                <Button colorScheme='blue' mt='24px' onClick={handleSubmit}>
                  Submit
                </Button>
              }
            </Center>
          </CardHeader>
          <CardBody>
          </CardBody>
        </Card>
      </Flex>
    );
  }
  
  export default CallSettingUpdateForm;
  