// Import the action type constants for material
import {
  MATERIAL_CLEAR_SNACKBAR,
  MATERIAL_LOADING,
  MATERIAL_ERROR,
  MATERIAL_GET_ALL_SUCCESS,
  MATERIAL_SEARCH  // Add the new action type for search if needed
} from '../constants/types';

const initialState = {
  response: {},
  materialList: [],
  materialListIN: [],
  materialDetail: {},
  logs: [],
  material: [],
  loading: false,
  status: 'success',
  text: '',
  showSnackbar: false,
  limit: 0,  // Add missing properties
  page: 0,
  totalPages: 0,
  searching: ""  // Add missing properties
};

const materialReducer = (state = initialState, action) => {
  switch (action.type) {
    case MATERIAL_CLEAR_SNACKBAR:
      return {
        ...state,
        showSnackbar: false,
      };
    case MATERIAL_LOADING:
      return {
        ...state,
        loading: true,
      };
    case MATERIAL_ERROR:
      return {
        ...state,
        materialList: [],
        materialListIN: [],
        response: {},
        materialDetail: {},
        logs: [],
        material: [],
        limit: 0,  // Add missing properties
        page: 0,
        totalPages: 0,
        loading: false,
        status: action.payload.status,
        text: action.payload.text,
        showSnackbar: true,
      };
    case MATERIAL_GET_ALL_SUCCESS:
      return {
        ...state,
        materialList: action.payload.materialList,
        materialListIN: action.payload.materialListIN,
        response: action.payload.response,
        materialDetail: action.payload.materialDetail,
        logs: action.payload.logs,
        material: action.payload.material,
        limit: action.payload.limit,
        page: action.payload.page,
        totalPages: action.payload.totalPages,
        loading: false,
        status: action.payload.status,
        text: action.payload.text,
        showSnackbar: true,
      };
    case MATERIAL_SEARCH: // Add the new case
      return {
        ...state,
        searching: action.payload.searching
      };
    default:
      return state;
  }
};

export default materialReducer;
