// Chakra imports
import {
  Box,
  Button,
  Center,
  Flex,
  Image,
  Input,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  Wrap,
  WrapItem,
  useColorModeValue
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { bannerPositionAddOrUpdate, bannerPositionClearShowSnackbar } from "actions/bannerpositionAction";
import { useToast } from '@chakra-ui/react'
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { BANNER_POSITION_CLEAR_SNACKBAR } from "constants/types";
import axios from 'axios';
import { server_url } from '../../constants/types';
import { useHistory } from 'react-router-dom';

import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react'
import { Skeleton, SkeletonCircle, SkeletonText } from '@chakra-ui/react'

function BannerPositionView() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [status, setStatus] = useState("");
  const [position, setPosition] = useState("");
  const [bannerType, setbannerType] = useState("");
  const [city, setCity] = useState("");

  const dispatch = useDispatch();
  const toast = useToast();

  const statuses = ['success', 'error', 'warning', 'info'];
  const responseStatus = useSelector(state => state.bannerposition.status);
  const responseMessage = useSelector(state => state.bannerposition.text);
  const loading = useSelector(state => state.bannerposition.loading);
  const bannerPositionDetail = useSelector(state => state.bannerposition.bannerPositionDetail);
  const showSnackbar = useSelector(state => state.bannerposition.showSnackbar);

  console.log("mnjhjhh./././././././././");
  console.log(bannerPositionDetail);
  const history = useHistory();

  const params = useParams();
  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
        console.log(bannerPositionDetail);
        setName(bannerPositionDetail.name);
        setImage(bannerPositionDetail.image);
        setStatus(bannerPositionDetail.status);
        setPosition(bannerPositionDetail.position);
        setbannerType(bannerPositionDetail.bannerType);
        setCity(bannerPositionDetail.city)


        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }

    dispatch({
      type: BANNER_POSITION_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);

  useEffect(() => {
    console.log("params");
    const bannerPositionId = params.id;
    const bannerPosition = {
      _id: bannerPositionId
    };
    dispatch(bannerPositionAddOrUpdate(bannerPosition, 'edit'));
  }, []);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])
  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Banner Position View 
          </Text>
        </CardHeader>
        <CardBody>
          {loading ? <Stack>
            <Skeleton height='20px' />
            <Skeleton height='20px' />
            <Skeleton height='20px' />
          </Stack>
            :
            <TableContainer>
              <Table variant='simple'>
                <Tbody>
                  <Tr>
                    <Td>Name</Td>
                    <Td>{name}</Td>
                  </Tr>
                  {/* <Tr>
                      <Td>Image</Td>
                      <Td><Flex direction="column">
                        <Text fontSize="md" color={textColor} fontWeight="bold">
                        <Image
                            boxSize='100px'
                            objectFit='cover'
                            src={image}
                            alt='Dan Abramov'
                          />
                          
                        </Text>
                      </Flex>
                      </Td>
                    </Tr> */}
                  <Tr>
                    <Td>Status</Td>
                    <Td>{status}</Td>
                  </Tr>
                  <Tr>
                    <Td>Position</Td>
                    <Td>{position}</Td>
                  </Tr>
                  <Tr>
                    <Td>Banner Type</Td>
                    <Td>{bannerType}</Td>
                  </Tr>
                  {/* <Tr>
                      <Td>City</Td>
                      <Td>{city}</Td>
                    </Tr> */}
                </Tbody>
              </Table>
            </TableContainer>}
        </CardBody>
      </Card>
    </Flex>
  );
}

export default BannerPositionView;