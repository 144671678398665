import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { useDispatch, useSelector } from "react-redux";

import { useToast } from '@chakra-ui/react';
import { useParams } from "react-router-dom";
import { HOWITWORK_CLEAR_SNACKBAR } from "constants/types";
import {
  Table,
  Tbody,
  Td,
  Tr,
  TableContainer,
} from '@chakra-ui/react';
import { Skeleton, SkeletonText } from '@chakra-ui/react';
import { howItWorkAddOrUpdate } from "../../actions/howItWorkCategoryAction";
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { server_url } from '../../constants/types';


function HowItWorkCategoryView() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [name, setName] = useState("");
  const [photo, setPhoto] = useState("");
  const [number, setNumber] = useState("");
  const [howItWorkId, setHowItWorkId] = useState("");
  // const [checkpost, setCheckpost] = useState("");
  const [desc, setDescription] = useState("");
  const [created, setCreatedAt] = useState("");
  const [updated, setUpdatedAt] = useState("");
  const [type, setType] = useState("");
  const [dob, setDob] = useState("");
  const [passcode, setPasscode] = useState("");
  const [adName, setAdName] = useState("");
  const [contact, setContact] = useState("");
  const [secContct, setSecContact] = useState("");
  const [email, setEmail] = useState("");
  // const [create,setCreate ] = useState("");
  // const [,setUpdate ] = useState("");

  const [line1, setLine1] = useState("");
  const [line2, setLine2] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [locality, setLocality] = useState("");
  const [state, setState] = useState("");

  const dispatch = useDispatch();
  const toast = useToast();
  const history = useHistory()
  const responseStatus = useSelector(state => state.HowItWorkCategory.status);
  const responseMessage = useSelector(state => state.HowItWorkCategory.text);
  const loading = useSelector(state => state.HowItWorkCategory.loading);
  const howItWorkDetail = useSelector(state => state.HowItWorkCategory.howItWorkDetail);
  const showSnackbar = useSelector(state => state.HowItWorkCategory.showSnackbar);

  console.log("howItWorkDetail>>>>???????")
  console.log(howItWorkDetail);

  const params = useParams();
  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
        setName(howItWorkDetail.name);
        setPhoto(howItWorkDetail.photo);
        // setNumber(howItWorkDetail.city.contactNo);
        // setEmail(howItWorkDetail.email);
        setHowItWorkId(howItWorkDetail.howItWorkId);
        // setCheckpost(howItWorkDetail.checkpost.name);
        // howItWorkDetail.Address.map((item)=>{
        //   setLine1(item.line1) ; 
        //   setLine2(item.line2) ; 
        //   setPostalCode(item.postalCode) ; 
        //   setLocality(item.locality) ; 
        //   setState(item.state)}) ;
        setDescription(howItWorkDetail.description);
        setCreatedAt(howItWorkDetail.createdAt);
        setUpdatedAt(howItWorkDetail.updatedAt)
        // setBlood(howItWorkDetail.city.bloodGroup);
        setDob(howItWorkDetail.city.dob);
        setPasscode(howItWorkDetail.city.passcode);
        setAdName(howItWorkDetail.city.name);
        setContact(howItWorkDetail.city.contactNo);
        setSecContact(howItWorkDetail.city.secoundarycontactNo);
        setEmail(howItWorkDetail.city.email);
        setType(howItWorkDetail.type)
        // setCreate(howItWorkDetail.city.createdAt);
        // setUpdate(howItWorkDetail.city.updatedAt);
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }

    dispatch({
      type: HOWITWORK_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    });
  }, [loading]);

  useEffect(() => {
    const howItWorkId = params.id;
    const howItWork = {
      _id: howItWorkId
    };
    dispatch(howItWorkAddOrUpdate(howItWork, 'view'));
  }, []);

  console.log("howItWorkDetail")
  console.log(howItWorkDetail)

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            How-It-Work Category View
          </Text>
        </CardHeader>
        <CardBody>
          {loading ? <SkeletonText noOfLines={3} spacing="4" /> :
            <TableContainer>
              <Table variant='simple'>
                <Tbody>
                  <Tr>
                    <Td>Name</Td>
                    <Td>{name}</Td>
                  </Tr>
                  <Tr>
                    <Td>Email</Td>
                    <Td>{email}</Td>
                  </Tr>
                  <Tr>
                    <Td>Description</Td>
                    <Td>{desc}</Td>
                  </Tr>
                  {/* <Tr>
                    <Td>Created At</Td>
                    <Td>{created}</Td>
                  </Tr>
                  <Tr>
                    <Td>Updated At</Td>
                    <Td>{updated}</Td>
                  </Tr> */}
                  <Tr>
                    <Td>Type</Td>
                    <Td>{type}</Td>
                  </Tr>
                  <Tr>
                    <Td>Passcode</Td>
                    <Td>{passcode}</Td>
                  </Tr>
                  <Tr>
                    <Td>Admin Name</Td>
                    <Td>{adName}</Td>
                  </Tr>
                  <Tr>
                    <Td>Primary Contact</Td>
                    <Td>{contact}</Td>
                  </Tr>
                  <Tr>
                    <Td>Secondary Contact</Td>
                    <Td>{secContct}</Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          }
        </CardBody>
      </Card>
    </Flex>
  );
}

export default HowItWorkCategoryView;
