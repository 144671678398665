import axios from 'axios';
import {
  DRIVER_LOADING,
  DRIVER_GET_ALL_SUCCESS,
  DRIVER_ERROR,
  DRIVER_CLEAR_SNACKBAR
} from '../constants/types';

import { server_url } from '../constants/types';

export const driverGetAll = (pagination = '') => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: DRIVER_LOADING,
        payload: {
          loading: true
        }
      });
      axios
        .get(`${server_url}/drivers?${pagination}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
        .then((response) => {
          console.log("bus list action")
          console.log(response.data.data)

          dispatch({
            type: DRIVER_GET_ALL_SUCCESS,
            payload: {
              driverList: response.data.data,
              response: response.data,
              limit: response.data.limit,
              page: response.data.currentPage,
              totalPages: response.data.totalPages,
              loading: false,
              status: 'success',
              text: 'Get all drivers successfully.'
            }
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: DRIVER_ERROR,
            payload: {
              status: 'error',
              text: 'Error occurred during getting driver data.',
              loading: false
            }
          });
          resolve();
        });
    });
  };
};


export const driverAttendanceHistoryGetAll = (driver,search) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: DRIVER_LOADING,
        payload: {
          loading: true
        }
      });
      axios
        .get(`${server_url}/driver/attendance/history/list/${driver._id}?${search}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
        .then((response) => {
          console.log("pradeep-data");
          console.log(response.data.data)
          dispatch({
            type: DRIVER_GET_ALL_SUCCESS,
            payload: {
              driverAttendenceHistoryList: response.data.data,
              employeeList: [],
              limit: response.data.limit,
              page: response.data.currentPage,
              totalPages: response.data.totalPages,
              response: response.data,
              loading: false,
              status: 'success',
              text: 'driver Attendence History List successfully.'
            }
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: DRIVER_ERROR,
            payload: {
              status: 'error',
              text: 'Error occurred during getting driver data.',
              loading: false
            }
          });
          resolve();
        });
    });
  };
};


export const driverAddOrUpdate = (driver, state,limit="",page="") => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: DRIVER_LOADING,
        payload: {
          loading: true
        }
      });
      if (state === 'add') {
        axios
          .post(`${server_url}/drivers`, driver, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
          })
          .then((responseF) => {
            axios
              .get(`${server_url}/drivers`, {
                headers: {
                  'Authorization': 'Bearer ' + localStorage.getItem('authToken')
                }
              })
              .then((response) => {
                console.log("bus list action")
                console.log(response.data.data)
                dispatch({

                  type: DRIVER_GET_ALL_SUCCESS,
                  payload: {
                    driverList: response.data.data,
                    response: response.data,
                    limit: response.data.limit,
                    page: response.data.currentPage,
                    totalPages: response.data.totalPages,
                    loading: false,
                    status: responseF.data['status'],
                    text: responseF.data['message']
                  }
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: DRIVER_ERROR,
                  payload: {
                    text: 'Error occurred during getting driver data.',
                    status: 'error',
                    loading: false
                  }
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: DRIVER_ERROR,
              payload: {
                text: 'Error occurred during adding driver data.',
                status: 'error',
                loading: false
              }
            });
            resolve();
          });
      }
      else if (state === "upload") {
        axios
          .post(`${server_url}/driver/upload-excelsheet`, driver, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: 'Bearer ' + localStorage.getItem('authToken'),
            },
          })
          .then((responseF) => {
            axios
              .get(`${server_url}/employee`, {
                headers: {
                  Authorization: 'Bearer ' + localStorage.getItem('authToken'),
                },
              })
              .then((response) => {
                dispatch({
                  type: DRIVER_GET_ALL_SUCCESS,
                  payload: {
                    driverList: response.data.data,
                    response: response.data,
                    loading: false,
                    status: responseF.data["status"],
                    text: responseF.data["message"],
                  },
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: DRIVER_ERROR,
                  payload: {
                    text: "Error occurred during getting Driver data.",
                    status: "error",
                    loading: false,
                  },
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: DRIVER_ERROR,
              payload: {
                text: "Error occurred during adding Driver data.",
                status: "error",
                loading: false,
              },
            });
            resolve();
          });
      }


      else if (state === 'edit') {
        axios
          .put(`${server_url}/drivers/${driver._id}`, driver, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
          })
          .then((responseF) => {
            axios
              .get(`${server_url}/drivers?limit=${limit}&page=${page}`, {
                headers: {
                  'Authorization': 'Bearer ' + localStorage.getItem('authToken')
                }
              })
              .then((response) => {
                console.log("jsdkhasdyiasdkyuas");
                console.log(responseF.data['data']);
                dispatch({
                  type: DRIVER_GET_ALL_SUCCESS,
                  payload: {
                    driverList: response.data.data,
                    response: response.data,
                    limit: response.data.limit,
                    page: response.data.currentPage,
                    totalPages: response.data.totalPages,
                    loading: false,
                    status: responseF.data['status'],
                    driverDetail: responseF.data['data'],
                    text: responseF.data['message']
                  }
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: DRIVER_ERROR,
                  payload: {
                    text: 'Error occurred during getting driver data.',
                    status: 'error',
                    loading: false
                  }
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: DRIVER_ERROR,
              payload: {
                text: 'Error occurred during updating driver data.',
                status: 'error',
                loading: false
              }
            });
            resolve();
          });
      } else if (state === 'view') {
        axios
          .get(`${server_url}/driver/${driver._id}`, {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
          })
          .then((responseF) => {
            axios
              .get(`${server_url}/drivers?limit=${limit}&page=${page}`, {
                headers: {
                  'Authorization': 'Bearer ' + localStorage.getItem('authToken')
                }
              })
              .then((response) => {
                dispatch({
                  type: DRIVER_GET_ALL_SUCCESS,
                  payload: {
                    driverList: response.data.data,
                    response: response.data,
                    limit: response.data.limit,
                    page: response.data.currentPage,
                    totalPages: response.data.totalPages,
                    driverDetail: responseF.data['data'],
                    loading: false,
                    status: responseF.data['status'],
                    text: responseF.data['message']
                  }
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: DRIVER_ERROR,
                  payload: {
                    text: 'Error occurred during getting driver data.',
                    status: 'error',
                    loading: false
                  }
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: DRIVER_ERROR,
              payload: {
                text: 'Error occurred during updating driver data.',
                status: 'error',
                loading: false
              }
            });
            resolve();
          });
      }
    });
  };
};

export const driverDelete = (driver) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: DRIVER_LOADING,
        payload: {
          loading: true
        }
      });
      axios
        .delete(`${server_url}/drivers/${driver}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
        .then((responseF) => {
          axios
            .get(`${server_url}/drivers?limit=${limit}&page=${page}`, {
              headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
              }
            })
            .then((response) => {
              dispatch({
                type: DRIVER_GET_ALL_SUCCESS,
                payload: {
                  driverList: response.data.data,
                  response: response.data,
                  limit: response.data.limit,
                  page: response.data.currentPage,
                  totalPages: response.data.totalPages,
                  driverDetail: responseF.data['data'],
                  loading: false,
                  status: responseF.data['status'],
                  text: responseF.data['message']
                }
              });
              resolve();
            })
            .catch((e) => {
              dispatch({
                type: DRIVER_ERROR,
                payload: {
                  text: 'Error occurred during getting driver data.',
                  status: 'error',
                  loading: false
                }
              });
              resolve();
            });
        })
        .catch((e) => {
          dispatch({
            type: DRIVER_ERROR,
            payload: {
              text: 'Error occurred during deleting driver data.',
              status: 'error',
              loading: false
            }
          });
          resolve();
        });
    });
  };
};

export const driverClearShowSnackbar = () => {
  return (dispatch, getState) => {
    dispatch({
      type: DRIVER_CLEAR_SNACKBAR
    });
  };
};
