// Chakra imports
import {
    Box,
    Button,
    Center,
    Flex,
    Image,
    Input,
    SimpleGrid,
    Spacer,
    Stack,
    Text,
    Wrap,
    WrapItem,
    useColorModeValue
  } from "@chakra-ui/react";
  // Custom components
  import Card from "components/Card/Card.js";
  import CardBody from "components/Card/CardBody.js";
  import CardHeader from "components/Card/CardHeader.js";
  import React, { useEffect, useState } from "react";
  import { useDispatch, useSelector } from 'react-redux';

  import { useToast } from '@chakra-ui/react'
  import { useParams } from "react-router-dom/cjs/react-router-dom.min";
  import { EMPLOYEEATTENDENCE_CLEAR_SNACKBAR } from "constants/types";
  import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
  } from '@chakra-ui/react'
  import { Skeleton, SkeletonCircle, SkeletonText } from '@chakra-ui/react'
import { EmployeeGetById } from "actions/EmployeeAttendenceAction";

  
  function EmployeeAttendenceView() {
    const textColor = useColorModeValue("gray.700", "white");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    const [employeeName, setEmployeeName] = useState("");
    const [employeePhoto, setEmployeePhoto] = useState("");
    const [phone, setPhone] = useState("");
    const [officialEmail , setOfficialEmail] = useState('');
    const [department, setDepartment] = useState("");
    const [subdepartment, setSubdepartment] = useState("");
    const [inDateTime, setInDateTime] = useState("");
    const [outDateTime, setOutDateTime] = useState("");
    const [designation, setDesignation] = useState("");
    const [vehicleType, setVehicleType] = useState("");
    const [vehicleNumber, setVehicleNumber] = useState("");
    const [licenseNumber, setLicenseNumber] = useState('');
    const [licensePhoto, setLicensePhoto]= useState('');
   
   
    const dispatch = useDispatch();
    const toast = useToast();
  
    const statuses = ['success', 'error', 'warning', 'info'];
    const responseStatus = useSelector(state => state.employeeattendence.status);
    const responseMessage = useSelector(state => state.employeeattendence.text);
    const loading = useSelector(state => state.employeeattendence.loading);
    const employeeattendenceDetail = useSelector(state => state.employeeattendence.employeeattendenceDetail);
    const showSnackbar = useSelector(state => state.employeeattendence.showSnackbar);
  
    const params = useParams();
    useEffect(() => {
      if (showSnackbar) {
        if (responseStatus === 'OK' && loading === false) {
          console.log(employeeattendenceDetail);
          setEmployeeName(employeeattendenceDetail.employee.employeeName);
          setEmployeePhoto(employeeattendenceDetail.employee.photo);
          setPhone(employeeattendenceDetail.employee.phone);
          setOfficialEmail(employeeattendenceDetail.employee.officialEmail);
          setInDateTime(employeeattendenceDetail.type === "IN" ?(employeeattendenceDetail.attendanceAt):(<></>));
          setOutDateTime(employeeattendenceDetail.type === "OUT" ?(employeeattendenceDetail.attendanceAt):(<></>))
          setDesignation(employeeattendenceDetail.employee.designation);
          setVehicleType(employeeattendenceDetail.employee?.vehicleDetails?.vehicleType);
          setVehicleNumber(employeeattendenceDetail.employee?.vehicleDetails?.vehicleNumber);
          setLicenseNumber(employeeattendenceDetail.employee?.vehicleDetails?.licenseNumber)
          setLicensePhoto(employeeattendenceDetail.employee?.vehicleDetails?.licensePhoto)
         
            
          if (responseMessage !== "") {
            toastFunction(responseMessage, 'success');
          }
        } else if (responseStatus === 'NOK' && loading === false) {
          if (responseMessage !== "") {
            toastFunction(responseMessage, 'error');
          }
        }
      }
  
      dispatch({
        type: EMPLOYEEATTENDENCE_CLEAR_SNACKBAR,
        payload: {
          showSnackbar: false,
        }
      })
    }, [loading]);
  
    useEffect(() => {
      console.log("params");
      const EmployeeId = params.id;
      const Employee = {
        _id : EmployeeId
      };
      dispatch(EmployeeGetById(Employee._id));
    }, []);
  
    const toastFunction = (title, status) => {
      toast({
        title: title,
        status: status,
        duration: 3000,
        isClosable: true,
      })
    };

    
    return (
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              View Form
            </Text>
          </CardHeader>
          <CardBody>
            {loading ? <Stack>
              <Skeleton height='20px' />
              <Skeleton height='20px' />
              <Skeleton height='20px' />
            </Stack>
              :
              <TableContainer>
                <Table variant='simple'>
                  <Tbody>
                    <Tr>
                      <Td>employeeName</Td>
                      <Td>{employeeName}</Td>
                    </Tr>
                    <Td>Image</Td>
                    <Td><Flex direction="column">
                        <Text fontSize="md" color={textColor} fontWeight="bold">
                        <Image
                            boxSize='100px'
                            objectFit='cover'
                            src={employeePhoto}
                            alt='Dan Abramov'
                          />
                          
                        </Text>
                      </Flex>
                      </Td>
                    <Tr>
                      <Td>phone</Td>
                      <Td>{phone}</Td>
                    </Tr>
                    <Tr>
                      <Td>officialEmail</Td>
                      <Td>{officialEmail}</Td>
                    </Tr>
                   
                    <Tr>
                      <Td>department</Td>
                      <Td>{department}</Td>
                    </Tr>
                    <Tr>
                      <Td>subdepartment</Td>
                      <Td>{subdepartment}</Td>
                    </Tr>
                    <Tr>
                      <Td>inDateTime</Td>
                      <Td>{inDateTime}</Td>
                    </Tr>
                    <Tr>
                      <Td>outDateTime</Td>
                      <Td>{outDateTime}</Td>
                    </Tr>
                    <Tr>
                      <Td>designation</Td>
                      <Td>{designation}</Td>
                    </Tr>
                   <Tr>
                      <Td>Vechile Details</Td>
                       <Td><p><b>vehicleType</b> : {vehicleType} <br/>
                    <b>vehicleNumber</b> : {vehicleNumber}<br/>
                    <b>vehicleType</b> : {vehicleType} <br/>
                    <b>licenseNumber</b> : {licenseNumber}<br/>
                   
                    <b>licensePhoto</b> :  <Image
                            boxSize='100px'
                            objectFit='cover'
                            src={licensePhoto}
                            alt='Dan Abramov'
                          /></p></Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>}
          </CardBody>
        </Card>
      </Flex>
    );
  }
  
  export default EmployeeAttendenceView;
