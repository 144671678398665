const {
    SUBDEPARTMENT_LOADING,
    SUBDEPARTMENT_GET_ALL_SUCCESS,
    SUBDEPARTMENT_ERROR,
    SUBDEPARTMENT_CLEAR_SNACKBAR
  } = require('../constants/types');
  
  const initialState = {
    response: {},
    subDepartmentList: [],
    subDepartmentDetail: {},
    loading: false,
    status: 'success',
    text: '',
    showSnackbar: false
};

console.log("initialState.subDepartmentDetail");
console.log(initialState.subDepartmentDetail);

  
  const subDepartmentReducer = (state = initialState, action) => {
    switch (action.type) {
      case SUBDEPARTMENT_CLEAR_SNACKBAR:
        return {
          ...state,
          showSnackbar: false
        };
      case SUBDEPARTMENT_LOADING:
        return {
          ...state,
          loading: true
        };
      case SUBDEPARTMENT_ERROR:
        return {
          ...state,
          subDepartmentList: [],
          response: {},
          subDepartmentDetail: {},
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true
        };
      case SUBDEPARTMENT_GET_ALL_SUCCESS:
        return {
          ...state,
          subDepartmentList: action.payload.subDepartmentList,
          response: action.payload.response,
          subDepartmentDetail: action.payload.subDepartmentDetail,
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true
          
        };
      default:
        return state || {};
    }
  };


  
  // module.exports = subDepartmentReducer;
  export default subDepartmentReducer;
  