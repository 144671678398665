import React, { useEffect, useState } from "react";
import {
    Box,
    Flex,
    Text,
    useColorModeValue,
    Wrap
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { useDispatch, useSelector } from "react-redux";
import { useToast } from '@chakra-ui/react';
import { useParams } from "react-router-dom";
import { INTERCARTINGINOUT_CLEAR_SNACKBAR } from "constants/types"; // Update this import
import { Table, Tbody, Td, Tr, TableContainer } from '@chakra-ui/react';
import { Skeleton, SkeletonText } from '@chakra-ui/react';
import { intercartAddOrUpdate } from "actions/IntercartingInOutAction"; // Update this import
import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom';
function IntercartView() {

    const textColor = useColorModeValue("gray.700", "white");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    const [name, setName] = useState("");
    const [capacity, setCapacity] = useState("");
    const [phone, setPhone] = useState("");
    const [mail, setMail] = useState("");
    const [altPhone, setAltPhone] = useState("");
    const [driverName, setdriverName] = useState("");
    const [passcode, setPasscode] = useState("");
    const [password, setPassword] = useState("");
    const [photo, setPhoto] = useState("");
    const [plantAdmin, setPlantAdmin] = useState("");
    const [createdAt, setCreatedAt] = useState("");
    const [updated, setUpdated] = useState("");
    const [trips, setTrips] = useState("");
    const [material, setMaterial] = useState("");
    const [type, setType] = useState("");
    const [inDate, setInDate] = useState("");
    const [outDate, setOutDate] = useState("");
    const [total, setTotalTime] = useState("");
    const [guard, setGuard] = useState("");

    const [busNo, setBusNo] = useState("");
    const [id, setId] = useState("");
    const [insurance, setInsurance] = useState("");
    const [driver, setDriver] = useState("");
    const [txNo, setTxNo] = useState("");
    const [blood, setBlood] = useState("");


    const history = useHistory();
    const dispatch = useDispatch();
    const toast = useToast();

    const responseStatus = useSelector(state => state.IntercartingInOut.status); // Update state access
    const responseMessage = useSelector(state => state.IntercartingInOut.text); // Update state access
    const loading = useSelector(state => state.IntercartingInOut.loading); // Update state access
    const showSnackbar = useSelector(state => state.IntercartingInOut.showSnackbar); // Update state access
    const intercartDetail = useSelector(state => state.IntercartingInOut.intercartingInOutDetail); // Update state access
    const logs = useSelector(state => state.IntercartingInOut.intercartingInOutList.logs);



    console.log("logs;';;;;;';;;;;;");
    console.log(logs);

    const params = useParams();

    console.log("intercartDetail0000000000000000000000");
    console.log(intercartDetail);

    useEffect(() => {
        if (showSnackbar) {
            if (responseStatus === 'OK' && !loading) {
                const vehicleNumber = intercartDetail.vehicleNumber;
                const driver = intercartDetail.driver;

                if (vehicleNumber && driver) {
                    setName(vehicleNumber.busNo);
                    setCapacity(vehicleNumber.sittingCapacity);
                    setPhone(driver.phone);
                    setMail(driver.officialEmail);
                    setAltPhone(driver.alternateNumber);
                    setBlood(driver.bloodGroup);
                    setdriverName(driver.Name);
                    setPasscode(driver.passcode);
                    setPassword(driver.password);
                    setPhoto(driver.photo);
                    setPlantAdmin(driver.plantAdmin);
                    setCreatedAt(driver.createdAt);
                    setUpdated(driver.updatedAt);
                }
                setTrips(intercartDetail.trips);
                setMaterial(intercartDetail.materialDescription);
                setType(intercartDetail.type);
                setInDate(intercartDetail.inDateTime);
                setOutDate(intercartDetail.outDateTime);
                setTotalTime(intercartDetail.totalTime);
                setGuard(intercartDetail.guard);
                setBusNo(vehicleNumber ? vehicleNumber.busNo : '');
                setId(intercartDetail._id);
                setInsurance(vehicleNumber ? vehicleNumber.insuranceValidityDate : '');
                setDriver(driver ? driver.Name : '');
                setTxNo(intercartDetail.txnNumber);

                if (responseMessage !== "") {
                    toastFunction(responseMessage, 'success');
                }
            } else if (responseStatus === 'NOK' && !loading) {
                if (responseMessage !== "") {
                    toastFunction(responseMessage, 'error');
                }
            }
        }

        dispatch({
            type: INTERCARTINGINOUT_CLEAR_SNACKBAR,
            payload: {
                showSnackbar: false,
            }
        });
    }, [loading]);

    useEffect(() => {
        const intercartId = params.id; // Update variable name
        const intercart = {
            _id: intercartId
        };
        console.log(intercart._id);
        dispatch(intercartAddOrUpdate(intercart, 'view')); // Update action name
    }, [params.id]); // Include params.id as a dependency

    useEffect(() => {
        axios
          .get(`${server_url}/admin/detail`, {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
          }).then((responce) => {
            console.log("uadlfdafljadhfoadilf");
          })
          .catch((e) => {
            history.push('/auth/signin');
    
          });
      }, [])
    const toastFunction = (title, status) => {
        toast({
            title: title,
            status: status,
            duration: 3000,
            isClosable: true,
        })
    };
    {
        if (logs !== undefined) {
            return (
                <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
                    <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
                        <CardHeader p="6px 0px 22px 0px">
                            <Text fontSize="xl" color={textColor} fontWeight="bold">
                                Intercart In Out View
                            </Text>
                        </CardHeader>
                        <CardBody>
                            {loading ? <SkeletonText noOfLines={3} spacing="4" /> :
                                <TableContainer>
                                    <Table variant='simple'>
                                        <Tbody>
                                            <Tr>
                                                <Td>Driver Name</Td>
                                                <Td>{driver}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>Vehicle Number</Td>
                                                <Td>{name}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>Capacity</Td>
                                                <Td>{capacity}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>Driver Phone Number</Td>
                                                <Td>{phone}</Td>
                                            </Tr>
                                            {/* <Tr>
                                        <Td>Patrolling Route</Td>
                                        <Td>{patrollingRoute}</Td>
                                    </Tr> */}
                                            <Tr>
                                                <Td>Driver Alternate Phone Number</Td>
                                                <Td>{altPhone}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>Mail</Td>
                                                <Td>{mail}</Td>
                                            </Tr>

                                            {/* <Tr>
                                        <Td>DriverName</Td>
                                        <Td>{passcode}</Td>
                                    </Tr> */}
                                            {/* <Tr>
                                        <Td>Password</Td>
                                        <Td>{password}</Td>
                                    </Tr> */}
                                            <Tr>
                                                <Td>Photo</Td>
                                                <Td>
                                                    <img src={photo} style={{ maxWidth: '120px' }} />
                                                </Td>
                                            </Tr>
                                            {/* <Tr>
                                        <Td>PlantAdmin</Td>
                                        <Td>{plantAdmin}</Td>
                                    </Tr> */}
                                            {/* <Tr>
                                        <Td>Created At</Td>
                                        <Td>{createdAt}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td>Updated At</Td>
                                        <Td>{updated}</Td>
                                    </Tr> */}
                                            <Tr>
                                                <Td>Trips</Td>
                                                <Td>{trips}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>Material</Td>
                                                <Td>{material}</Td>
                                            </Tr>
                                            {/* <Tr>
                                        <Td>Blood</Td>
                                        <Td>{blood}</Td>
                                    </Tr> */}
                                            <Tr>
                                                <Td>Type</Td>
                                                <Td>{type}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>Entry Date</Td>
                                                <Td>{inDate}</Td>
                                            </Tr>
                                            {/* <Tr>
                                        <Td>Exit Date</Td>
                                        <Td>{outDate}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td>Total</Td>
                                        <Td>{total}</Td>
                                    </Tr> */}
                                            {/* <Tr>
                                        <Td>Guard</Td>
                                        <Td>{guard}</Td>
                                    </Tr> */}
                                            {/* <Tr>
                                        <Td>Bus Number</Td>
                                        <Td>{busNo}</Td>
                                    </Tr> */}
                                            {/* <Tr>
                                        <Td>Id</Td>
                                        <Td>{id}</Td>
                                    </Tr> */}
                                            {/* <Tr>
                                        <Td>Insurance</Td>
                                        <Td>{insurance}</Td>
                                    </Tr> */}

                                            <Tr>
                                                <Td>Txn Number</Td>
                                                <Td>{txNo}</Td>
                                            </Tr>


                                        </Tbody>
                                    </Table>
                                </TableContainer>
                            }
                        </CardBody>
                    </Card>
<br/>
                    <Flex direction='row' gap='15px' >
                        {logs.map((hello) => {
                            return (

                                <Wrap spacing={4} justify="center" align="center" >

                                    <Box >
                                        <Card mb={4}>
                                            <Text fontSize="xl" color={textColor} fontWeight="bold" >Logs</Text>
                                            <br/>
                                            <ul>
                                                <li style={{ textDecoration: "none" }}>
                                                    Quantity= {hello.QTY}
                                                </li>
                                                <br />
                                                <li >
                                                    Driver= {hello.driver}
                                                </li>
                                                <br />
                                                <li>
                                                    Description = {hello.description}
                                                </li>
                                                <br />
                                                <li>
                                                    Quantity = {hello.quantity}
                                                </li>
                                                <br />
                                                <li>
                                                    Material Description = {hello.materialDescription}
                                                </li>
                                                <br />
                                                <li>
                                                    Oum = {hello.oum}
                                                </li>
                                                <br />
                                                <li>
                                                    Trips = {hello.trips}
                                                </li>

                                            </ul>
                                        </Card>
                                    </Box>

                                </Wrap >
                            )
                        })}
                    </Flex>


                </Flex>
            )
        } else if (logs == undefined) {
            return (
                <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
                    <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
                        <CardHeader p="6px 0px 22px 0px">
                            <Text fontSize="xl" color={textColor} fontWeight="bold">
                                Intercart Form
                            </Text>
                        </CardHeader>
                        <CardBody>
                            {loading ? <SkeletonText noOfLines={3} spacing="4" /> :
                                <TableContainer>
                                    <Table variant='simple'>
                                        <Tbody>
                                            <Tr>
                                                <Td>Driver Name</Td>
                                                <Td>{driver}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>Vehicle Number</Td>
                                                <Td>{name}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>Capacity</Td>
                                                <Td>{capacity}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>Driver Phone Number</Td>
                                                <Td>{phone}</Td>
                                            </Tr>
                                            {/* <Tr>
                                            <Td>Patrolling Route</Td>
                                            <Td>{patrollingRoute}</Td>
                                        </Tr> */}
                                            <Tr>
                                                <Td>Driver Alternate Phone Number</Td>
                                                <Td>{altPhone}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>Mail</Td>
                                                <Td>{mail}</Td>
                                            </Tr>

                                            {/* <Tr>
                                            <Td>DriverName</Td>
                                            <Td>{passcode}</Td>
                                        </Tr> */}
                                            {/* <Tr>
                                            <Td>Password</Td>
                                            <Td>{password}</Td>
                                        </Tr> */}
                                            <Tr>
                                                <Td>Photo</Td>
                                                <Td>
                                                    <img src={photo} style={{ maxWidth: '120px' }} />
                                                </Td>
                                            </Tr>
                                            {/* <Tr>
                                            <Td>PlantAdmin</Td>
                                            <Td>{plantAdmin}</Td>
                                        </Tr> */}
                                            {/* <Tr>
                                            <Td>Created At</Td>
                                            <Td>{createdAt}</Td>
                                        </Tr>
                                        <Tr>
                                            <Td>Updated At</Td>
                                            <Td>{updated}</Td>
                                        </Tr> */}
                                            <Tr>
                                                <Td>Trips</Td>
                                                <Td>{trips}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>Material</Td>
                                                <Td>{material}</Td>
                                            </Tr>
                                            {/* <Tr>
                                            <Td>Blood</Td>
                                            <Td>{blood}</Td>
                                        </Tr> */}
                                            <Tr>
                                                <Td>Type</Td>
                                                <Td>{type}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>Entry Date</Td>
                                                <Td>{inDate}</Td>
                                            </Tr>
                                            {/* <Tr>
                                            <Td>Exit Date</Td>
                                            <Td>{outDate}</Td>
                                        </Tr>
                                        <Tr>
                                            <Td>Total</Td>
                                            <Td>{total}</Td>
                                        </Tr> */}
                                            {/* <Tr>
                                            <Td>Guard</Td>
                                            <Td>{guard}</Td>
                                        </Tr> */}
                                            {/* <Tr>
                                            <Td>Bus Number</Td>
                                            <Td>{busNo}</Td>
                                        </Tr> */}
                                            {/* <Tr>
                                            <Td>Id</Td>
                                            <Td>{id}</Td>
                                        </Tr> */}
                                            {/* <Tr>
                                            <Td>Insurance</Td>
                                            <Td>{insurance}</Td>
                                        </Tr> */}

                                            <Tr>
                                                <Td>Txn Number</Td>
                                                <Td>{txNo}</Td>
                                            </Tr>

                                            <Tr>
                                                <Td>Logs</Td>
                                                <Td>Logs have no data</Td>
                                            </Tr>

                                        </Tbody>
                                    </Table>
                                </TableContainer>
                            }
                        </CardBody>
                    </Card>
                    
                    
                </Flex>
            );
        }
    }
}

export default IntercartView;
