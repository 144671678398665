import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  SimpleGrid,
  Spacer,
  Text,
  Wrap,
  WrapItem,
  useColorModeValue,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Spinner,
  Image,
  Select
} from "@chakra-ui/react";
import Relect from 'react-select';
import dataConstant from "constants/dataConstant";
import { PLANTADMIN_CLEAR_SNACKBAR } from "constants/types";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { useDispatch, useSelector } from "react-redux";

import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom';

import { useToast } from '@chakra-ui/react'

import { useParams } from "react-router-dom/cjs/react-router-dom.min";
// import { plantadminmoduleAddOrUpdate } from "actions/plantadminModuleAction";
import { plantadminAddOrUpdate } from "../../actions/plantAction";
import CustomInput from "views/CustomValidation";

function PlantAdminUpdate() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [photo, setPhoto] = useState("");
  const [name, setName] = useState("");
  const [plantName, setPlantName] = useState("");
  const [conName, setConName] = useState("");
  const [number, setNumber] = useState("");
  const [altNumber, setAltNumber] = useState("");
  const [email, setEmail] = useState("");
  const [perEmail, setPerEmail] = useState("");
  const [status, setStatus] = useState("");

  const [line1, setLine1] = useState("");
  const [line2, setLine2] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [locality, setLocality] = useState("");
  const [state, setState] = useState("");


  const [isErrorName, setIsErrorName] = useState(false);
  const [isErrorConName, setIsErrorConName] = useState(false);
  const [isErrorNumber, setIsErrorNumber] = useState(false);
  const [isErrorAltNumber, setIsErrorAltNumber] = useState(false);
  const [isErrorEmail, setIsErrorEmail] = useState(false);
  const [isErrorPerEmail, setIsErrorPerEmail] = useState(false);

  const [submitted, setSubmitted] = useState(false);

  const [isErrorCompanyName, setIsErrorCompanyName] = useState(false);
  const [isErrorStatus, setIsErrorStatus] = useState(false);

  const [isErrorPostalcode, setIsErrorPostalcode] = useState(false);
  const [isErrorLine1, setIsErrorLine1] = useState(false);
  const [isErrorLine2, setIsErrorLine2] = useState(false);
  const [isErrorLocality, setIsErrorLocality] = useState(false);
  const [isErrorState, setIsErrorState] = useState(false);

  const statuses = ['success', 'error', 'warning', 'info'];
  const responseStatus = useSelector(state => state.plantadmin.status);
  const responseMessage = useSelector(state => state.plantadmin.text);
  const loading = useSelector(state => state.plantadmin.loading);
  const showSnackbar = useSelector(state => state.plantadmin.showSnackbar);
  const plantDetail = useSelector(state => state.plantadmin.plantDetail);

  const history = useHistory();
  const dispatch = useDispatch();
  const toast = useToast();

  const params = useParams();
  const Idofplantadmin = params.id;




  console.log("department")
  console.log(plantDetail)
  console.log(name);
  console.log("department")
  useEffect(() => {

    const plantadmin = {
      _id: Idofplantadmin
    }
    dispatch(plantadminAddOrUpdate(plantadmin, 'view'));
  }, []);


  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {


        setName(plantDetail.plant[0].plantName);
        setConName(plantDetail.plant[0].contactPersonName);
        setNumber(plantDetail.contactNo);
        setAltNumber(plantDetail.plant[0].alternateNo);
        setEmail(plantDetail.plant[0].officalEmail);
        setPerEmail(plantDetail.email);
        setStatus(plantDetail.status)

        plantDetail.address.map((item) => {
          setLine1(item.line1);
          setLine2(item.line2);
          setPostalCode(item.postalCode);
          setLocality(item.locality);
          setState(item.state)
        });


      }

    }

    function replaceUnderscoresAndCapitalize(inputString) {
      // Replace underscores with spaces
      const stringWithSpaces = inputString.replace(/_/g, ' ');

      // Capitalize the first letter of each word
      const words = stringWithSpaces.split(' ');
      const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());

      // Join the words back into a single string
      const resultString = capitalizedWords.join(' ');

      return resultString;
    }

    dispatch({
      type: PLANTADMIN_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    setIsErrorName(name === '');
    setIsErrorConName(conName === '');
    setIsErrorPostalcode(line1 === '');
    setIsErrorLine1(line2 === '');
    setIsErrorLine2(postalCode === '');
    setIsErrorLocality(locality === '');
    setIsErrorState(state === '');
    setIsErrorNumber(number === '');
    setIsErrorAltNumber(altNumber === '');
    setIsErrorEmail(email === '');
    setIsErrorPerEmail(perEmail === '');
    setIsErrorStatus(status === '');

    if (name !== "" && status !== 0 && conName !== '' && number !== '' && altNumber !== '' && email !== '' && perEmail !== ''
      && line1 !== "" && line2 !== "" && postalCode !== "" && locality !== "" && state !== ""
    ) {

      const newPlant = {
        _id: Idofplantadmin,

        status: status,
        contactNo: number,
        perEmail: perEmail,
        "plant": [
          {
            "plantName": name,
            "contactPersonName": conName,
            "alternateNo": altNumber,
            "officalEmail": email
          }
        ],
        address: [
          {
            line1,
            line2,
            postalCode,
            locality,
            state,
          }
        ],

      };
      console.log("kagdikdafik12222222221222222221");
      console.log(newPlant)
      dispatch(plantadminAddOrUpdate(newPlant, 'edit'));
    }

  };


  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: PLANTADMIN_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);
  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };





  const textRegex = /^[A-Za-z\s]+$/;
  const numberRegex = /^\d{10}$/;
  const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,10}$/;
  const plantadminIdRegex = /^[a-zA-Z0-9]+$/;
  const postalCodeRegex = /^[0-9]{6}$/;

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Plant Admin Update Form
          </Text>
          <br />
          <SimpleGrid minChildWidth='120px' spacing='40px'>
            <FormControl isInvalid={isErrorName || (submitted && name === "")}>
              <CustomInput
                type='text'
                label='Plant Admin  Name'
                value={name}
                onChange={(value) => {
                  setIsErrorName(value === '' || !textRegex.test(value));
                  setName(value);
                }}

                regex={textRegex}
                submitted={submitted}
              />
            </FormControl>
            {/* <FormControl isInvalid={isErrorPhoto || (submitted && photo === "")}>
              <FormLabel>Photo</FormLabel>
              <Input
                type='file'
                onChange={(e) => {
                  setIsErrorPhoto(e.target.value === '')
                  setPhoto(e.target.files[0])
                }}
              />
              {isErrorPhoto && submitted && (
                <FormErrorMessage>Photo is required.</FormErrorMessage>
              )}
            </FormControl> */}
            <FormControl isInvalid={isErrorNumber || (submitted && number === "")}>
              <CustomInput
                type="number"
                label="Contact Number"
                value={number}
                onChange={(value) => {
                  setIsErrorNumber(value === '' || !numberRegex.test(value));
                  setNumber(value);
                }}

                regex={numberRegex}
                submitted={submitted}
              />
            </FormControl>

            <FormControl isInvalid={isErrorAltNumber || (submitted && altNumber === "")}>
              <CustomInput
                type="number"
                label="Alternate Number"
                value={altNumber}
                onChange={(value) => {
                  setIsErrorAltNumber(value === '' || !numberRegex.test(value));
                  setAltNumber(value);
                }}

                regex={numberRegex}
                submitted={submitted}
              />
            </FormControl>
          </SimpleGrid>

          <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>

            <FormControl isInvalid={isErrorEmail || (submitted && email === "")}>
              <CustomInput
                type="email"
                label="Official Email"
                value={email}
                onChange={(value) => {
                  setIsErrorEmail(value === '' || !emailRegex.test(value));
                  setEmail(value);
                }}

                regex={emailRegex}
                submitted={submitted}
              />
            </FormControl>
            <FormControl isInvalid={isErrorPerEmail || (submitted && perEmail === "")}>
              <CustomInput
                type="email"
                label="Personal Email"
                value={perEmail}
                onChange={(value) => {
                  setIsErrorPerEmail(value === '' || !emailRegex.test(value));
                  setPerEmail(value);
                }}

                regex={emailRegex}
                submitted={submitted}
              />
            </FormControl>
          </SimpleGrid>

          <SimpleGrid minChildWidth='120px' spacing='40px'>
            <FormControl isInvalid={isErrorConName || (submitted && conName === "")}>
              <CustomInput
                type='text'
                label='Contact Person Name'
                value={conName}
                onChange={(value) => {
                  setIsErrorConName(value === '' || !textRegex.test(value));
                  setConName(value);
                }}

                regex={textRegex}
                submitted={submitted}
              />
            </FormControl>

            <FormControl isInvalid={isErrorStatus}>
              <FormLabel>Status</FormLabel>
              <Select
                placeholder="Select status"
                value={status}
                onChange={(e) => {
                  setIsErrorStatus(e.target.value === '')
                  setStatus(e.target.value)
                }}
              >
                {dataConstant.status.map((element, index) => {
                  return (
                    <option key={element['id']} value={element['value']}>
                      {element['name']}
                    </option>
                  );
                })}
              </Select>
              {!isErrorStatus ? (
                <></>
              ) : (
                <FormErrorMessage>Status is required.</FormErrorMessage>
              )}
            </FormControl>

          </SimpleGrid>

          {/* <SimpleGrid minChildWidth='120px' spacing='40px'>
            <FormControl isInvalid={isErrorConName || (submitted && conName === "")}>
              <CustomInput
                type='text'
                label='Address Line 1'
                value={conName}
                onChange={(value) => {
                  setIsErrorConName(value === '' || !textRegex.test(value));
                  setConName(value);
                }}

                regex={textRegex}
                submitted={submitted}
              />
            </FormControl>

            <FormControl isInvalid={isErrorConName || (submitted && conName === "")}>
              <CustomInput
                type='text'
                label='Address Line 2'
                value={conName}
                onChange={(value) => {
                  setIsErrorConName(value === '' || !textRegex.test(value));
                  setConName(value);
                }}

                regex={textRegex}
                submitted={submitted}
              />
            </FormControl>

            <FormControl isInvalid={isErrorNumber || (submitted && number === "")}>
              <CustomInput
                type="number"
                label="Postal Code"
                value={number}
                onChange={(value) => {
                  setIsErrorNumber(value === '' || !numberRegex.test(value));
                  setNumber(value);
                }}

                regex={numberRegex}
                submitted={submitted}
              />
            </FormControl>

            <FormControl isInvalid={isErrorConName || (submitted && conName === "")}>
              <CustomInput
                type='text'
                label='Locality'
                value={conName}
                onChange={(value) => {
                  setIsErrorConName(value === '' || !textRegex.test(value));
                  setConName(value);
                }}

                regex={textRegex}
                submitted={submitted}
              />
            </FormControl>

            <FormControl isInvalid={isErrorConName || (submitted && conName === "")}>
              <CustomInput
                type='text'
                label='State'
                value={conName}
                onChange={(value) => {
                  setIsErrorConName(value === '' || !textRegex.test(value));
                  setConName(value);
                }}

                regex={textRegex}
                submitted={submitted}
              />
            </FormControl>





          </SimpleGrid> */}

          <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
            <FormControl isInvalid={isErrorLine1 || (submitted && line1 === "")}>
              <CustomInput
                type='text'
                label="Line 1"
                placeholder="Line 1"
                regex={textRegex}
                value={line1}
                onChange={(value) => {
                  setIsErrorLine1(value === '' || !textRegex.test(value));
                  setLine1(value);
                }}
                isInvalid={isErrorLine1 || (submitted && line1 === "")}
                submitted={submitted}
              />
            </FormControl>
            <FormControl isInvalid={isErrorLine2 || (submitted && line2 === "")}>
              <CustomInput
                type='text'
                label="Line 2"
                placeholder="Line 2"
                regex={textRegex}
                value={line2}
                onChange={(value) => {
                  setIsErrorLine2(value === '' || !textRegex.test(value));
                  setLine2(value);
                }}
                isInvalid={isErrorLine2 || (submitted && line2 === "")}
                submitted={submitted}
              />
            </FormControl>
          </SimpleGrid>

          <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
            <FormControl isInvalid={isErrorPostalcode || (submitted && postalCode === "")}>
              <CustomInput
                type='number'
                label="Postal Code"
                placeholder="Postal Code"
                regex={postalCodeRegex}
                value={postalCode}
                onChange={(value) => {
                  setIsErrorPostalcode(value === '' || !postalCodeRegex.test(value));
                  setPostalCode(value);
                }}
                isInvalid={isErrorPostalcode || (submitted && postalCode === "")}
                submitted={submitted}
              />
            </FormControl>

            <FormControl isInvalid={isErrorLocality || (submitted && locality === "")}>
              <CustomInput
                type='text'
                label="Locality"
                placeholder="Locality"
                regex={textRegex}
                value={locality}
                onChange={(value) => {
                  setIsErrorLocality(value === '' || !textRegex.test(value));
                  setLocality(value);
                }}
                isInvalid={isErrorLocality || (submitted && locality === "")}
                submitted={submitted}
              />
            </FormControl>

            <FormControl isInvalid={isErrorState || (submitted && state === "")}>
              <CustomInput
                type='text'
                label="State"
                placeholder="State"
                regex={textRegex}
                value={state}
                onChange={(value) => {
                  setIsErrorState(value === '' || !textRegex.test(value));
                  setState(value);
                }}
                isInvalid={isErrorState || (submitted && state === "")}
                submitted={submitted}
              />
            </FormControl>


          </SimpleGrid>



          {/* ... Additional form fields ... */}
          <Center>
            {loading ? (
              <Spinner mt="24px" size="lg" />
            ) : (
              <Button colorScheme="blue" mt="24px" onClick={handleSubmit}>
                Submit
              </Button>
            )}
          </Center>
        </CardHeader>
        <CardBody>
          {/* ... Additional form content ... */}
        </CardBody>
      </Card>
      <Spacer></Spacer>

    </Flex>
  );
}

export default PlantAdminUpdate;
