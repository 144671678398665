import React, { useEffect, useState,useRef } from 'react';
import { Flex, InputGroup, Spacer,Button, Table, Tbody, Text, Td, Th, Thead, Tr, useColorModeValue, InputLeftElement, IconButton, Input, Icon, Link } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { subDepartmentGetAll, subDepartmentAddOrUpdate, subDepartmentDelete, subDepartmentClearShowSnackbar } from "../../actions/subDepartmentAction";
import { useToast } from '@chakra-ui/react';
import { SUBDEPARTMENT_CLEAR_SNACKBAR } from "constants/types";
import SubDepartmentTableRow from './SubDepartmentTableRow';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
// import SubDepartmentPagination from './SubDepartmentPagination';
import { AddIcon } from '@chakra-ui/icons';
import SubDepartmentPagination from './SubDepartmentPagination';
import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom';
import { DownloadTableExcel } from 'react-export-table-to-excel';

const SubDepartmentTable = (props) => {
  const { url = "", plant } = props;
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const responseStatus = useSelector(state => state.subDepartment.status);
  const responseMessage = useSelector(state => state.subDepartment.text);
  const loading = useSelector(state => state.subDepartment.loading);
  const subDepartmentList = useSelector(state => state.subDepartment.subDepartmentList);
  const response = useSelector(state => state.subDepartment.response);
  const showSnackbar = useSelector(state => state.subDepartment.showSnackbar);
  const dispatch = useDispatch();
  const toast = useToast();
  const history = useHistory();
  const searchIconColor = useColorModeValue("gray.700", "gray.200");
  const inputBg = useColorModeValue("#edf3f8", "navy.800");
  const [search, setSearch] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const tableRef = useRef(null);

  useEffect(() => {
    dispatch(subDepartmentGetAll("&plant=" + plant));
  }, [dispatch]);

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: SUBDEPARTMENT_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("desc");
    }
    const sorting = "&sortOrder=" + sortOrder + "&column=" + column;
    dispatch(subDepartmentGetAll(sorting));
  };

  useEffect(() => {
    const searching = "&search=" + search+"&plant=" + plant;
    dispatch(subDepartmentGetAll(searching));
  }, [search]);
  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');
      });
  }, [])
  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <Flex>
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Sub Departments
            </Text>
          </CardHeader>
          <Spacer />
          <Flex justify="flex-end" style={{ padding: '10px', marginTop: '-5px', marginRight: '12px' }}>
            {url == "" ? <></> : <Link href={url}>
              <IconButton
                colorScheme="blue"
                aria-label="Search database"
                icon={<AddIcon />}
              />
            </Link>}
          </Flex>
          <Flex justify="flex-end" style={{ padding: '10px', marginTop: '-5px', marginRight: '12px' }}>
            <DownloadTableExcel
              filename="Sub Department Table"
              sheet="users"
              currentTableRef={tableRef.current}
            >
              <Button colorScheme="blue" _hover={{ backgroundColor: 'black', color: 'white' }}>
                Export Excel
              </Button>
            </DownloadTableExcel>
          </Flex>
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              <InputGroup borderRadius="8px" w="200px">
                <InputLeftElement
                  children={
                    <IconButton
                      bg="inherit"
                      borderRadius="inherit"
                      _hover="none"
                      _active={{
                        bg: "inherit",
                        transform: "none",
                        borderColor: "transparent",
                      }}
                      _focus={{
                        boxShadow: "none",
                      }}
                      icon={
                        <SearchIcon
                          color={searchIconColor}
                          w="15px"
                          h="15px"
                        />
                      }
                    ></IconButton>
                  }
                />
                <Input
                  variant="search"
                  fontSize="xs"
                  bg={inputBg}
                  onKeyUp={(e) => {
                    setSearch(e.target.value);
                    if (e.target.value.length < 3) {
                      setSearch("");
                    }
                  }}
                  placeholder="Type here..."
                />
              </InputGroup>
            </Text>
          </CardHeader>
        </Flex>
        <CardBody pb={4}>
          <div style={{ overflowX: "auto" }}>

          

            <Table variant="simple" color={textColor} ref={tableRef}>
              <Thead>
                <Tr my=".8rem" pl="0px" color="gray.400">
                <Th
                    pl="15px"
                    borderColor={borderColor}
                    color="gray.400"
                    cursor="pointer"
                  >
                   SNO
                  </Th>
                  <Th
                    pl="0px"
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("name")}
                    cursor="pointer"
                  >
                    department 
                    {sortColumn === "name" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    pl="0px"
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("name")}
                    cursor="pointer"
                  >
                    name
                    {sortColumn === "name" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>

                  <Th
                    pl="0px"
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("name")}
                    cursor="pointer"
                  >
                    description
                    {sortColumn === "name" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("status")}
                    cursor="pointer"
                  >
                    Status
                    {sortColumn === "status" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>

                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("status")}
                    cursor="pointer"
                  >
                    Plant Admin 
                    {sortColumn === "status" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  
                  <Th borderColor={borderColor}></Th>
                </Tr>
              </Thead>
              <Tbody>
                {subDepartmentList.map((row, index, arr) => (
                  <SubDepartmentTableRow
                    serialnumber={((response.currentPage - 1)*response.limit) +( index + 1)}
                    departmentId={row.departmentId?.name}
                    name={row.name}
                    description={row.description}
                    status={row.status ? "Active" : "Inactive"}
                    adminId={row.plant.plant[0].plantName}
                    isLast={index === arr.length - 1 ? true : false}
                    id={row._id}
                  //   key={row._id}
                  />
                ))}
              </Tbody>
            </Table>
          </div>
          {subDepartmentList.length === 0 ? (
            <></>
          ) : (
            <SubDepartmentPagination
              limit = {response.limit}
              totalPages={response.totalPages}
              currentPage={response.currentPage}
              perPage={response.perPage}
              sortBy={sortColumn}
              sortOrder={sortOrder}
              handlePageClick={(data) => {
                const selectedPage = data.selected + 1;
                dispatch(subDepartmentGetAll(`&page=${selectedPage}`));
              }}
            />
          )}
        </CardBody>
      </Card>
    </Flex>
  );
};

export default SubDepartmentTable;
