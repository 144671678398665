import React, { useState } from "react";
import {
  chakra,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  HStack,
  Flex,
} from "@chakra-ui/react";
import {
  IoIosArrowBack,
  IoIosArrowForward,
} from "react-icons/io";
import { HiDotsHorizontal } from "react-icons/hi";
import { ArrowLeftIcon, ArrowRightIcon, ChevronDownIcon } from "@chakra-ui/icons";

import { useDispatch, useSelector } from 'react-redux';
import { designationGetAll, designationClearShowSnackbar, designationAddOrUpdate } from "actions/designationAction";
import { useToast } from '@chakra-ui/react';
import { DESIGNATION_CLEAR_SNACKBAR } from "constants/types";

export default function DesignationPagination(props) {
  const { totalPages, currentPage } = props;
  const designationList = useSelector(state => state.designation.designationList);
  const response = useSelector(state => state.designation.response);
  const showSnackbar = useSelector(state => state.designation.showSnackbar);
  const dispatch = useDispatch();
  const toast = useToast();

  const [pageLimit, setPageLimit] = useState(10);

  const PagButton = ({ active, children, onClick }) => {
    const activeStyle = {
      bg: "brand.600",
      _dark: { bg: "brand.500" },
      color: "red",
    };

    return (
      <chakra.button
        mx={1}
        px={4}
        py={2}
        rounded="md"
        bg="white"
        _dark={{ bg: "gray.800" }}
        color="gray.700"
        opacity={props.disabled && 0.6}
        _hover={!props.disabled && activeStyle}
        cursor={props.disabled && "not-allowed"}
        {...(active && activeStyle)}
        onClick={onClick}
      >
        {children}
      </chakra.button>
    );
  };

  const MButton = ({ right }) => {
    const DoubleArrow = right ? ArrowRightIcon : ArrowLeftIcon;
    const [hovered, setHovered] = useState(false);

    return (
      <chakra.a
        w={4}
        py={2}
        color="gray.700"
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        cursor="pointer"
        textAlign="center"
      >
        {hovered ? (
          <Icon
            as={DoubleArrow}
            boxSize={3}
            cursor="pointer"
          />
        ) : (
          <Icon
            as={HiDotsHorizontal}
            boxSize={4}
            opacity={0.5}
          />
        )}
      </chakra.a>
    );
  };

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) {
      return;
    }
    const pagination = "page=" + page + "&limit=10";
    dispatch(designationGetAll(pagination));
    console.log("Page changed to:", page);
  };

  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === totalPages;

  const renderPageButtons = () => {
    const pageButtons = [];
    const maxDisplayedPages = 10; // Set your desired maximum number of displayed pages
    const ellipsisThreshold = 5; // Set a threshold for displaying ellipsis
  
    if (totalPages <= maxDisplayedPages) {
      // Display all pages if the total number of pages is within the limit
      for (let page = 1; page <= totalPages; page++) {
        pageButtons.push(
          <PagButton
            key={page}
            active={currentPage === page}
            onClick={() => handlePageChange(page)}
          >
            {page}
          </PagButton>
        );
      }
    } else {
      // Display pages with ellipsis for large datasets
      const showEllipsisBefore = currentPage > ellipsisThreshold + 1;
      const showEllipsisAfter = currentPage < totalPages - ellipsisThreshold;
  
      let startPage = 1;
      let endPage = totalPages;
  
      if (showEllipsisBefore) {
        startPage = currentPage - ellipsisThreshold;
      }
  
      if (showEllipsisAfter) {
        endPage = currentPage + ellipsisThreshold;
      }
  
      for (let page = startPage; page <= endPage; page++) {
        if ((showEllipsisBefore && page === startPage + 1) || (showEllipsisAfter && page === endPage - 1)) {
          // Display ellipsis
          pageButtons.push(
            <chakra.button
              key={page}
              mx={1}
              px={4}
              py={2}
              rounded="md"
              bg="white"
              _dark={{ bg: "gray.800" }}
              color="gray.700"
              opacity={0.6}
              cursor="not-allowed"
            >
              ...
            </chakra.button>
          );
        } else {
          // Display page button
          pageButtons.push(
            <PagButton
              key={page}
              active={currentPage === page}
              onClick={() => handlePageChange(page)}
            >
              {page}
            </PagButton>
          );
        }
      }
    }
  
    return pageButtons;
  };

  const handleLimit = () => {
    const pagination = "page=1" + "&limit=" + pageLimit;
    dispatch(designationGetAll(pagination));
    console.log("Page changed to:", pageLimit);
  };

  return (
    <Flex
      bg="#edf3f8"
      pb={2}
      pt={2}
      w="full"
      alignItems="center"
      justifyContent="center"
    >
      <HStack>
        <PagButton disabled={isFirstPage} onClick={() => handlePageChange(currentPage - 1)}>
          <Icon as={IoIosArrowBack} color="gray.700" boxSize={4} />
        </PagButton>
        {renderPageButtons()}
        <PagButton disabled={isLastPage} onClick={() => handlePageChange(currentPage + 1)}>
          <Icon as={IoIosArrowForward} color="gray.700" boxSize={4} />
        </PagButton>
        <Menu>
          <MenuButton ml={1} as={Button} rightIcon={<ChevronDownIcon />}>
            {pageLimit} / page
          </MenuButton>
          <MenuList>
            <MenuItem onClick={() => {
              setPageLimit(20);
              handleLimit();
            }}>20 / page</MenuItem>
            <MenuItem onClick={() => {
              setPageLimit(30);
              handleLimit();
            }}>30 / page</MenuItem>
            <MenuItem onClick={() => {
              setPageLimit(40);
              handleLimit();
            }}>40 / page</MenuItem>
            <MenuItem onClick={() => {
              setPageLimit(50);
              handleLimit();
            }}>50 / page</MenuItem>
          </MenuList>
        </Menu>
      </HStack>
    </Flex>
  );
}
