import axios from 'axios';
import {
  EMPLOYEEMODULE_LOADING,
  EMPLOYEEMODULE_GET_ALL_SUCCESS,
  EMPLOYEEMODULE_ERROR,
  EMPLOYEEMODULE_CLEAR_SNACKBAR
} from '../constants/types';

import {
  server_url
} from '../constants/types';

export const employeemoduleGetAll = (pagination = "") => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: EMPLOYEEMODULE_LOADING,
        payload: {
          loading: true
        }
      });

      axios.get(`${server_url}/employeeModules?${pagination}`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      })
      .then(response => {
        dispatch({
          type: EMPLOYEEMODULE_GET_ALL_SUCCESS,
          payload: {
            employeemoduleList: response.data.data,
            response: response.data,
            loading: false,
            status: "success",
            text: "Get all employeemodule data successfully."
          }
        });
        resolve();
      })
      .catch((e) => {
        dispatch({
          type: EMPLOYEEMODULE_ERROR,
          payload: {
            status: "error",
            text: "Error occurred during getting employeemodule data.",
            loading: false
          }
        });
        resolve();
      });
    });
  };
}

export const employeemoduleAddOrUpdate = (employeemodule, state) => {
    return (dispatch, getState) => {
      return new Promise((resolve) => {
        dispatch({
          type: EMPLOYEEMODULE_LOADING,
          payload: {
            loading: true
          }
        });
  
        if (state === "add") {
          axios.post(`${server_url}/employeeModule`, employeemodule, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
          })
          .then(responseF => {
            axios.get(`${server_url}/employeeModules`, {
              headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
              }
            })
            .then(response => {
              dispatch({
                type: EMPLOYEEMODULE_GET_ALL_SUCCESS,
                payload: {
                  employeemoduleList: response.data.data,
                  response: response.data,
                  loading: false,
                  status: responseF.data['status'],
                  text: responseF.data['message']
                }
              });
              resolve();
            })
            .catch((e) => {
              dispatch({
                type: EMPLOYEEMODULE_ERROR,
                payload: {
                  text: "Error occurred during getting employeemodule data.",
                  status: "error",
                  loading: false
                }
              });
              resolve();
            });
          })
          .catch((e) => {
            dispatch({
              type: EMPLOYEEMODULE_ERROR,
              payload: {
                text: "Error occurred during adding employeemodule data.",
                status: "error",
                loading: false
              }
            });
            resolve();
          });
        } else if (state === "edit") {
          axios.put(`${server_url}/employeeModule/${employeemodule._id}`, employeemodule, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
          })
          .then(responseF => {
            axios.get(`${server_url}/employeeModules`, {
              headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
              }
            })
            .then(response => {
              dispatch({
                type: EMPLOYEEMODULE_GET_ALL_SUCCESS,
                payload: {
                  employeemoduleList: response.data.data,
                  response: response.data,
                  employeemoduleDetail: responseF.data['data'],
                  loading: false,
                  status: responseF.data['status'],
                  text: responseF.data['message']
                }
              });
              resolve();
            })
            .catch((e) => {
              dispatch({
                type: EMPLOYEEMODULE_ERROR,
                payload: {
                  text: "Error occurred during getting employeemodule data.",
                  status: "error",
                  loading: false
                }
              });
              resolve();
            });
          })
          .catch((e) => {
            dispatch({
              type: EMPLOYEEMODULE_ERROR,
              payload: {
                text: "Error occurred during updating employeemodule data.",
                status: "error",
                loading: false
              }
            });
            resolve();
          });
        } else if (state === "view") {
          axios.get(`${server_url}/employeeModules/${employeemodule._id}`, {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
          })
          .then(responseF => {
            axios.get(`${server_url}/employeeModules`, {
              headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
              }
            })
            .then(response => {
              dispatch({
                type: EMPLOYEEMODULE_GET_ALL_SUCCESS,
                payload: {
                  employeemoduleList: response.data.data,
                  response: response.data,
                  employeemoduleDetail: responseF.data['data'],
                  loading: false,
                  status: responseF.data.status,
                  text: responseF.data.message,
                }
              });
              resolve();
            })
            .catch((e) => {
              dispatch({
                type: EMPLOYEEMODULE_ERROR,
                payload: {
                  text: "Error occurred during getting employeemodule data.",
                  status: "error",
                  loading: false
                }
              });
              resolve();
            });
          })
          .catch((e) => {
            dispatch({
              type: EMPLOYEEMODULE_ERROR,
              payload: {
                text: "Error occurred during getting employeemodule data.",
                status: "error",
                loading: false
              }
            });
            resolve();
          });
        }
      });
    };
  }
  

  export const employeemoduleDelete = (employeemodule) => {
    return (dispatch, getState) => {
      return new Promise((resolve) => {
        dispatch({
          type: EMPLOYEEMODULE_LOADING,
          payload: {
            loading: true
          }
        });
  
        axios.delete(`${server_url}/employeeModule/${employeemodule._id}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
        .then(responseF => {
          axios.get(`${server_url}/employeeModules`, {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
          })
          .then(response => {
            dispatch({
              type: EMPLOYEEMODULE_GET_ALL_SUCCESS,
              payload: {
                employeemoduleList: response.data.data,
                response: response.data,
                employeemoduleDetail: responseF.data['data'],
                loading: false,
                status: responseF.data['status'],
                text: responseF.data['message']
              }
            });
            resolve();
          })
          .catch((e) => {
            dispatch({
              type: EMPLOYEEMODULE_ERROR,
              payload: {
                text: "Error occurred during getting employeemodule data.",
                status: "error",
                loading: false
              }
            });
            resolve();
          });
        })
        .catch((e) => {
          dispatch({
            type: EMPLOYEEMODULE_ERROR,
            payload: {
              text: "Error occurred during deleting employeemodule data.",
              status: "error",
              loading: false
            }
          });
          resolve();
        });
      });
    };
  }
  

export const employeemoduleClearShowSnackbar = () => {
  return (dispatch, getState) => {
    dispatch({
      type: EMPLOYEEMODULE_CLEAR_SNACKBAR
    });
  };
}
