// Chakra imports
import {
    Box,
    Button,
    Center,
    Flex,
    Image,
    Input,
    SimpleGrid,
    Spacer,
    Stack,
    Text,
    Wrap,
    WrapItem,
    useColorModeValue
  } from "@chakra-ui/react";
  // Custom components
  import Card from "components/Card/Card.js";
  import CardBody from "components/Card/CardBody.js";
  import CardHeader from "components/Card/CardHeader.js";
  import React, { useEffect, useState } from "react";
  import { useDispatch, useSelector } from 'react-redux';

  import { useToast } from '@chakra-ui/react'
  import { useParams } from "react-router-dom/cjs/react-router-dom.min";
  import { CANTEENINOUT_CLEAR_SNACKBAR } from "constants/types";
  import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
  } from '@chakra-ui/react'
  import { Skeleton, SkeletonCircle, SkeletonText } from '@chakra-ui/react'
import { canteenGetById } from "actions/canteenInOutAction";
  
  function CanteenInOutView() {
    const textColor = useColorModeValue("gray.700", "white");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    const [vehicleNumber, setVehicleNumber] = useState("");
    const [mobile , setMobile] = useState("");
    const [inDateTime, setInDateTime] = useState("");
    const [outDateTime, setOutDateTime] = useState("");
    const [driverName, setDriverName] = useState('');
    const [driverLicence, setDriverLicence]= useState('');
    const [photo , setPhoto] = useState('');
    const [driverLicenceExpiryDate , setDriverLicenceExpiryDate] = useState('');
    const[canteenCategory , setCanteenCategory] = useState("");
    const [guard , setGuard]= useState("");

   
    const dispatch = useDispatch();
    const toast = useToast();
  
    const statuses = ['success', 'error', 'warning', 'info'];
    const responseStatus = useSelector(state => state.canteenInOut.status);
    const responseMessage = useSelector(state => state.canteenInOut.text);
    const loading = useSelector(state => state.canteenInOut.loading);
    const CanteenInOutDetails = useSelector(state => state.canteenInOut.canteenInOutDetail);
    const showSnackbar = useSelector(state => state.canteenInOut.showSnackbar);
  
    const params = useParams();
    useEffect(() => {
      if (showSnackbar) {
        if (responseStatus === 'OK' && loading === false) {
          console.log(CanteenInOutDetails);
          setMobile(CanteenInOutDetails.mobileNo);
          setPhoto(CanteenInOutDetails.image);
          setDriverName(CanteenInOutDetails.driverName);
          setVehicleNumber(CanteenInOutDetails.vehicleNo);
          setInDateTime(CanteenInOutDetails.inDateTime);
          setOutDateTime(CanteenInOutDetails.outDateTime);
          setDriverLicence(CanteenInOutDetails.drivingLicence);
          setDriverLicenceExpiryDate(CanteenInOutDetails.drivingLicenceExpiryDate);
          setCanteenCategory(CanteenInOutDetails.canteenCategory.name);
          // setGuard(CanteenInOutDetails.guard);
          
         
            
          if (responseMessage !== "") {
            toastFunction(responseMessage, 'success');
          }
        } else if (responseStatus === 'NOK' && loading === false) {
          if (responseMessage !== "") {
            toastFunction(responseMessage, 'error');
          }
        }
      }
  
      dispatch({
        type: CANTEENINOUT_CLEAR_SNACKBAR,
        payload: {
          showSnackbar: false,
        }
      })
    }, [loading]);
  
    
    const toastFunction = (title, status) => {
      toast({
        title: title,
        status: status,
        duration: 3000,
        isClosable: true,
      })
    };


    useEffect(() => {
      console.log("params");
      console.log(params);
      const canteenId = params.id;
      const canteen = {
        _id: canteenId,
      };
      dispatch(canteenGetById(canteen._id));
    }, [dispatch]);
  
    console.log("CanteenInOutDetails")
    console.log(CanteenInOutDetails)
  
    return (
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              View Form
            </Text>
          </CardHeader>
          <CardBody>
            {loading ? <Stack>
              <Skeleton height='20px' />
              <Skeleton height='20px' />
              <Skeleton height='20px' />
            </Stack>
              :
              <TableContainer>
                <Table variant='simple'>
                  <Tbody>
                    <Tr>
                      <Td>Driver Name</Td>
                      <Td>{driverName}</Td>
                    </Tr>

                    <Tr>
                      <Td>Driver Photo</Td>
                      <Td><Flex direction="column">
                        <Text fontSize="md" color={textColor} fontWeight="bold">
                        <Image
                            boxSize='100px'
                            objectFit='cover'
                            src={photo}
                            alt='Dan Abramov'
                          />
                          
                        </Text>
                      </Flex>
                      </Td>
                    </Tr>
                   
                    
                    <Tr>
                      <Td>Mobile Number</Td>
                      <Td>{mobile}</Td>
                    </Tr>
                    <Tr>
                      <Td>Vechile Number</Td>
                      <Td>{vehicleNumber}</Td>
                    </Tr>
                    <Tr>
                      <Td>Driver Licence</Td>
                      <Td>{driverLicence}</Td>
                    </Tr>
                    <Tr>
                      <Td>Driver Licence ExpiryDate</Td>
                      <Td>{driverLicenceExpiryDate}</Td>
                    </Tr>
                    <Tr>
                      <Td>In DateTime</Td>
                      <Td>{inDateTime}</Td>
                    </Tr>
                   
                    <Tr>
                      <Td>Out DateTime</Td>
                      <Td>{outDateTime}</Td>
                    </Tr>

                    <Tr>
                      <Td>CanteenCategory</Td>
                      <Td>{canteenCategory}</Td>
                    </Tr>
                  
                    
                  
                  </Tbody>
                </Table>
             </TableContainer>}
          </CardBody>
        </Card>
      </Flex>
    );
  }
  
  export default CanteenInOutView;
