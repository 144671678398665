import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  SimpleGrid,
  Spacer,
  Text,
  Wrap,
  WrapItem,
  useColorModeValue,
  Spinner,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {emergencyContactAddOrUpdate} from "../../actions/EmergencyContactAction"
import Card from "components/Card/Card";
import dataConstant from 'constants/dataConstant';
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader";
import { useToast } from '@chakra-ui/react'
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
} from '@chakra-ui/react'
import Select from 'react-select';
import axios from "axios";
import { server_url } from "constants/types";
import { EMERGENCY_CONTACT_CLEAR_SNACKBAR } from "constants/types";
import CustomInput from "views/CustomValidation";
import { useHistory } from 'react-router-dom';

function EmergencyContactForm() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [primaryContact, setPrimaryContact] = useState("");
  const [secondaryContact, setSecondaryContact] = useState("");
  const [status, setStatus] = useState("");

  const [submitted, setSubmitted] = useState(false);
  const history = useHistory();
  const nameRegex = /^[A-Za-z\s]+$/;
  const descriptionRegex = /^[A-Za-z\s]+$/;
  const primaryContactRegex = /^\d{10}$/;
  const secondaryContactRegex = /^\d{10}$/;

  const [isErrorName, setIsErrorName] = useState(false);
  const [isErrorDescription, setIsErrorDescription] = useState(false);
  const [isErrorPrimaryContact, setIsErrorPrimaryContact] = useState(false);
  const [isErrorSecondaryContact, setIsErrorSecondaryContact] = useState(false);
  const [isErrorStatus, setIsErrorStatus] = useState(false);
  const statuses = ['success', 'error', 'warning', 'info'];
  const responseStatus = useSelector(state => state.emergencyContact.status);
  const responseMessage = useSelector(state => state.emergencyContact.text);
  const loading = useSelector(state => state.emergencyContact.loading);
  const showSnackbar = useSelector(state => state.emergencyContact.showSnackbar);


  console.log(loading ? "yes" : "no")

  const dispatch = useDispatch();
  const toast = useToast();

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);

    setIsErrorName(name === '' || !nameRegex.test(name));
    setIsErrorDescription(description === '' || !descriptionRegex.test(description));
    setIsErrorPrimaryContact(primaryContact === '' || !primaryContactRegex.test(primaryContact));
    setSecondaryContact(secondaryContact === '' || !secondaryContactRegex.test(secondaryContact));
    setStatus(status === '');
    if (
      name !== "" &&
      description !== "" &&
      primaryContact !== "" &&
      secondaryContact !== "" &&
      status !== "" 
    ) {
      
      const emergencyContact = {
        name : name,
        description : description,
        primaryContactNo : primaryContact,
        secondaryContactNo : secondaryContact,
        status : status,
      };
    //   console.log(emergencyContact)
      dispatch(emergencyContactAddOrUpdate(emergencyContact, 'add'));
    }
  };

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        //   history.push('#/admin/gaurd-table');
        }
      } else if (responseStatus === 'NOK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: EMERGENCY_CONTACT_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <form onSubmit={handleSubmit}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">

          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
            Emergency Contact Form
            </Text>
            <SimpleGrid minChildWidth='120px' spacing='40px'>
              <FormControl isInvalid={isErrorName || (submitted && name === "")}>
                <CustomInput
                  type='text'
                  label='Name'
                  value={name}
                  onChange={(value) => {
                    setIsErrorName(value === '' || !nameRegex.test(value));
                    setName(value);
                  }}
                  isInvalid={isErrorName || (submitted && name === "")}
                  regex={nameRegex}
                  submitted={submitted}
                />
              </FormControl>
              <FormControl isInvalid={isErrorDescription || (submitted && description === "")}>
                <CustomInput
                  type='text'
                  label='Description'
                  value={description}
                  onChange={(value) => {
                    setIsErrorDescription(value === '' || !descriptionRegex.test(value));
                    setDescription(value);
                  }}
                  isInvalid={isErrorDescription || (submitted && description === "")}
                  regex={descriptionRegex}
                  submitted={submitted}
                />
              </FormControl>
            </SimpleGrid>

            <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
              <FormControl isInvalid={isErrorPrimaryContact || (submitted && primaryContact === "")}>
                <CustomInput
                  type="number"
                  label="Primary Contact Number"
                  value={primaryContact}
                  onChange={(value) => {
                    setIsErrorPrimaryContact(value === '' || !primaryContactRegex.test(value));
                    setPrimaryContact(value);
                  }}
                  isInvalid={isErrorPrimaryContact || (submitted && primaryContact === "")}
                  regex={primaryContactRegex}
                  submitted={submitted}
                />
              </FormControl>
              <FormControl isInvalid={isErrorSecondaryContact || (submitted && secondaryContact === "")}>
                <CustomInput
                  type="number"
                  label="Secondary Contact Number"
                  value={secondaryContact}
                  onChange={(value) => {
                    setIsErrorSecondaryContact(value === '' || !secondaryContactRegex.test(value));
                    setSecondaryContact(value);
                  }}
                  isInvalid={isErrorSecondaryContact || (submitted && secondaryContact === "")}
                  regex={secondaryContactRegex}
                  submitted={submitted}
                />
              </FormControl>

              <FormControl isInvalid={isErrorStatus}>
                <FormLabel>Status</FormLabel>
                <Select
                  placeholder="Select status"
                  onChange={(e) => {
                    setIsErrorStatus(e.target.value === '')
                    setStatus(e.target.value)
                  }}
                >
                  { dataConstant.status.map((element,index) => {
                      return (<option key={element['id']} value={element['value']} >{element['name']}</option>);   
                        
                    })}
                </Select>
                {!isErrorStatus ? (
                  <></>
                ) : (
                  <FormErrorMessage>Status is required.</FormErrorMessage>
                )}
              </FormControl>
            </SimpleGrid>

            <Center>
              {loading ? <Spinner mt='24px' size='lg' /> :
                <Button colorScheme='blue' mt='24px' type="submit">
                  Submit
                </Button>
              }
            </Center>
          </CardHeader>
          <CardBody>
            {/* ... */}
          </CardBody>
        </Card>
      </form>
    </Flex>
  );
}

export default EmergencyContactForm;
