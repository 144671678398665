// Chakra imports
import {
  Flex,
  InputGroup,
  Spacer,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  InputLeftElement,
  IconButton,
  Input,
  Icon,
  Link,
  Button
} from "@chakra-ui/react";
import { AddIcon, SearchIcon } from "@chakra-ui/icons";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

import React, { useEffect, useState,useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { checkpostGetAll } from '../../actions/checkpostActions';
import { useToast } from '@chakra-ui/react';
import { CHECKPOST_CLEAR_SNACKBAR } from "constants/types";
import CheckpostTableRow from "./CheckpostTableRow";
import Pagination from "./Pagination";
import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom';
import { DownloadTableExcel } from 'react-export-table-to-excel';
function CheckpostTable(props) {
  const { url = "", plant } = props;
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const responseStatus = useSelector(state => state.checkpost.status);
  const responseMessage = useSelector(state => state.checkpost.text);
  const loading = useSelector(state => state.checkpost.loading);
  const checkpostList = useSelector(state => state.checkpost.checkpostList);
  const response = useSelector(state => state.checkpost.response);
  const showSnackbar = useSelector(state => state.checkpost.showSnackbar);
  const dispatch = useDispatch();
  const toast = useToast();
  const searchIconColor = useColorModeValue("gray.700", "gray.200");
  const inputBg = useColorModeValue("#edf3f8", "navy.800");
  const [search, setSearch] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const tableRef = useRef(null);
  useEffect(() => {
    dispatch(checkpostGetAll("&plant=" + plant));
  }, []);

  console.log("checkpostList")
  console.log(checkpostList)
  const history = useHistory();
  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: CHECKPOST_CLEAR_SNACKBAR,
    });
  }, [loading]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  };

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("desc");
    }
    const sorting = `&sortOrder=${sortOrder}&column=${column}`;
    dispatch(checkpostGetAll(sorting));
  };

  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])
  useEffect(() => {
    const searching = `&search=${search}` + "&plant=" + plant;
    dispatch(checkpostGetAll(searching));
  }, [search]);

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <Flex>
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Checkposts
            </Text>
          </CardHeader>
          <Spacer />
          <Flex justify="flex-end" style={{ padding: '10px', marginTop: '-5px', marginRight: '12px' }}>
            {url == "" ? <></> : <Link href={url}>
              <IconButton
                colorScheme="blue"
                aria-label="Search database"
                icon={<AddIcon />}
              />
            </Link>}
          </Flex>
          <Flex justify="flex-end" style={{ padding: '10px', marginTop: '-5px', marginRight: '12px' }}>
            <DownloadTableExcel
              filename="Checkpost Table"
              sheet="users"
              currentTableRef={tableRef.current}
            >
              <Button colorScheme="blue" _hover={{ backgroundColor: 'black', color: 'white' }}>
                Export Excel
              </Button>
            </DownloadTableExcel>
          </Flex>
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              <InputGroup borderRadius="8px" w="200px">
                <InputLeftElement
                  children={
                    <IconButton
                      bg="inherit"
                      borderRadius="inherit"
                      _hover="none"
                      _active={{
                        bg: "inherit",
                        transform: "none",
                        borderColor: "transparent",
                      }}
                      _focus={{
                        boxShadow: "none",
                      }}
                      icon={
                        <SearchIcon color={searchIconColor} w="15px" h="15px" />
                      }
                    />
                  }
                />
                <Input
                  variant="search"
                  fontSize="xs"
                  bg={inputBg}
                  onKeyUp={(e) => {
                    setSearch(e.target.value);
                    if (e.target.value.length < 3) {
                      setSearch("");
                    }
                  }}
                  placeholder="Type here..."
                />
              </InputGroup>
            </Text>
          </CardHeader>
        </Flex>
        <CardBody pb={4}>
          <div style={{ overflowX: "auto" }}>
            
            <Table variant="simple" color={textColor} ref={tableRef}>
              <Thead>
                <Tr my=".8rem" pl="0px" color="gray.400">
                  <Th
                    pl="15px"
                    borderColor={borderColor}
                    color="gray.400"
                    cursor="pointer"
                  >
                   SNO
                  </Th>
                  <Th
                    pl="0px"
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("name")}
                    cursor="pointer"
                  >
                    Checkpost
                    {sortColumn === "name" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("description")}
                    cursor="pointer"
                  >
                    Description
                    {sortColumn === "description" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("status")}
                    cursor="pointer"
                  >
                    Status
                    {sortColumn === "status" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("adminId")}
                    cursor="pointer"
                  >
                    Plant Admin
                    {sortColumn === "adminId" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th borderColor={borderColor}></Th>
                </Tr>
              </Thead>
              <Tbody>
                {checkpostList.map((checkpost, index, arr) => (
                  <CheckpostTableRow
                  serialnumber={response.currentPage - 1 + index + 1}
                    name={checkpost.name}
                    description={checkpost.description}
                    status={checkpost.status}
                    adminId={checkpost.plant.plant[0].plantName}
                    isLast={index === arr.length - 1 ? true : false}
                    id={checkpost._id}
                  />
                ))
               }
              </Tbody>
            </Table>
          </div>
          <Pagination
            totalPages={response.totalPages}
            currentPage={response.currentPage}
          />
        </CardBody>
      </Card>
    </Flex>
  );
}

export default CheckpostTable;
