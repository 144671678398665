// Chakra imports
import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  Link,
  ListItem,
  OrderedList,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  Wrap,
  IconButton,
  WrapItem,
  useColorModeValue
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import React, { useEffect, useState ,useRef } from "react";
import { tablesProjectData, tablesTableData } from "variables/general";
import { useDispatch, useSelector } from 'react-redux';
import { AiFillEdit } from "react-icons/ai";
//   import { roleAddOrUpdate, } from '../../actions/roleActions';
import { useToast } from '@chakra-ui/react'
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { PARKING_CLEAR_SNACKBAR } from "constants/types";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react'
import { Skeleton, SkeletonCircle, SkeletonText } from '@chakra-ui/react'
import { plantadminAddOrUpdate } from "actions/plantAction";
import CheckpostTable from "views/Checkpost/CheckpostTable";
import DepartmentTable from "views/Department/DepartmentTable";
import SubDepartmentTable from "views/subDepartment/SubDepartmentTable";
import ParkingDepartmentTable from "views/ParkingDepartment/ParkingDepartmentTable";
import DevicesTable from "views/Devices/DevicesTable";
import DesignationsTable from "views/Designation/DesignationTable";
import ParkingTable from "views/Parking/ParkingTable";
import PatrollingRouteTable from "views/PatrollingRoute/PatrollingRouteTable";
import FloorTable from "views/Floor/FloorTable";
import ScheduleHighAlertTable from "views/ScheduleHighAlert/ScheduleHighAlertTable";
import PurposeTable from "views/Purpose/PurposeTable";

import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom';
import HighAlertTable from "views/highAlert/HighAlertTable";
import DelivaryTable from "views/Delivary/DelivaryTable";
function PlantAdminView() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  // const [allPermission,setAllPermission]= useState("");
  // const [permissions,setPermissions]= useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("");

  const [availability, setAvailability] = useState("");
  const [parkingdepartment, setParkingdepartment] = useState("");
  const [selectedPermissions, setSelectedPermissions] = useState([]);





  const history = useHistory();
  const dispatch = useDispatch();
  const toast = useToast();

  const statuses = ['success', 'error', 'warning', 'info'];
  const responseStatus = useSelector(state => state.plantadmin.status);
  const responseMessage = useSelector(state => state.plantadmin.text);
  const loading = useSelector(state => state.plantadmin.loading);
  const plantAdminDetail = useSelector(state => state.plantadmin.plantDetail);
  const showSnackbar = useSelector(state => state.plantadmin.showSnackbar);

  const params = useParams();

  console.log("plantAdminDetail")
  console.log(plantAdminDetail)
  const [contactPersonName, setContactPersonName] = useState("");
  const [officalEmail, setOfficialEmail] = useState("");
  const [personalEmail, setPersonalEmail] = useState("");
  const [passcode, setPasscode] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [alternateNo, setAlternateNo] = useState("");
  const [timeOne, setTime1] = useState("");
  const [timeTwo, setTime2] = useState("");
  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus == 'OK' && loading == false) {
        //   console.log(subParkingDetail);

        setName(plantAdminDetail.plant[0]['plantName']);
        setContactPersonName(plantAdminDetail.name);
        setOfficialEmail(plantAdminDetail.plant[0]['officalEmail']);
        setPersonalEmail(plantAdminDetail.email);
        setContactNo(plantAdminDetail.contactNo);
        setAlternateNo(plantAdminDetail.plant[0]['alternateNo']);
        setPasscode(plantAdminDetail.passcode);
        setTime1(plantAdminDetail.longstayTime1)
        setTime2(plantAdminDetail.longstayTime2)


        setStatus(plantAdminDetail.status);
        setAvailability(plantAdminDetail.availability);
        setParkingdepartment(plantAdminDetail.parkingdepartment);
        if (responseMessage != "") {
          toastFunction(responseMessage, 'success');
        }

      } else if (responseStatus == 'NOK' && loading == false) {
        if (responseMessage != "") {
          toastFunction(responseMessage, 'error');
        }

      }
    }

    dispatch({
      type: PARKING_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);

  useEffect(() => {
    console.log(params);
    const state = 'view';
    // Call the roleAddOrUpdate action
    const parking = {
      _id: params.id,
    };
    console.log("parking----------------");
    console.log(parking._id);
    dispatch(plantadminAddOrUpdate(parking, state))
  }, []);

  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])
  
  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };
  const handleTimeOneChange = (event) => {
    setTime1(event.target.value);
  };

  const handleTimeOneChange1 = (event) => {
    setTime2(event.target.value);
  };

  const handleClick = (event) => {
    event.preventDefault();
   if(timeOne !== ""){
    const admin = {
      _id: params.id,
      longstayTime1: timeOne
    }
    const state = 'edit';
    dispatch(plantadminAddOrUpdate(admin, state));
   }
    
  };

  const handleClick1 = (event) => {
    event.preventDefault();
   if(timeTwo !== ""){
    const admin = {
      _id: params.id,
      longstayTime2: timeTwo
    }
    const state = 'edit';
    dispatch(plantadminAddOrUpdate(admin, state));
   }
    
  };
  const myCheckPost = useRef(null);
  const myDepartment = useRef(null);
  const mySubdepartment = useRef(null);
  const myDesignation = useRef(null);
  // const myParkingDepartment = useRef(null)
  const myFloorTable = useRef(null)
  const myPatrollingRoute = useRef(null)
  const myDeviceTable = useRef(null)
  const myScheduleHighAlertTable = useRef(null)
  const myParking = useRef(null)
  const myPurpose = useRef(null)
  const myDelivarypartner = useRef(null)

  const scrollToCheckpost = () => {
    myCheckPost.current.scrollIntoView({ behavior: 'smooth' });
  };
  const scrollToDepartment = () => {
    myDepartment.current.scrollIntoView({ behavior: 'smooth' });
  };
  const scrollToSubdepartment = () => {
    mySubdepartment.current.scrollIntoView({ behavior: 'smooth' });
  };
  const scrollToDesignation = () => {
    myDesignation.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToPurpose = () => {
    myPurpose.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToDelivarypartner = () => {
    myDelivarypartner.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToFloor = () => {
    myFloorTable.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToPatrollingRoute = () => {
    myPatrollingRoute.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToDeviceTable = () => {
    myDeviceTable.current.scrollIntoView({behavior : 'smooth'})
  }
  const scrollToScheduleHighAlert = () => {
    myScheduleHighAlertTable.current.scrollIntoView({behavior : 'smooth'})
  }
  const scrollToParking = () => {
    myParking.current.scrollIntoView({behavior : 'smooth'})
  }

  const buttonStyle = {
    size: "md",
    height: "48px",
    width: "200px",
    border: "2px",
    borderColor: "green.500",
  };




  return (
    <><Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Plant Admin View
          </Text>
        </CardHeader>
        <CardBody>
          {loading ? <Stack>
            <Skeleton height='20px' />
            <Skeleton height='20px' />
            <Skeleton height='20px' />
          </Stack>
            :
            <TableContainer>
              <Table variant='simple'>
                <Tbody>

                  <Tr>
                    <Td>Name</Td>
                    <Td>{name}</Td>
                  </Tr>
                  <Tr>
                    <Td>Contact Person Name</Td>
                    <Td>{contactPersonName}</Td>
                  </Tr>
                  <Tr>
                    <Td>Offical Email</Td>
                    <Td>{officalEmail}</Td>
                  </Tr>
                  <Tr>
                    <Td>Personal Email</Td>
                    <Td>{personalEmail}</Td>
                  </Tr>
                  <Tr>
                    <Td>Contact No</Td>
                    <Td>{contactNo}</Td>
                  </Tr>
                  <Tr>
                    <Td>Alternate Number</Td>
                    <Td>{alternateNo}</Td>
                  </Tr>
                  <Tr>
                    <Td>Passcode</Td>
                    <Td>{passcode}</Td>
                  </Tr>


                  <Tr>
                    <Td>Status</Td>
                    <Td>{status ? "Active" : "Inactive"}</Td>
                  </Tr>

                  <Tr>
                    <Td>Long Stay Time (Visitor/Delivery)/hr</Td>
                    <Td>
                      <Input type="text" w="80px" color="white" borderRadius="20px" padding="10px" textAlign="center" backgroundColor="teal" placeholder="Enter Long Stay Time" onChange={handleTimeOneChange} value={timeOne} />
                    </Td>
                    <Td>
                      <Flex align="center">
                        <Button type="submit" _hover={{ bgColor: 'black' }} backgroundColor="black" color="white" size="md" mr={2} onClick={handleClick}>
                          Submit
                        </Button>
                      </Flex>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Long Stay Time (Material)/hr</Td>
                    <Td>
                      <Input type="text" w="80px" color="white" borderRadius="20px" padding="10px" textAlign="center" backgroundColor="teal" placeholder="Enter Long Stay Time" onChange={handleTimeOneChange1} value={timeTwo} />
                    </Td>
                    <Td>
                      <Flex align="center">
                        <Button type="submit" _hover={{ bgColor: 'black' }} backgroundColor="black" color="white" size="md" mr={2} onClick={handleClick1}>
                          Submit
                        </Button>
                      </Flex>
                    </Td>
                  </Tr>






                </Tbody>

              </Table>
            </TableContainer>}
        </CardBody>
      </Card>
    </Flex>
    
    <Flex direction="column" pt={{ base: "10px", md: "75px" }}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
          {/* <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Plant Action
            </Text>
          </CardHeader> */}
          <CardBody>
            {loading ? <Stack>
              <Skeleton height='20px' />
              <Skeleton height='20px' />
              <Skeleton height='20px' />
            </Stack>
              :
              <TableContainer>
                <Table variant='simple'>
                  <Tbody>

                          <Tr>
                            <Td><Button style={buttonStyle} onClick={scrollToCheckpost}>CheckPost</Button></Td>
                            <Td><Button style={buttonStyle} onClick={scrollToDepartment}>Departments</Button></Td>
                            <Td><Button style={buttonStyle} onClick={scrollToSubdepartment}>Sub Departments</Button></Td>
                            <Td><Button style={buttonStyle} onClick={scrollToDesignation}>Designation</Button></Td>
                          </Tr>

                          <Tr>
                            <Td><Button style={buttonStyle} onClick={scrollToParking}>Parking Management</Button></Td>
                            {/* <Td><Button style={buttonStyle} onClick={scrollToParkingDepartment}>Parking Departments</Button></Td> */}
                            <Td><Button style={buttonStyle} onClick={scrollToPatrollingRoute}>Patrolling Route</Button></Td>
                            <Td><Button style={buttonStyle} onClick={scrollToFloor}>Floor</Button></Td>
                            <Td><Button style={buttonStyle} onClick={scrollToDeviceTable}>Devices</Button></Td>
                          </Tr>
                          <Tr>
                            <Td><Button style={buttonStyle} onClick={scrollToScheduleHighAlert}>ScheduleHighAlert</Button></Td>
                            <Td><Button style={buttonStyle} onClick={scrollToPurpose}>Purpose</Button></Td>
                            <Td><Button style={buttonStyle} onClick={scrollToDelivarypartner}>Delivary Partners</Button></Td>
                          </Tr>

                  </Tbody>

                </Table>
              </TableContainer>}
          </CardBody>
        </Card>
      </Flex>
      <SimpleGrid minChildWidth="120px" spacing="40px" ref={myCheckPost}>
        <CheckpostTable url={`#/admin/checkpost-form/${params.id}`} plant={params.id}></CheckpostTable>
      </SimpleGrid>

      <SimpleGrid minChildWidth="120px" spacing="40px" ref={myDepartment}>
        <DepartmentTable url={`#/admin/department-form/${params.id}`} plant={params.id}></DepartmentTable>
      </SimpleGrid>

      <SimpleGrid minChildWidth="120px" spacing="40px" ref={mySubdepartment}>
        <SubDepartmentTable url={`#/admin/subdepartment-form/${params.id}`} plant={params.id}></SubDepartmentTable>
      </SimpleGrid>
      
      <SimpleGrid minChildWidth="120px" spacing="40px" ref={myDesignation}>
        <DesignationsTable url={`#/admin/Designation-form/${params.id}`} plant={params.id}></DesignationsTable>
      </SimpleGrid>

      <SimpleGrid minChildWidth="120px" spacing="40px"  ref={myParking}>
        <ParkingTable url={`#/admin/parking-form/${params.id}`} plant={params.id}></ParkingTable>
      </SimpleGrid>

      {/* <SimpleGrid minChildWidth="120px" spacing="40px" ref={myParkingDepartment}>
        <ParkingDepartmentTable url={`#/admin/parkingdepartment-form/${params.id}`} plant={params.id}></ParkingDepartmentTable>
      </SimpleGrid> */}

      <SimpleGrid minChildWidth="120px" spacing="40px" ref={myPatrollingRoute}>
        <PatrollingRouteTable url={`#/admin/patrolling-route-form/${params.id}`} plant={params.id}></PatrollingRouteTable>
      </SimpleGrid>

      
      <SimpleGrid minChildWidth="120px" spacing="40px" ref={myFloorTable}>
        <FloorTable url={`#/admin/floor-form/${params.id}`} plant={params.id}></FloorTable>
      </SimpleGrid>
{/* 
      <SimpleGrid minChildWidth="120px" spacing="40px">
        <PatrollingRouteTable url={`#/admin/patrolling-route-form/${params.id}`} plant={params.id}></PatrollingRouteTable>
        <FloorTable url={`#/admin/floor-form/${params.id}`} plant={params.id}></FloorTable>
      </SimpleGrid> */}
      
      <SimpleGrid minChildWidth="120px" spacing="40px" ref={myDeviceTable}>
        <DevicesTable url={`#/admin/device-form/${params.id}`} plant={params.id} ></DevicesTable>
      </SimpleGrid>


      <SimpleGrid minChildWidth="120px" spacing="40px" ref={myScheduleHighAlertTable}>
        <ScheduleHighAlertTable url={`#/admin/scheduleHighAlert-form/${params.id}`} plant={params.id}></ScheduleHighAlertTable>
      </SimpleGrid>

      <SimpleGrid minChildWidth="120px" spacing="40px" ref={myPurpose}>
        <PurposeTable url={`#/admin/purpose-form/${params.id}`} plant={params.id}></PurposeTable>
      </SimpleGrid>

      <SimpleGrid minChildWidth="120px" spacing="40px" ref={myDelivarypartner}>
        <DelivaryTable url={`#/admin/delivary-partner-form/${params.id}`} plant={params.id}></DelivaryTable>
      </SimpleGrid>

      </>
  );
}

export default PlantAdminView;
