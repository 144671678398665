// Import the action type constants for weighmentBridge
import {
  WEIGHMENTBRIDGE_CLEAR_SNACKBAR,
  WEIGHMENTBRIDGE_LOADING,
  WEIGHMENTBRIDGE_ERROR,
  WEIGHMENTBRIDGE_GET_ALL_SUCCESS,
  WEIGHMENTBRIDGE_SEARCH // Add the new action type
} from '../constants/types';

const initialState = {
  response: {},
  weighmentBridgeList: [],
  weighmentBridgeDetail: {},
  logs: [],
  materialData: [],
  weighmentBridge: [],
  loading: false,
  status: 'success',
  text: '',
  showSnackbar: false,
  limit: 0, // Add missing properties
  page: 0,
  totalPages: 0,
  searching: '' // Add missing properties
};

const weighmentBridgeReducer = (state = initialState, action) => {
  switch (action.type) {
    case WEIGHMENTBRIDGE_CLEAR_SNACKBAR:
      return {
        ...state,
        showSnackbar: false,
      };
    case WEIGHMENTBRIDGE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case WEIGHMENTBRIDGE_ERROR:
      return {
        ...state,
        weighmentBridgeList: [],
        response: {},
        weighmentBridgeDetail: {},
        logs: [],
        materialData: [],
        weighmentBridge: [],
        limit: 0, // Add missing properties
        page: 0,
        totalPages: 0,
        loading: false,
        status: action.payload.status,
        text: action.payload.text,
        showSnackbar: true,
      };
    case WEIGHMENTBRIDGE_GET_ALL_SUCCESS:
      return {
        ...state,
        weighmentBridgeList: action.payload.weighmentBridgeList,
        response: action.payload.response,
        weighmentBridgeDetail: action.payload.weighmentBridgeDetail,
        logs: action.payload.logs,
        materialData: action.payload.materialData,
        weighmentBridge: action.payload.weighmentBridge,
        limit: action.payload.limit,
        page: action.payload.page,
        totalPages: action.payload.totalPages,
        loading: false,
        status: action.payload.status,
        text: action.payload.text,
        showSnackbar: true,
      };
    case WEIGHMENTBRIDGE_SEARCH: // Add the new case
      return {
        ...state,
        searching: action.payload.searching,
      };
    default:
      return state || {};
  }
};

export default weighmentBridgeReducer;
