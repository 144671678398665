import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  SimpleGrid,
  Spacer,
  Text,
  Wrap,
  WrapItem,
  useColorModeValue,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Spinner,
  Image,
  Select,
  Video
} from "@chakra-ui/react";
import Relect from 'react-select';
import dataConstant from "constants/dataConstant";
import { GUARD_WORKEMP_CLEAR_SNACKBAR } from "constants/types";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { useDispatch, useSelector } from "react-redux";

import axios from "axios";
import { server_url } from "constants/types";

import { useToast } from '@chakra-ui/react'

import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { guardWorkEmpAddOrUpdate } from "../../actions/howItWorkAction";
import CustomInput from "views/CustomValidation";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


function HowItWorksUpdate() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [photo, setPhoto] = useState("");
  const [name, setName] = useState("");
  const [status, setStatus] = useState('');
  const [bannerType, setType] = useState("");
  const [createdFor , setCreatedFor] = useState("");
  const [description, setDescription] = useState("")
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [guardWorkEmpId, setGuardWorkEmpId] = useState("");
  const [catName, setCatName] = useState("");
  const [bannertype, setBannerType] = useState("");
  const [type, setPopType] = useState("");
  const [catDesc, setCatDesc] = useState("");
  const [image, setImage] = useState("");
  const [video, setVideo] = useState("");
  const [howitsworkcategoryList, setHowitsworkcategoryList] = useState([]);

  const [selectedCheckposts, setSelectedCheckposts] = useState([]);
  const [homeModule, setHomeModule] = useState([]);
  const [departmentList, setDepartmentList] = useState('')
  const [department, setDepartment] = useState("");
  const [designation, setDesignation] = useState("");
  const [subdepartment, setSubdepartment] = useState("");
  const [subdepartmentList, setSubdepartmentList] = useState([])
  const [designationList, setDesignationList] = useState([])

  const [isErrorName, setIsErrorName] = useState(false);
  const [isErrorDescription, setIsErrorDescription] = useState(false)
  const [isErrorStatus, setIsErrorStatus] = useState(false);
  const [isErrorCatName, setIsErrorCatName] = useState(false)
  const [isErrorCatDesc, setIsErrorCatDesc] = useState(false)
  const [isErrorType, setIsErrorType] = useState(false)
  const [isErrorPopType, setIsErrorPopType] = useState(false);
  const [isErrorCreatedFor , setIsErrorCreatedFor] = useState(false);
  const [isErrorNumber, setIsErrorNumber] = useState(false);
  const [isErrorEmail, setIsErrorEmail] = useState(false);
  const [isErrorGuardWorkEmpId, setIsErrorGuardWorkEmpId] = useState(false);
  const [isErrorCheckpost, setIsErrorCheckpost] = useState(false);
  const [isErrorPostalcode, setIsErrorPostalcode] = useState(false);
  const [isErrorLine1, setIsErrorLine1] = useState(false);
  const [isErrorLine2, setIsErrorLine2] = useState(false);
  const [isErrorLocality, setIsErrorLocality] = useState(false);
  const [isErrorHomeModule, setIsErrorHomeModule] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isErrorDepartment, setIsErrorDepartment] = useState(false);
  const [isErrorDesignation, setIsErrorDesignation] = useState(false);
  const [isErrorSubdepartment, setIsErrorSubdepartment] = useState(false);

  const statuses = ['success', 'error', 'warning', 'info'];
  const responseStatus = useSelector(state => state.HowItWork.status);
  const responseMessage = useSelector(state => state.HowItWork.text);
  const loading = useSelector(state => state.HowItWork.loading);
  const showSnackbar = useSelector(state => state.HowItWork.showSnackbar);
  const guardWorkEmpDetail = useSelector(state => state.HowItWork.guardWorkEmpDetail);
  const [isErrorState, setIsErrorState] = useState(false);
  const [isErrorCategory, setIsErrorCategory] = useState(false);
  const [howitWorkCategory, setHowitworkCategory] = useState('');

  const dispatch = useDispatch();
  const toast = useToast();
  const history = useHistory()
  const params = useParams();
  const IdofguardWorkEmp = params.id;

  console.log("guardWorkEmpDetail")
  console.log(guardWorkEmpDetail)
  console.log("guardWorkEmpDetail")
  useEffect(() => {
    howitsworkCategoryforDropdown();
    const guardWorkEmp = {
      _id: IdofguardWorkEmp
    }
    dispatch(guardWorkEmpAddOrUpdate(guardWorkEmp, 'view'));
  }, []);


  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
        if (guardWorkEmpDetail) { // Check if guardWorkEmpDetail is available
          setName(guardWorkEmpDetail?.name || "");
          setDescription(guardWorkEmpDetail?.description || "");
          setHowitworkCategory(guardWorkEmpDetail.howItWorkCategory._id );
          setPopType(guardWorkEmpDetail.type );
          setCreatedFor(guardWorkEmpDetail?.createdFor || '');
          setImage(guardWorkEmpDetail?.thumbnail || "");
          setVideo(guardWorkEmpDetail?.video || "");
          setStatus(guardWorkEmpDetail.status)
          setType(guardWorkEmpDetail?.type || '')
        }
      }
    }

    dispatch({
      type: GUARD_WORKEMP_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      },
    });
  }, [loading]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    setIsErrorName(name === '');
    setIsErrorDescription(description === "");
    setIsErrorCatName(catName === "");
    setIsErrorCatDesc(catDesc === "");
    setIsErrorPopType(type === "");
    setIsErrorStatus(status === "");
    setIsErrorCreatedFor(status === "");



    if (name !== "" && video !== "" && image !== "" && status !== "" && description !== "" && type !== "" && createdFor !== "" && howitWorkCategory !== "") {

      const newGuardWorkEmp = {
        _id: params.id,
        name: name,
        status : status,
        howItWorkCategory: howitWorkCategory,
        description: description,
        file1: video,
        type: type,
        createdFor: createdFor,
        file2: image
      };
      console.log("newGuardWorkEmp")
      console.log(newGuardWorkEmp)
      console.log('newGuardWorkEmp')
      dispatch(guardWorkEmpAddOrUpdate(newGuardWorkEmp, 'edit'));
    }
    // if (responseStatus === 'OK' && loading === false){
    //   history.push("/admin/howItWork-table")
    // }


  };



  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: GUARD_WORKEMP_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);


  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])

  const howitsworkCategoryforDropdown = () => {
    axios
      .get(`${server_url}/howitworkcategory?limit=1000`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        setHowitsworkcategoryList(responce.data.data)
      }).catch(() => console.log("error"))
  }


  const textRegex = /^[A-Za-z\s]+$/;
  const numberRegex = /^\d{10}$/;
  const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,10}$/;
  const guardWorkEmpIdRegex = /^[a-zA-Z0-9]+$/;
  const postalCodeRegex = /^[0-9]{6}$/;

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            How-It-Work Update Form
          </Text>
          <br/>
          <SimpleGrid minChildWidth='120px' spacing='40px' mb='20px'>
            <FormControl isInvalid={isErrorName || (submitted && name === "")}>
              <CustomInput
                type='text'
                label='Name'
                value={name}
                onChange={(value) => {
                  // setIsErrorName(value === '' || !textRegex.test(value));
                  setName(value);
                }}
                isInvalid={isErrorName || (submitted && name === "")}
                regex={textRegex}
                submitted={submitted}
              />
            </FormControl>

            <FormControl isInvalid={isErrorDescription || (submitted && description === "")}>
              <CustomInput
                type="text"
                label="Description"
                value={description}
                onChange={(value) => {
                  // setIsErrorDescription(value === '' || !textRegex.test(value));
                  setDescription(value);
                }}
                isInvalid={isErrorDescription || (submitted && description === "")}
                regex={textRegex}
                submitted={submitted}
              />
            </FormControl>
            </SimpleGrid>


            <SimpleGrid  minChildWidth='120px' spacing='40px' mb='20px'> 
            <FormControl isInvalid={isErrorStatus}>
              <FormLabel>Status</FormLabel>
              <Select
                placeholder="Select status"
                value={status}
                onChange={(e) => {
                  // setIsErrorStatus(e.target.value === '')
                  setStatus(e.target.value)
                }}
              >
                {dataConstant.status.map((element, index) => {
                  return (<option key={element['id']} value={element['value']} >{element['name']}</option>);

                })}
              </Select>
              {!isErrorStatus ? (
                <></>
              ) : (
                <FormErrorMessage>Status is required.</FormErrorMessage>
              )}
            </FormControl>


            <FormControl isInvalid={isErrorCategory}>
                <FormLabel>Category</FormLabel>
                <Select
                  placeholder="Select Category"
                  onChange={(e) => {
                    // setIsErrorCategory(e.target.value === '')
                    setHowitworkCategory(e.target.value)
                  }}
                >
                  {howitsworkcategoryList.map((element, index) => {
                    return (<option key={element['_id']} value={element['_id']} selected={element['_id'] == howitWorkCategory?true:false}>{element['name']}</option>);

                  })}
                </Select>
                {!isErrorCategory ? (
                  <></>
                ) : (
                  <FormErrorMessage>Category is required.</FormErrorMessage>
                )}
              </FormControl>
            </SimpleGrid>
         
         


          <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>

            <FormControl>
              <FormLabel>Video</FormLabel>
              <Input
                type='file'

                onChange={(e) => {
                  // setIsErrorImage(e.target.value === '')
                  setVideo(e.target.files[0])
                }}
              />
              {/* {!isErrorImage ? (
                <></>
              ) : (
                <FormErrorMessage>Image is required.</FormErrorMessage>
              )} */}
            </FormControl>

            <FormControl >
              <FormLabel>Thumbnail</FormLabel>
              <Input
                type='file'

                onChange={(e) => {
                  // setIsErrorImage(e.target.value === '')
                  setImage(e.target.files[0])
                }}
              />
              {/* {!isErrorImage ? (
                <></>
              ) : (
                <FormErrorMessage>Image is required.</FormErrorMessage>
              )} */}
            </FormControl>

          </SimpleGrid>

          <SimpleGrid minChildWidth='120px' spacing='40px' mb='20px'>
            <FormControl isInvalid={isErrorType}>
                <FormLabel>Created By</FormLabel>
                <Select
                  placeholder="Created By"
                  value={createdFor}
                  onChange={(e) => {
                    // setIsErrorCreatedFor(e.target.value === '')
                    setCreatedFor(e.target.value)
                  }}
                >
                  { dataConstant.bannerType.map((element,index) => {
                      return (<option key={element['name']} value={element['value']} >{element['name']}</option>);   
                        
                    })}
                </Select>
                {!isErrorCreatedFor ? (
                  <></>
                ) : (
                  <FormErrorMessage>Created By is required.</FormErrorMessage>
                )}
              </FormControl>

              <FormControl isInvalid={isErrorPopType}>
                <FormLabel>Type</FormLabel>
                <Select
                  placeholder="Type"
                  value={type}
                  onChange={(e) => {
                    // setIsErrorPopType(e.target.value === '')
                    setPopType(e.target.value)
                  }}
                >
                  { dataConstant.type.map((element,index) => {
                      return (<option key={element['id']} value={element['name']} >{element['name']}</option>);   
                        
                    })}
                </Select>
                {!isErrorPopType ? (
                  <></>
                ) : (
                  <FormErrorMessage>Type is required.</FormErrorMessage>
                )}
              </FormControl>
            </SimpleGrid>



          {/* ... Additional form fields ... */}
          <Center>
            {loading ? (
              <Spinner mt="24px" size="lg" />
            ) : (
              <Button colorScheme="blue" mt="24px" onClick={handleSubmit}>
                Submit
              </Button>
            )}
          </Center>
        </CardHeader>
        <CardBody>
          {/* ... Additional form content ... */}
        </CardBody>
      </Card>
      {/* <Spacer></Spacer> */}
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px" mt="4">
        {/* <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            GuardWorkEmp
          </Text>
          <Box boxSize='sm'>
            <Image src={photo} alt='Dan Abramov' />
          </Box>
        </CardHeader> */}
        <CardBody>
          {/* ... Additional content ... */}
        </CardBody>
      </Card>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px" mt="4">
        <Flex direction="row" pt={{ base: "120px", md: "75px" }} gap='80px' >
          <CardHeader p="6px 0px 22px 0px" >
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Video
            </Text>
            <Box boxSize='sm'>
              <video width="100%" controls>
                <source src={video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </Box>
          </CardHeader>
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Thumbnail
            </Text>
            <Box boxSize='sm'>
              <Image src={image} alt='Dan Abramov' />
            </Box>
          </CardHeader>
          <CardBody>
          </CardBody>
        </Flex>
      </Card>

    </Flex >
  );
}

export default HowItWorksUpdate;
