// Chakra imports
import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  Wrap,
  WrapItem,
  useColorModeValue
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { busAddOrUpdate, busClearShowSnackbar } from '../../actions/busAction';
import { useToast } from '@chakra-ui/react'
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react'
import { Skeleton, SkeletonCircle, SkeletonText } from '@chakra-ui/react'
import { BUS_CLEAR_SNACKBAR } from "constants/types";
import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom';
function BusViewForm() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [busNo, setBusNo] = useState("");
  const [driverName, setDriverName] = useState("");
  const [driverPhone, setDriverPhone] = useState("");
  const [driverTrip, setDriverTrip] = useState("");
  const [routeName, setRouteName] = useState("");
  const [type, setType] = useState("");
  const [sittingCapacity, setSittingCapacity] = useState("");
  const [insuranceValidityDate, setInsuranceValidityDate] = useState("");

  const dispatch = useDispatch();
  const toast = useToast();
  const history = useHistory();
  const statuses = ['success', 'error', 'warning', 'info'];
  const responseStatus = useSelector(state => state.bus.status);
  const responseMessage = useSelector(state => state.bus.text);
  const loading = useSelector(state => state.bus.loading);
  const busDetail = useSelector(state => state.bus.busDetail);
  const showSnackbar = useSelector(state => state.bus.showSnackbar);

console.log("busDETAIL");
console.log(busDetail);

  const params = useParams();
  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
        console.log(busDetail);
        setType(busDetail.type);
        setBusNo(busDetail.busNo);
        setDriverName(busDetail.driver.Name);
        setDriverPhone(busDetail.driver.phone);
        setDriverTrip(busDetail.driver.trips);
        setRouteName(busDetail.route.name);
        setSittingCapacity(busDetail.sittingCapacity);
        setInsuranceValidityDate(busDetail.insuranceValidityDate);
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }

    dispatch({
      type: BUS_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);

  useEffect(() => {
    console.log("params");
    const busId = params.id;
    const bus = {
      _id : busId
    };
    dispatch(busAddOrUpdate(bus,'view'));
  }, []);
  
  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Vehicle Details View 
          </Text>
         
        </CardHeader>
        <CardBody>
          {loading ? <Stack>
            <Skeleton height='20px' />
            <Skeleton height='20px' />
            <Skeleton height='20px' />
          </Stack>
            :
            <TableContainer>
              <Table variant='simple'>
                <Tbody>
                  <Tr>
                    <Td>vehicle Type</Td>
                    <Td>{type}</Td>
                  </Tr>
                  <Tr>
                    <Td>vehicle No</Td>
                    <Td>{busNo}</Td>
                  </Tr>
                  <Td fontSize="lg" fontWeight="bold">Driver Detail</Td>
                  <Tr>
                    <Td>Driver Name</Td>
                    <Td>{driverName}</Td>
                  </Tr>
                  <Tr>
                    <Td>Driver Phone</Td>
                    <Td>{driverPhone}</Td>
                  </Tr>
                  <Tr>
                    <Td>Number Of Trips</Td>
                    <Td>{driverTrip}</Td>
                  </Tr>
                  <Tr>
                    <Td>Route</Td>
                    <Td>{routeName}</Td>
                  </Tr>
                  <Tr>
                    <Td>Sitting Capacity</Td>
                    <Td>{sittingCapacity}</Td>
                  </Tr>
                  {/* <Tr>
                    <Td>Insurance Validity Date</Td>
                    <Td>{insuranceValidityDate}</Td>
                  </Tr> */}
                </Tbody>
              </Table>
            </TableContainer>}
        </CardBody>
      </Card>
    </Flex>
  );
}

export default BusViewForm;
