const {
  INTERCARTINGINOUT_LOADING,
  INTERCARTINGINOUT_GET_ALL_SUCCESS,
  INTERCARTINGINOUT_ERROR,
  INTERCARTINGINOUT_CLEAR_SNACKBAR,
  INTERCARTINGINOUT_SEARCH // Add the new action type
} = require('../constants/types');

const initialState = {
  response: {},
  intercartingInOutList: [],
  intercartingInOutDetail: {},
  loading: false,
  logs: [],
  IntercartingInOut: [],
  status: 'success',
  text: '',
  showSnackbar: false,
  limit: 0, // Add missing properties
  page: 0,
  totalPages: 0,
  searching: "" // Add missing properties
};

const IntercartingInOutReducer = (state = initialState, action) => {
  switch (action.type) {
    case INTERCARTINGINOUT_CLEAR_SNACKBAR:
      return {
        ...state,
        showSnackbar: false
      };
    case INTERCARTINGINOUT_LOADING:
      return {
        ...state,
        loading: true
      };
    case INTERCARTINGINOUT_ERROR:
      return {
        ...state,
        intercartingInOutList: [],
        response: {},
        intercartingInOutDetail: {},
        logs: [],
        IntercartingInOut: [],
        limit: 0, // Add missing properties
        page: 0,
        totalPages: 0,
        loading: false,
        status: action.payload.status,
        text: action.payload.text,
        showSnackbar: true
      };
    case INTERCARTINGINOUT_GET_ALL_SUCCESS:
      return {
        ...state,
        intercartingInOutList: action.payload.intercartingInOutList,
        response: action.payload.response,
        intercartingInOutDetail: action.payload.intercartingInOutDetail,
        logs: action.payload.logs,
        limit: action.payload.limit,
        page: action.payload.page,
        totalPages: action.payload.totalPages,
        loading: false,
        status: action.payload.status,
        text: action.payload.text,
        showSnackbar: true
      };
    case INTERCARTINGINOUT_SEARCH: // Add the new case
      return {
        ...state,
        searching: action.payload.searching
      };
    default:
      return state || {};
  }
};

export default IntercartingInOutReducer;
