import axios from 'axios';
import {
  BANNER_LOADING,
  BANNER_GET_ALL_SUCCESS,
  BANNER_ERROR,
  BANNER_CLEAR_SNACKBAR
} from '../constants/types';

import {
  server_url
} from '../constants/types';

export const bannerGetAll = (pagination) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: BANNER_LOADING,
        payload: {
          loading: true
        }
      });
      axios.get(`${server_url}/banner?${pagination}`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      })
        .then(response => {
          dispatch({
            type: BANNER_GET_ALL_SUCCESS,
            payload: {
              bannerList: response.data.data,
              limit: response.data.limit,
              page: response.data.currentPage,
              totalPages: response.data.totalPages,
              response: response.data,
              loading: false,
              status: "success",
              text: "Get all banner data successfully."
            }
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: BANNER_ERROR,
            payload: {
              status: "error",
              text: "Error occurred during getting banner data.",
              loading: false
            }
          });
          resolve();
        })
    })
  }
}

export const bannerAddOrUpdate = (banner, state) => {
  console.log("banner")
  console.log(banner)
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: BANNER_LOADING,
        payload: {
          loading: true
        }
      });
      if (state === "add") {
        axios.post(`${server_url}/banner`, banner, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
          .then(responseF => {
            axios.get(`${server_url}/banner`, {
              headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
              }
            })
              .then(response => {
                dispatch({
                  type: BANNER_GET_ALL_SUCCESS,
                  payload: {
                    bannerList: response.data.data,
                    limit: response.data.limit,
                    page: response.data.currentPage,
                    totalPages: response.data.totalPages,
                    response: response.data,
                    loading: false,
                    status: responseF.data['status'],
                    text: responseF.data['message']
                  }
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: BANNER_ERROR,
                  payload: {
                    text: "Error occurred during getting banner data.",
                    status: "error",
                    loading: false
                  }
                });
                resolve();
              })
          })
          .catch((e) => {
            dispatch({
              type: BANNER_ERROR,
              payload: {
                text: "Error occurred during adding banner data.",
                status: "error",
                loading: false
              }
            });
            resolve();
          })
      } else if (state === "edit") {
        axios.put(`${server_url}/banner/${banner._id}`, banner, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
          .then(responseF => {
            axios.get(`${server_url}/banner`, {
              headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
              }
            })
              .then(response => {
                dispatch({
                  type: BANNER_GET_ALL_SUCCESS,
                  payload: {
                    bannerList: response.data.data,
                    limit: response.data.limit,
                    page: response.data.currentPage,
                    totalPages: response.data.totalPages,
                    response: response.data,
                    bannerDetail: responseF.data['data'],
                    loading: false,
                    status: responseF.data['status'],
                    text: responseF.data['message']
                  }
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: BANNER_ERROR,
                  payload: {
                    text: "Error occurred during getting banner data.",
                    status: "error",
                    loading: false
                  }
                });
                resolve();
              })
          })
          .catch((e) => {
            dispatch({
              type: BANNER_ERROR,
              payload: {
                text: "Error occurred during updating banner data.",
                status: "error",
                loading: false
              }
            });
            resolve();
          })
      }
      else if (state === "view") {
        axios.put(`${server_url}/banner/${banner._id}`, banner, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
          .then(responseF => {
            axios.get(`${server_url}/banner`, {
              headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
              }
            })
              .then(response => {
                dispatch({
                  type: BANNER_GET_ALL_SUCCESS,
                  payload: {
                    bannerList: response.data.data,
                    limit: response.data.limit,
                    page: response.data.currentPage,
                    totalPages: response.data.totalPages,
                    response: response.data,
                    bannerDetail: responseF.data['data'],
                    loading: false,
                    status: responseF.data.status,
                    text: responseF.data.message,
                  }
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: BANNER_ERROR,
                  payload: {
                    text: "Error occurred during getting banner data.",
                    status: "error",
                    loading: false
                  }
                });
                resolve();
              })
          })
          .catch((e) => {
            dispatch({
              type: BANNER_ERROR,
              payload: {
                text: "Error occurred during updating banner data.",
                status: "error",
                loading: false
              }
            });
            resolve();
          })
      }
    })
  }
}

export const bannerDelete = (banner) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: BANNER_LOADING,
        payload: {
          loading: true
        }
      });
      axios.delete(`${server_url}/banner/${banner._id}`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then(responseF => {
        axios.get(`${server_url}/banner`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then(response => {
          dispatch({
            type: BANNER_GET_ALL_SUCCESS,
            payload: {
              bannerList: response.data.data,
              limit: response.data.limit,
              page: response.data.currentPage,
              totalPages: response.data.totalPages,
              response: response.data,
              bannerDetail: responseF.data['data'],
              loading: false,
              status: responseF.data['status'],
              text: responseF.data['message']
            }
          });
          resolve();
        }).catch((e) => {
          dispatch({
            type: BANNER_ERROR,
            payload: {
              text: "Error occured during getting banner data.",
              status: "error",
              loading: false
            }
          });
          resolve();
        })
      }).catch((e) => {
        dispatch({
          type: BANNER_ERROR,
          payload: {
            text: "Error occured during deleteing banner data.",
            status: "error",
            loading: false
          }
        });
        resolve();
      })
    })
  }
}

export const bannerClearShowSnackbar = () => {
  return (dispatch, getState) => {
    dispatch({
      type: BANNER_CLEAR_SNACKBAR
    });
  }
}
