import {
  REPORTINCEDENT_LOADING,
  REPORTINCEDENT_GET_ALL_SUCCESS,
  REPORTINCEDENT_ERROR,
  REPORTINCEDENT_CLEAR_SNACKBAR,
  REPORTINCEDENT_SEARCH  // Add the new action type
} from '../constants/types';

const initialState = {
  response: {},
  reportIncedentList: [],
  reportIncedentDetail: {},
  loading: false,
  typeOfIncident: [],
  reportIncedent: [],
  status: 'success',
  text: 'Got the data',
  showSnackbar: false,
  limit: 0,  // Add missing properties
  page: 0,
  totalPages: 0,
  searching: ''  // Add missing properties
};

const reportIncedentReducer = (state = initialState, action) => {
  switch (action.type) {
    case REPORTINCEDENT_CLEAR_SNACKBAR:
      return {
        ...state,
        showSnackbar: false
      };
    case REPORTINCEDENT_LOADING:
      return {
        ...state,
        loading: true
      };
    case REPORTINCEDENT_ERROR:
      return {
        ...state,
        reportIncedentList: [],
        response: {},
        reportIncedentDetail: {},
        loading: false,
        typeOfIncident: [],
        reportIncedent: [],
        status: 'success',
        text: 'Got the data',
        limit: 0,  // Add missing properties
        page: 0,
        totalPages: 0,
        showSnackbar: true
      };
    case REPORTINCEDENT_GET_ALL_SUCCESS:
      return {
        ...state,
        reportIncedentList: action.payload.reportIncedentList,
        response: action.payload.response,
        reportIncedentDetail: action.payload.reportIncedentDetail,
        typeOfIncident: action.payload.typeOfIncident,
        loading: false,
        limit: action.payload.limit,
        page: action.payload.page,
        totalPages: action.payload.totalPages,
        status: action.payload.status,
        text: action.payload.text,
        showSnackbar: true
      };
    case REPORTINCEDENT_SEARCH: // Add the new case
      return {
        ...state,
        searching: action.payload.searching
      };
    default:
      return state;
  }
};

export default reportIncedentReducer;
