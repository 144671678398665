// Chakra imports
import {
  Flex,
  Box,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  WrapItem,
  TableContainer,
  Image,
  useColorModeValue,
  Wrap,
  HStack,
  Stack,
  SimpleGrid,
  Grid,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  weighmentBridgeClearShowSnackbar,
  weighmentBridgeAddOrUpdate,
} from "../../actions/weighmentBridgeAction";
import { useToast } from "@chakra-ui/react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { WEIGHMENTBRIDGE_CLEAR_SNACKBAR } from "constants/types";
import { Skeleton, SkeletonCircle, SkeletonText } from "@chakra-ui/react";
// import { weighmentBridgeClearShowSnackbar } from "actions/weighmentBridgeAction";
import axios from "axios";
import { server_url } from "constants/types";
import { useHistory } from "react-router-dom";
function WeighmentBridgeView() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [mode, setMode] = useState("");
  const [materialShipmentType, setMaterialShipmentType] = useState("");
  const [materialBy, setMaterialBy] = useState("");
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [vendor, setVendor] = useState("");
  const [purpose, setPurpose] = useState("");
  const [weighmentStatus, setWeighmentStatus] = useState("");
  const [status, setStatus] = useState("");
  const [type, setType] = useState("");
  const [weighment, setWeighment] = useState("");
  // const [material, setMaterial] = useState([]);
  // const [invoice, setInvoice] = useState("");
  // const [invoiceNumber, setInvoiceNumber] = useState("");
  // const [materialDescription, setMaterialDescription] = useState("");
  // const [quantity, setQuantity] = useState("");
  // const [description, setDescription] = useState("");
  // const [dateTime, setDateTime] = useState("");
  // const [formattedDateTime, setFormattedDateTime] = useState("");

  const dispatch = useDispatch();
  const toast = useToast();

  const statuses = ["success", "error", "warning", "info"];
  const responseStatus = useSelector((state) => state.weighmentBridge.status);
  const responseMessage = useSelector((state) => state.weighmentBridge.text);
  const loading = useSelector((state) => state.weighmentBridge.loading);
  // const designationDetail = useSelector(state => state.weighmentBridge.designationDetail);
  const showSnackbar = useSelector((state) => state.weighmentBridge.showSnackbar);
  const logs = useSelector((state) => state.weighmentBridge.logs);
  const weighmentBridgeDetail = useSelector((state) => state.weighmentBridge.weighmentBridgeDetail);
  const materialData = useSelector((state) => state.weighmentBridge.materialData);
  // const address = useSelector(state => state.weighmentBridge.guard.Address);
  // const checkpost = useSelector(state => state.weighmentBridge.guard.checkpost);

  const params = useParams();

  console.log("materialData vi ew");
  console.log(materialData);

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === "OK" && loading === false) {
        setMode(weighmentBridgeDetail.mode);
        setMaterialShipmentType(weighmentBridgeDetail.materialShipmentType);
        setMaterialBy(weighmentBridgeDetail.materialBy);
        setVehicleNumber(weighmentBridgeDetail.matevehicleNumberrialBy);
        setVendor(weighmentBridgeDetail.vendor);
        setPurpose(weighmentBridgeDetail.purpose);
        setWeighmentStatus(weighmentBridgeDetail.weighmentStatus);
        setStatus(weighmentBridgeDetail.status);
        setType(weighmentBridgeDetail.type);
        setWeighment(weighmentBridgeDetail.weighment);
        // setMaterial(weighmentBridgeDetail.material);
        if (responseMessage !== "") {
          toastFunction(responseMessage, "success");
        }
      } else if (responseStatus === "NOK" && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, "error");
        }
      }
      dispatch({
        type: WEIGHMENTBRIDGE_CLEAR_SNACKBAR,
        payload: {
          showSnackbar: false,
        },
      });
    }
  }, [loading]);
  useEffect(() => {
    const weighmentBridgeId = params.id;
    const weighmentBridge = {
      _id: weighmentBridgeId,
    };
    dispatch(weighmentBridgeAddOrUpdate(weighmentBridge, "view"));
  }, []);
  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: True,
    });
  };

  // useEffect(() => {
  //   axios
  //     .get(`${server_url}/admin/detail`, {
  //       headers: {
  //         'Authorization': 'Bearer ' + localStorage.getItem('authToken')
  //       }
  //     }).then((responce) => {
  //       console.log("uadlfdafljadhfoadilf");
  //     })
  //     .catch((e) => {
  //       history.push('/auth/signin');

  //     });
  // }, [])

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px" mb={4}>
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Weighment Bridge View
          </Text>
        </CardHeader>
        <CardBody>
          {loading ? (
            <Stack>
              <Skeleton height="20px" />
              <Skeleton height="20px" />
              <Skeleton height="20px" />
            </Stack>
          ) : (
            <TableContainer>
              <Table variant="simple">
                <Tbody>
                  <Tr>
                    <Td>Mode</Td>
                    <Td>{mode}</Td>
                  </Tr>

                  <Tr>
                    <Td>Material-ShipmentType</Td>
                    <Td>{materialShipmentType}</Td>
                  </Tr>

                  <Tr>
                    <Td>Material-By</Td>
                    <Td>{materialBy}</Td>
                  </Tr>
                  <Tr>
                    <Td>vehicle-Number</Td>
                    <Td>{vehicleNumber}</Td>
                  </Tr>
                  <Tr>
                    <Td>vendor</Td>
                    <Td>{vendor}</Td>
                  </Tr>
                  <Tr>
                    <Td>purpose</Td>
                    <Td>{purpose}</Td>
                  </Tr>
                  <Tr>
                    <Td>weighment-Status</Td>
                    <Td>{weighmentStatus}</Td>
                  </Tr>
                  <Tr>
                    <Td>Status</Td>
                    <Td>{status}</Td>
                  </Tr>
                  <Tr>
                    <Td>weighment-type</Td>
                    <Td>{type}</Td>
                  </Tr>

                  <Tr>
                    <Td>Weighment</Td>
                    <Td>{weighment}</Td>
                  </Tr>
                  <Tr>
                    <Td>Material Logs</Td>
                    <Td>
                      <ol>
                      {logs.map((ele, index) => (
                        <li>
                        <div key={index}>
                          <div>
                            <span>{ele.description} {ele.formattedDateTime}</span> 
                          </div>
                          
                        </div>
                        </li>
                      ))}
                      </ol>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          )}
        </CardBody>
      </Card>

      {loading ? (
            <Stack>
              <Skeleton height="20px" />
              <Skeleton height="20px" />
              <Skeleton height="20px" />
            </Stack>
          ) : (<Grid
      templateColumns={{ base: '100%', md: 'repeat(2, 1fr)' }}
      gap={4}
      justifyContent="center"
    >
      {materialData.map((hello, index) => (
        <Box key={index}>
          <Card mb={4}>
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Material
            </Text>
            <ul style={{ listStyleType: "none" }}>
              <li>
              <Image
              src={hello.invoice}
              alt=""
              boxSize="35%"  // Adjust the size as needed
              objectFit="cover" // Use 'contain' for a different fit
            />
              </li>
              <br />
              <li>Invoice Number : {hello.invoiceNumber}</li>
              <li>Material Description : {hello.materialDescription}</li>
              <li>Quantity : {hello.quantity}</li>
            </ul>
          </Card>
        </Box>
      ))}
    </Grid>)}
    </Flex>
  );
}
export default WeighmentBridgeView;
