const {
    PARKINGDEPARTMENT_LOADING,
    PARKINGDEPARTMENT_GET_ALL_SUCCESS,
    PARKINGDEPARTMENT_ERROR,
    PARKINGDEPARTMENT_CLEAR_SNACKBAR
  } = require('../constants/types');
  
  const initialState = {
    response: {},
    parkingdepartmentList: [],
    parkingdepartmentDetail: {},
    loading: false,
    status: 'success',
    text: '',
    showSnackbar: false
  };




  
  const parkingdepartmentReducer = (state = initialState, action) => {
    switch (action.type) {
      case PARKINGDEPARTMENT_CLEAR_SNACKBAR:
        return {
          ...state,
          showSnackbar: false
        };
      case PARKINGDEPARTMENT_LOADING:
        return {
          ...state,
          loading: true
        };
      case PARKINGDEPARTMENT_ERROR:
        return {
          ...state,
          parkingdepartmentList: [],
          response: {},
          departmentDetail: {},
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true
        };
      case PARKINGDEPARTMENT_GET_ALL_SUCCESS:
        return {
          ...state,
          parkingdepartmentList: action.payload.parkingdepartmentList,
          response: action.payload.response,
          parkingdepartmentDetail: action.payload.parkingdepartmentDetail,
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true,
        };
      default:
        return state || {};
    }
  };
  
  export default parkingdepartmentReducer;
  // module.exports = parkingdepartmentReducer;
  