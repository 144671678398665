import {
  SOS_LOADING,
  SOS_GET_ALL_SUCCESS,
  SOS_ERROR,
  SOS_CLEAR_SNACKBAR,
  SOS_SEARCH  // Add the new action type
} from '../constants/types';

const initialState = {
  response: {},
  sosList: [],
  sosTodayList: [],
  sosDetail: {},
  loading: false,
  status: 'success',
  text: '',
  showSnackbar: false,
  limit: 0,  // Add missing properties
  page: 0,
  totalPages: 0,
  searching: ''  // Add missing properties
};

const sosReducer = (state = initialState, action) => {
  switch (action.type) {
    case SOS_CLEAR_SNACKBAR:
      return {
        ...state,
        showSnackbar: false
      };
    case SOS_LOADING:
      return {
        ...state,
        loading: true
      };
    case SOS_ERROR:
      return {
        ...state,
        sosList: [],
        sosTodayList: [],
        response: {},
        sosDetail: {},
        limit: 0,  // Add missing properties
        page: 0,
        totalPages: 0,
        loading: false,
        status: action.payload.status,
        text: action.payload.text,
        showSnackbar: true
      };
    case SOS_GET_ALL_SUCCESS:
      return {
        ...state,
        sosList: action.payload.sosList,
        sosTodayList: action.payload.sosTodayList,
        response: action.payload.response,
        sosDetail: action.payload.sosDetail,
        limit: action.payload.limit,
        page: action.payload.page,
        totalPages: action.payload.totalPages,
        loading: false,
        status: action.payload.status,
        text: action.payload.text,
        showSnackbar: true
      };
    case SOS_SEARCH: // Add the new case
      return {
        ...state,
        searching: action.payload.searching
      };
    default:
      return state;
  }
};

export default sosReducer;
