// Chakra Imports
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  Flex, Link,
  Spacer,
  Switch,
  Text,
  useColorMode,
  useColorModeValue,
  useDisclosure
} from "@chakra-ui/react";
import { adminLogout } from "actions/authActions";
import { BrowserRouter, Route, useHistory } from 'react-router-dom';
import { ProfileIcon } from "components/Icons/Icons";
import { HSeparator } from "components/Separator/Separator";
import React, { useState } from "react";
import { useEffect } from "react";
import GitHubButton from "react-github-btn";
import { FaFacebook, FaTwitter } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";

export default function Configurator(props) {
  const {
    sidebarVariant,
    setSidebarVariant,
    secondary,
    isOpen,
    onClose,
    fixed,
    ...rest
  } = props;
  const [switched, setSwitched] = useState(props.isChecked);

  const { colorMode, toggleColorMode } = useColorMode();

  let bgButton = useColorModeValue(
    "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
    "white"
  );
  const dispatch = useDispatch();
  let colorButton = useColorModeValue("white", "gray.700");
  const secondaryButtonBg = useColorModeValue("white", "transparent");
  const secondaryButtonBorder = useColorModeValue("gray.700", "white");
  const secondaryButtonColor = useColorModeValue("gray.700", "white");
  const bgDrawer = useColorModeValue("white", "navy.800");
  const settingsRef = React.useRef();
  const authDetail = useSelector(state => state.auth.authDetail)

  console.log("lholehipohipewohihpe");
  console.log(authDetail);

  const [authName, setAuthName] = useState('');
  const [rolename, setRoleName] = useState('');
  const history = useHistory();

  useEffect(() => {

    if (authDetail) {
      setAuthName(authDetail.name || '');
      setRoleName(authDetail.role?.name || '');
    }
  }, [authDetail]);

  let navbarIcon =
    fixed && scrolled
      ? useColorModeValue("gray.700", "gray.200")
      : useColorModeValue("white", "gray.200");
  let menuBg = useColorModeValue("white", "navy.800");
  if (secondary) {
    navbarIcon = "white";
  }

  const logoutHandel = () => {
    dispatch(adminLogout());
    localStorage.clear();
    history.push('/auth/signin');
  };
  return (
    <>
      <Drawer
        isOpen={props.isOpen}
        onClose={props.onClose}
        placement={document.documentElement.dir === "rtl" ? "left" : "right"}
        finalFocusRef={settingsRef}
        blockScrollOnMount={false}
      >
        <DrawerContent bg={bgDrawer}>
          <DrawerHeader pt="24px" px="24px">
            <DrawerCloseButton />
            <Text fontSize="xl" fontWeight="bold" mt="16px">
              Kavach Configurator
            </Text>
            <Text fontSize="lg" fontWeight="bold" mt="16px">
              Name: {authName}
            </Text>
            <Text fontSize="lg" fontWeight="bold" mb="16px">
              Role: {rolename}
            </Text>
            <Text fontSize="md" mb="16px">
              See your dashboard options.
            </Text>
            <HSeparator />
          </DrawerHeader>
          <DrawerBody w="340px" ps="24px" pe="40px">
            <Flex flexDirection="column">
              <Flex justifyContent="space-between " mb="16px">
                <Text fontSize="md" fontWeight="600" mb="4px">
                  Navbar Fixed
                </Text>
                <Switch
                  colorScheme="blue"
                  isChecked={switched}
                  onChange={() => {
                    if (switched === true) {
                      props.onSwitch(false);
                      setSwitched(false);
                    } else {
                      props.onSwitch(true);
                      setSwitched(true);
                    }
                  }}
                />
              </Flex>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                mb="24px"
              >
                <Text fontSize="md" fontWeight="600" mb="4px">
                  Dark/Light
                </Text>
                <Button
                  onClick={toggleColorMode}
                  color={colorMode === "light" ? "Dark" : "Light"}
                >
                  Toggle {colorMode === "light" ? "Dark" : "Light"}
                </Button>
              </Flex>

              <HSeparator />
              <Box mt="24px">
                <Box>
                  <Link
                    href="#/admin/reset-password"
                    w="100%"
                    mb="16px"
                  >
                    <Button
                      w="100%"
                      mb="16px"
                      bg={bgButton}
                      color={colorButton}
                      variant="no-effects"
                      px="30px"
                      fontWeight="600"
                      onClick={() => onClose()}

                    >
                      Reset Password
                    </Button>
                  </Link>
                </Box>
              </Box>
            </Flex>

            <Button
              onClick={
                logoutHandel
              }
              w="100%"
              mb="16px"
              bg={bgButton}
              color={colorButton}
              variant="no-effects"
              px="30px"
              fontWeight="600"
            // position="absolute"  // Add this line
            // bottom="0"     

            >
              Logout
            </Button>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
