import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  SimpleGrid,
  Spacer,
  Text,
  Wrap,
  WrapItem,
  useColorModeValue,
  Spinner,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { highAlertAddOrUpdate } from "actions/highAlertAction";
import { checkpostGetAll } from "actions/checkpostActions";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader";
import { useToast } from '@chakra-ui/react'
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
} from '@chakra-ui/react'
import Select from 'react-select';
import axios from "axios";
import { server_url } from "constants/types";
import { HIGH_ALERT_CLEAR_SNACKBAR } from "constants/types";
import CustomInput from "views/CustomValidation";
import { useHistory } from 'react-router-dom';



function HighAlertForm() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [checkpost, setCheckpost] = useState([]);
  const [guard, setGuard] = useState([]);
  const [pin, setPin] = useState([]);
  const [status, setStatus] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const history = useHistory();
//   const textRegex = /^[A-Za-z\s]+$/;
//   const guardIdRegex = /^[a-zA-Z0-9]+$/;
//   const postalCodeRegex = /^[0-9]{6}$/;
//   const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,10}$/;
//   const numberRegex = /^\d{10}$/;
  const [isErrorCheckpost, setIsErrorCheckpost] = useState(false);

  const statuses = ['success', 'error', 'warning', 'info'];
  const responseStatus = useSelector(state => state.highAlert.status);
  const responseMessage = useSelector(state => state.highAlert.text);
  const loading = useSelector(state => state.highAlert.loading);
  const showSnackbar = useSelector(state => state.highAlert.showSnackbar);
  const checkpostList = useSelector(state => state.checkpost.checkpostList);
//   const deviceList = useSelector(state => state.device.deviceList);

  console.log(loading ? "yes" : "no")

  const dispatch = useDispatch();
  const toast = useToast();

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    setIsErrorCheckpost(checkpost === '');
    if (
      checkpost.length !== 0 
    ) {
      
      const newHighAlert = {
        'checkpost' : checkpost.map((module) => module.value),
         guard,
         pin,
         status,
      };

      console.log("newHighAlert");
      console.log(newHighAlert);
     
      dispatch(highAlertAddOrUpdate(newHighAlert, 'add'));
      history.push('/admin/highalert-table')
    }
  };

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        //   history.push('#/admin/gaurd-table');
        }
      } else if (responseStatus === 'NOK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: HIGH_ALERT_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);
  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])
  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };


  useEffect(() => {
    dispatch(checkpostGetAll());
  }, [])
  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <form onSubmit={handleSubmit}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">

          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              High Alert Form
            </Text>
            <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
             
              <FormControl isInvalid={isErrorCheckpost || (submitted && checkpost === "")}>
                <FormLabel>Check post</FormLabel>
                {/* <Select
                  placeholder="-- checkpost --"
                  onChange={(e) => {
                    setIsErrorCheckpost(e.target.value === '');
                    setCheckpost(e.target.value);
                  }}
                  isInvalid={isErrorCheckpost || (submitted && checkpost === "")}
                  value={checkpost}
                >
                  {checkpostList.length === 0 ? (<></>) : (checkpostList.map((item, index) => {
                    return (<option key={item._id} value={item._id}>{item.name}</option>)
                  }))}
                </Select> */}
                <Select
               isMulti
               name="colors"
               placeholder="--select checkpost--"
               options={checkpostList.map((item) => ({
                  value: item._id,
                  label: item.name
                  }))}
              
               onChange={(value) => {
                console.log(value);
                console.log(value)
                setCheckpost(value)
                 setIsErrorCheckpost(value === '')}}
                className="basic-multi-select"
                classNamePrefix="select"
              />
              
                {isErrorCheckpost && submitted && (
                  <FormErrorMessage>Checkpost is required.</FormErrorMessage>
                )}
              </FormControl>
            </SimpleGrid>
            <Center>
              {loading ? <Spinner mt='24px' size='lg' /> :
                <Button colorScheme='blue' mt='24px' type="submit">
                  Submit
                </Button>
              }
            </Center>
          </CardHeader>
          <CardBody>
            {/* ... */}
          </CardBody>
        </Card>
      </form>
    </Flex>
  );
}

export default HighAlertForm;
