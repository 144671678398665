import axios from 'axios';
import {
  EMPLOYEE_LOADING,
  EMPLOYEE_GET_ALL_SUCCESS,
  EMPLOYEE_ERROR,
  EMPLOYEE_CLEAR_SNACKBAR
} from '../constants/types';

import { server_url } from '../constants/types';

export const employeeGetAll = (pagination = '') => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: EMPLOYEE_LOADING,
        payload: {
          loading: true
        }
      });
      axios
        .get(`${server_url}/employee?${pagination}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
        .then((response) => {
          console.log("employee-data-data-data");
          console.log(response.data.data)
          dispatch({
            type: EMPLOYEE_GET_ALL_SUCCESS,
            payload: {
              employeeAttendenceHistoryList: [],
              employeeList: response.data.data,
              limit: response.data.limit,
              page: response.data.currentPage,
              totalPages: response.data.totalPages,
              response: response.data,
              loading: false,
              status: 'success',
              text: 'Get all role employee successfully.'
            }
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: EMPLOYEE_ERROR,
            payload: {
              status: 'error',
              text: 'Error occurred during getting employee data.',
              loading: false
            }
          });
          resolve();
        });
    });
  };
};

export const employeeAttendanceHistoryGetAll = (employee,search) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: EMPLOYEE_LOADING,
        payload: {
          loading: true
        }
      });
      axios
        .get(`${server_url}/employee/attendance/history/list/${employee._id}?${search}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
        .then((response) => {
          console.log("pradeep-data");
          console.log(response.data.data)
          dispatch({
            type: EMPLOYEE_GET_ALL_SUCCESS,
            payload: {
              employeeAttendenceHistoryList: response.data.data,
              employeeList: [],
              limit: response.data.limit,
              page: response.data.currentPage,
              totalPages: response.data.totalPages,
              response: response.data,
              loading: false,
              status: 'success',
              text: 'Employee Attendence History List successfully.'
            }
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: EMPLOYEE_ERROR,
            payload: {
              status: 'error',
              text: 'Error occurred during getting employee data.',
              loading: false
            }
          });
          resolve();
        });
    });
  };
};

export const employeeAddOrUpdate = (employee, state,limit="",page="") => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: EMPLOYEE_LOADING,
        payload: {
          loading: true
        }
      });
      if (state === 'add') {
        console.log(employee)
        axios
          .post(`${server_url}/employee`, employee, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
          })
          .then((responseF) => {
            console.log("yes")
            axios
              .get(`${server_url}/employee?limit=${limit}&page=${page}`, {
                headers: {
                  'Authorization': 'Bearer ' + localStorage.getItem('authToken')
                }
              })
              .then((response) => {
                console.log("response.data.data of employee add")
                console.log(response.data.data)
                console.log(responseF.data['status'])
                dispatch({
                  type: EMPLOYEE_GET_ALL_SUCCESS,
                  payload: {
                    employeeList: response.data.data,
                    limit: response.data.limit,
                    page: response.data.currentPage,
                    totalPages: response.data.totalPages,
                    response: response.data,
                    loading: false,
                    status: responseF.data['status'],
                    text: responseF.data['message']
                  }
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: EMPLOYEE_ERROR,
                  payload: {
                    text: 'Error occurred during getting employee data.',
                    status: 'error',
                    loading: false
                  }
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: EMPLOYEE_ERROR,
              payload: {
                text: 'Error occurred during adding employee data.',
                status: 'error',
                loading: false
              }
            });
            resolve();
          });
      }
      // else if (state === 'upload') {
      //   console.log(employee)
      //   axios
      //     .post(`${server_url}/employee/upload-excelsheet`, employee, {
      //       headers: {
      //         'Content-Type': 'multipart/form-data',
      //         'Authorization': 'Bearer ' + localStorage.getItem('authToken')
      //       }
      //     })
      //     .then((responseF) => { console.log("yes")
      //       axios
      //         .get(`${server_url}/employee`, {
      //           headers: {
      //             'Authorization': 'Bearer ' + localStorage.getItem('authToken')
      //           }
      //         })
      //         .then((response) => {
      //           console.log("response.data.data of employee add")
      //           console.log(response.data.data)
      //           console.log(responseF.data['status'])
      //           dispatch({
      //             type: EMPLOYEE_GET_ALL_SUCCESS,
      //             payload: {
      //               employeeList: response.data.data,
      //               response: response.data,
      //               loading: false,
      //               status: responseF.data['status'],
      //               text: responseF.data['message']
      //             }
      //           });
      //           resolve();
      //         })
      //         .catch((e) => {
      //           dispatch({
      //             type: EMPLOYEE_ERROR,
      //             payload: {
      //               text: 'Error occurred during getting employee data.',
      //               status: 'error',
      //               loading: false
      //             }
      //           });
      //           resolve();
      //         });
      //     })
      //     .catch((e) => {
      //       dispatch({
      //         type: EMPLOYEE_ERROR,
      //         payload: {
      //           text: 'Error occurred during adding employee data.',
      //           status: 'error',
      //           loading: false
      //         }
      //       });
      //       resolve();
      //     });
      // }
      else if (state === "upload") {
        axios
          .post(`${server_url}/employee/upload-excelsheet`, employee, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: 'Bearer ' + localStorage.getItem('authToken'),  
            },
          })
          .then((responseF) => {
            axios
              .get(`${server_url}/employee?limit=${limit}&page=${page}`, {
                headers: {
                  Authorization: 'Bearer ' + localStorage.getItem('authToken'),
                },
              })
              .then((response) => {
                dispatch({
                  type: EMPLOYEE_GET_ALL_SUCCESS,
                  payload: {
                    employeeList: response.data.data,
                    limit: response.data.limit,
                    page: response.data.currentPage,
                    totalPages: response.data.totalPages,
                    response: response.data,
                    loading: false,
                    status: responseF.data["status"],
                    text: responseF.data["message"],
                  },
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: EMPLOYEE_ERROR,
                  payload: {
                    text: "Error occurred during getting Employee data.",
                    status: "error",
                    loading: false,
                  },
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: EMPLOYEE_ERROR,
              payload: {
                text: "Error occurred during adding Employee data.",
                status: "error",
                loading: false,
              },
            });
            resolve();
          });
      }

      else if (state === 'edit') {
        axios
          .put(`${server_url}/employee/${employee._id}`, employee, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
          })
          .then((responseF) => {
            axios
              .get(`${server_url}/employee?limit=${limit}&page=${page}`, {
                headers: {
                  'Authorization': 'Bearer ' + localStorage.getItem('authToken')
                }
              })
              .then((response) => {
                console.log("responceece");
                console.log(responseF.data['data']);
                dispatch({
                  type: EMPLOYEE_GET_ALL_SUCCESS,
                  payload: {
                    employeeList: response.data.data,
                    limit: response.data.limit,
                    page: response.data.currentPage,
                    totalPages: response.data.totalPages,
                    response: response.data,
                    loading: false,
                    status: responseF.data['status'],
                    employeeDetail: responseF.data['data'],
                    text: responseF.data['message']
                  }
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: EMPLOYEE_ERROR,
                  payload: {
                    text: 'Error occurred during getting employee data.',
                    status: 'error',
                    loading: false
                  }
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: EMPLOYEE_ERROR,
              payload: {
                text: 'Error occurred during updating employee data.',
                status: 'error',
                loading: false
              }
            });
            resolve();
          });
      } else if (state === 'view') {
        axios
          .put(`${server_url}/employee/${employee._id}`, employee, {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
          })
          .then((responseF) => {

            axios
              .get(`${server_url}/employee`, {
                headers: {
                  'Authorization': 'Bearer ' + localStorage.getItem('authToken')
                }
              })
              .then((response) => {
                console.log("employee-details")
                console.log(responseF.data['data'])
                dispatch({
                  type: EMPLOYEE_GET_ALL_SUCCESS,
                  payload: {
                    employeeList: response.data.data,
                    limit: response.data.limit,
                    page: response.data.currentPage,
                    totalPages: response.data.totalPages,
                    response: response.data,
                    employeeDetail: responseF.data['data'],
                    loading: false,
                    status: responseF.data['status'],
                    text: responseF.data['message']
                  }
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: EMPLOYEE_ERROR,
                  payload: {
                    text: 'Error occurred during getting employee data.',
                    status: 'error',
                    loading: false
                  }
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: EMPLOYEE_ERROR,
              payload: {
                text: 'Error occurred during updating employee data.',
                status: 'error',
                loading: false
              }
            });
            resolve();
          });
      }
    });
  };
};

export const employeeDelete = (employee) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: EMPLOYEE_LOADING,
        payload: {
          loading: true
        }
      });
      axios
        .delete(`${server_url}/employee/${employee}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
        .then((responseF) => {
          axios
            .get(`${server_url}/employee`, {
              headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
              }
            })
            .then((response) => {
              dispatch({
                type: EMPLOYEE_GET_ALL_SUCCESS,
                payload: {
                  employeeList: response.data.data,
                  limit: response.data.limit,
                  page: response.data.currentPage,
                  totalPages: response.data.totalPages,
                  response: response.data,
                  employeeDetail: responseF.data['data'],
                  loading: false,
                  status: responseF.data['status'],
                  text: responseF.data['message']
                }
              });
              resolve();
            })
            .catch((e) => {
              dispatch({
                type: EMPLOYEE_ERROR,
                payload: {
                  text: 'Error occurred during getting employee data.',
                  status: 'error',
                  loading: false
                }
              });
              resolve();
            });
        })
        .catch((e) => {
          dispatch({
            type: EMPLOYEE_ERROR,
            payload: {
              text: 'Error occurred during deleting employee data.',
              status: 'error',
              loading: false
            }
          });
          resolve();
        });
    });
  };
};

export const employeeClearShowSnackbar = () => {
  return (dispatch, getState) => {
    dispatch({
      type: EMPLOYEE_CLEAR_SNACKBAR
    });
  };
};
