import React, { useEffect, useState } from 'react';
import { Flex, InputGroup, Spacer, Table, Tbody, Text, Td, Th, Thead, Tr, useColorModeValue, InputLeftElement, IconButton, Input, Icon, Link } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';

import { useToast } from '@chakra-ui/react';

import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';

import { AddIcon } from '@chakra-ui/icons';
import { VISITORINOUT_CLEAR_SNACKBAR ,VISITOR_SEARCH} from 'constants/types';

import VisitorInOutTableRow from './VisitorInOutTableRow';
import { VisitorInOutGetAll } from '../../actions/VisitorInOutAction';
import VisitorInOutPagination from './VisitorInOutPagination';
import VisitorInOutAdvanceSearch from "./VisitorInOutAdvanceSearch"

const VisitorInOutTable = () => {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const responseStatus = useSelector(state => state.visitorInOut.status);
  const responseMessage = useSelector(state => state.visitorInOut.text);
  const loading = useSelector(state => state.visitorInOut.loading);
  const VisitorInOutList = useSelector(state => state.visitorInOut.visitorInOutList);
  const response = useSelector(state => state.visitorInOut.response);
  const showSnackbar = useSelector(state => state.visitorInOut.showSnackbar);
  const currentPage = useSelector(state => state.visitorInOut.page);
  const limit = useSelector(state => state.visitorInOut.limit);
  const dispatch = useDispatch();
  const toast = useToast();
  const searchIconColor = useColorModeValue("gray.700", "gray.200");
  const inputBg = useColorModeValue("#edf3f8", "navy.800");
  const [search, setSearch] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {
    dispatch(VisitorInOutGetAll());
    dispatch({
      type : VISITOR_SEARCH,
      payload : {
        searching : '',
      }
    })
  }, [dispatch]);
  console.log("VisitorInOutList------")
  console.log(VisitorInOutList)

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: VISITORINOUT_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("desc");
    }
    const sorting = "&sortOrder=" + sortOrder + "&column=" + column;
    dispatch({
      type : VISITOR_SEARCH,
      payload : {
        searching : '',
      }
    })
    dispatch(VisitorInOutGetAll(sorting));
  };

  useEffect(() => {
    const searching = "&search=" + search;
    dispatch({
      type : VISITOR_SEARCH,
      payload : {
        searching : '',
      }
    })
    dispatch(VisitorInOutGetAll(searching));
  }, [search]);

  return (
  <>
  <VisitorInOutAdvanceSearch></VisitorInOutAdvanceSearch>
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <Flex>
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Visitor In Out Table
            </Text>
          </CardHeader>
          <Spacer />

          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              <InputGroup borderRadius="8px" w="200px">
                <InputLeftElement
                  children={
                    <IconButton
                      bg="inherit"
                      borderRadius="inherit"
                      _hover="none"
                      _active={{
                        bg: "inherit",
                        transform: "none",
                        borderColor: "transparent",
                      }}
                      _focus={{
                        boxShadow: "none",
                      }}
                      icon={
                        <SearchIcon
                          color={searchIconColor}
                          w="15px"
                          h="15px"
                        />
                      }
                    ></IconButton>
                  }
                />
                <Input
                  variant="search"
                  fontSize="xs"
                  bg={inputBg}
                  onKeyUp={(e) => {
                    setSearch(e.target.value);
                    if (e.target.value.length < 3) {
                      setSearch("");
                    }
                  }}
                  placeholder="Type here..."
                />
              </InputGroup>
            </Text>
          </CardHeader>
        </Flex>
        <CardBody pb={4}>
          <div style={{ overflowX: "auto" }}>
            <Table variant="simple" color={textColor}>
            <Thead>
              <Tr my=".8rem" pl="0px" color="gray.400">
              <Th  pl="0px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("vechilenumber")}
                  cursor="pointer">SNo</Th>
              <Th
                  pl="0px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("vechilenumber")}
                  cursor="pointer"
                >
                  Txt Number
                  {sortColumn === "vechilenumber" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  pl="0px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("vechilenumber")}
                  cursor="pointer"
                >
                  Guard Name Entry Raised by
                  {sortColumn === "vechilenumber" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>

                <Th
                  pl="0px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("vechilenumber")}
                  cursor="pointer"
                >
                  Checkpost In Name
                  {sortColumn === "vechilenumber" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  pl="0px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("vechilenumber")}
                  cursor="pointer"
                >
                  Image
                  {sortColumn === "vechilenumber" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("drivername")}
                  cursor="pointer"
                >
                  Name
                  {sortColumn === "drivername" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("drivername")}
                  cursor="pointer"
                >
                  Company
                  {sortColumn === "drivername" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("intime")}
                  cursor="pointer"
                >
                  Contact No
                  {sortColumn === "intime" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("intime")}
                  cursor="pointer"
                >
                  Visitor Type
                  {sortColumn === "intime" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("outtime")}
                  cursor="pointer"
                >
                 Purpose
                  {sortColumn === "outtime" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("outtime")}
                  cursor="pointer"
                >
                Department
                  {sortColumn === "outtime" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("outtime")}
                  cursor="pointer"
                >
                Sub Department
                  {sortColumn === "outtime" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("outtime")}
                  cursor="pointer"
                >
                Carry item
                  {sortColumn === "outtime" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("outtime")}
                  cursor="pointer"
                >
                vehicle Type
                  {sortColumn === "outtime" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>

                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("outtime")}
                  cursor="pointer"
                >
                vehicle Number
                  {sortColumn === "outtime" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>

                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("outtime")}
                  cursor="pointer"
                >
               total Time
                  {sortColumn === "outtime" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("outtime")}
                  cursor="pointer"
                >
               Exit By Guard Name
                  {sortColumn === "outtime" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("outtime")}
                  cursor="pointer"
                >
               checkpost Out Name
                  {sortColumn === "outtime" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("outtime")}
                  cursor="pointer"
                >
               in Time
                  {sortColumn === "outtime" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("outtime")}
                  cursor="pointer"
                >
               out Time
                  {sortColumn === "outtime" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>

              </Tr>
            </Thead>
              <Tbody>
                {VisitorInOutList ? (VisitorInOutList.map((row, index, arr) => {

                  return (
                    <VisitorInOutTableRow
                    serialnumber={((currentPage - 1)*limit) + (index+1)}
                    txnNumber = {row.txnNumber}
                    guardNameEntryRaisedBy = {row.guard.name}
                    checkpostInName = {row.checkpostNameIn}
                    image = {row.image}
                    name = {row.name}
                    company = {row.company}
                    contactno = {row.contactNo}
                    visitorType ={row.visitorType.name}
                    purpose = {row.purpose}
                    department = {row.department[0].departmentName}
                    subdepartment = {row.department[0].subDepartmentName}
                    carryItem = {row.otherDetails.carryItems}
                    vehicleType = {row.otherDetails.vehicleType}
                    vehicleNumber = {row.otherDetails.vehicleNumber}
                    totalTime = {row.totalTime}
                    ExitByGuardName = {row.guardOut ? row.guardOut.name : <></>}
                    checkpostOutName = {row.checkpostNameOut}
                    inTime={row.inDateTime}
                    outTime={row.outDateTime}
                    isLast={index === arr.length - 1 ? true : false}
                    id={row._id}

                    />
                  );
                })) : (<h1></h1>)}
              </Tbody>
            </Table>
          </div>
          {VisitorInOutList.length === 0 ? (
            <></>
          ) : (
            <VisitorInOutPagination
              totalPages={response.totalPages}
              currentPage={response.currentPage}
              perPage={response.perPage}
              sortBy={sortColumn}
              sortOrder={sortOrder}
              handlePageClick={(data) => {
                const selectedPage = data.selected + 1;
                dispatch(VisitorInOutGetAll(`&page=${selectedPage}`));
              }}
            />
          )}
        </CardBody>
      </Card>
    </Flex>
  </>
  );
};

export default VisitorInOutTable;
