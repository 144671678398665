// Chakra imports
import {
    Box,
    Button,
    Center,
    Flex,
    Image,
    Input,
    SimpleGrid,
    Spacer,
    Stack,
    Text,
    Wrap,
    WrapItem,
    useColorModeValue
  } from "@chakra-ui/react";
  // Custom components
  import Card from "components/Card/Card.js";
  import CardBody from "components/Card/CardBody.js";
  import CardHeader from "components/Card/CardHeader.js";
  import React, { useEffect, useState } from "react";
  import { useDispatch, useSelector } from 'react-redux';
  import { reportIncedentModuleAddOrUpdate, reportIncedentModuleClearShowSnackbar } from '../../actions/reportIncedentModuleAction';
  import { useToast } from '@chakra-ui/react'
  import { useParams } from "react-router-dom/cjs/react-router-dom.min";
  import { REPORTINCEDENT_MODULE_CLEAR_SNACKBAR } from "constants/types";
  import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
  } from '@chakra-ui/react'
  import { Skeleton, SkeletonCircle, SkeletonText } from '@chakra-ui/react'
  
  function ReportIncedentModuleView() {
    const textColor = useColorModeValue("gray.700", "white");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    const [name, setName] = useState("");
    const [image, setImage] = useState("");
    const [status, setStatus] = useState("");
    const [description, setDescription] = useState("");
    const [type, setType ] = useState("");
    const [createdAt,setCreatedAt ] = useState("");
    const [updatedAt,setUpdatedAt] = useState("");
  
    const dispatch = useDispatch();
    const toast = useToast();
  
    const statuses = ['success', 'error', 'warning', 'info'];
    const responseStatus = useSelector(state => state.reportIncedentModule.status);
    const responseMessage = useSelector(state => state.reportIncedentModule.text);
    const loading = useSelector(state => state.reportIncedentModule.loading);
    const reportIncedentModuleDetail = useSelector(state => state.reportIncedentModule.reportIncedentModuleDetail);
    const showSnackbar = useSelector(state => state.reportIncedentModule.showSnackbar);
  
    const params = useParams();
    useEffect(() => {
      if (showSnackbar) {
        if (responseStatus === 'OK' && loading === false) {
          console.log(reportIncedentModuleDetail);
          setName(reportIncedentModuleDetail.name);
          setImage(reportIncedentModuleDetail.image);
          setStatus(reportIncedentModuleDetail.status ? "Active" : "InActive");
          setDescription(reportIncedentModuleDetail.description);
          setType(reportIncedentModuleDetail.type)
          setCreatedAt(reportIncedentModuleDetail.createdAt)
          setUpdatedAt(reportIncedentModuleDetail.updatedAt)
          
          if (responseMessage !== "") {
            toastFunction(responseMessage, 'success');
          }
        } else if (responseStatus === 'NOK' && loading === false) {
          if (responseMessage !== "") {
            toastFunction(responseMessage, 'error');
          }
        }
      }
  
      dispatch({
        type: REPORTINCEDENT_MODULE_CLEAR_SNACKBAR,
        payload: {
          showSnackbar: false,
        }
      })
    }, [loading]);
  
    useEffect(() => {
      console.log("params");
      const reportIncedentModuleId = params.id;
      const reportIncedentModule = {
        _id : reportIncedentModuleId
      };
      dispatch(reportIncedentModuleAddOrUpdate(reportIncedentModule,'view'));
    }, []);
  
    const toastFunction = (title, status) => {
      toast({
        title: title,
        status: status,
        duration: 3000,
        isClosable: true,
      })
    };
  
    return (
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Report Incedent Module View
            </Text>
          </CardHeader>
          <CardBody>
            {loading ? <Stack>
              <Skeleton height='20px' />
              <Skeleton height='20px' />
              <Skeleton height='20px' />
            </Stack>
              :
              <TableContainer>
                <Table variant='simple'>
                  <Tbody>
                    <Tr>
                      <Td>Name</Td>
                      <Td>{name}</Td>
                    </Tr>
                    <Tr>
                      <Td>Description</Td>
                      <Td>{description}</Td>
                    </Tr>
                    <Tr>
                      <Td>Status</Td>
                      <Td>{status}</Td>
                    </Tr>
                    <Tr>
                      <Td>Type</Td>
                      <Td>{type}</Td>
                    </Tr>
                    {/* <Tr>
                      <Td>Created At</Td>
                      <Td>{createdAt}</Td>
                    </Tr>
                    <Tr>
                      <Td>Updated At</Td>
                      <Td>{updatedAt}</Td>
                    </Tr> */}
                    <Tr>
                      <Td>Image</Td>
                      <Td><Flex direction="column">
                        <Text fontSize="md" color={textColor} fontWeight="bold">
                        <Image
                            boxSize='100px'
                            objectFit='cover'
                            src={image}
                            alt='Dan Abramov'
                          />
                          
                        </Text>
                      </Flex>
                      </Td>
                    </Tr>
                   
                  </Tbody>
                </Table>
              </TableContainer>}
          </CardBody>
        </Card>
      </Flex>
    );
  }
  
  export default ReportIncedentModuleView;