const {
    DESIGNATION_LOADING,
    DESIGNATION_GET_ALL_SUCCESS,
    DESIGNATION_ERROR,
    DESIGNATION_CLEAR_SNACKBAR
  } = require('../constants/types');
  
  const initialState = {
    response: {},
    designationList: [],
    designationDetail: {},
    loading: false,
    status: 'success',
    text: '',
    showSnackbar: false
  };
  
  const designationReducer = (state = initialState, action) => {
    switch (action.type) {
      case DESIGNATION_CLEAR_SNACKBAR:
        return {
          ...state,
          showSnackbar: false
        };
      case DESIGNATION_LOADING:
        return {
          ...state,
          loading: true
        };
      case DESIGNATION_ERROR:
        return {
          ...state,
          designationList: [],
          response: {},
          designationDetail: {},
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true
        };
      case DESIGNATION_GET_ALL_SUCCESS:
        return {
          ...state,
          designationList: action.payload.designationList,
          response: action.payload.response,
          designationDetail: action.payload.designationDetail,
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true
        };
      default:
        return state || {};
    }
  };
  
  export default designationReducer;
  // module.exports = designationReducer;
  