import axios from 'axios';
import {
  MODULE_LOADING,
  MODULE_GET_ALL_SUCCESS,
  MODULE_ERROR,
  MODULE_CLEAR_SNACKBAR
} from '../constants/types';

import {
  server_url
} from '../constants/types';

export const moduleGetAll = (pagination = "") => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: MODULE_LOADING,
        payload: {
          loading: true
        }
      });
      axios.get(`${server_url}/module?${pagination}`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      })
        .then(response => {
          dispatch({
            type: MODULE_GET_ALL_SUCCESS,
            payload: {
              moduleList: response.data['data'],
              response: response.data,
              loading: false,
              status: "success",
              text: "Get all module data successfully."
            }
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: MODULE_ERROR,
            payload: {
              status: "error",
              text: "Error occurred during getting module data.",
              loading: false
            }
          });
          resolve();
        });
    });
  };
};

export const moduleAddOrUpdate = (module, state) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: MODULE_LOADING,
        payload: {
          loading: true
        }
      });
      if (state === "add") {
        axios.post(`${server_url}/module`, module, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
          .then(responseF => {
            axios.get(`${server_url}/module`, {
              headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
              }
            })
              .then(response => {
                dispatch({
                  type: MODULE_GET_ALL_SUCCESS,
                  payload: {
                    moduleList: response.data.data,
                    response: response.data,
                    loading: false,
                    status: responseF.data['status'],
                    text: responseF.data['message']
                  }
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: MODULE_ERROR,
                  payload: {
                    text: "Error occurred during getting module data.",
                    status: "error",
                    loading: false
                  }
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: MODULE_ERROR,
              payload: {
                text: "Error occurred during adding module data.",
                status: "error",
                loading: false
              }
            });
            resolve();
          });
      } else if (state === "edit") {
        axios.put(`${server_url}/module/${module._id}`, module, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
          .then(responseF => {
            axios.get(`${server_url}/module`, {
              headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
              }
            })
              .then(response => {
                dispatch({
                  type: MODULE_GET_ALL_SUCCESS,
                  payload: {
                    moduleList: response.data.data,
                    response: response.data,
                    moduleDetail: responseF.data['data'],
                    loading: false,
                    status: responseF.data['status'],
                    text: responseF.data['message']
                  }
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: MODULE_ERROR,
                  payload: {
                    text: "Error occurred during getting module data.",
                    status: "error",
                    loading: false
                  }
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: MODULE_ERROR,
              payload: {
                text: "Error occurred during updating module data.",
                status: "error",
                loading: false
              }
            });
            resolve();
          });
      }
      else if (state === "view") {
        axios.get(`${server_url}/module/${module._id}`, module, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
          .then(responseF => {
            axios.get(`${server_url}/module`, {
              headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
              }
            })
              .then(response => {
                dispatch({
                  type: MODULE_GET_ALL_SUCCESS,
                  payload: {
                    moduleList: response.data.data,
                    response: response.data,
                    moduleDetail: responseF.data['data'],
                    loading: false,
                    status: responseF.data['status'],
                    text: responseF.data['message']
                  }
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: MODULE_ERROR,
                  payload: {
                    text: "Error occurred during getting module data.",
                    status: "error",
                    loading: false
                  }
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: MODULE_ERROR,
              payload: {
                text: "Error occurred during updating module data.",
                status: "error",
                loading: false
              }
            });
            resolve();
          });
      }
    });
  };
};

export const moduleDelete = (module) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: MODULE_LOADING,
        payload: {
          loading: true
        }
      });
      axios.delete(`${server_url}/module/${module._id}`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      })
        .then(responseF => {
          axios.get(`${server_url}/module`, {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
          })
            .then(response => {
              dispatch({
                type: MODULE_GET_ALL_SUCCESS,
                payload: {
                  moduleList: response.data.data,
                  response: response.data,
                  moduleDetail: responseF.data['data'],
                  loading: false,
                  status: responseF.data['status'],
                  text: responseF.data['message']
                }
              });
              resolve();
            })
            .catch((e) => {
              dispatch({
                type: MODULE_ERROR,
                payload: {
                  text: "Error occurred during getting module data.",
                  status: "error",
                  loading: false
                }
              });
              resolve();
            });
        })
        .catch((e) => {
          dispatch({
            type: MODULE_ERROR,
            payload: {
              text: "Error occurred during deleting module data.",
              status: "error",
              loading: false
            }
          });
          resolve();
        });
    });
  };
};

export const moduleClearShowSnackbar = () => {
  return (dispatch, getState) => {
    dispatch({
      type: MODULE_CLEAR_SNACKBAR
    });
  };
};
