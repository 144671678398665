import axios from 'axios';
import {
    TRANSPORTINOUT_LOADING,
    TRANSPORTINOUT_GET_ALL_SUCCESS,
    TRANSPORTINOUT_ERROR,
    TRANSPORTINOUT_CLEAR_SNACKBAR
} from '../constants/types';

import { server_url } from '../constants/types';

export const TransportInOutGetAll = (pagination = '') => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: TRANSPORTINOUT_LOADING,
        payload: {
          loading: true
        }
      });
      axios
        .get(`${server_url}/transport?${pagination}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
        .then((response) => {
          
          console.log(response.data.data)
          dispatch({
            type: TRANSPORTINOUT_GET_ALL_SUCCESS,
            payload: {
              transportInOutList: response.data.data,
              limit: response.data.limit,
              page: response.data.currentPage,
              totalPages: response.data.totalPages,
              response: response.data,
              loading: false,
              status: 'success',
              text: 'Get all transport successfully.'
            }
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: TRANSPORTINOUT_ERROR,
            payload: {
              status: 'error',
              text: 'Error occurred during getting transport  data.',
              loading: false
            }
          });
          resolve();
        });
    });
  };
};


export const transportGetById = (transport , limit='' ,page='') => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: TRANSPORTINOUT_LOADING,
        payload: {
          loading: true
        }
      });
      axios
        .get(`${server_url}/Transport-view/${transport}?limit=${limit}&page=${page}`)
        .then((responseF) => {
          axios
            .get(`${server_url}/transport`, {
              headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
              }
            })
            .then((response) => {
              dispatch({
                type: TRANSPORTINOUT_GET_ALL_SUCCESS,
                payload: {
                  transportInOutList: response.data.data,
                  limit: response.data.limit,
                  page: response.data.currentPage,
                  totalPages: response.data.totalPages,
                  response: response.data,
                  transportInOutDetail: responseF.data['data'],
                  loading: false,
                  status: responseF.data['status'],
                  text: responseF.data['message']
                }
              });
              resolve();
            })
            .catch((e) => {
              dispatch({
                type: TRANSPORTINOUT_ERROR,
                payload: {
                  text: 'Error occurred during getting transport data.',
                  status: 'error',
                  loading: false
                }
              });
              resolve();
            });
        })
        .catch((e) => {
          dispatch({
            type: TRANSPORTINOUT_ERROR,
            payload: {
              text: 'Error occurred during deleting transport data.',
              status: 'error',
              loading: false
            }
          });
          resolve();
        });
    });
  };
};
