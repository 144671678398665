import React, { useEffect } from "react";
// Chakra imports
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Icon,
  Link,
  Switch,
  Text,
  useColorModeValue,
  FormErrorMessage,
  Center,
} from "@chakra-ui/react";
// Assets
import signInImage from "assets/img/signInImage.png";
import { FaApple, FaFacebook, FaGoogle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  adminLogin,
} from "../../actions/authActions";

import { useToast } from "@chakra-ui/react";
import { ADMIN_CLEAR_SNACKBAR } from "constants/types";
import { Spinner } from "@chakra-ui/react";
import { useState } from "react";
import { useHistory } from 'react-router-dom';

function SignIn() {
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const bgForm = useColorModeValue("white", "navy.800");
  const titleColor = useColorModeValue("gray.700", "blue.500");
  const colorIcons = useColorModeValue("gray.700", "white");
  const bgIcons = useColorModeValue("trasnparent", "navy.700");
  const bgIconsHover = useColorModeValue("gray.50", "whiteAlpha.100");
  const dispatch = useDispatch();
  const toast = useToast();
  const history = useHistory();

  const responseStatus = useSelector((state) => state.auth.status);
  const responseMessage = useSelector((state) => state.auth.text);
  const loading = useSelector((state) => state.auth.loading);
  const showSnackbar = useSelector((state) => state.auth.showSnackbar);
  const authDetail = useSelector((state) => state.auth.authDetail);

  const [email,setEmail] = useState("");
  const [password,setPassword] = useState("");
  const [isErrorEmail, setIsErrorEmail] = useState(false);
  const [isErrorPassword, setIsErrorPassword] = useState(false);

  const handelForm = () =>
  {
    
      
    setIsErrorEmail(email === "");
    setIsErrorPassword(password === "");
    

    if(email !="" && password !="")
    {
      const admin = {
        email : email,
        password : password
      };
      console.log(admin);
      dispatch(adminLogin(admin));
    }

  }

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === "OK" && loading === false) {
        if (responseMessage !== "") {
          localStorage.setItem('authToken', authDetail.authToken);
          localStorage.setItem('adminName', authDetail?.role.name);
          console.log(localStorage.getItem('authToken'));
          console.log( 'authToken');
          toastFunction(responseMessage, "success");
          if(localStorage.getItem('authToken') != null)
          {
            history.push('/admin/dashboard');
          }
          
        }
      } else if (responseStatus === "NOK" && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, "error");
        }
      }
    }
    dispatch({
      type: ADMIN_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      },
    });
  }, [loading]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  };
  return (
    <Flex position='relative' mb='40px'>
      <Flex
        // minH={{ md: "1000px" }}
        h={{ sm: "initial", md: "75vh", lg: "85vh" }}
        w='100%'
        maxW='1044px'
        mx='auto'
        justifyContent='space-between'
        mb='30px'
        pt={{ md: "0px" }}>
        <Flex
          w='100%'
          h='100%'
          alignItems='center'
          justifyContent='center'
          mb='60px'
          mt={{ base: "50px", md: "20px" }}>
          <Flex
            zIndex='2'
            direction='column'
            w='445px'
            background='transparent'
            borderRadius='15px'
            p='40px'
            mx={{ base: "100px" }}
            m={{ base: "20px", md: "auto" }}
            bg={bgForm}
            boxShadow={useColorModeValue(
              "0px 5px 14px rgba(0, 0, 0, 0.05)",
              "unset"
            )}>
            <Text
              fontSize='xl'
              color={textColor}
              fontWeight='bold'
              textAlign='center'
              mb='22px'>
              Login With
            </Text>
            <HStack spacing='15px' justify='center' mb='22px'>
              {/* <Flex
                justify='center'
                align='center'
                w='75px'
                h='75px'
                borderRadius='8px'
                border={useColorModeValue("1px solid", "0px")}
                borderColor='gray.200'
                cursor='pointer'
                transition='all .25s ease'
                bg={bgIcons}
                _hover={{ bg: bgIconsHover }}>
                <Link href='#'>
                  <Icon as={FaFacebook} color={colorIcons} w='30px' h='30px' />
                </Link>
              </Flex> */}
              {/* <Flex
                justify='center'
                align='center'
                w='75px'
                h='75px'
                borderRadius='8px'
                border={useColorModeValue("1px solid", "0px")}
                borderColor='gray.200'
                cursor='pointer'
                transition='all .25s ease'
                bg={bgIcons}
                _hover={{ bg: bgIconsHover }}>
                <Link href='#'>
                  <Icon
                    as={FaApple}
                    color={colorIcons}
                    w='30px'
                    h='30px'
                    _hover={{ filter: "brightness(120%)" }}
                  />
                </Link>
              </Flex> */}
              {/* <Flex
                justify='center'
                align='center'
                w='75px'
                h='75px'
                borderRadius='8px'
                border={useColorModeValue("1px solid", "0px")}
                borderColor='gray.200'
                cursor='pointer'
                transition='all .25s ease'
                bg={bgIcons}
                _hover={{ bg: bgIconsHover }}>
                <Link href='#'>
                  <Icon
                    as={FaGoogle}
                    color={colorIcons}
                    w='30px'
                    h='30px'
                    _hover={{ filter: "brightness(120%)" }}
                  />
                </Link>
              </Flex> */}
            </HStack>
            {/* <Text
              fontSize='lg'
              color='gray.400'
              fontWeight='bold'
              textAlign='center'
              mb='22px'>
              or
            </Text> */}
            <FormControl isInvalid={isErrorEmail} mb='24px'>
              <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>Email</FormLabel>
              <Input
              fontSize='sm'
             
              type='text'
              placeholder='Your full name'
              size='lg'
              onChange={(e) => {
                setIsErrorEmail(e.target.value === "");
                setEmail(e.target.value);
                }}
              />
              {!isErrorEmail ? (
                <></>
              ) : (
                <FormErrorMessage  >Email is required.</FormErrorMessage>
              )}
            </FormControl>
            
              <FormControl isInvalid={isErrorPassword} mb='24px'>
              <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                Password
              </FormLabel>
              <Input
                fontSize='sm'
                ms='4px'
                type='password'
                placeholder='Your password'
                
                size='lg'
                onChange={(e) => {
                  setIsErrorPassword(e.target.value === '')
                  setPassword(e.target.value);
                }}
              />
              {!isErrorPassword ? (
                <></>
              ) : (
                <FormErrorMessage>Password is required.</FormErrorMessage>
              )}
              </FormControl>
              {/* <FormControl display='flex' alignItems='center' mb='24px'>
                <Switch id='remember-login' colorScheme='blue' me='10px' />
                <FormLabel htmlFor='remember-login' mb='0' fontWeight='normal'>
                  Remember me
                </FormLabel>
              </FormControl> */}
              <FormControl>
              {loading ? (<Center><Spinner mt="24px" size="lg" /></Center>)
              :(<Button
                fontSize='10px'
                variant='dark'
                fontWeight='bold'
                w='100%'
                h='45'
                onClick={handelForm}
                mb='24px'>
                SIGN IN
              </Button>)}
            </FormControl >
            {/* <Flex
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
              maxW='100%'
              mt='0px'>
              <Text color={textColor} fontWeight='medium'>
                Already have an account?
                <Link
                  color={titleColor}
                  as='span'
                  ms='5px'
                  href='#'
                  fontWeight='bold'>
                  Sign In
                </Link>
              </Text>
            </Flex> */}
          </Flex>
        </Flex>
        <Box
          overflowX='hidden'
          h='100%'
          w='100%'
          left='0px'
          position='absolute'
          bgImage={signInImage}>
          <Box
            w='100%'
            h='100%'
            bgSize='cover'
            bg='blue.500'
            opacity='0.8'></Box>
        </Box>
      </Flex>
    </Flex>
  );
}

export default SignIn;
