import axios from 'axios';
import {
    GUARDATTENDENCE_LOADING,
    GUARDATTENDENCE_GET_ALL_SUCCESS,
    GUARDATTENDENCE_ERROR,
    GUARDATTENDENCE_CLEAR_SNACKBAR
} from '../constants/types';

import { server_url } from '../constants/types';

export const GuardAttendenceGetAll = (pagination = '') => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: GUARDATTENDENCE_LOADING,
        payload: {
          loading: true
        }
      });
      axios
        .get(`${server_url}/guard/attendance?${pagination}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
        .then((response) => {
            console.log("guard responce")
            console.log(response.data.data)
          dispatch({
            type: GUARDATTENDENCE_GET_ALL_SUCCESS,
            payload: {
              guardattendenceList: response.data.data,
              response: response.data,
              loading: false,
              status: 'success',
              text: 'Get all guards successfully.'
            }
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: GUARDATTENDENCE_ERROR,
            payload: {
              status: 'error',
              text: 'Error occurred during getting guard attendence data.',
              loading: false
            }
          });
          resolve();
        });
    });
  };
};


// export const GuardGetById = (guard) => {
//   return (dispatch, getState) => {
//     return new Promise((resolve) => {
//       dispatch({
//         type: GUARDATTENDENCE_LOADING,
//         payload: {
//           loading: true
//         }
//       });

//       // Fetch guard attendance details by ID
//       axios.get(`${server_url}/guard/attendance/${guard}`)
//         .then((responseF) => {
//           // Fetch guard attendence record list  by Id 
//           axios.get(`${server_url}/third/guard/attendance`, {
//             headers: {
//               'Authorization': 'Bearer ' + localStorage.getItem('authToken')
//             }
//           })
//           .then((ResponseE) => {
//             // Fetch other guard attendance records
//             axios.get(`${server_url}/guard/attendance`, {
//               headers: {
//                 'Authorization': 'Bearer ' + localStorage.getItem('authToken')
//               }
//             })
//             .then((response) => {
//               dispatch({
//                 type: GUARDATTENDENCE_GET_ALL_SUCCESS,
//                 payload: {
//                   guardattendenceList: response.data.data,
//                   response: response.data,
//                   guardattendenceDetail: responseF.data['data'],
//                   guardattendenceGetByIdList: ResponseE.data['data'], 
//                   loading: false,
//                   status: responseF.data['status'],
//                   text: responseF.data['message']
//                 }
//               });
//               resolve();
//             })
//             .catch((e) => {
//               dispatch({
//                 type: GUARDATTENDENCE_ERROR,
//                 payload: {
//                   text: 'Error occurred during getting Guard data.',
//                   status: 'error',
//                   loading: false
//                 }
//               });
//               resolve();
//             });
//           })
//           .catch((e) => {
//             dispatch({
//               type: GUARDATTENDENCE_ERROR,
//               payload: {
//                 text: 'Error occurred during getting third API data.',
//                 status: 'error',
//                 loading: false
//               }
//             });
//             resolve();
//           });
//         })
//         .catch((e) => {
//           dispatch({
//             type: GUARDATTENDENCE_ERROR,
//             payload: {
//               text: 'Error occurred during fetching guard attendance details.',
//               status: 'error',
//               loading: false
//             }
//           });
//           resolve();
//         });
//     });
//   };
// };



export const GuardGetById = (guard,month) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: GUARDATTENDENCE_LOADING,
        payload: {
          loading: true
        }
      });
      axios
        .post(`${server_url}/guard/attendance/${guard}`, month) 
        .then((responseF) => {
          axios
            .get(`${server_url}/guard/attendance`, {
              headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
              }
            })
            .then((response) => {
              dispatch({
                type: GUARDATTENDENCE_GET_ALL_SUCCESS,
                payload: {
                  guardattendenceList: response.data.data,
                  response: response.data,
                  guardattendenceDetail: responseF.data['data'],
                  loading: false,
                  status: responseF.data['status'],
                  text: responseF.data['message']
                }
              });
              resolve();
            })
            .catch((e) => {
              dispatch({
                type: GUARDATTENDENCE_ERROR,
                payload: {
                  text: 'Error occurred during getting Guard data.',
                  status: 'error',
                  loading: false
                }
              });
              resolve();
            });
        })
        .catch((e) => {
          dispatch({
            type: GUARDATTENDENCE_ERROR,
            payload: {
              text: 'Error occurred during deleting Guard data.',
              status: 'error',
              loading: false
            }
          });
          resolve();
        });
    });
  };
};
