import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
  useColorModeValue,
  useToast,
  Box,
  Center,
  Button,
  Flex,
} from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { useDispatch, useSelector } from "react-redux";
import { GROUP_CLEAR_SNACKBAR } from "constants/types";
// import { Spinner } from '@chakra-ui/react';
import dataConstant from "constants/dataConstant";
import { groupAddOrUpdate } from "../../actions/groupActions";
import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom'; 
function GroupForm() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [name, setName] = useState("");
  const [status, setStatus] = useState("");
  const [description, setDescription] = useState("");
  const [alertMessage, setAlertMessage] = useState('');
  const [isErrorName, setIsErrorName] = useState(false);
  const [isErrorStatus, setIsErrorStatus] = useState(false);
  const [isErrorDescription, setIsErrorDescription] = useState(false);

  const dispatch = useDispatch();
  const toast = useToast();
  const history = useHistory();
  const statuses = ['success', 'error', 'warning', 'info'];
  const responseStatus = useSelector(state => state.group.status);
  const responseMessage = useSelector(state => state.group.text);
  const loading = useSelector(state => state.group.loading);
  const showSnackbar = useSelector(state => state.group.showSnackbar);
  // Function to handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    setIsErrorName(name === '');
    setIsErrorStatus(status === '');
    setIsErrorDescription(description === '');

    if (name !== "" && status !== "" && description !== "") {
      console.log("Submit");
      // Get form data
      const groupData = {
        name: name,
        status: status,
        description: description,
        // Other group data fields
      };
      console.log(groupData);
      console.log('groupData');

      // Determine if it's an add or edit operation
      const state = 'add';
      // Call the groupAddOrUpdate action
      dispatch(groupAddOrUpdate(groupData , state))
    }
  };

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
          history.push('#/admin/group');
        }
      } else if (responseStatus === 'NOK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: GROUP_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);

  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
    <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
      
      <CardHeader p="6px 0px 22px 0px">
        <Text fontSize="xl" color={textColor} fontWeight="bold">
           Group Form
        </Text>
        <SimpleGrid minChildWidth='120px' spacing='40px'>
          <FormControl isInvalid={isErrorName}>
            <FormLabel>Name</FormLabel>
            <Input
              type='text'
              onChange={(e) => {
                setIsErrorName(e.target.value === '')
                setName(e.target.value)
              }}
            />
            {!isErrorName ? (
              <></>
            ) : (
              <FormErrorMessage>Name is required.</FormErrorMessage>
            )}
          </FormControl>

          <FormControl isInvalid={isErrorStatus}>
            <FormLabel>Status</FormLabel>
            <Select
              placeholder="Select status"
              onChange={(e) => {
                setIsErrorStatus(e.target.value === '')
                setStatus(e.target.value)
              }}
            >
              { dataConstant.status.map((element,index) => {
                  return (<option key={element['id']} value={element['value']} >{element['name']}</option>);   
                    
                })}
            </Select>
            {!isErrorStatus ? (
              <></>
            ) : (
              <FormErrorMessage>Status is required.</FormErrorMessage>
            )}
          </FormControl>
          
        </SimpleGrid>
        <SimpleGrid minChildWidth='120px' spacing='40px' mt='24px'>
          
          <FormControl isInvalid={isErrorDescription}>
            <FormLabel>Description</FormLabel>
            <Input
              type='text'
              onChange={(e) => {
                setIsErrorDescription(e.target.value === '')
                setDescription(e.target.value)
              }}
            />
            {!isErrorDescription ? (
              <></>
            ) : (
              <FormErrorMessage>Description is required.</FormErrorMessage>
            )}
          </FormControl>
        </SimpleGrid>
        <Center>
          {loading ? <Spinner mt='24px' size='lg' /> :
            <Button colorScheme='blue' mt='24px' onClick={handleSubmit}>
              Submit
            </Button>
          }
        </Center>
      </CardHeader>
      <CardBody>
      </CardBody>
    </Card>
  </Flex>
  );
}

export default GroupForm;
