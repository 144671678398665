import React, { useEffect, useState } from 'react';
import {
  Flex,
  Input,
  SimpleGrid,
  Text,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Select,
  Button,
  Center,
  useColorModeValue,
} from '@chakra-ui/react';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { useDispatch, useSelector } from 'react-redux';
import { useToast } from '@chakra-ui/react';
import { SOS_CATEGORY_CLEAR_SNACKBAR } from 'constants/types';
import { Spinner } from '@chakra-ui/react';
import dataConstant from 'constants/dataConstant';
import { useParams, useHistory } from "react-router-dom/cjs/react-router-dom.min";
// import { sosCategoryAddOrUpdate } from 'actions/SosCategoryAction'; // Import your SosCategory action
import { sosCategoryAddOrUpdate, sosCategoryGetAll } from 'actions/sosCategoryAction'

import axios from 'axios';
import { server_url } from '../../constants/types';

function SosCategoryForm() {
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [status, setStatus] = useState('');
  const [route, setRoute] = useState('');
  const [time, setTime] = useState('');
  const [isErrorName, setIsErrorName] = useState(false);
  const [isErrorImage, setIsErrorImage] = useState(false);
  const [isErrorDescription, setIsErrorDescription] = useState(false);
  const [isErrorCreatedAt, setIsErrorCreatedAt] = useState(false);
  const [isErrorUpdatedAt, setIsErrorUpdatedAt] = useState(false);
  const [isErrorStatus, setIsErrorStatus] = useState(false);
  const [createdAt, setCreatedAt] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");
  const [image, setImage] = useState("");


  const dispatch = useDispatch();
  const toast = useToast();
  const params = useParams();
  const history = useHistory();

  const statuses = ['success', 'error', 'warning', 'info'];
  const responseStatus = useSelector((state) => state.sosCategory.status);
  const responseMessage = useSelector((state) => state.sosCategory.text);
  const loading = useSelector((state) => state.sosCategory.loading);
  const showSnackbar = useSelector((state) => state.sosCategory.showSnackbar);
  const sosCategoryList = useSelector((state) => state.sosCategory.sosCategoryList);
  console.log("sosCategoryList")
  console.log(sosCategoryList);
  console.log(route)
  // Function to handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    setIsErrorName(name === '');
    setIsErrorDescription(description === '');
    setIsErrorStatus(status === '');
    setIsErrorCreatedAt(createdAt === "")
    setIsErrorUpdatedAt(updatedAt === "")
    setIsErrorImage(image === '');

    if (name !== '' && status !== '' && description !== '' && createdAt !== '' && image !== "" && updatedAt !== '') {
      // Get form data
      const sosCategoryData = {
        name: name,
        image: image,
        description: description,
        status: status,
        
        
        plant: params.id,
        createdAt: createdAt,
        updatedAt : updatedAt

        // plant: req._id,
        // Other sosCategory data fields
      };
      console.log("sosCategoryData+++++++++");

      console.log(sosCategoryData);

      // Determine if it's an add or edit operation
      const state = 'add';

      // Call the sosCategoryAddOrUpdate action
      dispatch(sosCategoryAddOrUpdate(sosCategoryData, state));

    }
  };
  useEffect(() => {
    dispatch(sosCategoryGetAll("&plant=" + params.id));
  }, [])

  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])
  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
        if (responseMessage !== '') {
          toastFunction(responseMessage, 'success');
          // Redirect to the sosCategory table or the desired page after successful submission
          history.push("/admin/sosCategory-table")
        }
      } else if (responseStatus === 'NOK' && loading === false) {
        if (responseMessage !== '') {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: SOS_CATEGORY_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      },
    });
  }, [loading]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            SOS Category Form
          </Text>
          <SimpleGrid minChildWidth='120px' spacing='40px'>
            <FormControl isInvalid={isErrorName}>
              <FormLabel>Name</FormLabel>
              <Input
                type='text'
                onChange={(e) => {
                  setIsErrorName(e.target.value === '');
                  setName(e.target.value);
                }}
              />
              {!isErrorName ? (
                <></>
              ) : (
                <FormErrorMessage>Name is required.</FormErrorMessage>
              )}
            </FormControl>

            <FormControl isInvalid={isErrorImage}>
              <FormLabel>Image</FormLabel>
              <Input
                type='file'
                onChange={(e) => {
                  console.log(e.target.files);
                  console.log(e.target.value);
                  setIsErrorImage(e.target.value === '')
                  setImage(e.target.files[0])
                }}
              />
              {!isErrorImage ? (
                <></>
              ) : (
                <FormErrorMessage>Image is required.</FormErrorMessage>
              )}
            </FormControl>

            {/* <FormControl isInvalid={isErrorDescription}>
              <FormLabel>Description</FormLabel>
              <Input
                type='text'
                onChange={(e) => {
                  setIsErrorDescription(e.target.value === '');
                  setDescription(e.target.value);
                }}
              />
              {!isErrorDescription ? (
                <></>
              ) : (
                <FormErrorMessage>Description is required.</FormErrorMessage>
              )}
            </FormControl> */}
          </SimpleGrid>

          <SimpleGrid minChildWidth='120px' spacing='40px' marginTop='30px'>
            <FormControl isInvalid={isErrorStatus}>
              <FormLabel>Status</FormLabel>
              <Select
                placeholder="Select status"
                onChange={(e) => {
                  setIsErrorStatus(e.target.value === '');
                  setStatus(e.target.value);
                }}
              >
                {dataConstant.status.map((element, index) => {
                  return (<option key={element['id']} value={element['value']} >{element['name']}</option>);
                })}
              </Select>
              {!isErrorStatus ? (
                <></>
              ) : (
                <FormErrorMessage>Status is required.</FormErrorMessage>
              )}
            </FormControl>

            <FormControl isInvalid={isErrorDescription}>
              <FormLabel>Description</FormLabel>
              <Input
                type='text'
                onChange={(e) => {
                  setIsErrorDescription(e.target.value === '')
                  setDescription(e.target.value)
                }}
              />
          
              {!isErrorDescription ? (
                <></>
              ) : (
                <FormErrorMessage>Description is required.</FormErrorMessage>
              )}
            </FormControl>

          </SimpleGrid>

          <SimpleGrid minChildWidth='120px' spacing='40px' marginTop='30px'>


            <FormControl isInvalid={isErrorCreatedAt}>
              <FormLabel>Created At</FormLabel>
              <Input
                type='Date'
                onChange={(e) => {
                  setIsErrorCreatedAt(e.target.value === '');
                  setCreatedAt(e.target.value);
                }}
              />
              {!isErrorCreatedAt ? (
                <></>
              ) : (
                <FormErrorMessage>Create date is required.</FormErrorMessage>
              )}
            </FormControl>

            <FormControl isInvalid={isErrorUpdatedAt}>
              <FormLabel>Updated At</FormLabel>
              <Input
                type='Date'
                onChange={(e) => {
                  setIsErrorUpdatedAt(e.target.value === '');
                  setUpdatedAt(e.target.value);
                }}
              />
              {!isErrorUpdatedAt ? (
                <></>
              ) : (
                <FormErrorMessage>Update date is required.</FormErrorMessage>
              )}
            </FormControl>
          </SimpleGrid>



          <Center>
            {loading ? <Spinner mt='24px' size='lg' /> :
              <Button colorScheme='blue' mt='24px' onClick={handleSubmit}>
                Submit
              </Button>
            }
          </Center>
        </CardHeader>
        <CardBody>
          {/* Additional form fields and content can be added here */}
        </CardBody>
      </Card>
    </Flex>
  );
}

export default SosCategoryForm;
