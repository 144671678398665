import axios from 'axios';
import {
  SUBDEPARTMENT_LOADING,
  SUBDEPARTMENT_GET_ALL_SUCCESS,
  SUBDEPARTMENT_ERROR,
  SUBDEPARTMENT_CLEAR_SNACKBAR
} from '../constants/types';

import { server_url } from '../constants/types';

export const subDepartmentGetAll = (pagination = '') => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: SUBDEPARTMENT_LOADING,
        payload: {
          loading: true
        }
      });
      axios
        .get(`${server_url}/subdepartment?${pagination}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
        .then((response) => {
            
          dispatch({
            type: SUBDEPARTMENT_GET_ALL_SUCCESS,
            payload: {
              subDepartmentList: response.data.data,
              response: response.data,
              loading: false,
              status: 'success',
              text: 'Get all subDepartment data successfully.'
            }
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: SUBDEPARTMENT_ERROR,
            payload: {
              status: 'error',
              text: 'Error occurred during getting subDepartment data.',
              loading: false
            }
          });
          resolve();
        });
    });
  };
};

export const subDepartmentAddOrUpdate = (subDepartment, state) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: SUBDEPARTMENT_LOADING,
        payload: {
          loading: true
        }
      });
      if (state === 'add') {
        axios
          .post(`${server_url}/subdepartment`, subDepartment, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
          })
          .then((responseF) => {
            axios
              .get(`${server_url}/subdepartment`, {
                headers: {
                  'Authorization': 'Bearer ' + localStorage.getItem('authToken')
                }
              })
              .then((response) => {
                console.log("resonse responseF.data['data']responseF.data['data']data")
console.log(responseF.data['data'])
                dispatch({
                  type: SUBDEPARTMENT_GET_ALL_SUCCESS,
                  payload: {
                    subDepartmentList: response.data.data,
                    response: response.data,
                    loading: false,
                    status: responseF.data['status'],
                    text: responseF.data['message']
                  }
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: SUBDEPARTMENT_ERROR,
                  payload: {
                    text: 'Error occurred during getting subDepartment data.',
                    status: 'error',
                    loading: false
                  }
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: SUBDEPARTMENT_ERROR,
              payload: {
                text: 'Error occurred during adding subDepartment data.',
                status: 'error',
                loading: false
              }
            });
            resolve();
          });
      } else if (state === 'edit') {
        axios
          .put(`${server_url}/subdepartment/${subDepartment._id}`, subDepartment, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
          })
          .then((responseF) => {
            axios
              .get(`${server_url}/subdepartment`, {
                headers: {
                  'Authorization': 'Bearer ' + localStorage.getItem('authToken')
                }
              })
              .then((response) => {


                dispatch({               
                  type: SUBDEPARTMENT_GET_ALL_SUCCESS,
                  payload: {
                    subDepartmentList: response.data.data,
                    response: response.data,
                    loading: false,
                    status: responseF.data['status'],
                    subDepartmentDetail: responseF.data['data'],
                    text: responseF.data['message']
                  }
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: SUBDEPARTMENT_ERROR,
                  payload: {
                    text: 'Error occurred during getting subDepartment data.',
                    status: 'error',
                    loading: false
                  }
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: SUBDEPARTMENT_ERROR,
              payload: {
                text: 'Error occurred during updating subDepartment data.',
                status: 'error',
                loading: false
              }
            });
            resolve();
          });
      } else if (state === 'view') {
        axios
          .get(`${server_url}/subdepartment/${subDepartment._id}`, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
          })
          .then((responseF) => {
            console.log(responseF);
            console.log("responseF");
            axios
              .get(`${server_url}/subdepartment`, {
                headers: {
                  'Authorization': 'Bearer ' + localStorage.getItem('authToken')
                }
              })
              .then((response) => {
                console.log(responseF.data['data']),
                console.log("responseF"),
                dispatch({
                  type: SUBDEPARTMENT_GET_ALL_SUCCESS,
                  payload: {
                    subDepartmentList: response.data.data,
                    response: response.data,
                    subDepartmentDetail: responseF.data['data'],
                    loading: false,
                    status: responseF.data['status'],
                    text: responseF.data['message']
                  }
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: SUBDEPARTMENT_ERROR,
                  payload: {
                    text: 'Error occurred during getting subDepartment data.',
                    status: 'error',
                    loading: false
                  }
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: SUBDEPARTMENT_ERROR,
              payload: {
                text: 'Error occurred during updating subDepartment data.',
                status: 'error',
                loading: false
              }
            });
            resolve();
          });
      }
    });
  };
};

export const subDepartmentDelete = (subDepartment) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: SUBDEPARTMENT_LOADING,
        payload: {
          loading: true
        }
      });
      axios
        .delete(`${server_url}/subdepartment/${subDepartment}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
        .then((responseF) => {
          axios
            .get(`${server_url}/subdepartment`, {
              headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
              }
            })
            .then((response) => {
              dispatch({
                type: SUBDEPARTMENT_GET_ALL_SUCCESS,
                payload: {
                  subDepartmentList: response.data.data,
                  response: response.data,
                  subDepartmentDetail: responseF.data['data'],
                  loading: false,
                  status: responseF.data['status'],
                  text: responseF.data['message']
                }
              });
              resolve();
            })
            .catch((e) => {
              dispatch({
                type: SUBDEPARTMENT_ERROR,
                payload: {
                  text: 'Error occurred during getting subDepartment data.',
                  status: 'error',
                  loading: false
                }
              });
              resolve();
            });
        })
        .catch((e) => {
          dispatch({
            type: SUBDEPARTMENT_ERROR,
            payload: {
              text: 'Error occurred during deleting subDepartment data.',
              status: 'error',
              loading: false
            }
          });
          resolve();
        });
    });
  };
};

export const subDepartmentClearShowSnackbar = () => {
  return (dispatch, getState) => {
    dispatch({
      type: SUBDEPARTMENT_CLEAR_SNACKBAR
    });
  };
};
