// Import the action type constants for busRoute
import {
    BUS_ROUTE_CLEAR_SNACKBAR,
    BUS_ROUTE_LOADING,
    BUS_ROUTE_ERROR,
    BUS_ROUTE_GET_ALL_SUCCESS,
  } from '../constants/types';
  
  const initialState = {
    response: {},
    busRouteList: [],
    busRouteDetail: {},
    loading: false,
    status: 'success',
    text: '',
    showSnackbar: false,
  };
  
  const busRouteReducer = (state = initialState, action) => {
    switch (action.type) {
      case BUS_ROUTE_CLEAR_SNACKBAR:
        return {
          ...state,
          showSnackbar: false,
        };
      case BUS_ROUTE_LOADING:
        return {
          ...state,
          loading: true,
        };
      case BUS_ROUTE_ERROR:
        return {
          ...state,
          busRouteList: [],
          response: {},
          busRouteDetail: {},
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true,
        };
      case BUS_ROUTE_GET_ALL_SUCCESS:
        return {
          ...state,
          busRouteList: action.payload.busRouteList,
          response: action.payload.response,
          busRouteDetail: action.payload.busRouteDetail,
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true,
        };
      default:
        return state || {};
    }
  };
  
  export default busRouteReducer;
  