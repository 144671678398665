import axios from 'axios';
import {
    SOS_CATEGORY_LOADING,
    SOS_CATEGORY_GET_ALL_SUCCESS,
    SOS_CATEGORY_ERROR,
    SOS_CATEGORY_CLEAR_SNACKBAR
} from '../constants/types';

import { server_url } from '../constants/types';

export const sosCategoryGetAll = (pagination = '') => {
    return (dispatch, getState) => {
        return new Promise((resolve) => {
            dispatch({
                type: SOS_CATEGORY_LOADING,
                payload: {
                    loading: true
                }
            });
            axios
                .get(`${server_url}/sos-category-list?${pagination}`, {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('authToken')
                    }
                })
                .then((response) => {
                    dispatch({
                        type: SOS_CATEGORY_GET_ALL_SUCCESS,
                        payload: {
                            sosCategoryList: response.data.data,
                            response: response.data,
                            loading: false,
                            status: 'success',
                            text: 'Get all sosCategory successfully.'
                        }
                    });
                    resolve();
                })
                .catch((e) => {
                    dispatch({
                        type: SOS_CATEGORY_ERROR,
                        payload: {
                            status: 'error',
                            text: 'Error occurred during getting sosCategory data.',
                            loading: false
                        }
                    });
                    resolve();
                });
        });
    };
};

export const sosCategoryAddOrUpdate = (sosCategory, state) => {
    return (dispatch, getState) => {
        return new Promise((resolve) => {
            dispatch({
                type: SOS_CATEGORY_LOADING,
                payload: {
                    loading: true
                }
            });
            if (state === 'add') {
                axios
                    .post(`${server_url}/sos-category`, sosCategory, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
                        }
                    })
                    .then((responseF) => {
                        axios
                            .get(`${server_url}/sos-category-list`, {
                                headers: {
                                    'Authorization': 'Bearer ' + localStorage.getItem('authToken')
                                }
                            })
                            .then((response) => {
                                console.log("response.data.data of sosCategory add")
                                console.log(response.data.data)
                                dispatch({
                                    type: SOS_CATEGORY_GET_ALL_SUCCESS,
                                    payload: {
                                        sosCategoryList: response.data.data,
                                        response: response.data,
                                        loading: false,
                                        status: responseF.data['status'],
                                        text: responseF.data['message']
                                    }
                                });
                                resolve();
                            })
                            .catch((e) => {
                                dispatch({
                                    type: SOS_CATEGORY_ERROR,
                                    payload: {
                                        text: 'Error occurred during getting sosCategory data.',
                                        status: 'error',
                                        loading: false
                                    }
                                });
                                resolve();
                            });
                    })
                    .catch((e) => {
                        dispatch({
                            type: SOS_CATEGORY_ERROR,
                            payload: {
                                text: 'Error occurred during adding sosCategory data.',
                                status: 'error',
                                loading: false
                            }
                        });
                        resolve();
                    });
            } else if (state === 'edit') {
                axios
                    .put(`${server_url}/sos-category/:id${sosCategory._id}`, sosCategory, {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
                        }
                    })
                    .then((responseF) => {
                        axios
                            .get(`${server_url}/sos-category-list`, {
                                headers: {
                                    'Authorization': 'Bearer ' + localStorage.getItem('authToken')
                                }
                            })
                            .then((response) => {
                                dispatch({
                                    type: SOS_CATEGORY_GET_ALL_SUCCESS,
                                    payload: {
                                        sosCategoryList: response.data.data,
                                        response: response.data,
                                        loading: false,
                                        status: responseF.data['status'],
                                        sosCategoryDetail: responseF.data['data'],
                                        text: responseF.data['message']
                                    }
                                });
                                resolve();
                            })
                            .catch((e) => {
                                dispatch({
                                    type: SOS_CATEGORY_ERROR,
                                    payload: {
                                        text: 'Error occurred during getting sosCategory data.',
                                        status: 'error',
                                        loading: false
                                    }
                                });
                                resolve();
                            });
                    })
                    .catch((e) => {
                        dispatch({
                            type: SOS_CATEGORY_ERROR,
                            payload: {
                                text: 'Error occurred during updating sosCategory data.',
                                status: 'error',
                                loading: false
                            }
                        });
                        resolve();
                    });
            } else if (state === 'view') {
                axios
                    .get(`${server_url}/sos-category-view/${sosCategory._id}`, {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
                        }
                    })
                    .then((responseF) => {
                        axios
                            .get(`${server_url}/sos-category-view/:id`, {
                                headers: {
                                    'Authorization': 'Bearer ' + localStorage.getItem('authToken')
                                }
                            })
                            .then((response) => {
                                console.log(" this is gurad deatil")
                                console.log(responseF.data['data'])
                                dispatch({
                                    type: SOS_CATEGORY_GET_ALL_SUCCESS,
                                    payload: {
                                        sosCategoryList: response.data.data,
                                        response: response.data,
                                        sosCategoryDetail: responseF.data['data'],
                                        loading: false,
                                        status: responseF.data['status'],
                                        text: responseF.data['message']
                                    }
                                });
                                resolve();
                            })
                            .catch((e) => {
                                dispatch({
                                    type: SOS_CATEGORY_ERROR,
                                    payload: {
                                        text: 'Error occurred during getting sosCategory data.',
                                        status: 'error',
                                        loading: false
                                    }
                                });
                                resolve();
                            });
                    })
                    .catch((e) => {
                        dispatch({
                            type: SOS_CATEGORY_ERROR,
                            payload: {
                                text: 'Error occurred during updating sosCategory data.',
                                status: 'error',
                                loading: false
                            }
                        });
                        resolve();
                    });
            }
        });
    };
};

export const sosCategoryDelete = (sosCategory) => {
    return (dispatch, getState) => {
        return new Promise((resolve) => {
            dispatch({
                type: SOS_CATEGORY_LOADING,
                payload: {
                    loading: true
                }
            });
            axios
                .delete(`${server_url}/sos-category/${sosCategory._id}`, {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('authToken')
                    }
                })
                .then((responseF) => {
                    axios
                        .get(`${server_url}/sos-category-list`, {
                            headers: {
                                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
                            }
                        })
                        .then((response) => {
                            dispatch({
                                type: SOS_CATEGORY_GET_ALL_SUCCESS,
                                payload: {
                                    sosCategoryList: response.data.data,
                                    response: response.data,
                                    sosCategoryDetail: responseF.data['data'],
                                    loading: false,
                                    status: responseF.data['status'],
                                    text: responseF.data['message']
                                }
                            });
                            resolve();
                        })
                        .catch((e) => {
                            dispatch({
                                type: SOS_CATEGORY_ERROR,
                                payload: {
                                    text: 'Error occurred during getting sosCategory data.',
                                    status: 'error',
                                    loading: false
                                }
                            });
                            resolve();
                        });
                })
                .catch((e) => {
                    dispatch({
                        type: SOS_CATEGORY_ERROR,
                        payload: {
                            text: 'Error occurred during deleting sosCategory data.',
                            status: 'error',
                            loading: false
                        }
                    });
                    resolve();
                });
        });
    };
};

export const sosCategoryClearShowSnackbar = () => {
    return (dispatch, getState) => {
        dispatch({
            type: SOS_CATEGORY_CLEAR_SNACKBAR
        });
    };
};
