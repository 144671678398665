const {
    EMPLOYEEATTENDENCE_LOADING,
    EMPLOYEEATTENDENCE_GET_ALL_SUCCESS,
    EMPLOYEEATTENDENCE_ERROR,
    EMPLOYEEATTENDENCE_CLEAR_SNACKBAR
  } = require('../constants/types');
  
  const initialState = {
    response: {},
    employeeattendenceList: [],
    employeeattendenceListIN: [],
    workForceIn : [],
    employeeattendenceListOut: [],
    employeeattendenceDetail: {},
    loading: false,
    status: 'success',
    text: '',
    limit: 0,  // Add missing properties
    page: 0,
    totalPages: 0,
    showSnackbar: false
  };
  
  const EmployeeAttendenceReducer = (state = initialState, action) => {
    switch (action.type) {
      case EMPLOYEEATTENDENCE_CLEAR_SNACKBAR:
        return {
          ...state,
          showSnackbar: false
        };
      case EMPLOYEEATTENDENCE_LOADING:
        return {
          ...state,
          loading: true
        };
      case EMPLOYEEATTENDENCE_ERROR:
        return {
          ...state,
          employeeattendenceList: [],
          employeeattendenceListIN: [],
          employeeattendenceListOut: [],
          workForceIn : [],
          response: {},
          employeeattendenceDetail: {},
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          limit: 0,  // Add missing properties
          page: 0,
          totalPages: 0,
          showSnackbar: true
        };
      case EMPLOYEEATTENDENCE_GET_ALL_SUCCESS:
        return {
          ...state,
          employeeattendenceListIN: action.payload.employeeattendenceListIN,
          employeeattendenceListOut: action.payload.employeeattendenceListOut,
          workForceIn: action.payload.workForceIn,
          response: action.payload.response,
          employeeattendenceDetail: action.payload.employeeattendenceDetail,
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          limit: action.payload.limit,
          page: action.payload.page,
          totalPages: action.payload.totalPages,
          showSnackbar: true
        };
      default:
        return state || {};
    }
  };
  
  export default EmployeeAttendenceReducer;
  // module.exports = EmployeeAttendenceReducer;