// Chakra imports
import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  ListItem,
  OrderedList,
  SimpleGrid,
  Spacer,
  Image,
  Stack,
  Text,
  Wrap,
  WrapItem,
  useColorModeValue
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import React, { useEffect, useState } from "react";
import { tablesProjectData, tablesTableData } from "variables/general";
import { useDispatch, useSelector } from 'react-redux';
//   import { roleAddOrUpdate, } from '../../actions/roleActions';
import { useToast } from '@chakra-ui/react'
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { SUBDEPARTMENT_CLEAR_SNACKBAR } from "constants/types";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react'
import { Skeleton, SkeletonCircle, SkeletonText } from '@chakra-ui/react'
import { subDepartmentAddOrUpdate } from "actions/subDepartmentAction";
import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom';
function SubDepartmentView() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  // const [allPermission,setAllPermission]= useState("");
  // const [permissions,setPermissions]= useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("");
  const [image, setImage] = useState("");
  const [subDepartmentId, setSubDepartmentId] = useState("");
  const [alertMessage, setAlertMessage] = useState('iii');
  const [isErrorName, setIsErrorName] = useState(false);
  const [isErrorEmail, setIsErrorEmail] = useState(false);
  const [isErrorContactNo, setIsErrorContactNo] = useState(false);
  const [isErrorPassword, setIsErrorPassword] = useState(false);

  const dispatch = useDispatch();
  const toast = useToast();
  const history = useHistory();
  const statuses = ['success', 'error', 'warning', 'info'];
  const responseStatus = useSelector(state => state.subDepartment.status);
  const responseMessage = useSelector(state => state.subDepartment.text);
  const loading = useSelector(state => state.subDepartment.loading);
  const subDepartmentDetail = useSelector(state => state.subDepartment.subDepartmentDetail);
  const showSnackbar = useSelector(state => state.subDepartment.showSnackbar);
  console.log("subDepartmentDetail???????????????");
  console.log(subDepartmentDetail);
  const params = useParams();
  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus == 'OK' && loading == false) {
        console.log(subDepartmentDetail);
        setSubDepartmentId(subDepartmentDetail.departmentId?.name);
        setName(subDepartmentDetail.name);
        setDescription(subDepartmentDetail.description);
        setStatus(subDepartmentDetail.status);
        setImage(subDepartmentDetail.departmentId.image);
        if (responseMessage != "") {
          toastFunction(responseMessage, 'success');
        }

      } else if (responseStatus == 'NOK' && loading == false) {
        if (responseMessage != "") {
          toastFunction(responseMessage, 'error');
        }

      }
    }

    dispatch({
      type: SUBDEPARTMENT_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);
  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])
  useEffect(() => {
    console.log(params);
    const state = 'view';
    // Call the roleAddOrUpdate action
    const subDepartment = {
      _id: params.id,
    };
    dispatch(subDepartmentAddOrUpdate(subDepartment, state))
  }, []);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };



  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Sub Department View
          </Text>
        </CardHeader>
        <CardBody>
          {loading ? <Stack>
            <Skeleton height='20px' />
            <Skeleton height='20px' />
            <Skeleton height='20px' />
          </Stack>
            :
            <TableContainer>
              <Table variant='simple'>
                <Tbody>
                  <Tr>
                    <Td>Department</Td>
                    <Td>{subDepartmentId}</Td>
                  </Tr>
                  <Tr>
                    <Td>Name</Td>
                    <Td>{name}</Td>
                  </Tr>
                  <Tr>
                    <Td>Description</Td>
                    <Td>{description}</Td>
                  </Tr>
                  <Tr>
                    <Td>Status</Td>
                    <Td>{status ? "Active" : "Inactive"}</Td>
                  </Tr>
                  <Tr>
                    <Td>Image</Td>
                    <Td>
                      <Flex direction="column">
                        <Text fontSize="md" color={textColor} fontWeight="bold">
                          <Image
                            boxSize='100px'
                            objectFit='cover'
                            src={image}
                            alt='Dan Abramov'
                          />
                        </Text>
                      </Flex>
                    </Td>
                  </Tr>
                </Tbody>


              </Table>
            </TableContainer>}
        </CardBody>
      </Card>
    </Flex>
  );
}

export default SubDepartmentView;
