import {
  Avatar,
  Badge,
  Button,
  ButtonGroup,
  Flex,
  IconButton,
  Link,
  Select,
  Switch,
  Td,
  Text,
  Tr,
  useColorModeValue,
  useDisclosure
} from "@chakra-ui/react";
import { adminDelete } from "actions/adminActions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AiFillEdit } from "react-icons/ai";
import { ChangeAdminStatus } from "../../actions/adminActions";
import { PlantAdminGetAll } from "actions/plantAction";
import { ViewIcon } from "@chakra-ui/icons";



function PlantAdminsTableRow(props) {
  const { PlantName, contactPersonName, AlternateNo, OfficalEmail, isLast, serialnumber , status, id } = props;
  const textColor = useColorModeValue("gray.500", "white");
  const titleColor = useColorModeValue("gray.700", "white");
  const bgStatus = useColorModeValue("gray.400", "navy.900");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  console.log(" of plant")
  console.log(status)
  console.log(id)


  const cancelRef = React.useRef()

  const dispatch = useDispatch();


  function handleSwitchChange() {

    const admin = {
      _id: id
    };
    console.log(admin._id)
    dispatch(ChangeAdminStatus(admin))
    dispatch(PlantAdminGetAll())


  }

  return (
    <>
      <Tr>
      <Td
          >
            <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
              <Flex direction="column">
                <Text
                  fontSize="md"
                  color={titleColor}
                  fontWeight="bold"
                  minWidth="100%"
                >
                  {serialnumber}
                </Text>
              </Flex>
            </Flex>
          </Td>
        <Td
          // minWidth={{ sm: "250px" }}
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {PlantName}
              </Text>

            </Flex>
          </Flex>
        </Td>

        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {contactPersonName}
            </Text>

          </Flex>
        </Td>

        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
            {AlternateNo}
          </Text>
        </Td>

        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {OfficalEmail}
          </Text>
        </Td>

        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Switch sx={{
            'span.chakra-switch__track:not([data-checked])': {
              backgroundColor: 'red'
            }
          }} colorScheme='green' id='isRequired' onChange={handleSwitchChange} isChecked={status} />
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <ButtonGroup>
            <Link href={`#/admin/plant-view/${id}`}>
              <IconButton
                colorScheme="blue"
                icon={<ViewIcon />}
                aria-label="View"
              />
            </Link>

            <Link href={`#/admin/plant-admin-update-form/${id}`}>
              <IconButton
                colorScheme="green"
                icon={<AiFillEdit />}
                aria-label="Edit"
              />
            </Link>
          </ButtonGroup>
        </Td>
      </Tr>

    </>
  );
}
export default PlantAdminsTableRow;

