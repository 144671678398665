var dataConstant = new Object();

///////// status type////
dataConstant.status = [
    {
        'id':1,
        'name': 'Active',
        'value' : true
    },
    {
        'id':2,
        'name': 'Inactive',
        'value' : false 
    }
];

//////deliveryType
dataConstant.deliveryType = [
    {
        'id':1,
        'name': 'DELIVERY',
        'value' : 'DELIVERY'
    },
    {
        'id':2,
        'name': 'COURIER',
        'value' : 'COURIER' 
    }
];
dataConstant.statusType = [
    {
        'id':1,
        'name': 'RGP',
        'value' : "REJECTED_GATE_PASS"
    },
    {
        'id':2,
        'name': 'NRGP',
        'value' : "NRGP" 
    }
];

///////// boolean type////
dataConstant.boolean = [
    {
        'id':1,
        'name': 'true',
    },
    {
        'id':2,
        'name': 'false'
    }
];

///////// marital Status////
dataConstant.maritalstatus = [
    {
        'id':1,
        'name': 'Married',
    },
    {
        'id':2,
        'name': 'Un-Married',
    }
];

///////// Gender////
dataConstant.gender = [
    {
        'id':1,
        'name': 'Male',
    },
    {
        'id':2,
        'name': 'Female',
    },
    {
        'id':3,
        'name': 'Trans-Gender',
    }
];

///////// Validation  ////
dataConstant.validation = [
    {
        'id':1,
        'name': 1,
    },
    {
        'id':2,
        'name': 2,
    },
    {
        'id':3,
        'name': 3,
    },
    {
        'id':4,
        'name': 4,
    },
    {
        'id':5,
        'name': 5,
    },
    {
        'id':6,
        'name': 6,
    },
    {
        'id':7,
        'name': 7,
    }
];

///////// employee type////
dataConstant.requestType = [
    {
        'id':1,
        'name': 'Raise Request',
        'value' : 1
    },
    {
        'id':2,
        'name': 'Request Accept',
        'value' : 2
    },
   
];


///////// vehicle type////
dataConstant.vechilType = [
    {
        'id':1,
        'name': 'FOUR_WHEELER',
    },
    {
        'id':2,
        'name': 'TWO_WHEELER',
    },
    {
        'id':3,
        'name': 'BY_WALK',
    }
];

///////// ReportIncedent minor major////
dataConstant.reportIncedent = [
    {
        'id':1,
        'name': 'MINOR',
    },
    {
        'id':2,
        'name': 'MAJOR',
    },
   
];

dataConstant.passType = [
    {
        "id" : 1,
        'name' : "MATERIAL_GATE_PASS",
        "value" : "MATERIAL_GATE_PASS",
    },
    {
        "id" : 2,
        "name" : "RAISED_GATE_PASS",
        "value": "RAISED_GATE_PASS"
    }
]

dataConstant.mode = [
    {
        "id" : 1,
        'name' : "INCOMING_MATERIAL",
        "value" : "INCOMING_MATERIAL",
    },
    {
        "id" : 2,
        "name" : "OUTGOING_MATERIAL",
        "value": "OUTGOING_MATERIAL"
    }
]
dataConstant.materialShipmentType = [
    {
        "id" : 1,
        'name' : "RETURNABLE",
        "value" : "RETURNABLE",
    },
    {
        "id" : 2,
        "name" : "NON_RETURNABLE",
        "value": "NON_RETURNABLE"
    }
]
dataConstant.weighmentStatus = [
    {
        "id" : 1,
        'name' : "PENDING",
        "value" : "PENDING",
    },
    {
        "id" : 2,
        "name" : "WEIGHMENT-1",
        "value": "WEIGHMENT1"
    },
    {
        "id" : 3,
        "name" : "WEIGHMENT-2",
        "value": "WEIGHMENT2"
    }
]

dataConstant.OutPassReason = [
    {
        "id" : 1,
        "name" : "Medical-Emergency",
        "value" : "Medical emergency",
    },
    {
        "id" : 2,
        "name" : "Personal",
        "value" : "Personal",
    },
    {
        "id" : 3,
        "name" : "Official-Purpose",
        "value" : "Official purpose",
    },
    {
        "id" : 4,
        "name" : "Others",
        "value" : "Others",
    }
]
dataConstant.visitorPurpose = [
    {
        "id" : 1,
        "name" : "Interview",
        "value" : "Interview",
    },
    {
        "id" : 2,
        "name" : "Meeting",
        "value" : "Meeting",
    },
    {
        "id" : 3,
        "name" : "Officical Visit",
        "value" : "Officical Visit",
    },
    {
        "id" : 4,
        "name" : "Others",
        "value" : "Others",
    }
]

dataConstant.outPassDuration = [
    {
        "id" : 1,
        "name" : "1hr",
        "value" : "1hr",
    },
    {
        "id" : 2,
        "name" : "2hrs",
        "value" : "2hrs",
    },
    {
        "id" : 3,
        "name" : "Half-Day",
        "value" : "Half Day",
    },
    {
        "id" : 4,
        "name" : "Full-Day",
        "value" : "Full Day",
    }
]
///// Banner Type
dataConstant.bannerType = [
    {
        'id':1,
        'name': 'Guard',
        'value' : 'guard'
    },
    {
        'id':2,
        'name': 'Employee',
        'value' : 'employee' 
    }
];

dataConstant.type = [
    {
        'id':1,
        'name': 'popular',
        'value' : "popular"
    },
    {
        'id':2,
        'name': 'unpopular',
        'value' : "unpopular" 
    }
];

///// Access app
dataConstant.accessApp = [
    {
        'id':1,
        'name': 'Enable',
        'value' : true
    },
    {
        'id':2,
        'name': 'Disable',
        'value' : false 
    }
];
///// Call type
dataConstant.callTypeList = [
    {
        'id':1,
        'name': 'Primary',
        'value' : "PRIMARY"
    },
    {
        'id':2,
        'name': 'Secondary',
        'value' : "SECONDARY" 
    },
    {
        'id':3,
        'name': 'Not Assigned',
        'value' : "NOTASSIGNED" 
    }
];

///// Enable Notification
dataConstant.notification = [
    {
        'id':1,
        'name': 'Enable',
        'value' : true
    },
    {
        'id':2,
        'name': 'Disable',
        'value' : false 
    }
];

///// Blood Group
dataConstant.bloodGroup = [
    {
        'id':1,
        'name': 'A+',
        'value' : 'A+'
    },
    {
        'id':2,
        'name': 'A-',
        'value' : 'A-' 
    },
    {
        'id':3,
        'name': 'B+',
        'value' : 'B+' 
    },
    {
        'id':4,
        'name': 'B-',
        'value' : 'B-' 
    },
    {
        'id':5,
        'name': 'O+',
        'value' : 'O+' 
    },
    {
        'id':6,
        'name': 'O-',
        'value' : 'O-' 
    },
    {
        'id':7,
        'name': 'AB+',
        'value' : 'AB+' 
    },
    {
        'id':8,
        'name': 'AB-',
        'value' : 'AB-' 
    },
];

///// Blood Group
dataConstant.employeeType = [
    {
        'id':1,
        'name': 'Part-time employees',
        'value' : 1
    },
    {
        'id':2,
        'name': 'Full-time employees',
        'value' : 2
    },
    {
        'id':3,
        'name': 'Contractual',
        'value' : 3
    },
    {
        'id':4,
        'name': 'Intern',
        'value' : 4 
    },
];

dataConstant.vehicleType = [
    {
        'id':1,
        'name': 'BY WALK',
        'value' : 'BY_WALK',
        'image' : ""
    },
    {
        'id':2,
        'name': 'TWO WHEELER',
        'value' : 'TWO_WHEELER',
        'image' : "https://firebasestorage.googleapis.com/v0/b/kavach-77a40.appspot.com/o/images%2FFrame%2058.png?alt=media&token=7419b4e3-1d1c-45d5-aad5-14e58664ff6d"
    },
    {
        'id':3,
        'name': 'FOUR WHEELER',
        'value' : 'FOUR_WHEELER',
        'image' : "https://firebasestorage.googleapis.com/v0/b/kavach-77a40.appspot.com/o/images%2FFrame%2059.png?alt=media&token=96d7538a-6c1f-4315-98e0-873c1aeb9071"
    },
    {
        'id':4,
        'name': 'BUS',
        'value' : 'BUS',
        'image' : "https://firebasestorage.googleapis.com/v0/b/kavach-77a40.appspot.com/o/images%2FVector.png?alt=media&token=03cc6a57-4013-4473-80da-b0d8bd5f2b8a"
    },
    {
        'id':5,
        'name': 'UNLOADED BAYS',
        'value' : 'UNLOADED_BAYS',
        'image' : "https://firebasestorage.googleapis.com/v0/b/kavach-77a40.appspot.com/o/images%2FFrame%2059%20(1).png?alt=media&token=8236de32-c303-43b4-b6fd-50b995537b83"
    },
    {
        'id':6,
        'name': 'LOADED BAYS',
        'value' : 'LOADED_BAYS',
        'image' : "https://firebasestorage.googleapis.com/v0/b/kavach-77a40.appspot.com/o/images%2FFrame%2058%20(1).png?alt=media&token=9a88e2ae-acf7-4fde-a5c7-0f84c02dc011"
    },
];

dataConstant.transportType = [
    {
        'id':1,
        'name': 'TRANSPORT',
    },
    {
        'id':2,
        'name': 'INTERCARTING',
    },
   
];

dataConstant.parkingDepartment = [
    {
        'id':1,
        'name': 'Transport',
        'value' : 'TRANSPORT'

    },
    {
        'id':2,
        'name': 'Intercarting',
        'value' : 'INTERCARTING'
    },
    {
        'id':3,
        'name': 'Employee',
        'value' : 'EMPLOYEE'
    },
    {
        'id':4,
        'name': 'Visitor',
        'value' : 'VISITOR'
    },
    {
        'id':5,
        'name': 'Material',
        'value' : 'MATERIAL'
    },
    {
        'id':6,
        'name': 'Delivery',
        'value' : 'DELIVERY'
    },
    {
        'id':7,
        'name': 'Canteen',
        'value' : 'CANTEEN'
    },
   
];

dataConstant.homeModule = [
    {
        'id':1,
        'name' : 'Passcode',
        'value': 'PASSCODE',
    },
    {
        'id':2,
        'name' : 'Mobile Number',
        'value': 'MOBILE_NUMBER',
    },
    {
        'id':3,
        'name' : 'Walkie Talkie',
        'value': 'WALKIE_TALKIE',
    },
    {
        'id':4,
        'name' : 'Vehicle Number',
        'value': 'VEHICLE_NUMBER',
    },
   
];

 
export default dataConstant;