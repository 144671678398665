// Chakra imports
import {
    Box,
    Button,
    Center,
    Flex,
    Input,
    ListItem,
    OrderedList,
    SimpleGrid,
    Spacer,
    Stack,
    Text,
    Wrap,
    WrapItem,
    useColorModeValue
  } from "@chakra-ui/react";
  // Custom components
  import Card from "components/Card/Card.js";
  import CardBody from "components/Card/CardBody.js";
  import CardHeader from "components/Card/CardHeader.js";
  import React, { useEffect, useState } from "react";
  import { tablesProjectData, tablesTableData } from "variables/general";
  import { useDispatch, useSelector } from 'react-redux';
//   import { roleAddOrUpdate, } from '../../actions/roleActions';
  import { useToast } from '@chakra-ui/react'
  import { useParams } from "react-router-dom/cjs/react-router-dom.min";
  import { PARKING_CLEAR_SNACKBAR } from "constants/types";
  import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
  } from '@chakra-ui/react'
  import { Skeleton, SkeletonCircle, SkeletonText } from '@chakra-ui/react'
import { parkingAddOrUpdate } from "actions/parkingAction";
import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom';
  function ParkingView() {
    const textColor = useColorModeValue("gray.700", "white");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    // const [allPermission,setAllPermission]= useState("");
    // const [permissions,setPermissions]= useState([]);
    const [type, setType] = useState("");
    const [availability, setAvailability] = useState("");
    const [twoWheeler, setTwoWheeler] = useState("");
    const [fourWheeler, setFourWheeler] = useState("");
    const [bus, setBus] = useState("");
    const [loadedBays, setLoadedBays] = useState("");
    const [unloadedBays, setUnloadedBays] = useState("");
    const [parkingStatus, setParkingStatus] = useState("");
    const [adminName, setAdminName] = useState("");
    const [parkingdepartment , setParkingdepartment]=useState("");
    const [selectedPermissions, setSelectedPermissions] = useState([]);
   
  
   
   
    const history = useHistory();
    
    const dispatch = useDispatch();
    const toast = useToast();
  
    const statuses = ['success', 'error', 'warning', 'info'];
    const responseStatus = useSelector(state => state.parking.status);
    const responseMessage = useSelector(state => state.parking.text);
    const loading = useSelector(state => state.parking.loading);
    const parkingDetail = useSelector(state => state.parking.parkingDetail);
    const showSnackbar = useSelector(state => state.parking.showSnackbar);

const params = useParams();

console.log("parkingDetail in view")
console.log(parkingDetail)
    
    useEffect(() => {
      if(showSnackbar)
      {
        if (responseStatus == 'OK' && loading == false) {
        //   console.log(subParkingDetail);
          
        setType(parkingDetail.type);
        setAvailability(parkingDetail.availability);
        setTwoWheeler(parkingDetail.twoWheeler);
        setFourWheeler(parkingDetail.fourWheeler);
        setBus(parkingDetail.bus);
        setLoadedBays(parkingDetail.loadedBays);
        setUnloadedBays(parkingDetail.unloadedBays);
        setParkingStatus(parkingDetail.parkingStatus);
        setAdminName(parkingDetail.admin.name);
        setParkingdepartment(parkingDetail.departmentName.name);
          if(responseMessage !="")
     {
            toastFunction(responseMessage, 'success');
          }
          
        } else if (responseStatus == 'NOK' && loading == false) {
          if(responseMessage !="")
          {
            toastFunction(responseMessage, 'error');
          }
          
        }
      }
  
      dispatch({
        type: PARKING_CLEAR_SNACKBAR,
        payload: {
            showSnackbar: false,
        }
    })
    }, [loading]);
  
    useEffect(() => {
      console.log(params);
      const state = 'view';
        // Call the roleAddOrUpdate action
        const parking = {
          _id : params.id,
        };
        console.log("parking----------------");
        console.log(parking._id);
        dispatch(parkingAddOrUpdate(parking, state))
    }, []);
    useEffect(() => {
      axios
        .get(`${server_url}/admin/detail`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then((responce) => {
          console.log("uadlfdafljadhfoadilf");
        })
        .catch((e) => {
          history.push('/auth/signin');
  
        });
    }, [])
    const toastFunction = (title,status) =>
    {
      toast({
        title: title,
        status: status,
        duration: 3000,
        isClosable: true,
      })
    };
  
    return (
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
            Parking View
            </Text>
          </CardHeader>
          <CardBody>
          {loading ? (<Stack>
            <Skeleton height='20px' />
            <Skeleton height='20px' />
            <Skeleton height='20px' />
          </Stack>
          ) : parkingDetail ?(
          <TableContainer>
            <Table variant='simple'>
              <Tbody>
            
                <Tr>
                  <Td>Type</Td>
                  <Td>{type}</Td>
                </Tr>
                <Tr>
                  <Td>Availability</Td>
                  <Td>{availability}</Td>
                </Tr>
                <Tr>
                  <Td>Parking Department</Td>
                  <Td>{parkingdepartment}</Td>
                </Tr>
                 
                <Tr>
                  <Td>Two Wheeler</Td>
                  <Td>{twoWheeler}</Td>
                </Tr>
               
                 {/* <Tr>
                  <Td>Status</Td>
                  <Td>{status ? "Active" : "Inactive"}</Td>
                </Tr> */}
                <Tr>
                  <Td>Four Wheeler</Td>
                  <Td>{fourWheeler}</Td>
                </Tr>
                <Tr>
                  <Td>Bus</Td>
                  <Td>{bus}</Td>
                </Tr>
                <Tr>
                  <Td>loaded Bays</Td>
                  <Td>{loadedBays}</Td>
                </Tr>
                <Tr>
                  <Td>Unloaded Bays</Td>
                  <Td>{unloadedBays}</Td>
                </Tr>
                <Tr>
                  <Td>Parking Status</Td>
                  <Td>{parkingStatus ? <span>Active</span> : <span>InActive</span>}</Td>
                </Tr>
                <Tr>
                  <Td>Admin Name</Td>
                  <Td>{adminName}</Td>
                </Tr>
              
                  
                
              </Tbody>
              
            </Table>
          </TableContainer>
          ) : null}
          </CardBody>
        </Card>
      </Flex>
    );
  }
  
  export default ParkingView;
  