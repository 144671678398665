// Import the action type constants for PatrollingRequest
import {
    PATROLLING_REQUEST_CLEAR_SNACKBAR,
    PATROLLING_REQUEST_LOADING,
    PATROLLING_REQUEST_ERROR,
    PATROLLING_REQUEST_GET_ALL_SUCCESS,
  } from '../constants/types';
  
  const initialState = {
    response: {},
    patrollingRequestList: [],
    patrollingRequestDetail: {},
    loading: false,
    status: 'success',
    text: '',
    showSnackbar: false,
  };
  
  const patrollingRequestReducer = (state = initialState, action) => {
    switch (action.type) {
      case PATROLLING_REQUEST_CLEAR_SNACKBAR:
        return {
          ...state,
          showSnackbar: false,
        };
      case PATROLLING_REQUEST_LOADING:
        return {
          ...state,
          loading: true,
        };
      case PATROLLING_REQUEST_ERROR:
        return {
          ...state,
          patrollingRequestList: [],
          response: {},
          patrollingRequestDetail: {},
          loading: false,
          status:"success",
          text: "",
          showSnackbar: true,
        };
      case PATROLLING_REQUEST_GET_ALL_SUCCESS:
        return {
          ...state,
          patrollingRequestList: action.payload.patrollingRequestList,
          response: action.payload.response,
          patrollingRequestDetail: action.payload.patrollingRequestDetail,
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true,
        };
      default:
        return state || {};
    }
  };
  
  export default patrollingRequestReducer;
  