import { combineReducers } from "redux";
import userReducer from './userReducer';
import adminReducer from './adminReducer';
import authReducer from './authReducer';
import bannerReducer from './bannerReducer';
import roleReducer from './roleReducer';
import permissionReducer from './permissionReducer';
import moduleReducer from './moduleReducer';
import checkpostReducer from './checkpostReducer';
import departmentReducer from "./departmentReducer";

import CityReducer from "./CityReducer";
import FactoryReducer from "./FactoryReducer";
// import DepartmentReducer from "./departmentReducer";
import groupReducer from "./groupReducer";
import subDepartmentReducer from "./subDepartmentReducer";
import PlantReducer from "./plantReducer";
import employeeReducer from "./EmployeeReducer";
import guardReducer from "./GaurdReducer"
import busReducer from "./busReducer";
import GuardAttendenceReducer from "./GuardAttendenceReducer";
import EmployeeAttendenceReducer from "./EmployeeAttendenceReducer";
import driverReducer from "./DriverReducer";
import TransportInOutReducer from "./TransportInOutReducer";
import IntercartingInOutReducer from "./IntercatingInOutReducer";
import parkingdepartmentReducer from "./parkingDepartmentReducer";
import parkingReducer from "./parkingReducer";
import delivaryReducer from "./delivaryReducer";
import canteenReducer from "./canteenReducer";
import CanteenInOutReducer from "./CanteenInOutReducer";
import DelivaryInOutReducer from "./DelivaryInOutReducer";
import guardmoduleReducer from "./guardmoduleReducer";
import VisitorInOutReducer from "./visitorReducer";
import reportIncedentReducer from "./reportIncedentReducer";
import bannerPositionReducer from "./bannerpositionReducer";
import deviceReducer from "./devicesReducer";
import designationReducer from "./designationReducer";
import employeemoduleReducer from "./employeeModuleReducer";
import employeeTypeReducer from "./EmployeeTypeReducer";
import highAlertReducer from "./highAlertReducer";
import emergencyContactReducer from "./emergencyContactReducer";
import patrollingRouteReducer from "./patrollingRouteReducer";
import patrollingRequestReducer from "./PatrollingRequestReducer";
import floorReducer from "./floorReducer"
import busRouteReducer from "./busRouteReducer";
import patrollingLogsReducer from "./patrollingLogsReducer";
import materialReducer from "./materialReducer";
import outPassReducer from "./outPassReducer";
import materialGatePassReducer from "./materialGetPassReducer";
import raisedGatePassReducer from "./raisedGatePassReducer"
import weighmentBridgeReducer from "./weighmentBridgeReducer";
import reportIncedentModuleReducer from "./reportIncedentModuleReducer";
import sosReducer from "./sosReducer";
import sosCategoryReducer from "./sosCategoryReducer";
import iamSafeReducer from "./iamSafeReducer";
import LongStayAlertReducer from "./longStayAlertReducer"
import WrongEntryReducer from "./wrongEntry";
import HowItWorkCategoryReducer from "./HowItWorkCategoryReducer";
import HowItWorkReducer from "./HowItWorkReducer";
import ScheduleHighAlertReducer from "./schedulehighalertReducer";
import PurposeReducer from "./purposeReducer";
import CallSettingReducer from "./callSettingReducer"

// Combine all reducers as root reducer
export default combineReducers({
	user: userReducer,
admin: adminReducer,
auth: authReducer,
banner : bannerReducer,
role : roleReducer,
permission : permissionReducer,
module : moduleReducer,
city : CityReducer,
factory : FactoryReducer,
checkpost : checkpostReducer,
department : departmentReducer,
group : groupReducer,
subDepartment : subDepartmentReducer,
plantadmin: PlantReducer,
employee: employeeReducer,
guard : guardReducer,
bus : busReducer,
guardattendence : GuardAttendenceReducer,
employeeattendence : EmployeeAttendenceReducer,
driver :driverReducer,
TransportInOut :TransportInOutReducer,
IntercartingInOut:IntercartingInOutReducer,
parkingdepartment:parkingdepartmentReducer,
parking:parkingReducer,
delivary:delivaryReducer,
canteen:canteenReducer,
canteenInOut:CanteenInOutReducer,
delivaryInOut:DelivaryInOutReducer,
guardmodule : guardmoduleReducer,
visitorInOut:VisitorInOutReducer,
reportIncedent:reportIncedentReducer,
bannerposition:bannerPositionReducer,
device:deviceReducer,
designation : designationReducer,
employeemodule : employeemoduleReducer,
employeeType : employeeTypeReducer,
highAlert : highAlertReducer,
emergencyContact :emergencyContactReducer,
patrollingRoute : patrollingRouteReducer,
floor : floorReducer,
patrollingRequest : patrollingRequestReducer,
busRoute : busRouteReducer,
patrollingLogs : patrollingLogsReducer,
material : materialReducer,
outPass : outPassReducer,
materialGatePass : materialGatePassReducer,
raisedGatePass : raisedGatePassReducer,
weighmentBridge : weighmentBridgeReducer,
reportIncedentModule : reportIncedentModuleReducer,
sos : sosReducer,
sosCategory : sosCategoryReducer,
iamSafe: iamSafeReducer,
longStayAlert : LongStayAlertReducer,
wrongEntry : WrongEntryReducer,
HowItWorkCategory : HowItWorkCategoryReducer,
HowItWork : HowItWorkReducer,
SchedulehighAlert : ScheduleHighAlertReducer,
purpose : PurposeReducer,
callSetting : CallSettingReducer

});