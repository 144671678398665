import {
    BANNER_LOADING,
    BANNER_GET_ALL_SUCCESS,
    BANNER_ERROR,
    BANNER_CLEAR_SNACKBAR
  } from '../constants/types';
  
  const initialState = {
    response: {},
    bannerList: [],
    bannerDetail: {},
    loading: false,
    status: 'success',
    text: '',
    limit:0,
    page: 0,
    totalPages: 0,
    showSnackbar: false
  };
  
  const bannerReducer = (state = initialState, action) => {
    switch (action.type) {
      case BANNER_CLEAR_SNACKBAR:
        return {
          ...state,
          showSnackbar: false
        };
      case BANNER_LOADING:
        return {
          ...state,
          loading: true
        };
      case BANNER_ERROR:
        return {
          ...state,
          bannerList: [],
          response: {},
          bannerDetail: {},
          limit:0,
          page: 0,
          totalPages: 0,
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true
        };
      case BANNER_GET_ALL_SUCCESS:
        return {
          ...state,
          bannerList: action.payload.bannerList,
          response: action.payload.response,
          bannerDetail: action.payload.bannerDetail,
          limit: action.payload.limit,
          page: action.payload.page,
          totalPages: action.payload.totalPages,
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true
        };
      default:
        return state || {};
    }
  };
  
  export default bannerReducer;