import {
    Avatar,
    Badge,
    Button,
    Flex,
    Link,
    Td,
    Text,
    Tr,
    useColorModeValue,
    useDisclosure,
    ButtonGroup,
    IconButton,
  } from "@chakra-ui/react";
  import { BsBoxArrowUpRight, BsFillTrashFill } from "react-icons/bs";
  import { ViewIcon } from '@chakra-ui/icons';
  import { AiFillEdit } from "react-icons/ai";
  import { groupDelete } from "../../actions/groupActions";
  import React from "react";
  import { useDispatch, useSelector } from "react-redux";
  import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
  } from '@chakra-ui/react';
  import dataConstant from "constants/dataConstant";
  
  function GroupTableRow(props) {
    const { name, description, status, adminId, isLast, serialnumber ,id } = props;
    const textColor = useColorModeValue("gray.500", "white");
    const titleColor = useColorModeValue("gray.700", "white");
    const bgStatus = useColorModeValue("gray.400", "navy.900");
    const borderColor = useColorModeValue("gray.200", "gray.600");
  
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = React.useRef();
  
    const dispatch = useDispatch();
  
    const deleteGroup = () => {
      const group = {
        _id: id
      };
      dispatch(groupDelete(group));
      onClose();
    }
  
    const element = dataConstant.status.find(item => item.value === status)
  
    const authDetail = useSelector((state) => state.auth.authDetail);
  
    return (
      <>
        <Tr>
        <Td
          >
            <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
              <Flex direction="column">
                <Text
                  fontSize="md"
                  color={titleColor}
                  fontWeight="bold"
                  minWidth="100%"
                >
                  {serialnumber}
                </Text>
              </Flex>
            </Flex>
          </Td>
          <Td
            minWidth={{ sm: "250px" }}
            pl="0px"
            borderColor={borderColor}
            borderBottom={isLast ? "none" : null}
          >
            <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
              <Flex direction="column">
                <Text
                  fontSize="md"
                  color={titleColor}
                  fontWeight="bold"
                  minWidth="100%"
                >
                  {name}
                </Text>
              </Flex>
            </Flex>
          </Td>
  
          <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
            <Flex direction="column">
              <Text fontSize="md" color={textColor} fontWeight="bold">
                {description}
              </Text>
            </Flex>
          </Td>
  
          <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
            <Badge colorScheme={status ? "green" : "red"}>{element.name}</Badge>
          </Td>
  
          <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
            <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
              {adminId.name}
            </Text>
          </Td>
  
          <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
            {authDetail.role.allPermissions === false ? (
              <ButtonGroup variant="solid" size="sm" spacing={3}>
                {authDetail.role.permissions.map((permission) => {
                  const { permissionName, moduleName } = permission;
  
                  if (
                    permissionName === "VIEW" &&
                    moduleName === "GROUP"
                  ) {
                    return (
                      <Link href={`#/admin/group-view/form/${id}`}>
                        <IconButton
                          colorScheme="blue"
                          icon={<ViewIcon />}
                          aria-label="View"
                        />
                      </Link>
                    );
                  }
  
                  if (
                    permissionName === "EDIT" &&
                    moduleName === "GROUP"
                  ) {
                    return (
                      <Link href={`#/admin/group-update/form/${id}`}>
                        <IconButton
                          colorScheme="green"
                          icon={<AiFillEdit />}
                          aria-label="Edit"
                        />
                      </Link>
                    );
                  }
  
                  if (
                    permissionName === "DELETE" &&
                    moduleName === "GROUP"
                  ) {
                    return (
                      <IconButton
                        colorScheme="red"
                        variant="outline"
                        icon={<BsFillTrashFill />}
                        aria-label="Delete"
                        onClick={onOpen}
                      />
                    );
                  }
  
                  return null;
                })}
              </ButtonGroup>
            ) : (
              <ButtonGroup variant="solid" size="sm" spacing={3}>
                <Link href={`#/admin/group-view/form/${id}`}>
                  <IconButton
                    colorScheme="blue"
                    icon={<ViewIcon />}
                    aria-label="View"
                  />
                </Link>
  
                <Link href={`#/admin/group-update/form/${id}`}>
                  <IconButton
                    colorScheme="green"
                    icon={<AiFillEdit />}
                    aria-label="Edit"
                  />
                </Link>
  
                <IconButton
                  colorScheme="red"
                  variant="outline"
                  icon={<BsFillTrashFill />}
                  aria-label="Delete"
                  onClick={onOpen}
                />
              </ButtonGroup>
            )}
          </Td>
        </Tr>
  
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                Delete Group
              </AlertDialogHeader>
  
              <AlertDialogBody>
                Are you sure? You can't undo this action afterwards.
              </AlertDialogBody>
  
              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  Cancel
                </Button>
                <Button colorScheme='red' onClick={deleteGroup} ml={3}>
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </>
    );
  }
  
  export default GroupTableRow;
  