import axios from "axios";
import {
    PURPOSE_LOADING,
    PURPOSE_GET_ALL_SUCCESS,
    PURPOSE_ERROR,
    PURPOSE_CLEAR_SNACKBAR
} from "../constants/types";

import { server_url } from "../constants/types";

export const purposeGetAll = (pagination = "") => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: PURPOSE_LOADING,
        payload: {
          loading: true,
        },
      });
      axios
        .get(`${server_url}/purpose?${pagination}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("authToken"),
          },
        })
        .then((response) => {
          dispatch({
            type: PURPOSE_GET_ALL_SUCCESS,
            payload: {
              purposeList: response.data.data,
              response: response.data,
              loading: false,
              status: "success",
              text: "Get all purpose successfully",
            },
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: PURPOSE_ERROR,
            payload: {
              status: "error",
              text: "Error occurred during getting purpose data.",
              loading: false,
            },
          });
          resolve();
        });
    });
  };
};

export const purposeAddOrUpdate = (purpose, state) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: PURPOSE_LOADING,
        payload: {
          loading: true,
        },
      });
      if (state === "add") {
        axios
          .post(`${server_url}/purpose`, purpose, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("authToken"),
            },
          })
          .then((responseF) => {
            axios
              .get(`${server_url}/purpose`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("authToken"),
                },
              })
              .then((response) => {
                dispatch({
                  type: PURPOSE_GET_ALL_SUCCESS,
                  payload: {
                    purposeList: response.data.data,
                    response: response.data,
                    loading: false,
                    status: responseF.data["status"],
                    text: responseF.data["message"],
                  },
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: PURPOSE_ERROR,
                  payload: {
                    text: "Error occurred during getting purpose data.",
                    status: "error",
                    loading: false,
                  },
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: PURPOSE_ERROR,
              payload: {
                text: "Error occurred during adding purpose data.",
                status: "error",
                loading: false,
              },
            });
            resolve();
          });
      } else if (state === "edit") {
        axios
          .put(`${server_url}/purpose/${purpose._id}`, purpose, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("authToken"),
            },
          })
          .then((responseF) => {
            axios
              .get(`${server_url}/purpose`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("authToken"),
                },
              })
              .then((response) => {
                dispatch({
                  type: PURPOSE_GET_ALL_SUCCESS,
                  payload: {
                    purposeList: response.data.data,
                    response: response.data,
                    purposeDetail: responseF.data["data"],
                    loading: false,
                    status: responseF.data["status"],
                    text: responseF.data["message"],
                  },
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: PURPOSE_ERROR,
                  payload: {
                    text: "Error occurred during getting purpose data.",
                    status: "error",
                    loading: false,
                  },
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: PURPOSE_ERROR,
              payload: {
                text: "Error occurred during updating purpose data.",
                status: "error",
                loading: false,
              },
            });
            resolve();
          });
      } else if (state === "view") {
        axios
          .get(`${server_url}/purpose/${purpose._id}`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("authToken"),
            },
          })
          .then((responseF) => {
            axios
              .get(`${server_url}/purpose`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("authToken"),
                },
              })
              .then((response) => {
                dispatch({
                  type: PURPOSE_GET_ALL_SUCCESS,
                  payload: {
                    purposeList: response.data.data,
                    response: response.data,
                    purposeDetail: responseF.data["data"],
                    loading: false,
                    status: responseF.data["status"],
                    text: responseF.data["message"],
                  },
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: PURPOSE_ERROR,
                  payload: {
                    text: "Error occurred during getting purpose data.",
                    status: "error",
                    loading: false,
                  },
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: PURPOSE_ERROR,
              payload: {
                text: "Error occurred during updating purpose data.",
                status: "error",
                loading: false,
              },
            });
            resolve();
          });
      }
    });
  };
};


export const purposeDelete = (purposeId) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: PURPOSE_LOADING,
        payload: {
          loading: true,
        },
      });
      axios
        .delete(`${server_url}/purpose/${purposeId}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("authToken"),
          },
        })
        .then((responseF) => {
          axios
            .get(`${server_url}/purpose`, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("authToken"),
              },
            })
            .then((response) => {
              dispatch({
                type: PURPOSE_GET_ALL_SUCCESS,
                payload: {
                    purposeList: response.data.data,
                  response: response.data,
                  purposeDetail: responseF.data["data"],
                  loading: false,
                  status: responseF.data["status"],
                  text: responseF.data["message"],
                },
              });
              resolve();
            })
            .catch((e) => {
              dispatch({
                type: PURPOSE_ERROR,
                payload: {
                  text: "Error occurred during getting purpose data.",
                  status: "error",
                  loading: false,
                },
              });
              resolve();
            });
        })
        .catch((e) => {
          dispatch({
            type: PURPOSE_ERROR,
            payload: {
              text: "Error occurred during deleting purpose data.",
              status: "error",
              loading: false,
            },
          });
          resolve();
        });
    });
  };
};


  export const purposeClearShowSnackbar = () => {
    return (dispatch, getState) => {
      dispatch({
        type:   PURPOSE_CLEAR_SNACKBAR,
      });
    };
  };
  