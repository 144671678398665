import {
  IAMSAFE_LOADING,
  IAMSAFE_GET_ALL_SUCCESS,
  IAMSAFE_ERROR,
  IAMSAFE_CLEAR_SNACKBAR,
  IAMSAFE_SEARCH  // Add the new action type
} from '../constants/types';

const initialState = {
  response: {},
  iamSafeList: [],
  iamSafeTodayList: [],
  iamSafeDetail: {},
  loading: false,
  status: 'success',
  text: '',
  showSnackbar: false,
  limit: 0,  // Add missing properties
  page: 0,
  totalPages: 0,
  searching: ''  // Add missing properties
};

const IamSafeReducer = (state = initialState, action) => {
  switch (action.type) {
    case IAMSAFE_CLEAR_SNACKBAR:
      return {
        ...state,
        showSnackbar: false
      };
    case IAMSAFE_LOADING:
      return {
        ...state,
        loading: true
      };
    case IAMSAFE_ERROR:
      return {
        ...state,
        iamSafeList: [],
        iamSafeTodayList: [],
        response: {},
        iamSafeDetail: {},
        limit: 0,  // Add missing properties
        page: 0,
        totalPages: 0,
        loading: false,
        status: action.payload.status,
        text: action.payload.text,
        showSnackbar: true
      };
    case IAMSAFE_GET_ALL_SUCCESS:
      return {
        ...state,
        iamSafeList: action.payload.iamSafeList,
        iamSafeTodayList: action.payload.iamSafeTodayList,
        response: action.payload.response,
        iamSafeDetail: action.payload.iamSafeDetail,
        limit: action.payload.limit,
        page: action.payload.page,
        totalPages: action.payload.totalPages,
        loading: false,
        status: action.payload.status,
        text: action.payload.text,
        showSnackbar: true
      };
    case IAMSAFE_SEARCH: // Add the new case
      return {
        ...state,
        searching: action.payload.searching
      };
    default:
      return state;
  }
};

export default IamSafeReducer;
