// Chakra imports
import {
  Box,
  Button,
  Center,
  Flex,
  Image,
  Input,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  Wrap,
  WrapItem,
  useColorModeValue
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
//   import { employeeAddOrUpdate, employeeClearShowSnackbar } from '../../actions/EmployeeAction';
import { reportIncedentAddOrUpdate, reportIncedentClearShowSnackbar } from '../../actions/reportIncedentAction'
import { useToast } from '@chakra-ui/react'
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { REPORTINCEDENT_CLEAR_SNACKBAR } from "constants/types";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react'
import { Skeleton, SkeletonCircle, SkeletonText } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { server_url } from '../../constants/types';

function ReportIncedentView() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [locationOfIncident, setLocationOfIncident] = useState("");
  const [image, setImage] = useState([]);
  const [nameOfPersonInvolved, setNameOfPersonInvolved] = useState("");
  const [reason, setReason] = useState("");
  const [description, setDescription] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [guard, setGuard] = useState("");
  const [guardEmail, setGuardEmail] = useState("");
  const [guardPhone, setGuardPhone] = useState("");

  const [employee, setEmployee] = useState("");
  const [employeeLicPic, setEmployeeLicPic] = useState("");
  const [employeeVehNum, setEmployeeVehNum] = useState("");
  const [employeeVehLic, setEmployeeVehLic] = useState("");

  // const [description, setDescription] = useState("");
  // const [designation, setDesignation] = useState("");
  // const [subdepartment, setSubdepartment] = useState("");
  // const [employeeType, setEmployeeType] = useState('');
  // const [employeeAddress, setEmployeeAddress]= useState('');
  // const [vehilcType, setVehilcType] = useState("");
  // const [vehicleNumber, setVehicleNumber] = useState("");
  // const [licenseNumber, setLicenseNumber] = useState('');
  // const [licenseExpiry, setLicenseExpiry]= useState('');
  // const [licensePhoto , setLicensePhoto]=useState('')

  const history = useHistory()
  const dispatch = useDispatch();
  const toast = useToast();

  console.log("udshohsdoufhsduof.../././././././.");
  // console.log(typeofincident);

  const statuses = ['success', 'error', 'warning', 'info'];
  const responseStatus = useSelector(state => state.reportIncedent.status);
  const responseMessage = useSelector(state => state.reportIncedent.text);
  const loading = useSelector(state => state.reportIncedent.loading);
  const reportIncedentDetail = useSelector(state => state.reportIncedent.reportIncedentDetail);
  const showSnackbar = useSelector(state => state.reportIncedent.showSnackbar);
  const typeOfIncident = useSelector(state => state.reportIncedent.typeOfIncident);




  console.log("logs./././.././");
  // console.log(logs);



  console.log("IIIIIIIII");
  console.log(image);

  console.log("reportIncedentDetail@@@@@@@@@@")
  console.log(reportIncedentDetail);
  const params = useParams();
  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
        console.log(reportIncedentDetail);

        setDate(reportIncedentDetail.date)
        setTime(reportIncedentDetail.time)
        setLocationOfIncident(reportIncedentDetail.locationOfIncident)
        setImage(reportIncedentDetail.image)
        setNameOfPersonInvolved(reportIncedentDetail.nameOfThePersonInvolved)
        setReason(reportIncedentDetail.reason)
        setDescription(reportIncedentDetail.description)
        setCreatedBy(reportIncedentDetail.createdBy)

        if (reportIncedentDetail.createdBy == 'guard') {
          if (reportIncedentDetail.guard === null) {
            setGuard(" Empty ")
            setGuardEmail("Empty")
            setGuardPhone("Empty")
          } else {
            setGuard(reportIncedentDetail.guard.name)
            setGuardEmail(reportIncedentDetail.guard.email)
            setGuardPhone(reportIncedentDetail.guard.number)
          }

        }
        else if (reportIncedentDetail.employee && reportIncedentDetail.employee.vehicleDetails) {
          setEmployee(reportIncedentDetail.employee.vehicleDetails.vehicleType);
          setEmployeeLicPic(reportIncedentDetail.employee.vehicleDetails.licensePhoto);
          setEmployeeVehNum(reportIncedentDetail.employee.vehicleDetails.vehicleNumber);
          setEmployeeVehLic(reportIncedentDetail.employee.vehicleDetails.licenseNumber);
        }

        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }

    dispatch({
      type: REPORTINCEDENT_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);

  useEffect(() => {
    console.log("params");
    const reportId = params.id;
    const reportIncedent = {
      _id: reportId
    };
    dispatch(reportIncedentAddOrUpdate(reportIncedent, 'view'));
  }, []);

  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  console.log("reportIncedentDetail-details")
  console.log(reportIncedentDetail)


  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Report Incedent View
          </Text>
        </CardHeader>
        <CardBody>
          {loading ? <Stack>
            <Skeleton height='20px' />
            <Skeleton height='20px' />
            <Skeleton height='20px' />
          </Stack>
            :
            <TableContainer>
              <Table variant='simple'>
                <Tbody>


                  <Tr>
                    <Td>Description</Td>
                    <Td>{description}</Td>
                  </Tr>
                  <Tr>
                    <Td>Reason</Td>
                    <Td>{reason}</Td>
                  </Tr>
                  <Tr>
                    <Td>Name Of Person Involved</Td>
                    <Td>{nameOfPersonInvolved}</Td>
                  </Tr>

                  <Tr>
                    <Td>Location Of Incident</Td>
                    <Td>{locationOfIncident}</Td>
                  </Tr>
                  {/* <Tr>
                    <Td>Created By</Td>
                    <Td>{createdBy}</Td>
                  </Tr> */}

                  <Tr>
                    {createdBy === 'guard' ? (
                      <>
                        <Tr>
                          <Td>Guard Name</Td>
                          <Td>{ guard}</Td>
                        </Tr>
                        <Tr >
                          <Td>Guard Phone Number</Td>
                          <Td>{guardPhone}</Td>
                        </Tr>
                        <Tr>
                          <Td>Guard Email</Td>
                          <Td>{guardEmail}</Td>
                        </Tr>
                      </>
                    ) : (
                      <>
                        <Tr>
                          <Td> Vehicle Type</Td>
                          <Td>{employee}</Td>
                        </Tr>
                        <Tr >
                          <Td> Vehicle Number </Td>
                          <Td>{employeeVehNum}</Td>
                        </Tr>
                        <Tr>
                          <Td> Licence Number</Td>
                          <Td>{guardEmail}</Td>
                        </Tr>
                        <Tr>
                          <Td> Licence Photo </Td>
                          <Td><img src={employeeLicPic} style={{ maxWidth: '120px' }} /></Td>
                        </Tr>
                      </>


                    )}
                  </Tr>
                  {/* <Tr>
                      <Td>Image</Td>
                      <Td>{
                        image.map((ele)=>(
                          <Flex direction="column">
                        <Text fontSize="md" color={textColor} fontWeight="bold">
                        <Image
                            boxSize='100px'
                            objectFit='cover'
                            src={ele.image}
                            alt='Dan Abramov'
                          />
                          
                        </Text>
                      </Flex>
                        ))
                      }
                      </Td>
                    </Tr> */}
                  {/* <Tr>
                    <Td>Date</Td>
                    <Td>{date}</Td>
                  </Tr> */}

                  {/* <Tr>
                    <Td>Image</Td>
                    <Td><img src={image} style={{ maxWidth: '120px' }} /></Td>
                  </Tr> */}

                  {/* <Tr>
                    <Td>Time</Td>
                    <Td>{time}</Td>
                  </Tr> */}
                  <Tr>
                    <Td>Type Of Incident</Td>
                    <Tr>
                      <Td>Type Of Incident</Td>
                      <Td>
                        {typeOfIncident && typeOfIncident.length > 0 ? (
                          <ul>
                            {typeOfIncident.map((incident, index) => (
                              <li key={index}>
                                <img src={incident.image} style={{ maxWidth: '120px' }} alt={incident.name} />
                                <p><b>Name:</b> {incident.name}</p>
                                <p><b>Created At:</b> {incident.createdAt}</p>
                                <p><b>Type:</b> {incident.type}</p>
                                <p><b>Updated At:</b> {incident.updatedAt}</p>
                              </li>
                            ))}
                          </ul>
                        ) : (
                          "No Data Available"
                        )}
                      </Td>
                    </Tr>

                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>}
        </CardBody>
      </Card>

      <Flex direction="row" gap="15px">
          <Wrap spacing={4} justify="center" align="center">
            {image.map((row, index) => (
              <Box key={index}>
                <Card mb={4} mt={10}>
                <strong>Images</strong>
                  <Image
                        // boxSize='70px'
                        objectFit='cover'
                        src={row}
                        alt='Dan Abramov'
                        cursor = 'pointer'
                  />
                </Card>
              </Box>
            ))}
          </Wrap>
      </Flex>


    </Flex >
  )

}

export default ReportIncedentView;
