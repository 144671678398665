// Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid,
  Progress,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorMode,
  useColorModeValue,
  Link,
  Spacer,
  InputGroup,
  InputLeftElement,
  IconButton,
  TableContainer,
  TableCaption,
  Tfoot
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import BarChart from "components/Charts/BarChart";
import LineChart from "components/Charts/LineChart";
import IconBox from "components/Icons/IconBox";
// Custom icons
import {
  CartIcon,
  DocumentIcon,
  GlobeIcon,
  WalletIcon,
} from "components/Icons/Icons.js";
import { MdDevices, MdCheckCircle,MdCancelScheduleSend   } from "react-icons/md";
import { LiaIndustrySolid } from "react-icons/lia";
import { FcDepartment } from "react-icons/fc";
import { BsBarChartSteps } from "react-icons/bs";

import React from "react";
// Variables
import {
  barChartData,
  barChartOptions,
  lineChartData,
  lineChartOptions,
} from "variables/charts";
import { pageVisits, socialTraffic } from "variables/general";
import { useHistory } from 'react-router-dom';
// import { useEffect } from "react";
import { useEffect, useState } from 'react';
import axios from 'axios';
import { server_url } from "constants/types";
import { useSelector } from "react-redux";
import { IoMdPeople } from 'react-icons/io';
import { GiEntryDoor, GiExitDoor } from 'react-icons/gi';
import { CiDeliveryTruck } from 'react-icons/ci';
import { IoMdCafe } from 'react-icons/io';
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { Value } from "sass";
import { useToast } from '@chakra-ui/react'
export default function Dashboard({ handleTitleClick }) {
  const toast = useToast();
  // Chakra Color Mode
  const iconBlue = useColorModeValue("blue.500", "blue.500");
  const iconBoxInside = useColorModeValue("white", "white");
  const textColor = useColorModeValue("gray.700", "white");
  const tableRowColor = useColorModeValue("#F7FAFC", "navy.900");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const textTableColor = useColorModeValue("gray.500", "white");
  const [devicesCount, setDevicesCount] = useState(0);
  const [countPlantAdmin, setCountPlantAdmin] = useState(0);
  const [countCityAdmin, setCountCityAdmin] = useState(0);
  const [role, setRole] = useState("")
  const [totalCanteen, setTotalCanteen] = useState("")
  const [totalDelivery, setTotalDelivery] = useState("")
  const [totalEmployee, setTotalEmployee] = useState("")
  const [totalEmployeeOut, setTotalEmployeeOut] = useState("")
  const [totalEmployeeIn, setTotalEmployeeIn] = useState("")
  const [totalVisitor, setTotalVisitor] = useState("")
  const [sortColumn, setSortColumn] = useState("");
  const [visitorHead, setVistiorHead] = useState(" ")
  const [materialHead, setMaterialHead] = useState(" ")
  const [workForceHead, setWorkForceHead] = useState(" ")
  const [canteenHead, setCanteenHead] = useState(" ")
  const [deliveryHead, setDeliveryHead] = useState(" ")
  const [totalHead, setTotalHead] = useState(" ")
  const [todaySos, setTodaySos] = useState(" ")
  const [todayIamSafe, setTodayIamSafe] = useState(" ")
  const [todayOutPass, setTodayOutPass] = useState(" ")

  const [devices,setDevices ] = useState(" ")
  const [countPlant,setCountPlant ] = useState(" ")
  const [checkpostCount, setCheckPost] = useState(" ")
  const [departmentCount, setDepartment] = useState(" ")
  const [subdepartmentCount,setSubDepartment ] = useState(" ")
  const [designationCount, setDesignation] = useState(" ")
  const [floorCount, setFloor] = useState(" ")

  const { colorMode } = useColorMode();
  const history = useHistory();
  const authDetail = useSelector(state => state.auth.authDetail);
  console.log(devicesCount);

  //  const someEvent = (value) => {
  //   // For example, you can call the handleTitleClick function when needed
  //   handleTitleClick(Value);
  // };
  useEffect(() => {
    var authKey = localStorage.getItem("authToken");
    setRole(authDetail.role?.name);
    if (authKey == null) {
      history.push('/auth/signin');
    }

    axios
      .get(`${server_url}/admin/devicesCount`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      })
      .then((response) => {
        if (role === 'Admin') {
          console.log("Admin response-------");
          console.log(response);
          const { devicesCount, countPlantAdmin, countCityAdmin } = response.data.responseData;
          setDevicesCount(devicesCount);
          setCountPlantAdmin(countPlantAdmin);
          setCountCityAdmin(countCityAdmin);
        } else if (role === 'City Admin') {
          // Handle City Admin response here
          const { devicesCount, countPlantAdmin,checkpostCount,departmentCount,subdepartmentCount,designationCount,floorCount} = response.data.responseData;
          setDevices(devicesCount);
          setCountPlant(countPlantAdmin);
          setCheckPost(checkpostCount);
          setDepartment(departmentCount);
          setSubDepartment(subdepartmentCount);
          setDesignation(designationCount);
          setFloor(floorCount)
        } else if (role === 'Plant Admin') {
          console.log("Plant Admin response-------");
          console.log(response);
          const { totalCanteen, totalDelivery, totalEmployee, totalEmployeeIn, totalEmployeeOut, totalVisitor, visitorHeadCount, materialHeadCount, workforceHeadCount, canteenHeadCount, deliveryHeadCount, totalHeadcount,todayIamSafe, todaySos , todayOutPass } = response.data.responseData;
          setTotalVisitor(totalVisitor);
          setTotalCanteen(totalCanteen);
          setTotalDelivery(totalDelivery);
          setTotalEmployee(totalEmployee);
          setTotalEmployeeIn(totalEmployeeIn);
          setTotalEmployeeOut(totalEmployeeOut);

          setVistiorHead(visitorHeadCount);
          setMaterialHead(materialHeadCount);
          setWorkForceHead(workforceHeadCount);
          setCanteenHead(canteenHeadCount);
          setDeliveryHead(deliveryHeadCount);
          setTotalHead(totalHeadcount);
          setTodaySos(todaySos)
          setTodayIamSafe(todayIamSafe)
          setTodayOutPass(todayOutPass)
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  });

  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])



//   useEffect(() => {
//     if(role === 'Plant Admin'){
//     // Define a function to make the API call
//     const fetchData = async () => {
//       try {
//        await axios.get(`${server_url}/Unseen-IamSafe`, {
//           headers: {
//             'Authorization': 'Bearer ' + localStorage.getItem('authToken')
//           }
//         })
//         .then((response) => {

//   if (response.data.status === 'OK') {
//     let arr = response.data.data;
//     console.log("arr");
//     console.log(arr);
  
  
//     arr.map((currentElement, index) => {
//       const object = {
//         "title": currentElement.title,
//         "name": currentElement.name,
//         "department": currentElement.department ? currentElement.department : '',
//         "subdepartment": currentElement.subdepartment ? currentElement.subdepartment : '',
//         "designation": currentElement.designation ? currentElement.designation : '',
//       };
  
//       setTimeout(() => {
//         showToast(object, 'success');
//       }, index * 10000);
//     });
//   }
//   else {
//     showToast(response.data.message, 'error');
//   }
// })
//       } catch (error) {
//         console.error('Error:', error);
//         showToast('An error occurred', 'error');
//       }
//     };
//     fetchData();
//     // Set up an interval to make the API call every 5 seconds (adjust as needed)
//     // const intervalId = setInterval(fetchData, 10000);

//     // Clear the interval when the component is unmounted
//     // return () => clearInterval(intervalId);

//     // The empty dependency array ensures that this effect runs only once, similar to componentDidMount
//   }
// },);



// const showToast = (object, status) => {
//   const message = `Title: ${object.title}\n\Name: ${object.name}\nDepartment: ${object.department}\nSubdepartment: ${object.subdepartment}\nDesignation: ${object.designation}`;

//   toast({
//     title: message,
//     status: status,
//     duration: 3000, // Display duration in milliseconds
//     position: "top-right",
//     isClosable: true,
//   });
// };

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("desc");
    }
  };



  if (role === 'Admin') {
    return (
      <Flex flexDirection='column' pt={{ base: "120px", md: "75px" }}>
        <SimpleGrid columns={{ sm: 1, md: 2, xl: 4 }} spacing='24px' mb='20px'>
          <Card minH='125px'>
            <Flex direction='column'>
              <Flex
                flexDirection='row'
                align='center'
                justify='center'
                w='100%'
                mb='25px'>
                <Stat me='auto'>
                  <StatLabel
                    fontSize='xs'
                    color='gray.400'
                    fontWeight='bold'
                    textTransform='uppercase'>
                    Plant Admins
                  </StatLabel>
                  <Flex>
                    <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                      {countPlantAdmin}
                    </StatNumber>
                  </Flex>
                </Stat>
                <IconBox
                  borderRadius='50%'
                  as='box'
                  h={"45px"}
                  w={"45px"}
                  bg={iconBlue}>
                  <WalletIcon h={"24px"} w={"24px"} color={iconBoxInside} />
                </IconBox>
              </Flex>
              {/* <Text color='gray.400' fontSize='sm'>
              <Text as='span' color='green.400' fontWeight='bold'>
                +3.48%{" "}
              </Text>
              Since last month
            </Text> */}
            </Flex>
          </Card>
          <Card minH='125px'>
            <Flex direction='column'>
              <Flex
                flexDirection='row'
                align='center'
                justify='center'
                w='100%'
                mb='25px'>
                <Stat me='auto'>
                  <StatLabel
                    fontSize='xs'
                    color='gray.400'
                    fontWeight='bold'
                    textTransform='uppercase'>
                    Devices
                  </StatLabel>
                  <Flex>
                    <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                      {devicesCount}
                    </StatNumber>
                  </Flex>
                </Stat>
                <IconBox
                  borderRadius='50%'
                  as='box'
                  h={"45px"}
                  w={"45px"}
                  bg={iconBlue}>
                  <GlobeIcon h={"24px"} w={"24px"} color={iconBoxInside} />
                </IconBox>
              </Flex>
              {/* <Text color='gray.400' fontSize='sm'>
              <Text as='span' color='green.400' fontWeight='bold'>
                +5.2%{" "}
              </Text>
              Since last month
            </Text> */}
            </Flex>
          </Card>
          <Card minH='125px'>
            <Flex direction='column'>
              <Flex
                flexDirection='row'
                align='center'
                justify='center'
                w='100%'
                mb='25px'>
                <Stat me='auto'>
                  <StatLabel
                    fontSize='xs'
                    color='gray.400'
                    fontWeight='bold'
                    textTransform='uppercase'>
                    City Admins
                  </StatLabel>
                  <Flex>
                    <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                      {countCityAdmin}
                    </StatNumber>
                  </Flex>
                </Stat>
                <IconBox
                  borderRadius='50%'
                  as='box'
                  h={"45px"}
                  w={"45px"}
                  bg={iconBlue}>
                  <DocumentIcon h={"24px"} w={"24px"} color={iconBoxInside} />
                </IconBox>
              </Flex>
              {/* <Text color='gray.400' fontSize='sm'>
              <Text as='span' color='red.500' fontWeight='bold'>
                -2.82%{" "}
              </Text>
              Since last month
            </Text> */}
            </Flex>
          </Card>
          <Card minH='125px'>
            <Flex direction='column'>
              <Flex
                flexDirection='row'
                align='center'
                justify='center'
                w='100%'
                mb='25px'>
                <Stat me='auto'>
                  <StatLabel
                    fontSize='xs'
                    color='gray.400'
                    fontWeight='bold'
                    textTransform='uppercase'>
                    B2B services
                  </StatLabel>
                  <Flex>
                    <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                      0
                    </StatNumber>
                  </Flex>
                </Stat>
                <IconBox
                  borderRadius='50%'
                  as='box'
                  h={"45px"}
                  w={"45px"}
                  bg={iconBlue}>
                  <CartIcon h={"24px"} w={"24px"} color={iconBoxInside} />
                </IconBox>
              </Flex>
              {/* <Text color='gray.400' fontSize='sm'>
              <Text as='span' color='green.400' fontWeight='bold'>
                +8.12%{" "}
              </Text>
              Since last month
            </Text> */}
            </Flex>
          </Card>
        </SimpleGrid>
        {/* <Grid
        templateColumns={{ sm: "1fr", lg: "2fr 1fr" }}
        templateRows={{ lg: "repeat(2, auto)" }}
        gap='20px'>
        <Card
          bg={
            colorMode === "dark"
              ? "navy.800"
              : "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)"
          }
          p='0px'
          maxW={{ sm: "320px", md: "100%" }}>
          <Flex direction='column' mb='40px' p='28px 0px 0px 22px'>
            <Text color='#fff' fontSize='lg' fontWeight='bold' mb='6px'>
              Sales Overview
            </Text>
            <Text color='#fff' fontSize='sm'>
              <Text as='span' color='green.400' fontWeight='bold'>
                (+5) more{" "}
              </Text>
              in 2022
            </Text>
          </Flex>
          <Box minH='300px'>
            <LineChart
              chartData={lineChartData}
              chartOptions={lineChartOptions}
            />
          </Box>
        </Card>
        <Card p='0px' maxW={{ sm: "320px", md: "100%" }}>
          <Flex direction='column' mb='40px' p='28px 0px 0px 22px'>
            <Text color='gray.400' fontSize='sm' fontWeight='bold' mb='6px'>
              PERFORMANCE
            </Text>
            <Text color={textColor} fontSize='lg' fontWeight='bold'>
              Total orders
            </Text>
          </Flex>
          <Box minH='300px'>
            <BarChart chartData={barChartData} chartOptions={barChartOptions} />
          </Box>
        </Card>
        <Card p='0px' maxW={{ sm: "320px", md: "100%" }}>
          <Flex direction='column'>
            <Flex align='center' justify='space-between' p='22px'>
              <Text fontSize='lg' color={textColor} fontWeight='bold'>
                Page visits
              </Text>
              <Button variant='primary' maxH='30px'>
                SEE ALL
              </Button>
            </Flex>
            <Box overflow={{ sm: "scroll", lg: "hidden" }}>
              <Table>
                <Thead>
                  <Tr bg={tableRowColor}>
                    <Th color='gray.400' borderColor={borderColor}>
                      Page name
                    </Th>
                    <Th color='gray.400' borderColor={borderColor}>
                      Visitors
                    </Th>
                    <Th color='gray.400' borderColor={borderColor}>
                      Unique users
                    </Th>
                    <Th color='gray.400' borderColor={borderColor}>
                      Bounce rate
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {pageVisits.map((el, index, arr) => {
                    return (
                      <Tr key={index}>
                        <Td
                          color={textTableColor}
                          fontSize='sm'
                          fontWeight='bold'
                          borderColor={borderColor}
                          border={index === arr.length - 1 ? "none" : null}>
                          {el.pageName}
                        </Td>
                        <Td
                          color={textTableColor}
                          fontSize='sm'
                          border={index === arr.length - 1 ? "none" : null}
                          borderColor={borderColor}>
                          {el.visitors}
                        </Td>
                        <Td
                          color={textTableColor}
                          fontSize='sm'
                          border={index === arr.length - 1 ? "none" : null}
                          borderColor={borderColor}>
                          {el.uniqueUsers}
                        </Td>
                        <Td
                          color={textTableColor}
                          fontSize='sm'
                          border={index === arr.length - 1 ? "none" : null}
                          borderColor={borderColor}>
                          {el.bounceRate}
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </Box>
          </Flex>
        </Card>
        <Card p='0px' maxW={{ sm: "320px", md: "100%" }}>
          <Flex direction='column'>
            <Flex align='center' justify='space-between' p='22px'>
              <Text fontSize='lg' color={textColor} fontWeight='bold'>
                Social traffic
              </Text>
              <Button variant='primary' maxH='30px'>
                SEE ALL
              </Button>
            </Flex>
          </Flex>
          <Box overflow={{ sm: "scroll", lg: "hidden" }}>
            <Table>
              <Thead>
                <Tr bg={tableRowColor}>
                  <Th color='gray.400' borderColor={borderColor}>
                    Referral
                  </Th>
                  <Th color='gray.400' borderColor={borderColor}>
                    Visitors
                  </Th>
                  <Th color='gray.400' borderColor={borderColor}></Th>
                </Tr>
              </Thead>
              <Tbody>
                {socialTraffic.map((el, index, arr) => {
                  return (
                    <Tr key={index}>
                      <Td
                        color={textTableColor}
                        fontSize='sm'
                        fontWeight='bold'
                        borderColor={borderColor}
                        border={index === arr.length - 1 ? "none" : null}>
                        {el.referral}
                      </Td>
                      <Td
                        color={textTableColor}
                        fontSize='sm'
                        borderColor={borderColor}
                        border={index === arr.length - 1 ? "none" : null}>
                        {el.visitors}
                      </Td>
                      <Td
                        color={textTableColor}
                        fontSize='sm'
                        borderColor={borderColor}
                        border={index === arr.length - 1 ? "none" : null}>
                        <Flex align='center'>
                          <Text
                            color={textTableColor}
                            fontWeight='bold'
                            fontSize='sm'
                            me='12px'>{`${el.percentage}%`}</Text>
                          <Progress
                            size='xs'
                            colorScheme={el.color}
                            value={el.percentage}
                            minW='120px'
                          />
                        </Flex>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Box>
        </Card>
      </Grid> */}
      </Flex>
    );
  }
  else if (role === 'City Admin') {

    return (
      <Flex flexDirection='column' pt={{ base: "120px", md: "75px" }}>
        <SimpleGrid columns={{ sm: 1, md: 2, xl: 4 }} spacing='24px' mb='20px'>
          <Card minH='125px'>
            <Flex direction='column'>
              <Flex
                flexDirection='row'
                align='center'
                justify='center'
                w='100%'
                mb='25px'>
                <Stat me='auto'>
                  <StatLabel
                    fontSize='xs'
                    color='gray.400'
                    fontWeight='bold'
                    textTransform='uppercase'>
                    Number Of Devices
                  </StatLabel>
                  <Flex>
                    <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                      {devices}
                    </StatNumber>
                  </Flex>
                </Stat>
                <IconBox
                  borderRadius='50%'
                  as='box'
                  h={"45px"}
                  w={"45px"}
                  bg={iconBlue}>
                  <MdDevices h={"28px"} w={"28px"} color={iconBoxInside} />
                </IconBox>
              </Flex>
              
            </Flex>
          </Card>
          <Card minH='125px'>
            <Flex direction='column'>
              <Flex
                flexDirection='row'
                align='center'
                justify='center'
                w='100%'
                mb='25px'>
                <Stat me='auto'>
                  <StatLabel
                    fontSize='xs'
                    color='gray.400'
                    fontWeight='bold'
                    textTransform='uppercase'>
                    Number Of Plants
                  </StatLabel>
                  <Flex>
                    <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                      {countPlant}
                    </StatNumber>
                  </Flex>
                </Stat>
                <IconBox
                  borderRadius='50%'
                  as='box'
                  h={"45px"}
                  w={"45px"}
                  bg={iconBlue}>
                  <LiaIndustrySolid h={"24px"} w={"24px"} color={iconBoxInside} />
                </IconBox>
              </Flex>
             
            </Flex>
          </Card>
          <Card minH='125px'>
            <Flex direction='column'>
              <Flex
                flexDirection='row'
                align='center'
                justify='center'
                w='100%'
                mb='25px'>
                <Stat me='auto'>
                  <StatLabel
                    fontSize='xs'
                    color='gray.400'
                    fontWeight='bold'
                    textTransform='uppercase'>
                    Number Of Checkpoints
                  </StatLabel>
                  <Flex>
                    <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                      {checkpostCount}
                    </StatNumber>
                  </Flex>
                </Stat>
                <IconBox
                  borderRadius='50%'
                  as='box'
                  h={"45px"}
                  w={"45px"}
                  bg={iconBlue}>
                  <MdCheckCircle h={"24px"} w={"24px"} color={iconBoxInside} />
                </IconBox>
              </Flex>
            </Flex>
          </Card>
          <Card minH='125px'>
            <Flex direction='column'>
              <Flex
                flexDirection='row'
                align='center'
                justify='center'
                w='100%'
                mb='25px'>
                <Stat me='auto'>
                  <StatLabel
                    fontSize='xs'
                    color='gray.400'
                    fontWeight='bold'
                    textTransform='uppercase'>
                    Number Of Department
                  </StatLabel>
                  <Flex>
                    <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                      {departmentCount}
                    </StatNumber>
                  </Flex>
                </Stat>
                <IconBox
                  borderRadius='50%'
                  as='box'
                  h={"45px"}
                  w={"45px"}
                  bg={iconBlue}>
                  <FcDepartment h={"24px"} w={"24px"} color={iconBoxInside} />
                </IconBox>
              </Flex>
            </Flex>
          </Card>
          <Card minH='125px'>
            <Flex direction='column'>
              <Flex
                flexDirection='row'
                align='center'
                justify='center'
                w='100%'
                mb='25px'>
                <Stat me='auto'>
                  <StatLabel
                    fontSize='xs'
                    color='gray.400'
                    fontWeight='bold'
                    textTransform='uppercase'>
                    Number Of Sub-Department
                  </StatLabel>
                  <Flex>
                    <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                      {subdepartmentCount}
                    </StatNumber>
                  </Flex>
                </Stat>
                <IconBox
                  borderRadius='50%'
                  as='box'
                  h={"45px"}
                  w={"45px"}
                  bg={iconBlue}>
                  <FcDepartment h={"24px"} w={"24px"} color={iconBoxInside} />
                </IconBox>
              </Flex>
            </Flex>
          </Card>

          <Card minH='125px'>
            <Flex direction='column'>
              <Flex
                flexDirection='row'
                align='center'
                justify='center'
                w='100%'
                mb='25px'>
                <Stat me='auto'>
                  <StatLabel
                    fontSize='xs'
                    color='gray.400'
                    fontWeight='bold'
                    textTransform='uppercase'>
                    Number Of Designation
                  </StatLabel>
                  <Flex>
                    <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                      {designationCount}
                    </StatNumber>
                  </Flex>
                </Stat>
                <IconBox
                  borderRadius='50%'
                  as='box'
                  h={"45px"}
                  w={"45px"}
                  bg={iconBlue}>
                  <MdCancelScheduleSend h={"24px"} w={"24px"} color={iconBoxInside} />
                </IconBox>
              </Flex>
            </Flex>
          </Card>
          <Card minH='125px'>
            <Flex direction='column'>
              <Flex
                flexDirection='row'
                align='center'
                justify='center'
                w='100%'
                mb='25px'>
                <Stat me='auto'>
                  <StatLabel
                    fontSize='xs'
                    color='gray.400'
                    fontWeight='bold'
                    textTransform='uppercase'>
                    Number Of Floors
                  </StatLabel>
                  <Flex>
                    <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                      {floorCount}
                    </StatNumber>
                  </Flex>
                </Stat>
                <IconBox
                  borderRadius='50%'
                  as='box'
                  h={"45px"}
                  w={"45px"}
                  bg={iconBlue}>
                  <BsBarChartSteps h={"24px"} w={"24px"} color={iconBoxInside} />
                </IconBox>
              </Flex>
            </Flex>
          </Card>
          {/* <Card minH='125px'>
            <Flex direction='column'>
              <Flex
                flexDirection='row'
                align='center'
                justify='center'
                w='100%'
                mb='25px'>
                <Stat me='auto'>
                  <StatLabel
                    fontSize='xs'
                    color='gray.400'
                    fontWeight='bold'
                    textTransform='uppercase'>
                    B2B services
                  </StatLabel>
                  <Flex>
                    <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                      0
                    </StatNumber>
                  </Flex>
                </Stat>
                <IconBox
                  borderRadius='50%'
                  as='box'
                  h={"45px"}
                  w={"45px"}
                  bg={iconBlue}>
                  <CartIcon h={"24px"} w={"24px"} color={iconBoxInside} />
                </IconBox>
              </Flex>
             
            </Flex>
          </Card> */}
        </SimpleGrid>
        
      </Flex>
    );


  }
  else if (role === 'Plant Admin') {
    return (
      <>
        <Flex flexDirection='column' pt={{ base: "120px", md: "75px" }}>
          <SimpleGrid columns={{ sm: 1, md: 2, xl: 4 }} spacing='24px' mb='20px'>
          <Link href="#/admin/employee-table">
            <Card minH='125px' style={{ cursor: 'pointer' }}>
              <Flex direction='column'>
                <Flex
                  flexDirection='row'
                  align='center'
                  justify='center'
                  w='100%'
                  mb='25px'>
                  <Stat me='auto'>
                    <StatLabel
                      fontSize='xs'
                      color='gray.400'
                      fontWeight='bold'
                      textTransform='uppercase'>
                      Total employee
                    </StatLabel>

                    <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                      {totalEmployee}
                    </StatNumber>

                  </Stat>
                  <IconBox
                    borderRadius='50%'
                    as='box'
                    h={"50px"}
                    w={"50px"}
                    bg={iconBlue}>
                    <IoMdPeople style={{ fontSize: '25px', color: 'white' }} />
                  </IconBox>
                </Flex>
                {/* <Text color='gray.400' fontSize='sm'>
              <Text as='span' color='green.400' fontWeight='bold'>
                +3.48%{" "}
              </Text>
              Since last month
            </Text> */}
              </Flex>
            </Card>
          </Link>

          <Link href="#/admin/employee-attendence-in">
            <Card minH='125px'  style={{ cursor: 'pointer' }}>
              <Flex direction='column'>
                <Flex
                  flexDirection='row'
                  align='center'
                  justify='center'
                  w='100%'
                  mb='25px'>
                  <Stat me='auto'>
                    <StatLabel
                      fontSize='xs'
                      color='gray.400'
                      fontWeight='bold'
                      textTransform='uppercase'>
                   Total Employee In
                    </StatLabel>
                    <Flex>
                      <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                        {totalEmployeeIn}
                      </StatNumber>
                    </Flex>
                  </Stat>
                  <IconBox
                    borderRadius='50%'
                    as='box'
                    h={"45px"}
                    w={"45px"}
                    bg={iconBlue}>
                    <GiEntryDoor style={{ fontSize: '25px', color: 'white' }} />
                  </IconBox>
                </Flex>
                {/* <Text color='gray.400' fontSize='sm'>
              <Text as='span' color='green.400' fontWeight='bold'>
                +5.2%{" "}
              </Text>
              Since last month
            </Text> */}
              </Flex>
            </Card>
          </Link>

          <Link href="#/admin/employee-attendence-out">
            <Card minH='125px' style={{ cursor: 'pointer' }}>
              <Flex direction='column'>
                <Flex
                  flexDirection='row'
                  align='center'
                  justify='center'
                  w='100%'
                  mb='25px'>
                  <Stat me='auto'>
                    <StatLabel
                      fontSize='xs'
                      color='gray.400'
                      fontWeight='bold'
                      textTransform='uppercase'>
                      Total Employee Out
                    </StatLabel>
                    <Flex>
                      <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                        {totalEmployeeOut}
                      </StatNumber>
                    </Flex>
                  </Stat>
                  <IconBox
                    borderRadius='50%'
                    as='box'
                    h={"45px"}
                    w={"45px"}
                    bg={iconBlue}>
                    <GiExitDoor style={{ fontSize: '25px', color: 'white' }} />
                  </IconBox>
                </Flex>
                {/* <Text color='gray.400' fontSize='sm'>
              <Text as='span' color='red.500' fontWeight='bold'>
                -2.82%{" "}
              </Text>
              Since last month
            </Text> */}
              </Flex>
            </Card>
          </Link>

          <Link href="#/admin/visitor/latest-visitor">
            <Card minH='125px' style={{ cursor: 'pointer' }}>
              <Flex direction='column'>
                <Flex
                  flexDirection='row'
                  align='center'
                  justify='center'
                  w='100%'
                  mb='25px'>
                  <Stat me='auto'>
                    <StatLabel
                      fontSize='xs'
                      color='gray.400'
                      fontWeight='bold'
                      textTransform='uppercase'>
                     Total Visitor
                    </StatLabel>
                    <Flex>
                      <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                        {totalVisitor}
                      </StatNumber>
                    </Flex>
                  </Stat>
                  <IconBox
                    borderRadius='50%'
                    as='box'
                    h={"45px"}
                    w={"45px"}
                    bg={iconBlue}>
                    <CartIcon style={{ fontSize: '25px', color: 'white' }} />
                  </IconBox>
                </Flex>
                {/* <Text color='gray.400' fontSize='sm'>
              <Text as='span' color='green.400' fontWeight='bold'>
                +8.12%{" "}
              </Text>
              Since last month
            </Text> */}
              </Flex>
            </Card>
          </Link>

          <Link href="#/admin/canteen/latest-canteen">
            <Card minH='125px' style={{ cursor: 'pointer' }}>
              <Flex direction='column'>
                <Flex
                  flexDirection='row'
                  align='center'
                  justify='center'
                  w='100%'
                  mb='25px'>
                  <Stat me='auto'>
                    <StatLabel
                      fontSize='xs'
                      color='gray.400'
                      fontWeight='bold'
                      textTransform='uppercase'>
                      Total Canteen
                    </StatLabel>
                    <Flex>
                      <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                        {totalCanteen}
                      </StatNumber>
                    </Flex>
                  </Stat>
                  <IconBox
                    borderRadius='50%'
                    as='box'
                    h={"45px"}
                    w={"45px"}
                    bg={iconBlue}>
                    <IoMdCafe style={{ fontSize: '25px', color: 'white' }} />
                  </IconBox>
                </Flex>
                {/* <Text color='gray.400' fontSize='sm'>
              <Text as='span' color='green.400' fontWeight='bold'>
                +8.12%{" "}
              </Text>
              Since last month
            </Text> */}
              </Flex>
            </Card>
          </Link>

          <Link href="#/admin/delivery/latest-delivery">
            <Card minH='125px' style={{ cursor: 'pointer' }}>
              <Flex direction='column'>
                <Flex
                  flexDirection='row'
                  align='center'
                  justify='center'
                  w='100%'
                  mb='25px'>
                  <Stat me='auto'>
                    <StatLabel
                      fontSize='xs'
                      color='gray.400'
                      fontWeight='bold'
                      textTransform='uppercase'>
                      Total Delivery
                    </StatLabel>
                    <Flex>
                      <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                        {totalDelivery}
                      </StatNumber>
                    </Flex>
                  </Stat>
                  <IconBox
                    borderRadius='50%'
                    as='box'
                    h={"45px"}
                    w={"45px"}
                    bg={iconBlue}>
                    <CiDeliveryTruck style={{ fontSize: '25px', color: 'white' }} />
                  </IconBox>
                </Flex>
                {/* <Text color='gray.400' fontSize='sm'>
              <Text as='span' color='green.400' fontWeight='bold'>
                +8.12%{" "}
              </Text>
              Since last month
            </Text> */}
              </Flex>
            </Card>
          </Link>

          <Link href="#/admin/latest-sos">
            <Card minH='125px' style={{ cursor: 'pointer' }}>
              <Flex direction='column'>
                <Flex
                  flexDirection='row'
                  align='center'
                  justify='center'
                  w='100%'
                  mb='25px'>
                  <Stat me='auto'>
                    <StatLabel
                      fontSize='xs'
                      color='gray.400'
                      fontWeight='bold'
                      textTransform='uppercase'>
                      Total SOS
                    </StatLabel>
                    <Flex>
                      <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                        {todaySos}
                      </StatNumber>
                    </Flex>
                  </Stat>
                  <IconBox
                    borderRadius='50%'
                    as='box'
                    h={"45px"}
                    w={"45px"}
                    bg={iconBlue}>
                    <CiDeliveryTruck style={{ fontSize: '25px', color: 'white' }} />
                  </IconBox>
                </Flex>
                {/* <Text color='gray.400' fontSize='sm'>
              <Text as='span' color='green.400' fontWeight='bold'>
                +8.12%{" "}
              </Text>
              Since last month
            </Text> */}
              </Flex>
            </Card>
          </Link>

          <Link href="#/admin/iamSafe-latest">
            <Card minH='125px'>
              <Flex direction='column'>
                <Flex
                  flexDirection='row'
                  align='center'
                  justify='center'
                  w='100%'
                  mb='25px'>
                  <Stat me='auto'>
                    <StatLabel
                      fontSize='xs'
                      color='gray.400'
                      fontWeight='bold'
                      textTransform='uppercase'>
                      Total I Am Safe
                    </StatLabel>
                    <Flex>
                      <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                        {todayIamSafe}
                      </StatNumber>
                    </Flex>
                  </Stat>
                  <IconBox
                    borderRadius='50%'
                    as='box'
                    h={"45px"}
                    w={"45px"}
                    bg={iconBlue}>
                    <CiDeliveryTruck style={{ fontSize: '25px', color: 'white' }} />
                  </IconBox>
                </Flex>
                {/* <Text color='gray.400' fontSize='sm'>
              <Text as='span' color='green.400' fontWeight='bold'>
                +8.12%{" "}
              </Text>
              Since last month
            </Text> */}
              </Flex>
            </Card>
            </Link>


            <Link href="#/admin/today-outpass">
            <Card minH='125px'>
              <Flex direction='column'>
                <Flex
                  flexDirection='row'
                  align='center'
                  justify='center'
                  w='100%'
                  mb='25px'>
                  <Stat me='auto'>
                    <StatLabel
                      fontSize='xs'
                      color='gray.400'
                      fontWeight='bold'
                      textTransform='uppercase'>
                      Total Out Pass
                    </StatLabel>
                    <Flex>
                      <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                        {todayOutPass}
                      </StatNumber>
                    </Flex>
                  </Stat>
                  <IconBox
                    borderRadius='50%'
                    as='box'
                    h={"45px"}
                    w={"45px"}
                    bg={iconBlue}>
                    <CiDeliveryTruck style={{ fontSize: '25px', color: 'white' }} />
                  </IconBox>
                </Flex>
                {/* <Text color='gray.400' fontSize='sm'>
              <Text as='span' color='green.400' fontWeight='bold'>
                +8.12%{" "}
              </Text>
              Since last month
            </Text> */}
              </Flex>
            </Card>
            </Link>
          </SimpleGrid>
          {/* <Grid
        templateColumns={{ sm: "1fr", lg: "2fr 1fr" }}
        templateRows={{ lg: "repeat(2, auto)" }}
        gap='20px'>
        <Card
          bg={
            colorMode === "dark"
              ? "navy.800"
              : "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)"
          }
          p='0px'
          maxW={{ sm: "320px", md: "100%" }}>
          <Flex direction='column' mb='40px' p='28px 0px 0px 22px'>
            <Text color='#fff' fontSize='lg' fontWeight='bold' mb='6px'>
              Sales Overview
            </Text>
            <Text color='#fff' fontSize='sm'>
              <Text as='span' color='green.400' fontWeight='bold'>
                (+5) more{" "}
              </Text>
              in 2022
            </Text>
          </Flex>
          <Box minH='300px'>
            <LineChart
              chartData={lineChartData}
              chartOptions={lineChartOptions}
            />
          </Box>
        </Card>
        <Card p='0px' maxW={{ sm: "320px", md: "100%" }}>
          <Flex direction='column' mb='40px' p='28px 0px 0px 22px'>
            <Text color='gray.400' fontSize='sm' fontWeight='bold' mb='6px'>
              PERFORMANCE
            </Text>
            <Text color={textColor} fontSize='lg' fontWeight='bold'>
              Total orders
            </Text>
          </Flex>
          <Box minH='300px'>
            <BarChart chartData={barChartData} chartOptions={barChartOptions} />
          </Box>
        </Card>
        <Card p='0px' maxW={{ sm: "320px", md: "100%" }}>
          <Flex direction='column'>
            <Flex align='center' justify='space-between' p='22px'>
              <Text fontSize='lg' color={textColor} fontWeight='bold'>
                Page visits
              </Text>
              <Button variant='primary' maxH='30px'>
                SEE ALL
              </Button>
            </Flex>
            <Box overflow={{ sm: "scroll", lg: "hidden" }}>
              <Table>
                <Thead>
                  <Tr bg={tableRowColor}>
                    <Th color='gray.400' borderColor={borderColor}>
                      Page name
                    </Th>
                    <Th color='gray.400' borderColor={borderColor}>
                      Visitors
                    </Th>
                    <Th color='gray.400' borderColor={borderColor}>
                      Unique users
                    </Th>
                    <Th color='gray.400' borderColor={borderColor}>
                      Bounce rate
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {pageVisits.map((el, index, arr) => {
                    return (
                      <Tr key={index}>
                        <Td
                          color={textTableColor}
                          fontSize='sm'
                          fontWeight='bold'
                          borderColor={borderColor}
                          border={index === arr.length - 1 ? "none" : null}>
                          {el.pageName}
                        </Td>
                        <Td
                          color={textTableColor}
                          fontSize='sm'
                          border={index === arr.length - 1 ? "none" : null}
                          borderColor={borderColor}>
                          {el.visitors}
                        </Td>
                        <Td
                          color={textTableColor}
                          fontSize='sm'
                          border={index === arr.length - 1 ? "none" : null}
                          borderColor={borderColor}>
                          {el.uniqueUsers}
                        </Td>
                        <Td
                          color={textTableColor}
                          fontSize='sm'
                          border={index === arr.length - 1 ? "none" : null}
                          borderColor={borderColor}>
                          {el.bounceRate}
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </Box>
          </Flex>
        </Card>
        <Card p='0px' maxW={{ sm: "320px", md: "100%" }}>
          <Flex direction='column'>
            <Flex align='center' justify='space-between' p='22px'>
              <Text fontSize='lg' color={textColor} fontWeight='bold'>
                Social traffic
              </Text>
              <Button variant='primary' maxH='30px'>
                SEE ALL
              </Button>
            </Flex>
          </Flex>
          <Box overflow={{ sm: "scroll", lg: "hidden" }}>
            <Table>
              <Thead>
                <Tr bg={tableRowColor}>
                  <Th color='gray.400' borderColor={borderColor}>
                    Referral
                  </Th>
                  <Th color='gray.400' borderColor={borderColor}>
                    Visitors
                  </Th>
                  <Th color='gray.400' borderColor={borderColor}></Th>
                </Tr>
              </Thead>
              <Tbody>
                {socialTraffic.map((el, index, arr) => {
                  return (
                    <Tr key={index}>
                      <Td
                        color={textTableColor}
                        fontSize='sm'
                        fontWeight='bold'
                        borderColor={borderColor}
                        border={index === arr.length - 1 ? "none" : null}>
                        {el.referral}
                      </Td>
                      <Td
                        color={textTableColor}
                        fontSize='sm'
                        borderColor={borderColor}
                        border={index === arr.length - 1 ? "none" : null}>
                        {el.visitors}
                      </Td>
                      <Td
                        color={textTableColor}
                        fontSize='sm'
                        borderColor={borderColor}
                        border={index === arr.length - 1 ? "none" : null}>
                        <Flex align='center'>
                          <Text
                            color={textTableColor}
                            fontWeight='bold'
                            fontSize='sm'
                            me='12px'>{`${el.percentage}%`}</Text>
                          <Progress
                            size='xs'
                            colorScheme={el.color}
                            value={el.percentage}
                            minW='120px'
                          />
                        </Flex>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Box>
        </Card>
      </Grid> */}
        </Flex>

        {/* <Card>
    <Table variant="simple" color={textColor}>
            <Thead>
              <Tr my=".8rem" pl="0px" color="gray.400">
                <Th
                  pl="0px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("name")}
                  cursor="pointer"
                  style={{ fontSize: "16px" }}
                >
                  Visitor
                  {sortColumn === "name" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  pl="20px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("name")}
                  cursor="pointer"
                  style={{ fontSize: "16px", color: "Black" }}
                >
                  {visitorHead}
                  {sortColumn === "name" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>

              </Tr>
            </Thead>
            </Table>

    <Table variant="simple" color={textColor}>
            <Thead>
              <Tr my=".8rem" pl="0px" color="gray.400">
                <Th
                  pl="0px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("name")}
                  cursor="pointer"
                  style={{ fontSize: "16px" }}
                >
                  Material
                  {sortColumn === "name" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  pl="33px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("name")}
                  cursor="pointer"
                  style={{ fontSize: "16px", color: "Black" }}
                >
                  {materialHead}
                  {sortColumn === "name" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>

              </Tr>
            </Thead>
            </Table>

    <Table variant="simple" color={textColor}>
            <Thead>
              <Tr my=".8rem" pl="0px" color="gray.400">
                <Th
                  pl="0px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("name")}
                  cursor="pointer"
                  style={{ fontSize: "16px" }}
                >
                  Work Force
                  {sortColumn === "name" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  pl="55px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("name")}
                  cursor="pointer"
                  style={{ fontSize: "16px", color: "Black" }}
                >
                  {workForceHead}
                  {sortColumn === "name" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>

              </Tr>
            </Thead>
            </Table>

    <Table variant="simple" color={textColor}>
            <Thead>
              <Tr my=".8rem" pl="0px" color="gray.400">
                <Th
                  pl="0px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("name")}
                  cursor="pointer"
                  style={{ fontSize: "16px" }}
                >
                  Canteen
                  {sortColumn === "name" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  pl="28px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("name")}
                  cursor="pointer"
                  style={{ fontSize: "16px" , color: "Black" }}
                >
                  {canteenHead}
                  {sortColumn === "name" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>

              </Tr>
            </Thead>
            </Table>

    <Table variant="simple" color={textColor}>
            <Thead>
              <Tr my=".8rem" pl="0px" color="gray.400">
                <Th
                  pl="0px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("name")}
                  cursor="pointer"
                  style={{ fontSize: "16px", padding: "10px" }}
                >
                  Delivery
                  {sortColumn === "name" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  pl="26px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("name")}
                  cursor="pointer"
                  style={{ fontSize: "16px", color: "Black" }}
                >
                  {deliveryHead}
                  {sortColumn === "name" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>

              </Tr>
            </Thead>
            </Table>
            <br/>

    <Table variant="simple" color={textColor}>
            <Thead>
              <Tr my=".8rem" pl="0px" color="gray.400">
                <Link href = "#">
                <Th
                  pl="0px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("name")}
                  cursor="pointer"
                  style={{ fontSize: "16px" }}
                >
                  Total
                  {sortColumn === "name" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                </Link>
                
                <Th
                  pl="10px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("name")}
                  cursor="pointer"
                  style={{ fontSize: "16px", color: "Black" }}
                >
                  {totalHead}
                  {sortColumn === "name" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>

              </Tr>
            </Thead>
            </Table>

            </Card> */}

        <Flex direction="column" >
          <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
            <Flex>
              <CardHeader p="6px 0px 22px 0px">
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                  Head Count
                </Text>
              </CardHeader>
              <Spacer />
              <CardHeader p="6px 0px 22px 0px">
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                </Text>
              </CardHeader>
            </Flex>
            <CardBody pb={4}>
              <TableContainer>
                <Table size='sm'>
                  <Thead>
                    <Tr my=".8rem" pl="0px" color="gray.400">
                      <Th pl="0px"
                        borderColor={borderColor}
                        color="gray.400">Module</Th>
                      <Th isNumeric pl="0px"
                        borderColor={borderColor}
                        color="gray.400">Count</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td><Link href="#/admin/visitor/head-count">Visitor</Link></Td>
                      <Td isNumeric>{visitorHead}</Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <Link href="#/admin/material/head-count"
                        >
                          Material
                        </Link>
                      </Td>
                      <Td isNumeric>{materialHead}</Td>
                    </Tr>
                    <Tr>
                      <Td><Link href='#/admin/workforce/head-count'>Work Force</Link></Td>
                      <Td isNumeric>{workForceHead}</Td>
                    </Tr>
                    <Tr>
                      <Td><Link href="#/admin/canteen/head-count">Canteen</Link> </Td>
                      <Td isNumeric>{canteenHead}</Td>
                    </Tr>
                    <Tr>
                      <Td><Link href="#/admin/delivery/head-count">Delivery</Link></Td>
                      <Td isNumeric>{deliveryHead}</Td>
                    </Tr>
                  </Tbody>
                  <Tfoot>
                    <Tr>
                      <Th>Total</Th>
                      <Th isNumeric>{totalHead}</Th>
                    </Tr>
                  </Tfoot>
                </Table>
              </TableContainer>
            </CardBody>
          </Card>
        </Flex>
      </>



    );
  }
  else {
    return (
      <Flex flexDirection='column' pt={{ base: "120px", md: "75px" }}>
        <SimpleGrid columns={{ sm: 1, md: 2, xl: 4 }} spacing='24px' mb='20px'>

        </SimpleGrid>
        {/* <Grid
        templateColumns={{ sm: "1fr", lg: "2fr 1fr" }}
        templateRows={{ lg: "repeat(2, auto)" }}
        gap='20px'>
        <Card
          bg={
            colorMode === "dark"
              ? "navy.800"
              : "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)"
          }
          p='0px'
          maxW={{ sm: "320px", md: "100%" }}>
          <Flex direction='column' mb='40px' p='28px 0px 0px 22px'>
            <Text color='#fff' fontSize='lg' fontWeight='bold' mb='6px'>
              Sales Overview
            </Text>
            <Text color='#fff' fontSize='sm'>
              <Text as='span' color='green.400' fontWeight='bold'>
                (+5) more{" "}
              </Text>
              in 2022
            </Text>
          </Flex>
          <Box minH='300px'>
            <LineChart
              chartData={lineChartData}
              chartOptions={lineChartOptions}
            />
          </Box>
        </Card>
        <Card p='0px' maxW={{ sm: "320px", md: "100%" }}>
          <Flex direction='column' mb='40px' p='28px 0px 0px 22px'>
            <Text color='gray.400' fontSize='sm' fontWeight='bold' mb='6px'>
              PERFORMANCE
            </Text>
            <Text color={textColor} fontSize='lg' fontWeight='bold'>
              Total orders
            </Text>
          </Flex>
          <Box minH='300px'>
            <BarChart chartData={barChartData} chartOptions={barChartOptions} />
          </Box>
        </Card>
        <Card p='0px' maxW={{ sm: "320px", md: "100%" }}>
          <Flex direction='column'>
            <Flex align='center' justify='space-between' p='22px'>
              <Text fontSize='lg' color={textColor} fontWeight='bold'>
                Page visits
              </Text>
              <Button variant='primary' maxH='30px'>
                SEE ALL
              </Button>
            </Flex>
            <Box overflow={{ sm: "scroll", lg: "hidden" }}>
              <Table>
                <Thead>
                  <Tr bg={tableRowColor}>
                    <Th color='gray.400' borderColor={borderColor}>
                      Page name
                    </Th>
                    <Th color='gray.400' borderColor={borderColor}>
                      Visitors
                    </Th>
                    <Th color='gray.400' borderColor={borderColor}>
                      Unique users
                    </Th>
                    <Th color='gray.400' borderColor={borderColor}>
                      Bounce rate
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {pageVisits.map((el, index, arr) => {
                    return (
                      <Tr key={index}>
                        <Td
                          color={textTableColor}
                          fontSize='sm'
                          fontWeight='bold'
                          borderColor={borderColor}
                          border={index === arr.length - 1 ? "none" : null}>
                          {el.pageName}
                        </Td>
                        <Td
                          color={textTableColor}
                          fontSize='sm'
                          border={index === arr.length - 1 ? "none" : null}
                          borderColor={borderColor}>
                          {el.visitors}
                        </Td>
                        <Td
                          color={textTableColor}
                          fontSize='sm'
                          border={index === arr.length - 1 ? "none" : null}
                          borderColor={borderColor}>
                          {el.uniqueUsers}
                        </Td>
                        <Td
                          color={textTableColor}
                          fontSize='sm'
                          border={index === arr.length - 1 ? "none" : null}
                          borderColor={borderColor}>
                          {el.bounceRate}
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </Box>
          </Flex>
        </Card>
        <Card p='0px' maxW={{ sm: "320px", md: "100%" }}>
          <Flex direction='column'>
            <Flex align='center' justify='space-between' p='22px'>
              <Text fontSize='lg' color={textColor} fontWeight='bold'>
                Social traffic
              </Text>
              <Button variant='primary' maxH='30px'>
                SEE ALL
              </Button>
            </Flex>
          </Flex>
          <Box overflow={{ sm: "scroll", lg: "hidden" }}>
            <Table>
              <Thead>
                <Tr bg={tableRowColor}>
                  <Th color='gray.400' borderColor={borderColor}>
                    Referral
                  </Th>
                  <Th color='gray.400' borderColor={borderColor}>
                    Visitors
                  </Th>
                  <Th color='gray.400' borderColor={borderColor}></Th>
                </Tr>
              </Thead>
              <Tbody>
                {socialTraffic.map((el, index, arr) => {
                  return (
                    <Tr key={index}>
                      <Td
                        color={textTableColor}
                        fontSize='sm'
                        fontWeight='bold'
                        borderColor={borderColor}
                        border={index === arr.length - 1 ? "none" : null}>
                        {el.referral}
                      </Td>
                      <Td
                        color={textTableColor}
                        fontSize='sm'
                        borderColor={borderColor}
                        border={index === arr.length - 1 ? "none" : null}>
                        {el.visitors}
                      </Td>
                      <Td
                        color={textTableColor}
                        fontSize='sm'
                        borderColor={borderColor}
                        border={index === arr.length - 1 ? "none" : null}>
                        <Flex align='center'>
                          <Text
                            color={textTableColor}
                            fontWeight='bold'
                            fontSize='sm'
                            me='12px'>{`${el.percentage}%`}</Text>
                          <Progress
                            size='xs'
                            colorScheme={el.color}
                            value={el.percentage}
                            minW='120px'
                          />
                        </Flex>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Box>
        </Card>
      </Grid> */}
      </Flex>
    );
  }

}
