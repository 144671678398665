import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Image,
  Text,
  Wrap,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { useDispatch, useSelector } from "react-redux";

import { useToast } from '@chakra-ui/react';
import { useParams } from "react-router-dom";
import { GUARD_CLEAR_SNACKBAR } from "constants/types";
import {
  Table,
  Tbody,
  Td,
  Tr,
  TableContainer,
} from '@chakra-ui/react';
import { Skeleton, SkeletonText } from '@chakra-ui/react';
import { guardWorkEmpAddOrUpdate } from "../../actions/howItWorkAction";
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { server_url } from '../../constants/types';


function HowItWorksView() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [orderName, setOrderName] = useState("")
  const [catDesc, setCatDesc] = useState("")
  const [type, setType] = useState("")
  const [createdAt, setCreatedAt] = useState("")
  const [updatedAt, setUpdatedAt] = useState("")
  const [video, setVideo] = useState("")
  const [thumbNail, setThumbNail] = useState("")


  const [line1, setLine1] = useState("");
  const [line2, setLine2] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [locality, setLocality] = useState("");
  const [state, setState] = useState("");

  const dispatch = useDispatch();
  const toast = useToast();
  const history = useHistory()

  const responseStatus = useSelector(state => state.HowItWork.status);
  const responseMessage = useSelector(state => state.HowItWork.text);
  const loading = useSelector(state => state.HowItWork.loading);
  const guardWorkEmpDetail = useSelector(state => state.HowItWork.guardWorkEmpDetail);
  const showSnackbar = useSelector(state => state.HowItWork.showSnackbar);

  console.log(guardWorkEmpDetail)

  const params = useParams();
  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
        setName(guardWorkEmpDetail?.name || "");
        setDescription(guardWorkEmpDetail?.description || "");
        setOrderName(guardWorkEmpDetail?.howItWorkCategory?.name || "");
        setCatDesc(guardWorkEmpDetail?.howItWorkCategory?.description || "");
        setType(guardWorkEmpDetail?.howItWorkCategory?.type || "");
        setCreatedAt(guardWorkEmpDetail?.createdAt || "");
        setUpdatedAt(guardWorkEmpDetail?.updatedAt || "");
        setVideo(guardWorkEmpDetail?.video || "");
        setThumbNail(guardWorkEmpDetail?.thumbnail || "");

        // setCheckpost(guardWorkEmpDetail.checkpost.name);
        // guardWorkEmpDetail.Address.map((item) => {
        //   setLine1(item.line1);
        //   setLine2(item.line2);
        //   setPostalCode(item.postalCode);
        //   setLocality(item.locality);
        //   setState(item.state)
        // });

        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }

    dispatch({
      type: GUARD_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    });
  }, [loading]);

  useEffect(() => {
    const guardWorkEmpId = params.id;
    const guardWorkEmp = {
      _id: guardWorkEmpId
    };
    dispatch(guardWorkEmpAddOrUpdate(guardWorkEmp, 'view'));
  }, []);

  console.log("guardWorkEmpDetail")
  console.log(guardWorkEmpDetail)

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            How-It-Work View 
          </Text>
        </CardHeader>
        <CardBody>
          {loading ? <SkeletonText noOfLines={3} spacing="4" /> :
            <TableContainer>
              <Table variant='simple'>
                <Tbody>
                  <Tr>
                    <Td>Name</Td>
                    <Td>{name}</Td>
                  </Tr>

                  <Tr>
                    <Td>Description</Td>
                    <Td>{description}</Td>
                  </Tr>
                  <Tr>
                    <Td>Category Name</Td>
                    <Td>{orderName}</Td>
                  </Tr>
                  <Tr>
                    <Td>Category Description</Td>
                    <Td>{catDesc}</Td>
                  </Tr>
                  <Tr>
                    <Td>Type</Td>
                    <Td>{type}</Td>
                  </Tr>
                  {/* <Tr>
                    <Td>Created at</Td>
                    <Td>{createdAt}</Td>
                  </Tr>
                  <Tr>
                    <Td>Updated At</Td>
                    <Td>{updatedAt}</Td>
                  </Tr> */}

                  <Flex direction='row' gap='15px' >
                    <Wrap spacing={4} justify="center" align="center" >
                      <Box >
                        <Card mb={4}>
                          
                          <Text fontSize="xl" color={textColor} fontWeight="bold">Video
                          <br/>
                          </Text>
                          <Flex direction='row' gap='15px'>
                          <Tr>
                            <Td>
                              <video width="800" height="800" controls>
                                <source src={video} type="video/mp4" />
                                Your browser does not support the video tag.
                              </video>
                            </Td>
                          </Tr>

                          <Tr>
                            <Td>
                              <Image src={thumbNail}></Image>
                            </Td>
                          </Tr>
                          </Flex>

                        </Card>
                      </Box>
                    </Wrap>
                  </Flex>



                </Tbody>
              </Table>
            </TableContainer>
          }
        </CardBody>
      </Card>
    </Flex>
  );
}

export default HowItWorksView;
