// Import the action type constants for longStayAlert
import {
  LONG_ALERT_CLEAR_SNACKBAR,
  LONG_ALERT_LOADING,
  LONG_ALERT_ERROR,
  LONG_ALERT_GET_ALL_SUCCESS,
  LONG_ALERT_SEARCH // Add the new action type
} from '../constants/types';

const initialState = {
  response: {},
  longStayAlertList: [],
  longStayAlertDetail: {},
  logs: [],
  material: [],
  longStayAlert: [],
  loading: false,
  status: 'success',
  text: '',
  showSnackbar: false,
  limit: 0, // Add missing properties
  page: 0,
  totalPages: 0,
  searching: "" // Add missing properties
};

const LongStayAlertReducer = (state = initialState, action) => {
  switch (action.type) {
    case LONG_ALERT_CLEAR_SNACKBAR:
      return {
        ...state,
        showSnackbar: false,
      };
    case LONG_ALERT_LOADING:
      return {
        ...state,
        loading: true,
      };
    case LONG_ALERT_ERROR:
      return {
        ...state,
        longStayAlertList: [],
        response: {},
        longStayAlertDetail: {},
        logs: [],
        material: [],
        longStayAlert: [],
        limit: 0, // Add missing properties
        page: 0,
        totalPages: 0,
        loading: false,
        status: action.payload.status,
        text: action.payload.text,
        showSnackbar: true,
      };
    case LONG_ALERT_GET_ALL_SUCCESS:
      return {
        ...state,
        longStayAlertList: action.payload.longStayAlertList,
        response: action.payload.response,
        longStayAlertDetail: action.payload.longStayAlertDetail,
        logs: action.payload.logs,
        material: action.payload.material,
        longStayAlert: action.payload.longStayAlert,
        limit: action.payload.limit,
        page: action.payload.page,
        totalPages: action.payload.totalPages,
        loading: false,
        status: action.payload.status,
        text: action.payload.text,
        showSnackbar: true,
      };
    case LONG_ALERT_SEARCH: // Add the new case
      return {
        ...state,
        searching: action.payload.searching,
      };
    default:
      return state || {};
  }
};

export default LongStayAlertReducer;
