import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  useToast,
  Box,
  Center,
  Spinner,
  useColorModeValue,
  Text,
  
  Flex,
} from '@chakra-ui/react';
import { FormErrorMessage } from '@chakra-ui/react';
import { AUTH_CLEAR_SNACKBAR } from "constants/types";
import CardHeader from "components/Card/CardHeader.js";
import Card from "components/Card/Card.js";
import { changePassword, loginPermissionDetail } from '../../actions/authActions';

function ChangePasswordForm() {
    const textColor = useColorModeValue("gray.700", "white");
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [retypeNewPassword, setRetypeNewPassword] = useState('');
  const [isErrorOldPassword, setIsErrorOldPassword] = useState(false);
  const [isErrorNewPassword, setIsErrorNewPassword] = useState(false);
  const [isErrorRetypeNewPassword, setIsErrorRetypeNewPassword] = useState(false);


  const dispatch = useDispatch();
  const authDetail = useSelector((state) => state.auth.authDetail);
  const loading = useSelector((state) => state.auth.loading);
  const responseStatus = useSelector((state) => state.auth.status);
  const responseMessage = useSelector((state) => state.auth.text);
  const showSnackbar = useSelector((state) => state.auth.showSnackbar);
  const toast = useToast();
 

 useEffect(() => {
    dispatch(loginPermissionDetail());
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsErrorOldPassword(oldPassword === "");
    setIsErrorNewPassword(newPassword === "");
    setIsErrorRetypeNewPassword(retypeNewPassword === "");
    
    if (
        oldPassword !== "" &&
        newPassword !== "" &&
        retypeNewPassword !== ""
      ){
    const changepass={
        oldPassword : oldPassword, 
        newPassword : newPassword,
        retypeNewPassword : retypeNewPassword,
    }
    dispatch(changePassword(changepass));
  }};




  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
       
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    
    }
    dispatch({
      type: AUTH_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);


  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  };


  
  return (
    <>
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
    <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
      
      <CardHeader p="6px 0px 22px 0px">
        <Center>
        <Text fontSize="xl" color={textColor} fontWeight="bold">
          Update Password
        </Text>
        </Center>
    <Box mt={4}>
      <form onSubmit={handleSubmit}>
      <FormControl isInvalid={isErrorOldPassword}>
          <FormLabel>Old Password</FormLabel>
          <Input
            type="password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
          />
            {!isErrorOldPassword ? (
                <></>
              ) : (
                <FormErrorMessage>OldPassword is required.</FormErrorMessage>
              )}
            </FormControl>
       
        <FormControl isInvalid={isErrorNewPassword}>
          <FormLabel>New Password</FormLabel>
          <Input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
           {!isErrorNewPassword ? (
                <></>
              ) : (
                <FormErrorMessage>New Password is required.</FormErrorMessage>
              )}
            </FormControl>
        
        <FormControl isInvalid={isErrorRetypeNewPassword}>
          <FormLabel>Retype New Password</FormLabel>
          <Input
            type="password"
            value={retypeNewPassword}
            onChange={(e) => setRetypeNewPassword(e.target.value)}
          />
          {!isErrorRetypeNewPassword ? (
                <></>
              ) : (
                <FormErrorMessage>retypeNewPassword is required.</FormErrorMessage>
              )}
            </FormControl>
        
        <Center mt={6}>
          <Button type="submit" isLoading={loading} colorScheme="blue">
            {loading ? <Spinner size="sm" color="white" /> : 'Change Password'}
          </Button>
        </Center>
      </form>
    </Box>
    </CardHeader>
       
      </Card>
    </Flex>
    </>
  );
}

export default ChangePasswordForm;
