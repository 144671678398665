import axios from 'axios';
import {
  IAMSAFE_LOADING,
  IAMSAFE_GET_ALL_SUCCESS,
  IAMSAFE_ERROR,
  IAMSAFE_CLEAR_SNACKBAR,
} from '../constants/types';

import { server_url } from '../constants/types';

export const iamSafeGetAll = (pagination = '') => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: IAMSAFE_LOADING,
        payload: {
          loading: true
        }
      });
      axios
        .get(`${server_url}/iam-safe-list?${pagination}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
        .then((response) => {
          dispatch({
            type: IAMSAFE_GET_ALL_SUCCESS,
            payload: {
              iamSafeList: response.data.data,
              limit: response.data.limit,
              page: response.data.currentPage,
              totalPages: response.data.totalPages,
              response: response.data,
              loading: false,
              status: 'success',
              text: 'Get all iamSafes successfully.'
            }
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: IAMSAFE_ERROR,
            payload: {
              status: 'error',
              text: 'Error occurred during getting iamSafe data.',
              loading: false
            }
          });
          resolve();
        });
    });
  };
};

export const iamSafeToday = (pagination='') => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: IAMSAFE_LOADING,
        payload: {
          loading: true
        }
      });
      axios
        .get(`${server_url}/admin/todays-iam-safe?${pagination}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
        .then((response) => {
          dispatch({
            type: IAMSAFE_GET_ALL_SUCCESS,
            payload: {
              iamSafeTodayList: response.data.data,
              limit: response.data.limit,
              page: response.data.currentPage,
              totalPages: response.data.totalPages,
              response: response.data,
              loading: false,
              status: 'success',
              text: 'Get all iamSafes successfully.'
            }
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: IAMSAFE_ERROR,
            payload: {
              status: 'error',
              text: 'Error occurred during getting iamSafe data.',
              loading: false
            }
          });
          resolve();
        });
    });
  };
};

export const iamSafeAddOrUpdate = (iamSafe, state ,limit="",page="") => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: IAMSAFE_LOADING,
        payload: {
          loading: true
        }
      });
      if (state === 'add') {
        axios
          .post(`${server_url}/iamsafe`, iamSafe, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
          })
          .then((responseF) => {
            axios
              .get(`${server_url}/iamsafe?limit=${limit}&page=${page}`, {
                headers: {
                  'Authorization': 'Bearer ' + localStorage.getItem('authToken')
                }
              })
              .then((response) => {
                console.log("response.data.data of iamSafe add")
                console.log(response.data.data)
                dispatch({
                  type: IAMSAFE_GET_ALL_SUCCESS,
                  payload: {
                    iamSafeList: response.data.data,
                    limit: response.data.limit,
                    page: response.data.currentPage,
                    totalPages: response.data.totalPages,
                    response: response.data,
                    loading: false,
                    status: responseF.data['status'],
                    text: responseF.data['message']
                  }
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: IAMSAFE_ERROR,
                  payload: {
                    text: 'Error occurred during getting iamSafe data.',
                    status: 'error',
                    loading: false
                  }
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: IAMSAFE_ERROR,
              payload: {
                text: 'Error occurred during adding iamSafe data.',
                status: 'error',
                loading: false
              }
            });
            resolve();
          });
      } else if (state === 'edit') {
        axios
          .put(`${server_url}/iamsafe/${iamSafe._id}`, iamSafe, {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
          })
          .then((responseF) => {
            axios
              .get(`${server_url}/iamsafe?limit=${limit}&page=${page}`, {
                headers: {
                  'Authorization': 'Bearer ' + localStorage.getItem('authToken')
                }
              })
              .then((response) => {
                dispatch({
                  type: IAMSAFE_GET_ALL_SUCCESS,
                  payload: {
                    iamSafeList: response.data.data,
                    limit: response.data.limit,
                    page: response.data.currentPage,
                    totalPages: response.data.totalPages,
                    response: response.data,
                    loading: false,
                    status: responseF.data['status'],
                    iamSafeDetail: responseF.data['data'],
                    text: responseF.data['message']
                  }
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: IAMSAFE_ERROR,
                  payload: {
                    text: 'Error occurred during getting iamSafe data.',
                    status: 'error',
                    loading: false
                  }
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: IAMSAFE_ERROR,
              payload: {
                text: 'Error occurred during updating iamSafe data.',
                status: 'error',
                loading: false
              }
            });
            resolve();
          });
      } else if (state === 'view') {
        axios
          .get(`${server_url}/iam-safe-view/${iamSafe._id}`, {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
          })
          .then((responseF) => {
            axios
              .get(`${server_url}/iam-safe-list?limit=${limit}&page=${page}`, {
                headers: {
                  'Authorization': 'Bearer ' + localStorage.getItem('authToken')
                }
              })
              .then((response) => {
                console.log(" this is iamSafe details")
                console.log(responseF.data['data'])
                dispatch({
                  type: IAMSAFE_GET_ALL_SUCCESS,
                  payload: {
                    iamSafeList: response.data.data,
                    limit: response.data.limit,
                    page: response.data.currentPage,
                    totalPages: response.data.totalPages,
                    response: response.data,
                    iamSafeDetail: responseF.data['data'],
                    loading: false,
                    status: responseF.data['status'],
                    text: responseF.data['message']
                  }
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: IAMSAFE_ERROR,
                  payload: {
                    text: 'Error occurred during getting iamSafe data.',
                    status: 'error',
                    loading: false
                  }
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: IAMSAFE_ERROR,
              payload: {
                text: 'Error occurred during updating iamSafe data.',
                status: 'error',
                loading: false
              }
            });
            resolve();
          });
      }
    });
  };
};

export const iamSafeDelete = (iamSafe , limit="",page="") => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: IAMSAFE_LOADING,
        payload: {
          loading: true
        }
      });
      axios
        .delete(`${server_url}/iamSafe/${iamSafe}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
        .then((responseF) => {
          axios
            .get(`${server_url}/iamSafe?limit=${limit}&page=${page}`, {
              headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
              }
            })
            .then((response) => {
              dispatch({
                type: IAMSAFE_GET_ALL_SUCCESS,
                payload: {
                  iamSafeList: response.data.data,
                  limit: response.data.limit,
                  page: response.data.currentPage,
                  totalPages: response.data.totalPages,
                  response: response.data,
                  iamSafeDetail: responseF.data['data'],
                  loading: false,
                  status: responseF.data['status'],
                  text: responseF.data['message']
                }
              });
              resolve();
            })
            .catch((e) => {
              dispatch({
                type: IAMSAFE_ERROR,
                payload: {
                  text: 'Error occurred during getting iamSafe data.',
                  status: 'error',
                  loading: false
                }
              });
              resolve();
            });
        })
        .catch((e) => {
          dispatch({
            type: IAMSAFE_ERROR,
            payload: {
              text: 'Error occurred during deleting iamSafe data.',
              status: 'error',
              loading: false
            }
          });
          resolve();
        });
    });
  };
};

export const iamSafeClearShowSnackbar = () => {
  return (dispatch, getState) => {
    dispatch({
      type: IAMSAFE_CLEAR_SNACKBAR
    });
  };
};
