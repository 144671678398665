import { 
    GROUP_LOADING, 
    GROUP_GET_ALL_SUCCESS, 
    GROUP_ERROR,
    GROUP_CLEAR_SNACKBAR
} from '../constants/types';

const initialState = {
    response : {},
    groupList: [],
    groupDetail: {},
    loading: false,
    status: "success",
    text: "",
    showSnackbar: false
};

const groupReducer = (state = initialState, action) => {
    switch (action.type) {
        case GROUP_CLEAR_SNACKBAR: 
            return {
                ...state,
                showSnackbar: false
            }
        case GROUP_LOADING: 
            return {
                ...state,
                loading: true,
            }
        case GROUP_ERROR:
            return {
                ...state,
                groupList: [],
                response : {},
                groupDetail : {},
                loading: false,
                status: action.payload.status,
                text: action.payload.text,
                showSnackbar: true
            }
        case GROUP_GET_ALL_SUCCESS:
            return {
                ...state,
                groupList: action.payload.groupList,
                response: action.payload.response,
                groupDetail: action.payload.groupDetail,
                loading: false,
                status: action.payload.status,
                text: action.payload.text,
                showSnackbar: true
            }
        default: 
            return state || {};
    }
}

export default groupReducer;
