import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { useDispatch, useSelector } from "react-redux";
import { useToast } from '@chakra-ui/react';
import { useParams } from "react-router-dom";
import { PATROLLING_REQUEST_CLEAR_SNACKBAR } from "constants/types";
import { Table, Tbody, Td, Tr, TableContainer } from '@chakra-ui/react';
import { Skeleton, SkeletonText } from '@chakra-ui/react';
import { patrollingRequestAddOrUpdate } from "actions/patrollingRequestAction";
import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom';
function PatrollingRequestView() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [patrollingRouteName, setPatrollingRouteName] = useState([]);
  const [floor, SetFloor] = useState([]);
  const [guard, setGuard] = useState('');
  const [guardNumber, setGuardNumber] = useState('');

  const dispatch = useDispatch();
  const toast = useToast();
  const history = useHistory();
  const responseStatus = useSelector(state => state.patrollingRequest.status);
  const responseMessage = useSelector(state => state.patrollingRequest.text);
  const loading = useSelector(state => state.patrollingRequest.loading);
  const showSnackbar = useSelector(state => state.patrollingRequest.showSnackbar);
  const patrollingRequestDetail = useSelector(state => state.patrollingRequest.patrollingRequestDetail);
  const detail = useSelector(state => state.patrollingRequest);
  console.log("detail,<><<><");
  console.log(detail);
  console.log("1234");
console.log(patrollingRequestDetail)
  const params = useParams();

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && loading === false) {
        if (patrollingRequestDetail) {
          setPatrollingRouteName(patrollingRequestDetail.patrollingRoute);
          SetFloor(patrollingRequestDetail.floor);
          setGuard(patrollingRequestDetail.guard.name);
          setGuardNumber(patrollingRequestDetail.guard.number);
  
          if (responseMessage !== "") {
            toastFunction(responseMessage, 'success');
          }
        }
      } else if (responseStatus === 'NOK' && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }
  
    dispatch({
      type: PATROLLING_REQUEST_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    });
  }, [loading]);

  useEffect(() => {
    const patrollingRequestId = params.id;
    console.log(patrollingRequestId);
    const patrollingRequest = {
      _id: patrollingRequestId
    };
    dispatch(patrollingRequestAddOrUpdate(patrollingRequest, 'view'));
  }, [params.id]); // Include params.id as a dependency

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };
  
  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, [])
  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Patrolling Request View
          </Text>
        </CardHeader>
        <CardBody>
          {loading ? <SkeletonText noOfLines={3} spacing="4" /> :
            <TableContainer>
              <Table variant='simple'>
                <Tbody>
                <Tr>
                    <Td><strong>Patrolling Route</strong></Td>
                  </Tr>              
                  <Tr>
                    <Td>{patrollingRouteName.map((ele)=>(
                        <li>Name : {ele.name}</li>
                    ))}</Td>
                    <Td>{patrollingRouteName.map((ele)=>(
                        <li>Description : {ele.description}</li>
                    ))}</Td>
                  </Tr>
                  <Tr>
                    <Td><strong>Floor</strong></Td>
                  </Tr>
                  <Tr>
                    <Td>{floor.map((ele)=>(
                        <li>Name : {ele.name}</li>
                    ))}</Td>
                    <Td>{floor.map((ele)=>(
                        <li>Description : {ele.description}</li>
                    ))}</Td>
                    <Td>{floor.map((ele)=>(
                        <li>Time : {ele.time}</li>
                    ))}</Td>
                  </Tr>
                
                  <Tr>
                    <Td><strong>Guard</strong></Td>
                  </Tr>
                  <Tr>
                    <Td>Name : {guard}</Td>
                    <Td> Number : {guardNumber}</Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          }
        </CardBody>
      </Card>
    </Flex>
  );
}

export default PatrollingRequestView;
