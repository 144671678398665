import React, { useEffect, useState ,useRef } from 'react';
import { Flex, InputGroup, Spacer, Table, Tbody, Text, Td, Th, Thead, Tr, useColorModeValue, InputLeftElement, IconButton, Input, Icon, Link , Button  } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';

import { useToast } from '@chakra-ui/react';

import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';

import { AddIcon } from '@chakra-ui/icons';
import { CANTEENINOUT_CLEAR_SNACKBAR  ,CANTEENINOUT_SEARCH } from 'constants/types';

import CanteenInOutTableRow from './CanteenInOutTableRow';
import { CanteenInOutGetAll } from 'actions/canteenInOutAction';
import CanteenInOutPagination from './CanteenInOutPagination';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import CanteenInOutAdvanceSearch from './CanteenInOutAdvanceSearch';
const CanteenInOutTable = () => {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const responseStatus = useSelector(state => state.canteenInOut.status);
  const responseMessage = useSelector(state => state.canteenInOut.text);
  const loading = useSelector(state => state.canteenInOut.loading);
  const CanteenInOutList = useSelector(state => state.canteenInOut.canteenInOutList);
  const response = useSelector(state => state.canteenInOut.response);
  const showSnackbar = useSelector(state => state.canteenInOut.showSnackbar);
  const currentPage = useSelector(state => state.canteenInOut.page);
  const limit = useSelector(state => state.visitorInOut.limit);
  const dispatch = useDispatch();
  const toast = useToast();
  const searchIconColor = useColorModeValue("gray.700", "gray.200");
  const inputBg = useColorModeValue("#edf3f8", "navy.800");
  const [search, setSearch] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const tableRef = useRef(null);
  useEffect(() => {
    dispatch({
      type : CANTEENINOUT_SEARCH,
      payload : {
        searching : "",
      }
    })
    dispatch(CanteenInOutGetAll());
  }, [dispatch]);
  console.log("CanteenInOutList")
  console.log(CanteenInOutList)

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: CANTEENINOUT_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("desc");
    }
    const sorting = "&sortOrder=" + sortOrder + "&column=" + column;
    dispatch({
      type : CANTEENINOUT_SEARCH,
      payload : {
        searching : "",
      }
    })
    dispatch(CanteenInOutGetAll(sorting));
  };

  useEffect(() => {
    const searching = "&search=" + search;
    dispatch({
      type : CANTEENINOUT_SEARCH,
      payload : {
        searching : "",
      }
    })
    dispatch(CanteenInOutGetAll(searching));
  }, [search]);

  return (
    <>
    <CanteenInOutAdvanceSearch></CanteenInOutAdvanceSearch>
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <Flex>
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
            Canteens
            </Text>
          </CardHeader>
          <Spacer />


          <Flex justify="flex-end" style={{ padding: '10px', marginTop: '-5px', marginRight: '12px' }}>
            <DownloadTableExcel
              filename="Canteen Table"
              sheet="users"
              currentTableRef={tableRef.current}
            >
              <Button colorScheme="blue" _hover={{ backgroundColor: 'black', color: 'white' }}>
                Export Excel
              </Button>
            </DownloadTableExcel>
          </Flex>

          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              <InputGroup borderRadius="8px" w="200px">
                <InputLeftElement
                  children={
                    <IconButton
                      bg="inherit"
                      borderRadius="inherit"
                      _hover="none"
                      _active={{
                        bg: "inherit",
                        transform: "none",
                        borderColor: "transparent",
                      }}
                      _focus={{
                        boxShadow: "none",
                      }}
                      icon={
                        <SearchIcon
                          color={searchIconColor}
                          w="15px"
                          h="15px"
                        />
                      }
                    ></IconButton>
                  }
                />
                <Input
                  variant="search"
                  fontSize="xs"
                  bg={inputBg}
                  onKeyUp={(e) => {
                    setSearch(e.target.value);
                    if (e.target.value.length < 3) {
                      setSearch("");
                    }
                  }}
                  placeholder="Type here..."
                />
              </InputGroup>
            </Text>
          </CardHeader>
        </Flex>
        <CardBody pb={4}>
        <div style={{ overflowX: "auto" }}>
          <Table variant="simple" color={textColor}>
            <Thead>
              <Tr my=".8rem" pl="0px" color="gray.400">
              <Th
                    pl="15px"
                    borderColor={borderColor}
                    color="gray.400"
                    cursor="pointer"
                  >
                   SNO
                  </Th>
                <Th
                  pl="0px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("vechilenumber")}
                  cursor="pointer"
                >
                  type Of Items
                  {sortColumn === "vechilenumber" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("drivername")}
                  cursor="pointer"
                >
              delivery Agent Name
                  {sortColumn === "drivername" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("intime")}
                  cursor="pointer"
                >
                 driver Photo
                  {sortColumn === "intime" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("outtime")}
                  cursor="pointer"
                >
                   checkpost In Name
                  {sortColumn === "outtime" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("outtime")}
                  cursor="pointer"
                >
                   checkpost In Time
                  {sortColumn === "outtime" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("outtime")}
                  cursor="pointer"
                >
                   checkpost In Guard name
                  {sortColumn === "outtime" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("outtime")}
                  cursor="pointer"
                >
                   checkpost out Name
                  {sortColumn === "outtime" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("outtime")}
                  cursor="pointer"
                >
                   checkpost out time
                  {sortColumn === "outtime" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("outtime")}
                  cursor="pointer"
                >
                checkpost Out Guard  name
                  {sortColumn === "outtime" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {CanteenInOutList ? (CanteenInOutList.map((row, index, arr) => {
                
                return (
                  <CanteenInOutTableRow
                 serialnumber={((currentPage - 1)*limit) + (index+1)}
                    typeOfItems={row.canteenCategoryName}
                    deliveryAgentName={row.driverName}
                    driverPhoto={row.image}
                    checkpostInName={row.inCheckpostName ? row.inCheckpostName : "N/A"}
                    checkpostInTime={row.checkpostInTime === "Invalid date"  ?  "N/A" : row.checkpostInTime}
                    checkpostInGuardName={row.guard  ? row.guard.name : "N/A"}

                    checkpostOutName={row.outCheckpostName ? row.outCheckpostName : "N/A"}
                    checkpostOutTime={row.checkpostOutTime === "Invalid date"  ?  "N/A" : row.checkpostOutTime}
                    checkpostOutGuardName={row.outGuard  ? row.outGuard.name : "N/A"}
                    isLast={index === arr.length - 1 ? true : false}
                    id={row._id}
                  />
                );
              })) : (<h1></h1>)}
            </Tbody>
          </Table>
        </div>
          {CanteenInOutList.length === 0 ? (
            <></>
          ) : (
            <CanteenInOutPagination
              totalPages={response.totalPages}
              currentPage={response.currentPage}
              perPage={response.perPage}
              sortBy={sortColumn}
              sortOrder={sortOrder}
              handlePageClick={(data) => {
                const selectedPage = data.selected + 1;
                dispatch(CanteenInOutGetAll(`&page=${selectedPage}`));
              }}
            />
          )}
        </CardBody>
      </Card>
    </Flex></>
  );
};

export default CanteenInOutTable;
