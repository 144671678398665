// Chakra imports
import {
    Box,
    Button,
    Center,
    Flex,
    Input,
    SimpleGrid,
    Spacer,
    Stack,
    Text,
    Wrap,
    WrapItem,
    useColorModeValue
  } from "@chakra-ui/react";
  // Custom components
  import Card from "components/Card/Card.js";
  import CardBody from "components/Card/CardBody.js";
  import CardHeader from "components/Card/CardHeader.js";
  import React, { useEffect, useState } from "react";
  import { tablesProjectData, tablesTableData } from "variables/general";
  import { useDispatch, useSelector } from 'react-redux';
  import { userGetAll, userDelete, userAddOrUpdate, userClearShowSnackbar } from '../../actions/userActions';
  import { useToast } from '@chakra-ui/react'
  import { useParams } from "react-router-dom/cjs/react-router-dom.min";
  import { USER_CLEAR_SNACKBAR } from "constants/types";
  import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
  } from '@chakra-ui/react'
  import { Skeleton, SkeletonCircle, SkeletonText } from '@chakra-ui/react'
  
  function ViewForm() {
    const textColor = useColorModeValue("gray.700", "white");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    const [name,setName]= useState("");
    const [email,setEmail]= useState("");
    const [password,setPassword]= useState("");
    const [contactNo,setContactNo]= useState("");
    const [alertMessage, setAlertMessage] = useState('iii');
    const [isErrorName, setIsErrorName] = useState(false);
    const [isErrorEmail, setIsErrorEmail] = useState(false);
    const [isErrorContactNo, setIsErrorContactNo] = useState(false);
    const [isErrorPassword, setIsErrorPassword] = useState(false);
    
    const dispatch = useDispatch();
    const toast = useToast();
  
    const statuses = ['success', 'error', 'warning', 'info'];
    const responseStatus = useSelector(state => state.user.status);
    const responseMessage = useSelector(state => state.user.text);
    const loading = useSelector(state => state.user.loading);
    const userDetail = useSelector(state => state.user.userDetail);
    const showSnackbar = useSelector(state => state.user.showSnackbar);
  
    const params = useParams();
    useEffect(() => {
      if(showSnackbar)
      {
        if (responseStatus == 'OK' && loading == false) {
          console.log(userDetail);
            setName(userDetail.name);
            setEmail(userDetail.email);
            setContactNo(userDetail.contactNo);
            setPassword(userDetail.password);
          if(responseMessage !="")
          {
            toastFunction(responseMessage, 'success');
          }
          
        } else if (responseStatus == 'NOK' && loading == false) {
          if(responseMessage !="")
          {
            toastFunction(responseMessage, 'error');
          }
          
        }
      }
  
      dispatch({
        type: USER_CLEAR_SNACKBAR,
        payload: {
            showSnackbar: false,
        }
    })
    }, [loading]);
  
    useEffect(() => {
      console.log(params);
      const state = 'edit';
        // Call the userAddOrUpdate action
        const user = {
          '_id' : params.id
        };
        dispatch(userAddOrUpdate(user, state))
    }, []);
  
    const toastFunction = (title,status) =>
    {
      toast({
        title: title,
        status: status,
        duration: 3000,
        isClosable: true,
      })
    };
  
    return (
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              View Form
            </Text>
          </CardHeader>
          <CardBody>
          {loading ? <Stack>
            <Skeleton height='20px' />
            <Skeleton height='20px' />
            <Skeleton height='20px' />
          </Stack>
          :
          <TableContainer>
            <Table variant='simple'>
              <Tbody>
                <Tr>
                  <Td>Name</Td>
                  <Td>{name}</Td>
                </Tr>
                <Tr>
                  <Td>Email</Td>
                  <Td>{email}</Td>
                </Tr>
                <Tr>
                  <Td>Contact No.</Td>
                  <Td>{contactNo}</Td>
                </Tr>
                <Tr>
                  <Td>Password</Td>
                  <Td>{password}</Td>
                </Tr>
              </Tbody>
              
            </Table>
          </TableContainer>}
          </CardBody>
        </Card>
      </Flex>
    );
  }
  
  export default ViewForm;
  