import {
  DELIVARYINOUT_LOADING,
  DELIVARYINOUT_GET_ALL_SUCCESS,
  DELIVARYINOUT_ERROR,
  DELIVARYINOUT_CLEAR_SNACKBAR,
  DELIVARYINOUT_SEARCH  // Add the new action type
} from '../constants/types';

const initialState = {
  response: {},
  delivaryInOutList: [],
  delivaryTodayList: [],
  delivaryInOutDetail: {},
  delivaryIN: [],
  loading: false,
  status: 'success',
  text: '',
  showSnackbar: false,
  limit: 0,  // Add missing properties
  page: 0,
  totalPages: 0,
  searching: ''  // Add missing properties
};

const DelivaryInOutReducer = (state = initialState, action) => {
  switch (action.type) {
    case DELIVARYINOUT_CLEAR_SNACKBAR:
      return {
        ...state,
        showSnackbar: false
      };
    case DELIVARYINOUT_LOADING:
      return {
        ...state,
        loading: true
      };
    case DELIVARYINOUT_ERROR:
      return {
        ...state,
        delivaryInOutList: [],
        delivaryTodayList: [],
        response: {},
        delivaryInOutDetail: {},
        delivaryIN: [],
        limit: 0,  // Add missing properties
        page: 0,
        totalPages: 0,
        loading: false,
        status: action.payload.status,
        text: action.payload.text,
        showSnackbar: true
      };
    case DELIVARYINOUT_GET_ALL_SUCCESS:
      return {
        ...state,
        delivaryInOutList: action.payload.delivaryInOutList,
        delivaryTodayList: action.payload.delivaryTodayList,
        delivaryIN: action.payload.delivaryIN,
        response: action.payload.response,
        delivaryInOutDetail: action.payload.delivaryInOutDetail,
        limit: action.payload.limit,
        page: action.payload.page,
        totalPages: action.payload.totalPages,
        loading: false,
        status: action.payload.status,
        text: action.payload.text,
        showSnackbar: true
      };
    case DELIVARYINOUT_SEARCH: // Add the new case
      return {
        ...state,
        searching: action.payload.searching
      };
    default:
      return state;
  }
};

export default DelivaryInOutReducer;
