import axios from 'axios';
import {
  SOS_LOADING,
  SOS_GET_ALL_SUCCESS,
  SOS_ERROR,
  SOS_CLEAR_SNACKBAR
} from '../constants/types';

import { server_url } from '../constants/types';

export const sosGetAll = (pagination = '' , limit='' , page='') => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: SOS_LOADING,
        payload: {
          loading: true
        }
      });
      axios
        .get(`${server_url}/sos-list?${pagination}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
        .then((response) => {
          dispatch({
            type: SOS_GET_ALL_SUCCESS,
            payload: {
              sosList: response.data.data,
              limit: response.data.limit,
              page: response.data.currentPage,
              totalPages: response.data.totalPages,
              response: response.data,
              loading: false,
              status: 'success',
              text: 'Get all sos successfully.'
            }
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: SOS_ERROR,
            payload: {
              status: 'error',
              text: 'Error occurred during getting sos data.',
              loading: false
            }
          });
          resolve();
        });
    });
  };
};
export const sosTodayGetAll = (pagination) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: SOS_LOADING,
        payload: {
          loading: true
        }
      });
      axios
        .get(`${server_url}/admin/todays-sos?${pagination}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
        .then((response) => {
          dispatch({
            type: SOS_GET_ALL_SUCCESS,
            payload: {
              sosTodayList: response.data.data,
              limit: response.data.limit,
              page: response.data.currentPage,
              totalPages: response.data.totalPages,
              response: response.data,
              loading: false,
              status: 'success',
              text: 'Get all sos successfully.'
            }
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: SOS_ERROR,
            payload: {
              status: 'error',
              text: 'Error occurred during getting sos data.',
              loading: false
            }
          });
          resolve();
        });
    });
  };
};

export const sosAddOrUpdate = (sos, state , limit='' , page='') => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: SOS_LOADING,
        payload: {
          loading: true
        }
      });
      if (state === 'add') {
        axios
          .post(`${server_url}/sos`, sos, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
          })
          .then((responseF) => {
            axios
              .get(`${server_url}/sos-list?limit=${limit}&page=${page}`, {
                headers: {
                  'Authorization': 'Bearer ' + localStorage.getItem('authToken')
                }
              })
              .then((response) => {
                console.log("response.data.data of sos add")
                console.log(response.data.data)
                dispatch({
                  type: SOS_GET_ALL_SUCCESS,
                  payload: {
                    sosList: response.data.data,
                    limit: response.data.limit,
                    page: response.data.currentPage,
                    totalPages: response.data.totalPages,
                    response: response.data,
                    loading: false,
                    status: responseF.data['status'],
                    text: responseF.data['message']
                  }
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: SOS_ERROR,
                  payload: {
                    text: 'Error occurred during getting sos data.',
                    status: 'error',
                    loading: false
                  }
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: SOS_ERROR,
              payload: {
                text: 'Error occurred during adding sos data.',
                status: 'error',
                loading: false
              }
            });
            resolve();
          });
      } else if (state === 'edit') {
        axios
          .put(`${server_url}/sos/${sos._id}`, sos, {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
          })
          .then((responseF) => {
            axios
              .get(`${server_url}/sos-list?limit=${limit}&page=${page}`, {
                headers: {
                  'Authorization': 'Bearer ' + localStorage.getItem('authToken')
                }
              })
              .then((response) => {
                dispatch({
                  type: SOS_GET_ALL_SUCCESS,
                  payload: {
                    sosList: response.data.data,
                    limit: response.data.limit,
                    page: response.data.currentPage,
                    totalPages: response.data.totalPages,
                    response: response.data,
                    loading: false,
                    status: responseF.data['status'],
                    sosDetail: responseF.data['data'],
                    text: responseF.data['message']
                  }
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: SOS_ERROR,
                  payload: {
                    text: 'Error occurred during getting sos data.',
                    status: 'error',
                    loading: false
                  }
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: SOS_ERROR,
              payload: {
                text: 'Error occurred during updating sos data.',
                status: 'error',
                loading: false
              }
            });
            resolve();
          });
      } else if (state === 'view') {
        axios
          .get(`${server_url}/sos/${sos._id}`, {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
          })
          .then((responseF) => {
            axios
              .get(`${server_url}/sos/:id`, {
                headers: {
                  'Authorization': 'Bearer ' + localStorage.getItem('authToken')
                }
              })
              .then((response) => {
                console.log(" this is gurad deatil")
                console.log(responseF.data['data'])
                dispatch({
                  type: SOS_GET_ALL_SUCCESS,
                  payload: {
                    sosList: response.data.data,
                    limit: response.data.limit,
                    page: response.data.currentPage,
                    totalPages: response.data.totalPages,
                    response: response.data,
                    sosDetail: responseF.data['data'],
                    loading: false,
                    status: responseF.data['status'],
                    text: responseF.data['message']
                  }
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: SOS_ERROR,
                  payload: {
                    text: 'Error occurred during getting sos data.',
                    status: 'error',
                    loading: false
                  }
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: SOS_ERROR,
              payload: {
                text: 'Error occurred during updating sos data.',
                status: 'error',
                loading: false
              }
            });
            resolve();
          });
      }
    });
  };
};

export const sosDelete = (sos , limit='' ,page='') => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: SOS_LOADING,
        payload: {
          loading: true
        }
      });
      axios
        .delete(`${server_url}/sos/:id${sos}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        })
        .then((responseF) => {
          axios
            .get(`${server_url}/sos?limit=${limit}&page=${page}`, {
              headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
              }
            })
            .then((response) => {
              dispatch({
                type: SOS_GET_ALL_SUCCESS,
                payload: {
                  sosList: response.data.data,
                  limit: response.data.limit,
                  page: response.data.currentPage,
                  totalPages: response.data.totalPages,
                  response: response.data,
                  sosDetail: responseF.data['data'],
                  loading: false,
                  status: responseF.data['status'],
                  text: responseF.data['message']
                }
              });
              resolve();
            })
            .catch((e) => {
              dispatch({
                type: SOS_ERROR,
                payload: {
                  text: 'Error occurred during getting sos data.',
                  status: 'error',
                  loading: false
                }
              });
              resolve();
            });
        })
        .catch((e) => {
          dispatch({
            type: SOS_ERROR,
            payload: {
              text: 'Error occurred during deleting sos data.',
              status: 'error',
              loading: false
            }
          });
          resolve();
        });
    });
  };
};

export const sosClearShowSnackbar = () => {
  return (dispatch, getState) => {
    dispatch({
      type: SOS_CLEAR_SNACKBAR
    });
  };
};
