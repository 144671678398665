import React, { useState, useEffect } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
} from "@chakra-ui/react";

function CustomInput({ type, label, value, onChange,isDisabled, regex, submitted }) {
  const [error, setError] = useState("");

  useEffect(() => {
    if (submitted) {
      validateInput(value);
    }
  }, [value, submitted]);

  const validateInput = (inputValue) => {
    setError("");
    if (inputValue === "") {
      setError(`${label} is required.`);
    } else if (!regex.test(inputValue)) {
      if (type === "email") {
        setError("Invalid email format.");
      } else if (type === "number" && label === "Phone Number") {
        
        setError("Phone number must be 10 digit.");
      }
      else if (type === "number" && label === "Bus No") {
        
        setError("Bus number must be 4 digit.");
      }
       else {
        setError("Invalid input.");
      }
    }
  };
  

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    onChange(inputValue);
    validateInput(inputValue);
    if (submitted) {
      validateInput(inputValue);
    }
  };

  const handleBlur = () => {
    if (submitted) {
      validateInput(value);
    }
  };

  return (
    <FormControl isInvalid={!!error}>
      <FormLabel>{label}</FormLabel>
      <Input
        type={type}
        isDisabled={isDisabled}
        value={value}
        onChange={handleInputChange}
        onBlur={handleBlur}
      />
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
}

export default CustomInput;
